import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'
export const SContainer = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundBlack};
    position: relative;
    width: 100%;
    padding: ${theme.space.md + 4}px 0 ${theme.space.xl + 8}px;
    margin: 0 auto;

    ${respondTo.lg`
      padding: 0 0 60px;
    `}

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    .positionRight {
      position: absolute;
      top: 181px;
      right: -65px;
      z-index: 2;
      svg {
        width: 250px;
      }
    }
    .positionLeft {
      position: absolute;
      top: 75px;
      left: -42px;
      z-index: 2;
    }
  `}
`
