import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

import mobilePomeraniaEmployersBanner from '../../assets/images/pomerania-employers-banner-mobile.jpg'
import desktopPomeraniaEmployersBanner from '../../assets/images/pomerania-employers-banner-desktop.jpg'

export const SCompaniesList = styled.section`
  ${({ theme }) => css`
    padding-top: ${theme.space.md}px;
    .heading {
      display: inline-block;
      font-size: 2.62rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.6;
      color: white;
    }
    
    .simplebar-scrollbar::before {
        background-color: #d8d8d8;
        opacity: 1;
      }
      
       ::-webkit-scrollbar {
          display: none;
      }

      scrollbar-width: none;  
    }
        
    .banner-wrapper {
      display: grid;
    }
    
    .pomerania-banner {
      width: 100%;
      max-width: initial;
      min-height: 320px;
      height: initial;

      flex-direction: column-reverse;
      justify-content: space-between;

      background-image: url(${mobilePomeraniaEmployersBanner});
      background-position: center bottom;

      margin: ${theme.space.xl}px 0 ${theme.space.md}px;

      .header {
        flex: 1;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      p {
        padding-top: 20px;
        font-size: 1.22rem;
        line-height: 2.3rem;
      }
    }

    .filtration {
      overflow-y: auto;

      margin-top: ${theme.space.md}px;
      margin-bottom: ${theme.space.xl}px;
    }

    .content {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
    }
    
    .listing {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      grid-gap: 0 ${theme.space.md}px;
    }

    .loading {
      margin: ${theme.space.xl}px 0;
    }

    ${respondTo.lg`
      .heading {
        font-size: 4.25rem;
        padding-top: ${theme.space.xxxl}px;
      }
      
      .banner-wrapper {
        grid-template-columns: 272px 1fr;
        align-items: flex-end;
        margin-right: ${theme.space.md + 2}px;
        width: 100%;
      }
      
      .pomerania-banner {
        min-height: 122px;
        max-height: 122px;
        flex-direction: row;
  
        background-image: url(${desktopPomeraniaEmployersBanner});
  
        .header {
          p:first-child {
            max-width: 431px;          
            font-size: 1.45rem;
            line-height: 2rem;
  
            margin-bottom: ${theme.space.md}px;
          }
        }
  
        div {
          svg:first-of-type {
            width: 124px;
            height: 88px;
          }
  
          svg:last-of-type {
            width: 172px;
            height: 88px;
          }
        }
      }

      .right-side {
        max-width: 850px; 
      }

      .filtration {
        margin-top: 0;
        margin-bottom: 0;
        padding-right: ${theme.space.md}px;
      }

      .content {
        grid-template-columns: 272px 1fr;
        grid-template-rows: 1fr;

        margin-top: ${theme.space.xxxl}px;
        padding-bottom: ${theme.space.xxxl * 2}px;
      }

      .listing {
        grid-template-columns: repeat(2, minmax(200px, 1fr));
        grid-gap: ${theme.space.xxxl}px ${theme.space.md}px;
        
        margin-top: 0;
        margin-bottom: ${theme.space.xl}px;
      }
    `}

    ${respondTo.xl`  
      .pomerania-banner {
        .header {
          p:first-child {
            font-size: 1.75rem;
            line-height: 2.375rem;
          }
        } 
      }
           
      .listing {
        grid-template-columns: repeat(3, minmax(200px, 1fr));
      }
    `}

    ${respondTo.xxl`
      .listing {
        grid-template-columns: repeat(4, minmax(200px, 1fr));
      }
    `}
  `}
`
