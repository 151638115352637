import styled, { css } from 'styled-components'

export const SContainer = styled.div`
  flex: 1;
  white-space: nowrap;
`

export const SArrow = styled.div`
  ${({ theme }) =>
    css`
      position: absolute;
      width: 100%;

      &:after {
        position: absolute;
        right: 0;
        left: 0;
        content: '';
        width: 0;
        height: 0;
        margin: auto;
        border-bottom: ${theme.space.sm + 2}px solid ${theme.colors.darkestGray};
        border-left: ${theme.space.sm + 2}px solid transparent;
      }
    `}
`

export const STooltip = styled.div`
  ${({ theme }) =>
    css`
      font-weight: ${theme.weight.bold};
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px ${theme.space.md + 4}px;
      position: relative;
      color: #ffffff;
      z-index: 12;

      height: auto;

      font-size: 1rem;
      line-height: 1.5rem;
      word-wrap: break-word;
      background-color: ${theme.colors.darkestGray};

      &[data-popper-placement^='top'] {
        &::before {
          -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
        }
      }

      &[data-popper-placement^='top'] > ${SArrow} {
        bottom: 0;
        right: 0;
        margin-bottom: -0.35em;

        &::after {
          transform: rotate(180deg);
        }
      }

      &[data-popper-placement^='bottom'] > ${SArrow} {
        top: 0;
        margin-top: -0.3em;
        &::after {
          transform: rotate(225deg);
        }
      }
    `}
`
