import styled, { css } from 'styled-components'
import respondTo from 'theme/respondTo'

export const SNavigation = styled.div`
  ${({ theme, mobile }) => css`
    display: flex;
    align-items: center;

    margin-right: ${theme.space.xl}px;

    ${mobile &&
    css`
      margin-right: ${theme.space.md}px;

      .menu {
        li {
          margin-right: 0 !important;
        }
      }
    `}

    .menu {
      display: flex;
      align-items: center;

      li {
        margin-right: ${theme.space.lg}px;

        ${respondTo.xxl`
          margin-right: 60px;
        `}

        a,
        span {
          cursor: pointer;
          font-size: 1rem;
          font-weight: 600;

          &.active {
            color: ${theme.colors.navigation.link.hover};
          }

          text-decoration: none;
          color: ${theme.colors.navigation.link.black};

          &:hover {
            color: ${theme.colors.navigation.link.hover};
          }
          &.disabled-link {
            pointer-events: none;
            opacity: 0.4;
          }
        }
        a {
          &.disable-link {
            cursor: not-allowed;
            color: ${theme.colors.disabledLink};
            &:hover {
              color: ${theme.colors.disabledLink};
            }
          }
          &.inactive {
            font-weight: ${theme.weight.normal};
          }
        }
      }
      i {
        margin-left: ${theme.space.sm}px;
        padding-top: 4px;
      }
    }

    .language-picker {
      margin-left: 48px;
      margin-right: 28px;
      a {
        color: ${theme.colors.navigation.link.black};
        &:hover {
          color: ${theme.colors.navigation.link.hover};
        }
      }
    }
  `}
`
