import React, { useEffect } from "react"

function RecordingAgreement() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.location.href = "/recording-agreement.pdf"
    }
  }, []);

  return null;
}

export { RecordingAgreement }
