import * as React from 'react'

function Github(props) {
  return (
    <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle fill="#292929" cx={12.5} cy={12.5} r={12.5} />
        <path
          d="M0 12.239a12.261 12.261 0 008.545 11.625c.625.113.853-.262.853-.591v-2.08c-3.478.739-4.216-1.636-4.216-1.636a3.25 3.25 0 00-1.387-1.796c-1.136-.75.091-.738.091-.738.796.106 1.5.57 1.91 1.261a2.693 2.693 0 003.636 1.011 2.648 2.648 0 01.795-1.636c-2.772-.307-5.682-1.352-5.682-6.045a4.716 4.716 0 011.262-3.307 4.295 4.295 0 01.125-3.227s1.057-.33 3.409 1.25a12.136 12.136 0 016.25 0C17.977 4.75 19 5.08 19 5.08a4.295 4.295 0 01.125 3.227 4.716 4.716 0 011.33 3.284c0 4.704-2.932 5.739-5.682 6.045a2.84 2.84 0 01.852 2.273v3.352c0 .398.227.716.852.591A12.273 12.273 0 0025 12.24C24.925 5.409 19.33-.07 12.5 0 5.67-.07.075 5.408 0 12.239z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default Github
