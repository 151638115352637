import React from "react"
import CreatableSelect from "react-select/creatable"
import { useTheme } from "styled-components"

function CreatableMultiselect({ name, options, error, onChange, ...res }) {
  const theme = useTheme()

  const getBorderColor = state => {
    if (error) {
      return theme.colors.lightRed
    } else if (state.isFocused) {
      return "white"
    } else {
      return theme.colors.lightGray
    }
  }

  return (
    <CreatableSelect
      isMulti
      name={name}
      options={options}
      placeholder=""
      isClearable={false}
      onChange={onChange}
      styles={{
        control: (styles, state) => ({
          ...styles,
          backgroundColor: "transparent",
          borderRadius: 0,
          borderColor: getBorderColor(state),
          borderWidth: state.isFocused ? 2 : 1,
          boxShadow: "none",
          ":hover": {
            borderColor: theme.colors.lightGray,
          },
        }),
        indicatorSeparator: () => ({
          display: "none",
        }),
        valueContainer: styles => ({
          ...styles,
          padding: `2px ${theme.space.md - 2}px`,
        }),
        multiValue: styles => ({
          ...styles,
          borderRadius: 0,
          color: theme.colors.black,
          backgroundColor: "white",
          cursor: "pointer",
          ":hover": {
            color: theme.colors.pink,
          },
        }),
        singleValue: styles => ({
          ...styles,
          color: "white"
        }),
        multiValueLabel: styles => ({
          ...styles,
          color: "inherit",
        }),
        multiValueRemove: styles => ({
          ...styles,
          ":hover": {
            backgroundColor: "transparent",
          },
        }),
        menu: styles => ({
          ...styles,
          margin: 0,
          padding: 0,
          backgroundColor: theme.colors.gray2,
          boxShadow: "none",
          borderLeftWidth: 1,
          borderRightWidth: 1,
          borderBottomWidth: 1,
          borderStyle: "solid",
          borderColor: theme.colors.lightGray,
          borderRadius: 0,
        }),
        menuList: styles => ({
          ...styles,
          padding: theme.space.sm / 2,
        }),
        option: (styles, state) => ({
          ...styles,
          height: 48,
          display: "flex",
          alignItems: "center",
          fontSize: "1rem",
          fontWeight: state.isFocused ? theme.weight.bold : theme.weight.normal,
          backgroundColor: state.isFocused
            ? theme.colors.lightPink
            : "transparent",
          color: state.isFocused
            ? theme.colors.darkerGray
            : theme.colors.lightGray,
          ":hover": {
            backgroundColor: theme.colors.lightPink,
            color: theme.colors.darkerGray,
            fontWeight: theme.weight.bold,
          },
        }),
      }}
      {...res}
    />
  )
}

export default CreatableMultiselect
