import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Helmet } from 'react-helmet'

import client from '../api/client'

import { OfferDetails } from 'sections'
import useLocalizeRoute from 'utils/useLocalizeRoute'
import { AppRoute } from 'const'

function Offer() {
  const { slug } = useParams()

  const history = useHistory()
  const { localizeRouteKey } = useLocalizeRoute()

  const [offer, setOffer] = useState(null)
  const [loading, setLoading] = useState(true)

  const { formatMessage, locale } = useIntl()

  const fetchOffer = async () => {
    setLoading(true)

    try {
      const response = await client({
        endpoint: `api/offers/get_by_slug?offer_slug=${slug}&locale=${locale}`,
      })

      if (!response.ok) {
        throw new Error(await response.json())
      }

      const { data } = await response.json()
      if (data && Array.isArray(data) && data.length) {
        const [offer] = data

        setOffer(offer)
        setLoading(false)
        return
      }

      setLoading(false)
      history.replace(localizeRouteKey(AppRoute.NotFound))
    } catch (error) {
      setLoading(false)
      history.replace(localizeRouteKey(AppRoute.NotFound))
      console.log('[GET] offer details error: ', error)
    }
  }

  useEffect(() => {
    fetchOffer()
  }, [locale])

  return (
    <>
      <Helmet>
        <title>
          {formatMessage(
            { id: 'seoTitleOffer' },
            { name: offer?.offer_title ?? '' }
          )}
        </title>
        <meta
          name="description"
          content={formatMessage(
            { id: 'seoDescriptionOffer' },
            { name: offer?.offer_title ?? '' }
          )}
        />
        <meta
          name="keywords"
          content={formatMessage(
            { id: 'seoKeywordsOffer' },
            { name: offer?.offer_title ?? '' }
          )}
        />
        <meta
          property="og:title"
          content={formatMessage(
            { id: 'seoTitleOffer' },
            { name: offer?.offer_title ?? '' }
          )}
        />
        <meta
          property="og:site_name"
          content={formatMessage(
            { id: 'seoTitleOffer' },
            { name: offer?.offer_title ?? '' }
          )}
        />
        <meta
          property="og:description"
          content={formatMessage(
            { id: 'seoDescriptionOffer' },
            { name: offer?.offer_title ?? '' }
          )}
        />
        <meta name="twitter:image:src" content={''} />
        <meta
          name="twitter:title"
          content={formatMessage(
            { id: 'seoTitleOffer' },
            { name: offer?.offer_title ?? '' }
          )}
        />
        <meta
          name="twitter:description"
          content={formatMessage(
            { id: 'seoDescriptionOffer' },
            { name: offer?.offer_title ?? '' }
          )}
        />
      </Helmet>
      <OfferDetails {...{ offer, loading }} />
    </>
  )
}

export { Offer }
