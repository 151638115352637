import React from 'react'
import { useIntl } from 'react-intl'

import { SlideTop, Social } from '../../components'
import { SContainer } from './TeamCE.styled'

import teamCE from '../../assets/images/team-ce.png'
import TwitterIcon from '../../assets/icons/TwitterIcon'
import LinkedinIcon from '../../assets/icons/LinkedinIcon'
import FacebookIcon from '../../assets/icons/FacebookIcon'
import { useBreakpoint } from '../../modules/breakpoint'

function TeamCE() {
  const { formatMessage } = useIntl()
  const breakpoint = useBreakpoint()
  const isMobile = breakpoint.lg
  const renderHeading = () => (
    <h2 className="team-ce-heading">
      {formatMessage({ id: 'teamCEHeading' })}
    </h2>
  )

  return (
    <SContainer>
      <div className="wrapper">
        <SlideTop>
          <div className="content">
            {isMobile ? renderHeading() : null}
            <img src={teamCE} alt="" />
            <div className="right-side">
              {!isMobile ? renderHeading() : null}
              <p className="subheader">
                {formatMessage({ id: 'teamCESubheader' })}
              </p>
              <p className="description">
                {formatMessage(
                  {
                    id: 'teamCEDescription',
                  },
                  { br: <br /> }
                )}
              </p>
              <hr className="divider-horizontal" />
              <div className="socials">
                <h2 className="socials-heading">
                  {formatMessage({ id: 'followUsAboutUsHeading' })}
                </h2>
                <div className="socials-container">
                  <Social
                    small
                    href="https://twitter.com/code_europe"
                    target="_blank"
                  >
                    <TwitterIcon />
                  </Social>
                  <Social
                    small
                    href="https://www.linkedin.com/showcase/codeeurope/"
                    target="_blank"
                  >
                    <LinkedinIcon />
                  </Social>
                  <Social
                    small
                    href="https://www.facebook.com/codeeurope"
                    target="_blank"
                  >
                    <FacebookIcon />
                  </Social>
                </div>
              </div>
            </div>
          </div>
        </SlideTop>
      </div>
    </SContainer>
  )
}

export default TeamCE
