import * as React from 'react'

function Link(props) {
  return (
    <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle fill="#FFF" cx={12.5} cy={12.5} r={12.5} />
        <g fill="#292929" fillRule="nonzero">
          <path d="M13.414 10.58l-2.834 2.834a.708.708 0 000 1.006.708.708 0 001.006 0l2.834-2.834a.711.711 0 10-1.006-1.006z" />
          <path d="M12.698 16.325l-.906.9a2.975 2.975 0 01-3.953.283 2.833 2.833 0 01-.191-4.2l1.006-1.013a.708.708 0 000-1.006.708.708 0 00-1.006 0l-.9.906a4.356 4.356 0 00-.474 5.717 4.293 4.293 0 006.424.425l1.006-1.006a.711.711 0 10-1.006-1.006zM17.926 6.28a4.377 4.377 0 00-5.759.482l-.765.78a.772.772 0 00-.12 1.14.708.708 0 001.005 0l.921-.928a2.954 2.954 0 013.946-.283 2.833 2.833 0 01.191 4.214l-1.006 1.013a.708.708 0 000 1.006.708.708 0 001.006 0l1.006-1.006a4.292 4.292 0 00-.425-6.417z" />
        </g>
      </g>
    </svg>
  )
}

export default Link
