import React, { useEffect, useState } from 'react'

import { ButtonLink, Loader } from '../../components'
import { SNewsletterUnsubscribe } from './NewsletterUnsubscribe.styled'
import logo from '../../assets/images/logo.png'

import config from 'const/config'

import {
  SUBSCRIBED_RESULT,
  UNSUBSCRIBED_RESULT,
  FAILED_RESULT,
} from '../Newsletter/Newsletter'
import { useIntl } from 'react-intl'

const IDLE_STATUS = 'IDLE_STATUS'
const PENDING_STATUS = 'PENDING_STATUS'
const SUCCESS_STATUS = 'SUCCESS_STATUS'
const ERROR_STATUS = 'ERROR_STATUS'

function NewsletterUnsubscribe() {
  const [status, setStatus] = useState(IDLE_STATUS)
  const { formatMessage } = useIntl()

  useEffect(() => {
    const unsub = async () => {
      try {
        const params = new URLSearchParams(window.location.search)
        const token = params.get('token')

        if (!token) {
          setStatus(ERROR_STATUS)
          return
        }

        setStatus(PENDING_STATUS)
        const response = await fetch(config.ABSOLVENT_API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: `
                mutation UnsubscribeNewsletter($input: NewsletterInputType) {
                    unsubscribeNewsletter(input: $input){
                        subscriber_email,
                        subscription_status,
                        error
                    }
                }
            `,
            variables: {
              input: {
                mailing_list: config.MAILING_LIST,
                unsubscribe_token: token,
              },
            },
          }),
        })

        const {
          data: {
            unsubscribeNewsletter: { subscription_status, error },
          },
          errors: graphqlErrors,
        } = await response.json()
        if (subscription_status === FAILED_RESULT || graphqlErrors) {
          throw new Error(error || 'Unsubscribe failed without error')
        }

        setStatus(SUCCESS_STATUS)
      } catch (error) {
        console.log('newsletter unsubscribe error', error)
        setStatus(ERROR_STATUS)
      }
    }
    unsub()
  }, [])

  const renderLoader = () => (
    <div>
      <Loader size={6} width={64} height={64} />
    </div>
  )

  const renderError = () => (
    <div className="content">
      <p className="heading">
        {formatMessage({ id: 'newsletterUnsubErrorHeading' })}
      </p>
      <p
        className="description mail"
        dangerouslySetInnerHTML={{
          __html: formatMessage({ id: 'newsletterUnsubErrorDescription' }),
        }}
      />
      <ButtonLink as="a" href="/">
        {formatMessage({ id: 'newsletterUnsubButton' })}
      </ButtonLink>
    </div>
  )

  const renderSuccess = () => (
    <div className="content">
      <p className="heading">
        {formatMessage({ id: 'newsletterUnsubSuccessHeading' })}
      </p>
      <p className="description">
        {formatMessage({ id: 'newsletterUnsubSuccessDescription' })}
      </p>
      <ButtonLink as="a" href="/">
        {formatMessage({ id: 'newsletterUnsubButton' })}
      </ButtonLink>
    </div>
  )

  const renderContent = () => {
    switch (status) {
      case SUCCESS_STATUS:
        return renderSuccess()
      case ERROR_STATUS:
        return renderError()
      case IDLE_STATUS:
      case PENDING_STATUS:
      default:
        return renderLoader()
    }
  }

  return (
    <SNewsletterUnsubscribe>
      <div className="header">
        <img src={logo} />
      </div>
      {renderContent()}
    </SNewsletterUnsubscribe>
  )
}

export default NewsletterUnsubscribe
