import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'
export const SContainer = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundBlack};
    position: relative;
    z-index: 0;
    
    ${respondTo.lg`
        padding: ${theme.space.xl + 10}px 0 56px;
     `}

    .content {
      max-width: 1190px;
      background-color: white;
      position: relative;
      padding: ${theme.space.xl + 8}px 0;
      
      &::before {
        ${respondTo.lg`
            content: "";
            width: 100%;
            height: 100%;
    
            position: absolute;
            background-color: ${theme.colors.darkGray};
    
            top: 27px;
            right: 25px;
    
            z-index: -1;
        `}
      }
      
      ${respondTo.lg`
        margin: 0 auto;
        padding: ${theme.space.xl + 8}px ${theme.space.lg}px;
      `}
    }
    
    .triangle {
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0.3;
      border-style: solid;
      border-width: 102px 102px 0 0;
      border-color: #BDBDBD transparent transparent transparent;
      
      display: none;
      
      ${respondTo.lg` 
          &:first-of-type {
            display: block;
            top:0;
            left: 10%;
          }
          &:nth-of-type(2) {
            display: block;
            top: 50%;
            left: 0;
          }
          &:nth-of-type(3) {
            display: block;
            transform: rotate(90deg);
            right: 0;
            top: 30%;
          }
          &:nth-of-type(4) {
            display: block;
            transform: rotate(90deg);
            right: 0;
            top: 0;
            border-color: ${theme.colors.backgroundBlack} transparent transparent transparent;
            opacity: 1;
          }
      `}

      &:nth-of-type(5) {
        display: block;
        transform: rotate(90deg);
        right: 0;
        top: 0;
        border-width: 50px 50px 0 0;
        border-color: ${
          theme.colors.backgroundBlack
        } transparent transparent transparent;
        opacity: 1;
        
        ${respondTo.lg` 
            display: none;
        `}
      }
    }

    .heading {
      text-align: center;
      font-size: 1.5rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.875rem;
      color: ${theme.colors.black};

      ${respondTo.lg`
        font-size: 2.25rem;
        line-height: 3.125rem;
      `}
    }

    .button-wrapper {
      display: flex;
      justify-content: center;
      margin: ${theme.space.xl * 2}px auto 0;
        
      i {
        padding-top: 4px;
        margin-left: 8px;
      }
    }
    
    .row {
      display: flex;
      position: relative;

      ${respondTo.xxs`
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -23px;
            width: calc(100% + 46px);
            height: calc(100% + 1px);
            border-top: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
        }
      `}
      ${respondTo.lg`
        &:before {
            width: calc(100% + 100px);
            left: -50px;
        }
      `}
    }
    
    .category {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-title {
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 1.56rem;
        color: ${theme.colors.black};
        text-align: center;

        margin: ${theme.space.xl + 8}px 0 ${theme.space.sm}px;

        ${respondTo.lg`
           font-size: 1.375rem;
           line-height: 1.875rem;
        `}
      }
    }

    .partner-type {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 400px;
      
      ${respondTo.lg`
        max-width: 835px;
      `}
     
      &-single {
        border: 1px solid #e0e0e0;
        margin:0 -1px -1px 0;
        display: inline-block;
      }

        &-logo {
          width: 103px;
          height: 51px;

          display: flex;
          justify-content: center;
          align-items: center;
          
          ${respondTo.lg`
            width: 166px;
            height: 83px;
          `}
        }

        img {
          max-width: 79px;
          max-height: 27px;

          ${respondTo.lg`
            max-width: 128px;
            max-height: 43px;
          `}
        }
      }
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  
    .positionLeft {
      position: absolute;
      top: 30%;
      left: 0;
    }
    .positionRight {
      position: absolute;
      top: 60%;
      right: 0;
    }
  `}
`
