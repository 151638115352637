import styled, { css } from 'styled-components'

export const STicketProcessInput = styled.div`
  ${({ theme, error }) => css`
    label {
      display: flex;
      justify-content: space-between;

      color: white;

      margin-top: ${theme.space.md}px;
      margin-bottom: ${theme.space.sm}px;

      .optional,
      .count {
        color: ${theme.colors.lightGray};
      }

      .count {
        font-size: 0.75rem;
        display: inline-block;
      }
    }

    [type='text'],
    [type='password'] {
      width: 100%;
      height: 48px;

      color: white;

      padding: 0 ${theme.space.sm}px;

      border: 1px solid ${theme.colors.lightGray};
      background: none;

      &:focus {
        outline: none;
        border-color: white;
      }

      &:disabled {
        color: ${theme.colors.lightGray};
        background-color: rgba(165, 165, 165, 0.2);
      }
    }

    ${error &&
    css`
      [type='text'],
      [type='password'] {
        border-color: ${theme.colors.lightRed};

        &:focus {
          border-color: ${theme.colors.lightRed};
        }
      }
    `}

    .description {
      font-size: 0.75rem;
      color: ${theme.colors.lightGray};
      margin-top: ${theme.space.sm}px;

      &.pre {
        font-size: 0.87rem;
        line-height: 1.14;
        margin-bottom: ${theme.space.sm}px;
      }

      &.error {
        font-size: 0.87rem;
        color: ${theme.colors.lightRed};
      }
    }
  `}
`
