import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'
import background from '../../assets/images/net-left-background.svg'

export const SContainer = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundBlack};
    padding: ${theme.space.xl + 8}px ${theme.space.lg}px;

    ${respondTo.lg`
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(${background});
      padding: 80px ${theme.space.lg}px;
    `}

    .content {
      ${respondTo.lg`
        max-width: 1072px;
        margin: 0 auto;
      `}
    }
    .heading {
      color: ${theme.colors.white};
      font-weight: ${theme.weight.bold};
      font-size: 1.5rem;
      line-height: 1.875rem;
      text-align: center;

      ${respondTo.lg`
        font-size: 2.25rem;
        line-height: 3.125rem;
        max-width: 600px;
        margin: 0 auto;
      `}
    }

    .subheader {
      color: ${theme.colors.lightGray2};
      font-size: 1.125rem;
      line-height: 1.56rem;
      text-align: center;
      margin: ${theme.space.md + 4}px auto ${theme.space.lg + 4}px;

      ${respondTo.lg` 
        font-size: 1.375rem;
        line-height: 1.875rem;
        margin: ${theme.space.md + 4}px auto 60px;
        max-width: 584px;
      `}
    }
  `}
`
