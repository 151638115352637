import * as React from "react"

function UploadIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 35 26">
      <g fill="none" fillRule="evenodd">
        <g stroke="#FC86F6" strokeWidth="2">
          <g>
            <g>
              <path strokeLinecap="round" d="M30.3 18.2c1.1-1.1 1.8-2.6 1.8-4.2 0-2.2-1.2-4.1-3-5.2-.1-3.2-2.8-5.8-6-5.8-.4 0-.8 0-1.2.1C20.5 1.2 18.4 0 16 0c-3.2 0-6 2.3-6.8 5.3C8 5.8 6.9 6.8 6.4 8 2.9 7.7 0 10.5 0 14c0 3.3 2.7 6 6 6h7.1" transform="translate(-824 -2524) translate(585 2440) translate(240 85)"/>
              <path d="M13.1 7.6L25.8 7.6 25.8 24 13.1 24z" transform="translate(-824 -2524) translate(585 2440) translate(240 85)"/>
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.9 16.1l3.5-3.5 3.6 3.5m-3.6 4.3v-7.8" transform="translate(-824 -2524) translate(585 2440) translate(240 85)"/>
            </g>
          </g>
        </g>
      </g>
    </svg>

  )
}

export default UploadIcon
