import styled, { css } from 'styled-components'

export const STicketPriceSummary = styled.div`
  ${({ theme }) => css`
    font-size: 0.875rem;
    color: white;

    p {
      display: flex;
      align-items: baseline;
      justify-content: space-between;

      margin-top: ${theme.space.lg}px;
    }

    .discount {
      .highlight {
        color: ${theme.colors.green};
      }
    }

    .price {
      .highlight {
        font-size: 1.25rem;
        font-weight: ${theme.weight.bold};
        line-height: 1.6;
      }
    }
  `}
`
