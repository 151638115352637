import { LanguageStrings } from './base-strings'

export const en: LanguageStrings = {
  /** Routes */
  'routes.home': '/',
  'routes.about_us': '/about-us',
  'routes.become_partner': '/become-a-partner',
  'routes.become_speaker': '/become-a-speaker',

  'routes.speaker': '/speakers/:slug',
  'routes.speakers': '/speakers',
  'routes.lecture_details_form': '/lecture-details',
  'routes.speaker_details_form': '/speaker-details',

  'routes.buy_ticket': '/buy-ticket',
  'routes.buy_ticket_success': '/register-success',
  'routes.buy_ticket_group_success': '/buy-ticket-success',

  'routes.agenda_archive': '/agenda-archive/:year',
  'routes.agenda': '/agenda',

  'routes.company': '/companies/:slug',
  'routes.companies': '/companies',

  'routes.offers': '/job-offers',
  'routes.offer': '/job-offers/:slug',

  'routes.cookies_policy': '/cookies-policy',
  'routes.privacy_policy': '/privacy-policy',

  'routes.code_of_conduct': '/code-of-conduct',
  'routes.personal_data_processing': '/personal-data-processing',
  'routes.recording_agreement': '/recording-agreement',

  'routes.list_of_entities_employer': '/wykaz-podmiotow-pracodawca',
  'routes.list_of_entities_employer_outside_eog':
    '/wykaz-podmiotow-pracodawca-poza-eog',
  'routes.list_of_entities_user': '/wykaz-podmiotow-uzytkownik',
  'routes.list_of_entities_user_outside_eog':
    '/wykaz-podmiotow-uzytkownik-poza-eog',

  'routes.clients_regulations_pl': '/regulamin-dla-klientow',
  'routes.clients_regulations_en': '/general-terms-of-service',

  'routes.user': '/profile',
  'routes.user_regulations': '/regulamin-code-europe',

  'routes.contest': '/contest',
  'routes.kubota_contest': '/konkurs-kubota',
  'routes.covid_statement': '/oswiadczenie-covid',

  'routes.unsubscribe': '/unsubscribe',
  'routes.sign-in': '/sign-in',
  'routes.logout': '/logout',
  'routes.forgot_password': '/forgot-password',
  'routes.reset_password': '/reset-password',

  'routes.not-found': '/404',

  topBarCoOrganizer: 'Strategic partner',
  heroHeading: 'Code Europe 2024',
  heroHomePageHeading:
    'Join <span>the biggest</span> tech {br} conference in Poland',
  heroAdditionalSubHeading: 'Connecting the world of Software Development',
  heroHomePageCta: "Join Poland's biggest tech festival in 2025:",
  speakersHeroHeading: '<span>Become a speaker</span> at Code Europe 2025',
  heroPartnersHeading:
    '<span>Code Europe</span>{br}Poland’s biggest IT festival',
  heroPartnersButtonText: 'Want to buy a ticket?',
  speakersHeroSubHeading:
    'Share your knowledge of programming languages{br}or present innovative technological solutions.',
  speakersOfAllTimeHeading:
    'Top Code Europe Speakers{br}<span>Of All Time</span>',
  speakersOfAllTimeSubHeading:
    'We are committed to providing participants with the highest quality speeches offered by world-class experts, among whom we count you. We had the pleasure to host in the past such great speakers, naming only a few.',
  callForPapersHeading:
    '<span>Call for Papers</span>{br}Join Code Europe as a speaker',
  callForPapersSubheader:
    "Join Poland's biggest IT festival as a speaker and set Code Europe's participants on fire!",
  heroAboutUsHeading:
    'Connecting the world of <span>Software Development</span>',
  heroAboutUsSubheader:
    "Code Europe is Poland's <span>biggest IT festival</span> gathering experts from various Tech backgrounds and countries for one week in several Polish cities.",
  heroAboutUsDescription:
    "Code Europe's purpose is to connect the world of Software Development, bringing together speakers and Tech experts from around the world, with different backgrounds and expertise. We want Code Europe to become the leading IT Festival focused on the full circle of Software Development in Europe.",
  contactUsHeading: 'Contact Us. <span>We’re happy to talk to you!</span>',
  contactUsParticipants: 'Participants - general questions',
  contactUsFaq: 'or maybe our FAQs could help?',
  contactUsCommunities: 'Communities',
  contactUsPartnerships: 'Partnerships & others',
  contactUsPartners: 'Partners',
  contactUsJoinPartner: 'want to join Code Europe as a Partner?',
  contactUsMedia: 'Media - general inquiries',
  contactUsSpeakers: 'Speakers,',
  contactUsJoinSpeaker: 'Want to join Code Europe as a speaker?',

  placeToBeForHeadingDesktop: 'Code Europe is <span>the place to be for</span>',
  placeToBeForHeadingMobile: 'Code Europe is <span>the place to be</span>',
  teamCEHeading: 'Team Code Europe',
  teamCESubheader:
    'The team behind Code Europe is a group of people that are inspired by technology, especially when it comes to Software Development, loving to bring people together. This is why we launched Code Europe in 2016.',
  teamCEDescription:
    "What sets Code Europe apart is the team's focus on creating a positive experience for their attendees. From the quality of the sessions and workshops to the overall atmosphere of the event, everything is designed with the goal of making attendees feel inspired and excited about the world of software development.{br}{br}The team's passion and dedication are reflected in the positive feedback they receive from participants. Knowing that their efforts have made a difference in the lives and careers of others is what motivates them to continue working tirelessly to make each edition of Code Europe even better than the last.",
  heroTicketsSalesStartSoon: 'Tickets available soon!',
  followUsHeading: 'Follow us and be the first to find out',
  followUsAboutUsHeading: 'Follow us on social media',
  tracksAtCE: 'Tracks at Code Europe',
  topicPathsHeading: 'Code <span>Europe</span> Tech Festival',
  tracksHeading: 'Connecting the world of Software Development',
  tracksPartnersHeading: 'Tracks',
  tracksDescription:
    "Code Europe strikes back with a huge success of the previous editions but we haven't said the last word yet. Check out the tracks which will be covered during upcoming Code Europe!",
  tracksPartnersDescription:
    "Code Europe's purpose is to connect the world of Software Development. That's why we have 6 tracks, with more than 70 lectures and workshops - that's a lot of knowledge and participants with different skills!",
  topicPathsDescription:
    "Code Europe strikes back with a huge success of the previous editions but we haven't said the last word yet. Check out the tracks which will be covered during upcoming Code Europe!",
  partnersHeading: 'Code Europe <span>Partners</span>',
  partnersButton: 'Attract and recruit Top Tech Talent',
  partnersButtonMobile: 'Recruit Top Tech Talent',
  partnersCoorganizer: '<span>Co-organizer:</span>',
  partnersCity: '<span>City</span> partner Warsaw:',
  partnersSocial: '<span>Community</span> partner:',
  topPartnersHeading: 'Top <span>Partners</span>',
  topPartnersSubheader:
    "Attract and Recruit Top Tech Talent at Poland's leading Tech&IT festival.  Be close to your target group - other TOP brands are already doing it.",
  topPartnersRating: 'Average Rating',
  topPartnersRatingSurveys: 'based on 36 partner surveys',
  whyAttendHeading: 'Why attend Code Europe?',
  whyAttendDescription:
    'Code Europe is Poland’s biggest IT festival gathering experts from various Tech backgrounds and continents for one week in Cracow.',
  whyAttendKnowledge: 'Top Notch Knowledge',
  whyAttendKnowledgeDescription:
    'Code Europe gathers top speakers from around the world - learn from the best!',
  whyAttendConnect: 'Connect and Share Knowledge',
  whyAttendConnectDescription:
    'Meet like minded people, exchange ideas and be on top of best practices and future trends.',
  whyAttendGrowth: 'Accelerate Growth',
  whyAttendGrowthDescription:
    'Connect with companies and explore opportunities in the industry.',
  numbersHeading: 'Code Europe <span>in numbers</span>',
  whyAttendPartnersHeading:
    'Why should my company join <span>Code Europe?</span>',
  whyAttendPartnersDescription:
    "Achieve your recruitment and employer branding goals with Code Europe, Poland's biggest IT festival.",
  whyAttendAttract: 'Attract and Recruit{br}the best IT specialists',
  whyAttendAttractDescription:
    'During Code Europe we guarantee you access to  thousands of IT professionals.',
  whyAttendEB: 'Build your Employer Brand{br}among your target group',
  whyAttendEBDescription:
    'Connect with the Tech community and show your Employer Brand to a large, yet cohesive target group.',
  whyAttendSpectrum: 'Comprehensive spectrum{br}of the Tech industry',
  whyAttendSpectrumDescription:
    'Code Europe is all about connecting the world of software development. We focus on professionals with various specializations.',
  whyAttendCandidates: 'Valuable candidate{br}Data Base',
  whyAttendCandidatesDescription:
    "Get access to candidates' profiles that you interacted with during Code Europe.",
  whyAttendEvent: 'One of the leading IT{br}events in Europe',
  whyAttendEventDescription:
    "Be part of an event that enjoys the IT community's esteem. Show your company among leading brands in the Tech industry.",
  whyAttendMarketing: 'Multi channel{br}marketing campaign',
  whyAttendMarketingDescription:
    'By becoming a partner of Code Europe enjoy being part of a large and multichannel marketing campaign targeted to IT professionals. Increase your Employer Brand awareness.',
  joinCeNow: 'Join Code Europe now',
  ceInNumbersHeading: 'Code Europe <span>in numbers</span> so far',
  numbersDescription:
    'Code Europe is a conference devoted to the most important trends in the IT world. We invite people who want to develop using the latest technologies under the guidance of international specialists and outstanding practitioners.',
  numbersUsersHeading: '10 000 participants',
  ceInNumbersParticipants: '<span>+18 439</span> Participants',
  numbersUsersDescription: 'IT talents from Central and Eastern Europe',
  numbersSpeakersHeading: '150 speakers from 16 countries',
  numbersSpeakersDescription:
    'IT world stars and CEOs, managers of top technology companies',
  numbersExhibitorsHeading: 'more than 150 top exhibitors',
  numbersLecturesHeading: '245 lectures and workshops',
  ceInNumbersWorkshops: '<span>+467</span> Workshops',
  ceInNumbersSpeakers: '<span>+401</span> Top Speakers',
  ceInNumbersPartners: '<span>+372</span> Partners',
  numbersLecturesDescription:
    "from Big Data to Software Engineering we deal with everything that is important for today's IT industry",
  speakersHeading: 'Meet speakers from{br}<span>Code Europe 2024</span>',
  showAllSpeakers: 'Show all Speakers',
  speakersCompaniesHeading: 'From <span>Top Tech</span>{br}organizations',
  speakersCompaniesInformation: '* data from previous Code Europe editions',
  speakersCompaniesInformationShort: '* data from Code Europe 2018',
  workshopsHeading: '<span>Workshops</span><span>&</span><span>lectures</span>',
  workshopsDescription:
    'Develop your practical skills in new areas or improve the knowledge you already have. Learn from world-class IT professionals.',
  workshopsStaticInformation:
    'What to expect from attending the conference? See inspiring lectures by leading technology companies from previous editions.',
  joinPartnersDataInformation: 'data from previous Code Europe editions',
  joinPartnersButton: 'Join as a Partner',
  joinSpeakersButton: 'Join as a Speaker',
  partnersBannerHeading:
    'Join the leaders of the IT industry at{br}<span>Code Europe 2025 Tech Festival</span>',
  partnersBannerSubHeading:
    'Get in touch with us today and partner with us in connecting the world of software development.',
  partnersBannerSubHeadingMobile:
    "Achieve your recruitment and employer branding goals with Code Europe, Poland's biggest IT festival.",
  latestEditionsHeading:
    '<span>Check the latest editions</span>{br}of Code Europe',
  aftermovieHeading:
    'Check the aftermovie of the <span>Code Europe’s latest edition!</span>',
  LecturesAndRecordings: 'Lectures and recordings',
  PhotoGalleries: 'Photo galleries',
  VideoSummary: 'Video summary',
  volunteersBannerHeading:
    '👋 Want to join as a{br}<span>Code Europe Volunteer?</span>',
  volunteersBannerHeadingMobile:
    '👋 Want to join as a{br}<span>Code Europe Volunteer</span>',
  graphsHeading:
    '<span>The best IT</span><span>specialists and</span><span>managers</span> all in one place!',
  graphExpHeading: "Participants' professional experience",
  graphExpOver5Years: 'more than 5 years',
  graphExp2To5Years: '3 to 5 years',
  graphExpTo2Years: '2 to 3 years',
  graphExpTo1Year: 'up to 1 year',
  graphSpecHeading: "Participants' specializations",
  graphSpecDevs: 'Developer / Programmer',
  graphSpecAdmins: 'DevOps & SysOps',
  graphSpecAnalysts: 'Analyst',
  graphSpecEngineers: 'Engineer',
  graphSpecManagers: 'Manager',
  graphSpecTesters: 'Tester',
  graphSpecOther: 'Other',
  partnerTweetsDescription:
    'Code Europe is an innovative formula that gathers all the tech professionals, potential members of your team, business partners and your future clients in one place.',
  partnerJoinHeading:
    'Join the <span>leaders of the</span><span> IT industry</span> at Code Europe 2024 Tech Festival',
  speakersJoinHeading: 'Are you <span>the one?</span>',
  partnerJoinDescription:
    'Contact us today and become a Code Europe 2024 Partner!',
  speakersJoinDescription:
    "If this year's theme of Code Europe is close to your heart - apply by filling out the form at the bottom of the page.",
  speakersJoinPhotoDescriptionTitleSpecialists: 'Professionals',
  speakersJoinPhotoDescriptionSpecialists:
    'Code Europe stands out{br}thanks to its excellent speakers{br}that are selected among{br}world-class specialists as well as{br}creators and users of pioneer{br}technological solutions.',
  speakersJoinPhotoDescriptionTitleContent: 'Content',
  speakersJoinPhotoDescriptionContent:
    'Thanks to them, the conference{br}program is of the highest{br}substantive level and participation{br}in the event is obligatory for every{br}enthusiast of the IT industry.',
  followUsAndFindOutFirst:
    '<span>Follow us</span> for all the latest from Code Europe',
  newsletterHeading:
    '<span>Join our newsletter</span> to be firstly informed about next edition!',
  newsletterPlaceholder: 'Your e-mail address',
  newsletterSubscribe: 'Register',
  newsletterError: 'You entered an incorrect e-mail address.',
  newsletterSuccess: 'Check your inbox to activate the newsletter.',
  newsletterPolicy:
    'By providing your e-mail address, you accept the <a>Privacy policy</a>.',
  faqNavigation: 'FAQ',
  faqHeading: 'Frequently Asked Questions',
  faqLocationQuestion: 'Where and When does Code Europe 2025 take place?',
  faqLocationAnswer:
    'Code Europe 2025 will take place in Cracow, Poland, a vibrant technological metropoly.{br}{br}<span>Cracow</span>: June 30th of June - 1st of July 2025, ICE Kraków Congress Center.',
  faqTimeQuestion: 'When do the event start and end?',
  faqTimeAnswer:
    'Registration will be open at 8.40 AM on the first day of the event. The official Code Europe opening will start at 9:30AM. The last session is scheduled for around 4:00 PM. The second day will start at 10:00 AM and the last session is planned for around 4:00 PM also.{br}{br}Last but not least, after the first day of Code Europe a proper After Party will be organized, for all speakers, partners and participants.',
  faqLineUpQuestion: 'Where can I check the speaker line-up?',
  faqLineUpAnswer:
    'If you would like to check the speakers’ line up, please visit <a>https://www.codeeurope.pl/en/speakers#Speakers Page</a>. The list is being regularly updated with confirmed speakers. This means that the list of attending speakers is growing constantly. We’re expecting more than 100 speakers at Code Europe 2025!',
  faqAgendaQuestion: 'Where can I see the Agenda?',
  faqAgendaAnswer:
    'The agenda from 2025 is available <a>https://www.codeeurope.pl/en/agenda#here</a>.{br}{br}Depending on the period of the year, it is being regularly updated until the beginning of Code Europe Tech Festival. All of the content is fully available a week before the start.',
  faqRecordingsQuestion: 'Are sessions being recorded and available online?',
  faqRecordingsAnswer:
    'It depends on the willingness and permission of the Speaker, but most of Code Europe’s lectures are being recorded and then available online at <a>https://www.youtube.com/@CodeEurope#Code Europe’s Youtube channel</a>.',
  faqHotelsQuestion: 'Do you recommend any specific hotels?',
  faqHotelsAnswer:
    'Yes, we have a special promo code for the most convenient hotels for each city.{br}{br}In <span>Cracow</span>, we can recommend the Q Hotel Cracow that is in close proximity to the Code Europe venue. You can use a <a>https://bit.ly/41EP7ai#link</a> to receive a <span>discount code</span> throughout Code Europe.{br}{br}In <span>Warsaw</span>, the closest and the best hotel for the Code Europe accommodation is Hilton Warsaw City. It is very convenient to just go downstairs and take part in Code Europe. If you want to use our discount code, click <a>https://bit.ly/3Apb6pK#here</a>.',
  faqTicketsQuestion: 'Where can I buy tickets?',
  faqTicketsAnswer:
    'The tickets can be purchased online at <a>https://www.codeeurope.pl/en/buy-ticket#Code Europe’s website</a> or with the direct help of our <a>mailto:kontakt@codeeurope.pl#Sales Team.</a>',
  faqRegistrationQuestion: 'Do I have to register to buy a ticket?',
  faqRegistrationAnswer:
    'Yes, all participants need to register. The registration process is quick and easy.',
  faqTeamQuestion: 'Can I buy tickets for my team?',
  faqTeamAnswer:
    'It is possible to buy a group ticket that includes 2 pax at least. After that, you will be provided with a unique code that can be used multiple times by different people - depending on how many tickets you bought. For example, you will receive a code for 10 uses and 10 people can register using that code.',
  faqTicketPassQuestion: 'Can I pass the ticket to someone else?',
  faqTicketPassAnswer:
    'Regarding a group ticket - it is possible to buy a group ticket that includes 2 pax at least. After that, you will be provided with a unique code that can be used multiple times by different people - depending on how many tickets you bought. For example, you will receive a code for 10 uses and 10 people can register using that code. When it comes to the single ticket, it is not possible to pass the ticket that was registered for a specific person.',
  faqSingleTicketQuestion: 'Can I buy a single ticket for someone else?',
  faqSingleTicketAnswer:
    'No, it is not possible to buy a single ticket for someone else. If you need to buy a single ticket for a person from your team, please contact us via email <a>mailto:kontakt@codeeurope.pl#kontakt@codeeurope.pl</a> and we will help you individually.',
  faqInvoiceQuestion: 'How do I receive my ticket and invoice?',
  faqInvoiceAnswer:
    'After the registration process ticket and invoice will be received via email that was introduced as designed to receive an invoice and ticket. While buying a group ticket you will be also asked to type in an email address for an invoice.',
  faqStudentsQuestion: 'Do you have any discounts for students?',
  faqStudentsAnswer:
    'Students have a lower price that is available during the whole sales process. We encourage all young people to join Code Europe and get inspired by international experts!',
  faqTicketsDifferenceQuestion:
    'What is the difference between a student ticket and a regular ticket?',
  faqTicketsDifferenceAnswer:
    'The student ticket is dedicated to students, which means young people that have a valid student ID during registration process and including Code Europe Tech Festival period.',
  faqTicketsRefundQuestion: 'Refund of tickets - is this possible?',
  faqTicketsRefundAnswer:
    'It is not possible, according to the Code Europe policy and Polish law regulations.',
  faqFoodVoucherQuestion:
    'How can I receive and use my lunch truck voucher voucher?',
  faqFoodVoucherAnswer:
    'During the registration you’ll receive two vouchers, one for each day. Each voucher can be used only during the day specified on the voucher.{br}{br}In Cracow you can use your voucher once at the food area. There will be a great variety of cuisines to choose from.',
  faqPicturesQuestion: 'Can I take pictures and recordings during Code Europe?',
  faqPicturesAnswer:
    'Yes, you can take pictures and recordings. However, it is not allowed to record Speakers’ sessions.',
  faqAfterpartyParticipateQuestion: 'Afterparty - how can I participate?',
  faqAfterpartyParticipateAnswer:
    'Every Code Europe participant receives an invitation and free entrance to the Code Europe Afterparty, which will take place in Cracow. Details on where and when the party will take place, will be given to participants.',
  faqAfterpartyVenueQuestion: 'Afterparty - where can I find the venue?',
  faqAfterpartyVenueAnswer:
    'The official Code Europe Afterparty in <span>Cracow</span> will take place on Monday, after the closure of the first day of the event, starting from 8:30 PM.{br}{br}During the party, we provide an open bar and a great DJ set!{br}{br}Details of the official Code Europe After Party, such as the after party venue, will be provided to participants also by email.',
  skilledProfessionalsHeading:
    'A wide range of{br}<span>skilled professionals</span>',
  skilledProfessionalsSubheader:
    'Attract and recruit a wide range of IT specialist with the help of Code Europe which is targeted at mid and senior specialists.',
  skilledProfessionalsExperience: "Participants' professional experience",
  moreThanFiveYears: 'more than 5 years',
  threeToFiveYears: '3 to 5 years',
  twoToThreeYears: '2 to 3 years',
  upToOneYear: 'up to 1 year',
  skilledProfessionalsSpecializations: "Participants' specializations",
  developer: 'Developer / Programmer',
  tester: 'Tester',
  manager: 'Manager',
  devOps: 'DevOps & SysOps',
  analyst: 'Analyst',
  engineer: 'Engineer',
  architect: 'Architect',
  other: 'Other',
  activitiesHeading: 'Days of packed activities',
  activitiesSubHeading: 'Activities at Code Europe',
  activitiesModalHeading: 'Activities',
  activitiesSchedule: 'Two Days, a full schedule',
  activitiesDescription:
    "During Code Europe Tech festival you'll get a massive number of lectures, workshops, networking opportunities with peers, industry experts and Top companies.{br}{br} To live up to being a tech festival, Code Europe also good food and lots of fun before, during and after.",
  activitiesLecturesTitle: 'Top Notch Lectures and Workshops',
  activitiesLecturesText:
    "Code Europe is focused on the full software development circle. This means that the festival is not just focused on one aspect of software development, but instead takes a more holistic approach that covers everything from coding to testing, deployment, and maintenance.{br}{br}By focusing on the full software development circle, Code Europe is able to provide attendees with a more comprehensive understanding of the software development process. This is incredibly valuable for IT professionals who want to improve their skills and stay up-to-date with the latest trends and best practices.{br}{br}In addition, by covering all aspects of software development, Code Europe is able to attract a diverse range of attendees, from developers and testers to project managers and business analysts. This creates a vibrant and collaborative atmosphere where people from different backgrounds and skill sets can come together to share ideas and learn from one another.{br}{br}And with the creator of C++, <a>Bjarne Stroustrup</a>, joining Code Europe as the first conference in Poland, it's clear that the festival is attracting some of the biggest names in the industry. This is incredibly exciting for attendees, as it provides them with a unique opportunity to learn from one of the most influential figures in the world of software development.",
  activitiesStreetFoodTitle: 'Street food Area and some fun!',
  activitiesStreetFoodText:
    "Coffee, street food or want to have some fun break? During Code Europe you'll be able to try different dishes from around the world, drink some fresh juices or just grab some coffee.{br}{br}During your break you be able to network and have some fun. In every city we prepared some fun activities.",
  activitiesNetworkingTitle:
    'Networking and career opportunities with industry leaders',
  activitiesNetworkingText:
    'At Code Europe we believe that sharing and exchanging perspectives is crucial when it comes to improving skills.{br}{br}Take the opportunity to connect with speakers, participants as well as check out career opportunities at various Code Europe partners, industry leaders. Gain valuable insights into the job market.',
  activitiesAfterpartyTitle: 'Great Code Europe Party!',
  activitiesAfterpartyText:
    "After first day full of lectures and workshops it's time for some fun and more networking opportunities!{br}{br}Speakers, Participants and our Partners are invited to join Code Europe's After Party which will take place in Cracow.{br}{br}Follow our Social Media to be up to date!",
  technologySkillsetHeading:
    'A wide range of participants’{br}<span>technology skillsets</span>',
  technologySkillsetSubheader:
    'Thanks to our various tracks which cover popular technologies but also novel ones, Code Europe gathers professionals from a wide range of tech skills.',
  andMore: 'and more...',
  becomeSpeakerHeading:
    '<span>Fill out</span><span> the form</span> and become a speaker',
  becomeSpeakerAmenitiesHeading: 'We will provide you with',
  becomeSpeakerAmenityTransport: 'Transportation to / from Code Europe',
  becomeSpeakerAmenityAccommodation: 'Accommodation',
  becomeSpeakerAmenitySupper: 'Common dinner with other speakers',
  becomeSpeakerAmenityAccess: 'Full access to the conference',
  becomeSpeakerCommentFirst:
    "I'm still living @code_europe in Poznań. Why this conference was so unique? Because participants stays to last speeches, that's why. Talking to the full-house is always a pleasure. ",
  becomeSpeakerCommentFirstAuthor: 'Michał Łukaszewski',
  becomeSpeakerCommentFirstInformation: '<strong>Intel AI</strong>',
  becomeSpeakerCommentSecond:
    'Did not expect to be in such an enormous room at @code_europe – such an honour to speak to so many people!',
  becomeSpeakerCommentSecondAuthor: 'Ryan Townsend',
  becomeSpeakerCommentSecondInformation: '<strong>ShiftCommerce CTO</strong>',
  becomeSpeakerFormHeading:
    'Be the part of the largest programming conference in Poland and apply until <span>30th March 2025</span>',
  becomeSpeakerFormPolicy:
    'By submitting the form, you accept the <a>Privacy Policy</a>',
  becomeSpeakerSuccessHeading: 'Thank you!{br}Your application has been sent.',
  becomeSpeakerSuccessSoon:
    'We will contact you shortly to discuss the details.',
  becomeSpeakerSuccessExpect: 'What to expect from attending the conference?',
  becomeSpeakerSuccessSee:
    'See inspiring lectures by leading technology companies from previous editions.',
  formFirstName: 'Name',
  formFirstNameError: 'Fix name',
  formSurname: 'Surname',
  formSurnameError: 'Fix surname',
  formEmail: 'E-mail address',
  formEmailError: 'Fix email',
  formCompanyName: 'Company name',
  formCompanyNameError: 'Add company name',
  formTechnologyTrack: 'Technology track',
  formTechnologyTrackError: 'Select technology track from list',
  formLectureType: 'Lecture type',
  formLectureTypeError: 'Select lecture type',
  formTechnologies: 'Technologies',
  formTechnologiesError: 'Select technologies from list',
  formCities: 'Cities of attendance',
  formCitiesError: 'Select city',
  formTitle: 'Lecture title',
  formTitleError: 'Fix title',
  formDescription: 'Short description of the lecture',
  formDescriptionError: 'Fix description',
  formSend: 'Apply',
  lecturesHeading:
    '<span>The uniqueness</span> of presentations at Code Europe',
  lecturesSubheading:
    'Lectures are the most important part of our conference, which is why we care about them being created based on 4 key criteria:',
  lecturesContent: 'High substantive level',
  lecturesSubjects:
    "Consistency of the presentation's content and the main topics of the conference",
  lecturesForm: 'Specific form',
  lecturesTimeLimit: 'Specified time of the lecture',
  subscribe: 'Subscribe',
  contactUs: 'Contact us',
  becomeSpeaker: 'Become a speaker',
  dataFrom2018: 'data from Code Europe 2022',
  tweetsHeading: 'What <span>people</span> think about us',
  tweetsHeadingSpeakers: 'What <span>Speakers</span> say about us',
  previousEvents: 'Previous events',
  lectures: 'Lectures',
  organizers: 'Organizers',
  contact: 'Contact',
  soon: 'Soon!',
  followUs: 'Follow us',
  forPartners: 'For Partners',
  forSpeakers: 'For Speakers',
  watchVideo: 'Watch Video',
  seeMore: 'See more',
  showMore: 'More',
  showLess: 'Less',
  offersPlural: '{count, plural, one {# offer} other {# offers}}',
  send: 'Send',
  loading: 'Loading...',
  '3city': '3cITy',
  cracow: 'Cracow',
  warsaw: 'Warsaw',
  citiesSpring2022: 'Cracow, Warsaw, Tricity',
  seoTitle: "Code Europe - Poland's biggest Tech Festival",
  seoTitleAboutUs:
    "The team behind Code Europe - Poland's biggest Tech Festival",
  seoTitleSpeaker: "{name} at Code Europe - Poland's biggest Tech Festival",
  seoTitleSpeakers: "Speakers at Code Europe - Poland's biggest Tech Festival",
  seoTitlePartners: "Code Europe's Partners - Poland's biggest Tech Festival",
  seoTitleTickets:
    "Choose your city and ticket to join Poland's biggest Tech Festival Code Europe",
  seoTitleBecomeSpeaker:
    'Join Code Europe as a Speaker - Code Europe Call for Papers',
  seoDescription:
    "Code Europe is Poland's biggest Tech Festival. We gather world-class experts and speakers from around the globe to share their knowledge among thousands of IT specialists. Be part of Code Europe and receive world class knowledge!",
  seoDescriptionAboutUs:
    "Learn more about Code Europe, its purpose and the team behind. Code Europe's goal is to connect the world of Software Development, bringing together speakers and Tech experts from around the world, with different backgrounds and expertise. Connecting the world of software development",
  seoDescriptionSpeakers:
    'Check out cities where our leading speakers will give their lectures. Choose your city and technology of choice to know which lectures you should attend. Connecting the world of software development',
  seoDescriptionSpeaker:
    "{name} at Code Europe. Check out what lectures {name} will give at Code Europe, Poland's biggest Tech Festival.",
  seoTitleCompanies: "Partners of Code Europe - Poland's biggest Tech Festival",
  seoDescriptionCompanies:
    "Check out what companies will attend Code Europe - Poland's biggest Tech Festival",
  seoDescriptionPartners:
    "Code Europe is Poland's biggest Tech Festival in Poland. Here you will find IT specialists and IT managers, extend your recruitment network among best IT Talent as well as show your Employer Brand among them.",
  seoDescriptionTickets:
    "Code Europe is Poland's biggest Tech Festival. We gather world-class experts and speakers from around the globe to share their knowledge among thousands of IT specialists. Be part of Code Europe and receive world class knowledge!",
  seoDescriptionBecomeSpeaker:
    'We are constantly looking for interesting perspectives from people eager to share them with the Tech community. Apply as a speaker and join Code Europe.',
  seoKeywords:
    'Code Europe, IT job offers, recruitment of IT professionals, partners in IT, IT recruitment',
  seoKeywordsAboutUs:
    'Code Europe organizers, about Code Europe organizers, Code Europe Team, Code Europe purpose',
  seoKeywordsSpeakers:
    'Code Europe speakers, Code Europe lecturers, Code Europe lectures, Code Europe presentations, javascript, backend, big data, java, C++, php, sql, python',
  seoKeywordsSpeaker:
    'Code Europe speakers, Code Europe lecturers, Code Europe lectures, Code Europe presentations, {name}',
  seoKeywordsCompanies:
    'Companies at Code Europe, Code Europe companies, companies attending Code Europe',
  seoKeywordsPartners:
    'Code Europe, IT job offers, recruitment of IT professionals, partners in IT, IT recruitment',
  seoKeywordsTickets:
    'Code Europe, IT job offers, recruitment of IT professionals, partners in IT, IT recruitment',
  titleUser: "Your Profile at Code Europe - Poland's biggest Tech Festival",
  newsletterUnsubSuccessHeading: 'You have unsubscribed.',
  newsletterUnsubSuccessDescription:
    'From now on you will not receive the latest information about Code Europe 2024. If you want to stay up to date, sign up again.',
  newsletterUnsubErrorHeading: 'Oooops… Something went wrong!',
  newsletterUnsubErrorDescription:
    "You can contact us: <a href='mailto:kontakt@codeeurope.pl'>kontakt@codeeurope.pl</a>",
  newsletterUnsubButton: 'Back to homepage',
  getTicketButton: 'Get a ticket',
  getTicketNowButton: 'Get your ticket now',
  searchButton: 'Search',
  noResults: 'No results',
  privacyPolicy: 'Privacy Policy',
  codeOfConduct: 'Code of Conduct',
  regulations: 'Regulations',
  regulationsUsers: 'Terms of Service',
  selectLanguagePlaceholder: 'Select language',
  spDetailsType: 'Specify the type',
  spDetailsTypeLecture: 'Lecture',
  spDetailsTypeWorkshop: 'Workshop',
  spDetailsTypeError: 'Select speech type',
  spDetailsCities: 'City',
  spDetailsCitiesError: 'Please select at least one location',
  spDetailsName: 'Speaker Name',
  addAnotherSpeaker: 'Add another Speaker',
  removeSpeaker: 'Remove',
  spDetailsNameMinError: 'Min one speaker',
  spDetailsEmailMinError: 'Min one speaker mail',
  spDetailsEmailRequiredError: 'Email field is required',
  spDetailsEmailValidMail: 'Enter the valid mail',
  SHeaderSpeaker: 'Dear Speaker,',
  SHeaderDescription:
    'Please fill out the form below. This information will be published on Code Europe website and in promotional materials.',
  spDetailsEmail: 'Speaker E-mail',
  spDetailsTitle: 'Title of Lecture / Workshop',
  spDetailsTitleError: 'Title field is required',
  spDetailsTitleMaxLengthError: 'Title field is required',
  spDetailsDescription: 'Short description',
  spDetailsNameError: 'Name field is required',
  spDetailsDescriptionError: 'Too many characters entered',
  participantsCountMinError: 'The number must be greater than 0',
  participantsCountMaxError: 'The number must be less than 200',
  spDetailsLanguage: 'Lecture language',
  spDetailsLanguageError: 'Language field is required',
  spDetailsExperience: 'Required level of experience',
  spDetailsExperienceInfo:
    'Specify the level of experience required from the participants (1 - beginners, 3 - advanced)',
  spDetailsExperienceLevel: 'Level',
  spDetailsTrack: 'Choose track of your Lecture / Workshop',
  spDetailsTrackError: 'Track field is required',
  spDetailsTech: 'Technological fields',
  spDetailsTechError: 'Choose at least one technology',
  spDetailsRequirements: 'Requirements for the participants',
  spDetailsParticipants: 'Number of participants',
  SLabelDisclaimer:
    'If any technology is missing, you may add yours by typing what you want.',
  spDetailsIfWorkshopSelected: 'Fill out if "workshop" was selected',
  getToKnowUs: 'Get to know us better',
  findOutMore: 'Find out more',
  ourTeamHeading: '<span>Our team</span>',
  ourTeamDescription:
    'Code Europe Team consists of highly engaged enthusiasts who, working day and night, share a common goal of creating the best IT conference in Poland. What motivates us the most are the positive opinions of our satisfied participants who are looking forward to the next editions of the event!',
  ourTeamBanner: 'Recruit the best IT specialists and managers!',
  aboutUsHeading: '<span>Code Europe is:</span>',
  aboutUsSubheading:
    'an exceptional point on the map of IT events in Poland. The conference serves as a platform for the exchange of best practices and experiences between enthusiasts and world-class experts of the new technology industry. The most distinguishing features of Code Europe include:',
  aboutUsSpeakers: 'Exceptional speakers',
  aboutUsTalks: 'Inspiring lectures',
  aboutUsScale: 'Remarkable scale of the event and unique atmosphere',
  proffesionsAboutUsHeading:
    '<span>Code Europe</span> is focusing on the full software development circle',
  proffesionsAboutUsSubheader:
    "Poland's biggest IT festival is directed to a broad audience with different skillsets.",
  partnersAboutUsHeading: '<span>Code Europe</span> Partners',
  partnersAboutUsSubheader:
    "The fact that leading brands are acknowledging Code Europe is a confirmation to the festival's success and the value it provides to the IT industry as a whole. As the festival continues to grow and evolve, we can expect to see even more brands come on board and show their support for this exciting and inspiring event.",
  organiser: 'Brought to you by:',
  speakerDescriptionError: 'The description cannot exceed 850 characters',
  speakerDescriptionMinError:
    'The description must be at least 10 characters long',
  contactUsModalTitle: "Are you interested in becoming the festival's Partner?",
  contactUsModalDescription: 'Contact us!',
  'filtrationGroupName.cities': 'Select city',
  'filtrationGroupName.tags': 'Select technology',
  'filtrationGroupName.tracks': 'Select track',
  'filtrationGroupName.employeesCount': 'Number of employees',
  'filtrationGroupName.jobAreas': 'Job areas',
  'filtrationGroupName.salaries': 'Salaries (PLN)',
  'filtrationGroupName.languages': 'Language',
  filtrationShowMore: 'More',
  filtrationShowLess: 'Less',
  filtrationLabel: 'Filters',
  filtration: 'Filtration',
  filtrationClear: 'Clear',
  filtrationClearDesktop: 'Clear filters',
  filtrationSubmit: 'Filter',
  speakers: 'Speakers',
  agenda2023: 'Agenda 2023',
  agenda: 'Agenda {year}',
  workshops: 'Workshops',
  jobOffers: 'Job offers',
  companies: 'Brands',
  buyTicket: 'Buy a ticket',
  signIn: 'Sign in',
  myAccount: 'My account',
  organization: 'Organization',
  location: 'Location and access',
  aboutUs: 'About us',
  edition: 'Edition',
  newsletterHeadingNav: 'Subscribe to the newsletter',
  newsletterHeadingNavText:
    'Get access to the latest information about Code Europe.',
  newsletterSend: 'Send',
  'speakers.bannerHeading': 'Share your knowledge at Code Europe 2025',
  'speakers.empty':
    'There are no speakers matching your criteria. Change the city, track or technology area.',
  'speakers.emptyButton': 'Show all',
  'companies.empty':
    'There are no companies matching your criteria. You can change the city or technology area.',
  'companies.emptyButton': 'Show all',
  'companies.search': 'Search brands...',
  'skill.speakers_think': 'Critical Thinking',
  'skill.speakers_problems': 'Problems Solving',
  'skill.speakers_program': 'Programming',
  'skill.speakers_system': 'System Analysis',
  'skill.speakers_error': 'Bug fixing',
  'skill.speakers_lazi': 'Laziness',
  'skill.basic': 'Basic',
  'skill.advanced': 'Advanced',
  'skill.expert': 'Expert',
  'speaker.mySkills': 'My skills',
  'speaker.bio': 'Bio',
  'speeches.emptyHeading': 'My speeches',
  'speeches.emptyInformation': 'More informations soon. Follow us on ',
  pomeraniaBannerText:
    '<strong>Invest in pomerania</strong> supports the growth of <span>Pomeranian business</span>',
  'employer.cities': 'Meet us at Code Europe:',
  'employer.tracks': 'Technology tracks:',
  'employer.tags': 'Technologies in the Company:',
  'employer.description': 'Career at ',
  'employer.benefits': 'Benefits',
  'employer.info': 'Company information:',
  'employer.industry': 'Industry: ',
  'employer.employeesCount': 'Company size: ',
  'employer.headquarters': 'Headquarters: ',
  'employer.socials': 'Web:',
  'employer.similar': 'Companies with similar technologies',
  'employer.offers': 'Job offers at {company}',
  'internal-external-trainings': 'internal-external-trainings',
  'hack-days': 'hack-days',
  'conference-budget': 'conference-budget',
  'training-budget': 'training-budget',
  'team-events': 'team-events',
  'private-health-care': 'private-health-care',
  'sport-card': 'sport-card',
  kitchen: 'kitchen',
  canteen: 'canteen',
  'free-coffee': 'free-coffee',
  'free-lunch': 'free-lunch',
  'free-beverages': 'free-beverages',
  'free-snacks': 'free-snacks',
  fruits: 'fruits',
  'free-parking': 'free-parking',
  'bike-parking': 'bike-parking',
  shower: 'shower',
  kindergarten: 'kindergarten',
  gym: 'gym',
  'play-room-chill-room': 'play-room-chill-room',
  'mobile-phone': 'mobile-phone',
  seoTitleCompany: "{company} at Code Europe - Poland's biggest Tech Festival",
  seoDescriptionCompany:
    "Meet us, {company} at Code Europe - Poland's biggest Tech Festival",
  seoKeywordsCompany:
    'Companies at Code Europe, Code Europe companies, {company} at Code Europe, {company} Code Europe',
  seoTitleAgenda:
    "Code Europe's program - Connecting the world of software development",
  seoDescriptionAgenda:
    "Check out Code Europe's program where the world of Software Development is connected. Check and find most interesting lectures for you and sign up for Code Europe!",
  seoKeywordsAgenda:
    'Code Europe agenda, Code Europe lectures, Code Europe topics',
  seoTitleAgendaArchive: `Agenda Code Europe {year} - Major Event for IT Professionals in Poland`,
  seoDescriptionAgendaArchive: `Explore the agenda of Code Europe {year} - the largest tech festival in Poland. Discover the top-notch topics in the IT world. Sign up now for current edition!`,
  seoKeywordsAgendaArchive: `Code Europe {year} agenda, IT trends, IT conference, Code Europe {year}, Code Europe {year} lectures, IT events`,
  'tickets.selectHeading':
    '<span>Code Europe - be</span><span> a part of it!</span>',
  'tickets.selectSubheading':
    "Two days filled with content, inspiring meetings, as well as great fun and an unforgettable experience!{br}Purchase your tickets now and don't miss the best deals!",
  'tickets.tabWarsaw': 'Warsaw',
  'tickets.tabCracow': 'Cracow',
  'tickets.bannerHeading':
    'Thanks to our co-organizers, you can get a <strong>50% discount for a ticket </strong> in the Tri-City.',
  'tickets.bannerUseCode': 'Use code',
  'tickets.selectDescription':
    'Select the number of tickets you want o buy. After completing the transaction, you will receive ticket codes that you can distribute among your team members.',
  'tickets.selectStudentInformation':
    'You have chosen a student ticket - remember to bring a document entitling you to a discount to the event (student ID card).',
  'tickets.selectDiscountCodeHeading':
    'If you have a promo code, you can use it here',
  'tickets.selectDiscountCodeLabel': 'Enter your Promotional Code',
  'tickets.selectDetailsHeading': 'Order details',
  'tickets.selectDetailsEmpty': 'Select ticket',
  'tickets.selectTicketType': 'Choose your tickets',
  'tickets.selectPass': 'Choose your Pass',
  'tickets.soldOut': 'SOLD OUT',
  'tickets.ticketDetailsType': 'Event ticket',
  'tickets.discountDisabledPlaceholder': 'Select ticket',
  'tickets.ticketTypeStudent': 'Student ticket',
  'tickets.ticketTypeEvent': 'Event ticket',
  'tickets.ticketPriceIncludingVat': 'incl. VAT 23%',
  'tickets.ticketChoose': 'Choose',
  'tickets.ticketAmount': 'Amount',
  'tickets.orderPrice': 'Total',
  'tickets.userRegistrationHeading': 'Register',
  'tickets.haveAccount': 'Have an account',
  'tickets.userBadgeHeading': 'Prepare your participant badge.',
  'tickets.userBadgeDescription':
    'Fill in the information that we will use to prepare a participant badge - your unique identifier for the conference.',
  'tickets.userDataHeading': 'Your experience',
  'tickets.userDataDescription':
    'Indicate your professional experience and interests. This information allows us to build an event that is more relevant to your needs and expectations.',
  'tickets.discountCode': 'Promo code',
  'tickets.priceSummaryTotal': 'Total payment (including VAT)',
  'tickets.orderSummaryLabel': 'Order details',
  'tickets.orderSummaryPaymentLabel': 'Payment details',
  'tickets.paymentSummaryVatInfo':
    'The invoice will be sent to your e-mail address',
  'tickets.successHeading': 'Check your inbox',
  'tickets.successInfo':
    'After the payment is credited, the ticket and an invoice will be sent to the following address: <span>{email}</span>',
  'tickets.successInfoGroup':
    "Your promotional code will be emailed to you once we'll receive the payment.",
  'tickets.successInfoSpam':
    'If you cannot find an e-mail from us, please check your SPAM folder.',
  'tickets.successInfoEdit':
    'You can edit your details and download your ticket at any time by logging into your <a>user account</a>.',
  'tickets.chooseCity': 'Choose your city',

  'tickets.stepperRegister': 'Registration',
  'tickets.stepperBadge': 'Participant badge',
  'tickets.stepperUserData': 'Additional information',
  'tickets.stepperPaymentData': 'Payment details',
  'tickets.stepperOrderSummary': 'Summary and payment',

  'tickets.optionEarlyBirdPass': 'Early Bird Pass',
  'tickets.optionDecidedPass': 'Decided Pass',
  'tickets.optionRegularPass': 'Regular Pass',

  'labels.name': 'Name',
  'labels.surname': 'Surname',
  'labels.phone': 'Phone number',
  'labels.email': 'E-mail address',
  'labels.password': 'Password',
  'labels.passwordConfirm': 'Repeat password',
  'labels.passwordRequirements':
    'Password should contain at least 8 characters (1 capital letter, 1 number and 1 special character: @$!%>£=()^-_*#+?&).',
  'labels.passwordEqual': 'Passwords musi be identical',
  'labels.terms': "I agree to Code Europe's <a>Terms and Conditions</a>.",
  'labels.jobPosition': 'My position',
  'labels.company': 'Company Name',
  'labels.interestingPath': 'The technology track that interests me the most',
  'labels.experience': 'My professional experience',
  'labels.technologiesWorkingWith': 'Technologies that I work with',
  'labels.technologiesInterestedIn': 'Technologies that I am interested in',
  'labels.knownLanguages': 'Foreign languages',
  'labels.cv': 'My Resume',
  'labels.cvRequirements':
    'Submit your CV to make yourself known to potential IT employers. Maximum file size is 5 MB, we accept .pdf extensions',
  'labels.street': 'Street',
  'labels.houseNumber': 'Building. no.',
  'labels.apartmentNumber': 'Apt. no.',
  'labels.postcode': 'Postal code',
  'labels.city': 'City',
  'labels.country': 'Country',
  'labels.vat': 'I want VAT invoice for the company',
  'labels.companyNip': 'NIP',
  'labels.copy': 'Fill address data',
  'labels.matchMaking':
    'I want to participate in recruitment processes for a position that match my experience and interests.',

  'errors.nameError': 'Invalid name',
  'errors.surnameError': 'Invalid surname',
  'errors.phoneError': 'Invalid phone number',
  'errors.emailError': 'Invalid e-mail address',
  'errors.paymentEmailError': 'Invalid e-mail address',
  'errors.passwordError':
    'Invalid password. Password should contain at least 8 characters (1 capital letter, 1 number and 1 special character: @$!%>£=()^-_*#+?&).',
  'errors.jobPositionError': 'Invalid job position',
  'errors.companyError': 'Invalid company name',
  'errors.interestingPathError': 'Invalid technology track.',
  'errors.technologiesWorkingWithOverError': 'Choose max. 5 technologies',
  'errors.technologiesInterestedInOverError': 'Choose max. 5 technologies',
  'errors.streetError': 'Invalid street name',
  'errors.companyStreetError': 'Invalid street name',
  'errors.houseNumberError': 'Invalid building number',
  'errors.companyHouseNumberError': 'Invalid building number',
  'errors.apartmentNumberError': 'Invalid apartment number',
  'errors.companyApartmentNumberError': 'Invalid apartment number',
  'errors.postcodeError': 'Invalid postal code',
  'errors.companyPostcodeError': 'Invalid postal code',
  'errors.cityError': 'Invalid city',
  'errors.companyCityError': 'Invalid city',
  'errors.countryError': 'Invalid country',
  'errors.companyCountryError': 'Invalid country',
  'errors.companyNipError': 'Invalid NIP number',
  'errors.resumeError': 'Invalid resume file',

  'errors.discountCodeNotFound': 'Invalid promo code',
  'errors.discountCodeCannotApply': 'Invalid promo code',
  'errors.incorrectLoginData': 'Incorrect login details',

  'signin.heading': 'Log in',
  'signin.subheading': 'Want to attend the event? <a>Buy a ticket.</a>',
  'signin.forgotPassword': 'Forgot password',

  'user.tabTickets': 'Tickets',
  'user.tabData': 'Data',
  'user.tabConsent': 'Agreements',
  'user.dataRegistrationSection': 'Registration data',

  'user.ticketsNeedHelpLabel': 'Need help',
  'user.ticketsNeedHelp': 'Contact us at',

  'user.ticketStatusSuccess': 'Download ticket',
  'user.ticketStatusPending': 'Pending payment',
  'user.ticketStatusCanceled': 'Payment rejected',

  'user.consentRemoveAccountLabel': 'Removal of User Account',
  'user.consentRemoveAccount':
    'An account allows you to edit your details and download your event ticket at any time. However, remember that you can always request to delete your account by mailing us at ',

  'user.forgotPasswordHeading': 'Reset password',
  'user.forgotPasswordDescription':
    "Enter the e-mail address used for registration, and then click on the link that we'll send you to your inbox.",
  'user.forgotPasswordSubmit': 'Reset password',
  'user.forgotSuccess':
    'A link with further instructions has been sent to the e-mail address provided',

  'user.resetPasswordHeading': 'Change password',
  'user.resetPasswordDescription':
    'Enter your new password. Avoid obvious passwords like first name or surname.',
  'user.resetPasswordSubmit': 'Change password',
  'user.resetSuccess': 'The password has been successfully updated',

  'general.back': 'Back',
  'general.next': 'Next',
  'general.or': 'or',
  'general.summary': 'Summary',
  'general.ready': 'Ok',
  'general.logout': 'Logout',
  'general.saveData': 'Save data',

  offers: '{count, plural, one {# offer} other {# offers}}',

  'offers.empty':
    'There are no positions matching your current criteria. Please change the job area, salary or required skills to display more offers.',
  'offers.emptyButton': 'Show all',
  'offers.search': 'Search for position, company, keyword, location...',
  'offer.endtime': 'Offer active until: ',
  'offer.responsibilitiesHeading': "Candidate's responsibilities",
  'offer.basicInfoHeading': 'Additional info',
  'offer.projectHeading': 'Project description',
  'offer.descriptionHeading': 'Basic info:',
  'offer.profileHeading': 'Profile',
  'offer.skillsHeading': 'Required technologies:',
  'offer.languagesHeading': 'Required languages:',
  'offer.employeesCount': 'Company size: ',
  'offer.teamCount': 'Team size: ',
  'offer.salary': 'Salary: ',
  'offer.contract': 'Contract type: ',
  'offer.industry': 'Industry: ',
  'offer.benefitsHeading': 'Benefits:',
  'offer.interested': 'Interested in this offer?',
  'profile.new_features': 'New features',
  'profile.maintenance_bug_fixing': 'Maintenance/Bug fixing',
  'profile.client_support': 'Client support',
  'profile.document_writing': 'Document writing',
  'profile.meetings': 'Meetings',
  requirements: 'Required Technologies: ',
  apply: 'Apply',
  seoTitleOffers: 'Tech and IT Jobs - Code Europe',
  seoDescriptionOffers:
    'Job offers for programmers and Tech specialists. Find the best job offers in the IT industry. Boost your career in IT with us!',
  seoKeywordsOffers:
    'Code Europe job offers, job offers in IT, work in IT, IT work, IT job offers, Code Europe work',
  seoTitleOffer: '{name} - Tech Jobs Code Europe',
  seoDescriptionOffer:
    '{name} Tech Job. Find the best job offers for programmers and IT specialists in Europe. Boost your career in IT!',
  seoKeywordsOffer:
    'Code Europe job offers, IT job offers, job offers in IT, IT work, working in IT, Code Europe work, {name} job offer, {name} work',

  'event.type_lecture': 'Conference session',
  'event.type_workshop': 'Work IT',

  'agenda.empty':
    'There are no events matching your criteria. You can change the city or technology area.',
  'agenda.emptyButton': 'Show all',
  'agenda.seeFull': 'See agenda',
  'agenda.technologies': 'Technologies',
  'agenda.level': 'Level',

  '404.homePage': 'Homepage',
  '404.notFound': 'Page does not exist',
  promoBannerText: 'GRRRAB YOUR TICKET',

  topicDevOpsDescription:
    'All about clouds, Networks, Data. Infrastructure management, Best Practices, Tools & Knowhow.',
  topicCybersecurityDescription:
    'Security Technologies and Tools, Encryption,  protection from various attacks, Tech and Behaviour enhancing security.',
  topicDataScienceDescription:
    'Deep & Machine Learning, Big Data & Warehousing, Visualisation and analysis, Neural links, AI.',
  topicProgrammingDescription:
    'Frontend, Backend, libraries, tools and better practices, newcomers, Software architecture, C++, .NET, Python, JAVA, C#, Javascript, Golang, PWA.',
  topicFutureDescription:
    'Blockchain, Robotics, Space, Metaverse, Autonomous vehicles.',
  topicDevelopmentDescription:
    "It's all about execution. Best Practices, tools and techniques.",

  earlyBird: 'Decided',
  earlyBirdTickets: 'tickets',
  earlyBirdAvailable:
    'available only till <span>May 22,</span> <span>2022!</span>',
  earlyBirdInfo: 'After this date the STANDARD price will be 689 PLN',

  encourageTicketBannerHeading: 'Decided?',
  encourageTicketBannerDescription:
    'Buy your ticket now. Price will increase in: ',

  eventCountdownBannerHeading:
    '<span>Hurry up!</span> Code Europe starts in: {time}',
  countdownPassPriceInfo: ' pass price will increase in ',
  countdownPassPricePlOnly: ' ',
  days: 'days',
  day: 'day',
  firstDay: 'DAY 1',
  secondDay: 'DAY 2',
  hurryUp: 'Hurry up!',
  codeEuropeDateFirst: '30 June - 1 July 2025',
  codeEuropeDateSecond: '13 - 14 June 2024',
  softwareDevelopers: 'Software{br}Developers',
  softwareArchitects: 'Software{br}Architects',
  devOpsEngineers: 'DevOps{br}Engineers',
  securityExperts: 'Security{br}Experts',
  productOwners: 'Product{br}Owners',
  dataScientists: 'Data{br}Scientists',
  qASpecialists: 'QA{br}Specialists',
  impressions: 'Impressions',
  learnMore: 'Learn more',
  explore: 'Explore',
  legal: 'Legal',
  applyNow: 'Apply now',
  soundOn: 'sound on',
  mute: 'mute',
  hello: 'Hello!',
  videoPopupContactUs: 'Contact us',
}
