import React from 'react'
import { animated, Spring } from 'react-spring'
import { useIntl } from 'react-intl'
import { useTheme } from 'styled-components'
import VisibilitySensor from 'react-visibility-sensor'
import { useDispatch } from 'react-redux'

import { Button, CustomUnderline, SlideTop } from '../../components'
import { SContainer } from './TopPartners.styled'

import getArrayChunks from 'utils/getArrayChunks'
import { useBreakpoint } from '../../modules/breakpoint'
import topGreenPattern from '../../assets/patterns/top-green-pattern.svg'
import twoLinesGreenBluePattern from '../../assets/patterns/two-lines-green-blue-pattern.svg'
import accenture from '../../assets/partners/accenture.svg'
import aws from '../../assets/partners/aws.svg'
import bcgPlatinion from '../../assets/partners/bcg-platinion.svg'
import dell from '../../assets/partners/dell.svg'
import goldman from '../../assets/partners/goldman-sachs.svg'
import ing from '../../assets/partners/ing.svg'
import intel from '../../assets/partners/intel.svg'
import amazon from '../../assets/partners/amazon.svg'
import roche from '../../assets/partners/roche.svg'
import motorola from '../../assets/partners/motorola.svg'
import netcompany from '../../assets/partners/netcompany.svg'
import netguru from '../../assets/partners/netguru.svg'
import nike from '../../assets/partners/nike.svg'
import nokia from '../../assets/partners/nokia.svg'
import olx from '../../assets/partners/olx-group.svg'
import onwelo from '../../assets/partners/onwelo.svg'
import pwc from '../../assets/partners/pwc.svg'
import softServe from '../../assets/partners/soft-serve.svg'
import tikTok from '../../assets/partners/tik-tok.svg'
import zf from '../../assets/partners/zf.svg'
import { setContactModalVisibility } from '../../state/reducers/app/appActions'

const partners = [
  { name: 'Amazon Web Services', logo: aws },
  { name: 'BCG Platinion', logo: bcgPlatinion },
  { name: 'Onwelo', logo: onwelo },
  { name: 'Netguru', logo: netguru },
  { name: 'Olx', logo: olx },
  { name: 'Nokia Solutions and Networks', logo: nokia },
  { name: 'Motorola Solutions', logo: motorola },
  { name: 'Dell Technologies', logo: dell },
  { name: 'ZF', logo: zf },
  { name: 'Accenture', logo: accenture },
  { name: 'Pwc', logo: pwc },
  { name: 'Netcompany', logo: netcompany },
  { name: 'Nike', logo: nike },
  { name: 'Goldman Sachs', logo: goldman },
  { name: 'Tik Tok', logo: tikTok },
  { name: 'Soft Serve', logo: softServe },
  { name: 'ING', logo: ing },
  { name: 'Amazon', logo: amazon },
  { name: 'Intel', logo: intel },
  { name: 'Roche', logo: roche },
]
function TopPartners() {
  const dispatch = useDispatch()
  const theme = useTheme()
  const { formatMessage } = useIntl()
  const breakpoint = useBreakpoint()
  const isMobile = breakpoint.xl

  const openCallpageWidget = () => {
    if (typeof window === 'undefined') return
    window.callpage('api.widget.open')
  }
  const openContactModal = () => {
    dispatch(setContactModalVisibility(true))
  }

  const openContactModalOrCallpage = () => {
    window.callpage ? openCallpageWidget() : openContactModal()
  }

  const partnersToDisplay = isMobile
    ? getArrayChunks(partners.slice(0, 12), 3)
    : getArrayChunks(partners, 5)

  const renderGrid = (partnersToDisplay) => (
    <SlideTop>
      <div className="grid-wrapper">
        {partnersToDisplay?.map((row, index) => (
          <div className="row-wrapper" key={index}>
            <div className="row">
              {row.map((partner) => (
                <div key={partner.name} className="logo">
                  <img src={partner.logo} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </SlideTop>
  )

  return (
    <VisibilitySensor
      minTopValue={15}
      scrollCheck
      offset={{ top: 400 }}
      scrollDelay={5}
      partialVisibility="top"
    >
      {({ isVisible }) => (
        <SContainer>
          {!isMobile ? (
            <Spring from={{ opacity: 0 }} to={{ opacity: isVisible ? 0 : 1 }}>
              {({ opacity }) => {
                return (
                  <div className="background">
                    <animated.div
                      style={{
                        opacity,
                        height: '100%',
                      }}
                    >
                      <div className="positionLeft">
                        <img src={topGreenPattern} alt="" />
                      </div>
                      <div className="positionRight">
                        <img src={twoLinesGreenBluePattern} alt="" />
                      </div>
                    </animated.div>
                  </div>
                )
              }}
            </Spring>
          ) : null}
          <div>
            <div className="triangle" />
            <div className="triangle" />
            <SlideTop>
              <h2 className="heading">
                {formatMessage(
                  { id: 'topPartnersHeading' },
                  {
                    span: (chunk) => (
                      <CustomUnderline color={theme.colors.blue}>
                        {chunk}
                      </CustomUnderline>
                    ),
                  }
                )}
              </h2>
              <p className="subheader">
                {formatMessage({ id: 'topPartnersSubheader' })}
              </p>
            </SlideTop>
            {renderGrid(partnersToDisplay)}
            <SlideTop>
              <div className="rating">
                <div className="rating-wrapper">
                  <div>
                    <span className="rating-title">
                      {formatMessage({ id: 'topPartnersRating' })}
                    </span>
                    <span className="rating-number">4,78</span>
                  </div>
                  <div className="rating-stars">
                    <i className="ri-star-fill" />
                    <i className="ri-star-fill" />
                    <i className="ri-star-fill" />
                    <i className="ri-star-fill" />
                    <i className="ri-star-half-fill" />
                  </div>
                  <p className="rating-text">
                    {formatMessage({ id: 'topPartnersRatingSurveys' })}
                  </p>
                </div>
              </div>
            </SlideTop>
            <SlideTop>
              <div className="button-wrapper">
                <Button
                  onClick={openContactModalOrCallpage}
                  secondary="true"
                  height="52px"
                >
                  {formatMessage({
                    id: 'joinCeNow',
                  })}
                  <i className="ri-arrow-right-line" />
                </Button>
              </div>
            </SlideTop>
          </div>
        </SContainer>
      )}
    </VisibilitySensor>
  )
}

export default TopPartners
