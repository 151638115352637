import { createReducer } from '@reduxjs/toolkit'
import {
  getDictionary,
  setContactModalVisibility,
  setActivitiesModalVisibility,
  setFooterVisibility,
  setHeaderVisibility,
  setPolicyPermissions,
} from './appActions'

const initialState = {
  dictionary: {
    data: {
      cities: [],
      tracks: [],
      tags: [],
      experienceLevel: [],
      languages: [],
      employeesCount: [],
      employmentPosition: [],
      lecturesLanguages: [],
    },
    loading: false,
  },
  contactModalVisibility: false,
  activitiesModalVisibility: false,
  headerVisibility: true,
  footerVisibility: true,
  policyPermissions: {},
}

export default createReducer(initialState, {
  [getDictionary.pending]: (state) => ({
    ...state,
    dictionary: {
      ...state.dictionary,
      loading: true,
    },
  }),
  [getDictionary.fulfilled]: (state, action) => ({
    ...state,
    dictionary: {
      ...state.dictionary,
      data: action.payload,
      loading: false,
    },
  }),
  [getDictionary.rejected]: (state) => ({
    ...state,
    dictionary: {
      ...state.dictionary,
      loading: false,
    },
  }),
  [setContactModalVisibility.type]: (state, action) => ({
    ...state,
    contactModalVisibility: action.payload,
  }),
  [setActivitiesModalVisibility.type]: (state, action) => ({
    ...state,
    activitiesModalVisibility: action.payload,
  }),
  [setHeaderVisibility.type]: (state, action) => ({
    ...state,
    headerVisibility: action.payload,
  }),
  [setFooterVisibility.type]: (state, action) => ({
    ...state,
    footerVisibility: action.payload,
  }),
  [setPolicyPermissions.type]: (state, action) => ({
    ...state,
    policyPermissions: action.payload,
  }),
})
