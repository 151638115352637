import styled, { css } from 'styled-components'
import respondTo from 'theme/respondTo'

import desktopBannerBackground from "../../assets/images/speakers-desktop-banner.svg"
import mobileBannerBackground from "../../assets/images/speakers-mobile-banner.svg"

export const SPromoCodeBar = styled.div`
  ${({ theme, asBanner, onMainPage }) => css` 
    width: 100%;
    height: 96px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10000;
   
     ${respondTo.xl`
        height: 112px;
    `}
   
     a {
       text-decoration: none;
     }
     
     .bold {
      font-weight: ${theme.weight.bold};
     }
     
    .bannerInner {
      width: 100%;
      height: 96px;
      background-color: ${theme.colors.pink};
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      z-index: 99;

     .textWrapper {
       display: block;
       margin: 0 auto ${theme.space.sm}px;
       order: 3;
       width: 100%;
       text-align: center;      
     
       ${respondTo.xl`
          width: initial;
          order: initial;
          display: inline-block;
          margin: 12px 106px 20px;
       `}
     }
     
     .offIcon {
       margin-top: ${theme.space.sm + 4}px;
       margin-right: ${theme.space.lg}px;
       height: 41px;
       width: 119px; 
       
      ${respondTo.xl`
        margin-top: ${theme.space.md}px;
        order: initial;
        height: 105px;
        width: 297px; 
      `}
     }
  
     img {
       display: inline-block;
     }
     
     .dino {
      margin-top: ${theme.space.sm}px;
      height: 48px;
      width: 44px;
      overflow: hidden;
      
       ${respondTo.xl`
        margin-top: ${theme.space.md}px;
        height: 122px;
        width: 112px; 
      `}
     }
     
     p {
      font-weight: ${theme.weight.bold};
      color: ${theme.colors.darkGray};
      font-size: 1.5rem;
      line-height: 2.5rem;
      text-align: center;
     }
     
     .promoCode {
      display: block;
      background-color: ${theme.colors.darkGray};
      color: ${theme.colors.white};
      border-radius: 20px;
      padding: 4px 10px;
      margin: 0 auto;
      
      font-size: 1.375rem;
      line-height: 1.75rem;
      
      ${respondTo.xl`
        font-size: 1.75rem;
        line-height: 2.5rem;
        margin-bottom: 20px;
        padding: 4px 36px;
        width: 512px;
      `}
     }
 
    ${respondTo.xl`
      height: 112px;
      flex-wrap: nowrap;
    `}

    
    ${asBanner &&
      css`
        background-image: url(${mobileBannerBackground});
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
        position: sticky;
        left: 0;
        top: 0;
        max-width: 1140px;
        height: 388px;
        z-index: 1;
        overflow: hidden;
        padding: 4px ${theme.space.md}px;
    
        ${respondTo.xl`
          background-image: url(${desktopBannerBackground});
          height: 142px;
          max-height: 142px;
          padding: 4px 40px;
       `}
        
        .textWrapper {
       
         ${respondTo.xl`
            margin: ${theme.space.md}px 0;
         `}
         
          p {
            color: ${theme.colors.white};
            font-size: 1.5rem;
            line-height: 2.5rem;
            margin-bottom: 6px;
          }
          
          .promoCode {
            background: ${theme.colors.lightTurquoise};
            color: #000;
            font-size: 2rem;
            line-height: 3rem;
            padding-top: 18px;
            padding-bottom: 18px;
            
           ${respondTo.xl`
              font-size: 1.75rem;
              line-height: 2.5rem;
              width: 100%;
              max-width: 608px;
              padding-top: 4px;
              padding-bottom: 4px;
          `}
          }
        } 
        
        .offIcon {
          height: 101px;
          max-width: 288px;
          width: 100%;
          margin: 29px auto ${theme.space.md}px;
            
           ${respondTo.xl` 
            margin: ${theme.space.sm + 4}px ${theme.space.lg}px 0 0;
            height: 126px;
            max-width: 295px; 
          `}
          
          text.shadow {
           fill: #FC86F6;
          }
        }    
        
        .dino {
          height: 55px;
          width: 62px;
          order: 3;
   
          path {
            fill: white;
          }
                      
         ${respondTo.xl`
            order: initial;
            margin-left: -50px;
            margin-top: 11px;
            height: 51px;
            width: 47px; 
        `}
       }
    `}
    }
      
   ${asBanner &&
    css`
      position: static;
      
      height: 388px;
      max-width: 1140px;
      margin: 0 auto 40px;
      
      ${respondTo.xl`
        height: 142px;
        max-height: 142px;
     `}
    `}
   
   ${onMainPage &&
     css`
       ${respondTo.xl`
         margin: ${-theme.space.xxxl}px auto 40px;
      `}
     `}
  `}
`

export const SCloseIcon = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    cursor: pointer;
    height: ${theme.space.lg}px;
    width: ${theme.space.lg}px;
    position: absolute;
    top: ${theme.space.sm}px;
    right:  ${theme.space.sm}px;
    background-color: ${theme.colors.darkGray};
    border-radius: 50%;
    padding: ${theme.space.xs}px;
    z-index: 100;
    
     ${respondTo.xl`
        top: ${theme.space.md}px;
        right:  ${theme.space.md}px;
    `}
  `}
`
