import { createReducer } from '@reduxjs/toolkit'
import { clearUser, getUser } from '../user/userActions'
import {
  buyTicket,
  calculatePrice,
  changeCity,
  changeStep,
  getTickets,
  selectTicket,
  setFields,
  unselectTicket,
  updateDiscountCode,
} from './ticketsActions'

export const TicketStep = {
  SELECTION: 'selection',
  REGISTRATION: 'registration',
  BADGE: 'badge',
  DATA: 'data',
  PAYMENT_DATA: 'payment_data',
  PAYMENT_SUMMARY: 'payment_summary',
}

export const PassType = {
  EARLY_BIRD_PASS: 'early bird',
  DECIDED_PASS: 'decided',
  STANDARD_PASS: 'standard',
}

export const TicketCity = {
  WARSAW: 'warsaw',
  CRACOW: 'cracow',
}

const initialState = {
  step: TicketStep.SELECTION,
  loading: false,
  items: {
    tickets: [],
    loading: false,
    selected: {
      city: TicketCity.CRACOW,
      entity: null,
      amount: 0,
    },
  },
  price: {
    discountCode: '',
    priceBeforeDiscount: null,
    priceAfterDiscount: null,
    discountPrice: null,
    loading: false,
    errors: null,
  },
  fields: {
    base: {
      name: '',
      surname: '',
      phone: '',
      email: '',
      password: '',

      terms: false,

      job_position: null,
      company: '',
      interesting_path: null,
      experience: null,
      technologies_working_with: null,
      technologies_interested_in: null,
      known_languages: null,

      resume: null,
    },
    payment: {
      name: '',
      surname: '',
      email: '',
      street: '',
      house_number: '',
      apartment_number: '',
      postcode: '',
      city: '',
      country: '',
      vat: false,
    },
    vat: {
      copy: false,
      company: '',
      company_nip: '',
      company_street: '',
      company_house_number: '',
      company_apartment_number: '',
      company_postcode: '',
      company_city: '',
      company_country: '',
    },
  },
}

export default createReducer(initialState, {
  [clearUser.type]: (state) => ({
    ...initialState,
  }),
  [selectTicket.type]: (state, action) => {
    return {
      ...state,
      items: {
        ...state.items,
        selected: {
          ...state.items.selected,
          entity: action.payload.ticket,
          amount:
            typeof action.payload.amount !== undefined
              ? action.payload.amount
              : 1,
        },
      },
      price: {
        ...state.price,
        discountCode: '',
      },
    }
  },
  [unselectTicket.type]: (state, action) => ({
    ...state,
    step: TicketStep.SELECTION,
    items: {
      ...state.items,
      selected: {
        ...state.items.selected,
        entity: null,
        amount: 0,
      },
    },
  }),
  [changeCity.type]: (state, action) => ({
    ...state,
    items: {
      ...state.items,
      selected: {
        ...initialState.items.selected,
        city: action.payload,
      },
    },
  }),
  [changeStep.type]: (state, action) => ({
    ...state,
    step: action.payload,
  }),
  [getTickets.pending]: (state, action) => ({
    ...state,
    items: {
      ...state.items,
      loading: true,
    },
  }),
  [getTickets.fulfilled]: (state, action) => ({
    ...state,
    items: {
      ...state.items,
      tickets: action.payload ?? [],
      loading: false,
    },
  }),
  [getTickets.rejected]: (state, action) => ({
    ...state,
    items: {
      ...state.items,
      loading: false,
    },
  }),
  [updateDiscountCode.type]: (state, action) => ({
    ...state,
    price: {
      ...state.price,
      discountCode: action.payload,
    },
  }),
  [calculatePrice.pending]: (state, action) => ({
    ...state,
    price: {
      ...state.price,
      loading: true,
    },
  }),
  [calculatePrice.fulfilled]: (state, action) => ({
    ...state,
    price: {
      ...state.price,
      priceBeforeDiscount: action.payload.priceBeforeDiscount,
      priceAfterDiscount: action.payload.priceAfterDiscount,
      discountPrice: action.payload.discountPrice,
      loading: false,
      errors: null,
    },
  }),
  [calculatePrice.rejected]: (state, action) => ({
    ...state,
    price: {
      ...state.price,
      loading: false,
      errors: action.payload,
    },
  }),
  [setFields.type]: (state, action) => {
    return {
      ...state,
      fields: {
        ...state.fields,
        [action.payload.type]: {
          ...state.fields[action.payload.type],
          ...action.payload.fields,
        },
      },
    }
  },
  [buyTicket.pending]: (state) => ({
    ...state,
    loading: true,
  }),
  [buyTicket.fulfilled]: (state) => ({
    ...state,
    loading: false,
  }),
  [buyTicket.rejected]: (state) => ({
    ...state,
    loading: false,
  }),
})
