import * as React from 'react'

function EmptyAgendaListIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 185 351" {...props}>
      <defs>
        <linearGradient
          id="prefix__a"
          x1="43.275%"
          x2="50%"
          y1="55.161%"
          y2="26.37%"
        >
          <stop offset="0%" stopColor="#292929" stopOpacity={0.11} />
          <stop offset="100%" stopColor="#E669DD" stopOpacity={0.316} />
        </linearGradient>
        <linearGradient
          id="prefix__b"
          x1="58.09%"
          x2="50%"
          y1="56.034%"
          y2="25.365%"
        >
          <stop offset=".055%" stopColor="#292929" stopOpacity={0} />
          <stop offset="100%" stopColor="#E784E1" stopOpacity={0.405} />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill="#DA00D5" d="M0 195.65l92.5-46.07 92.5 46.07-92.5 46.072z" />
        <path fill="#FFE4FD" d="M0 195.608l92.5-46.027V88.846L0 134.873z" />
        <path fill="#FC86F6" d="M92.5 265.49L0 219.463V134.65l92.5 46.027z" />
        <path fill="#FFD0FC" d="M184.5 195.608L92 149.58V88.65l92.5 46.027z" />
        <path
          fill="#F7B5F3"
          d="M92.5 265.49l92.5-46.027V134.65l-92.5 46.027z"
        />
        <path
          fill="url(#prefix__a)"
          d="M93 131L0 84.916V0l92.497 45.822z"
          transform="translate(0 219.345)"
        />
        <path
          fill="url(#prefix__b)"
          d="M92.5 122.123L185 76.318V.144l-92.5 46z"
          transform="translate(0 219.345)"
        />
        <path
          fill="#F7B5F3"
          d="M15.386 85.15L73.12 64.134l24.953 68.56L40.34 153.71z"
        />
        <path
          fill="#FFF"
          d="M20.308 87.444l50.517-18.387 18.387 50.518-50.517 18.387z"
        />
        <path
          fill="#DA00D5"
          fillRule="nonzero"
          d="M59.291 109.986l-.33-1.268c-.953-2.619-.804-4.188 1.327-5.72 4.028-2.982 5.891-7.951 3.924-13.357-2.435-6.688-8.934-9.055-16.172-6.42-7.236 2.633-10.573 8.959-7.977 16.092l8.106-2.95c-.851-2.34.28-4.457 2.71-5.341 2.433-.885 4.599-.159 5.207 1.513.446 1.226.615 2.048-1.027 3.844l-1.97 1.791c-5.262 4.819-4.405 7.535-2.234 13.498l.562 1.185 7.874-2.867zm.89 14.069c2.808-1.022 4.48-3.862 3.477-6.619-.983-2.7-4.09-3.8-6.897-2.778-2.81 1.022-4.423 3.84-3.44 6.54 1.004 2.758 4.051 3.879 6.86 2.857z"
        />
        <g>
          <path
            fill="#FC86F6"
            d="M117.403 45.735l53.209 30.72-36.48 63.186-53.209-30.72z"
          />
          <path
            fill="#FFD0FC"
            d="M118.809 50.981l46.557 26.88-26.88 46.557-46.557-26.88z"
          />
          <path
            d="M119.873 78.242l26.583 15.348m-9.993 1.897l.014.008m-6.632-3.83l.014.009m-6.646-3.837l.014.008m9.932 13.365l.014.008m-6.632-3.83l.014.009m-6.646-3.837l.014.008m25.559-15.074l-2.797 4.844m-9.251-11.8l-2.797 4.844m13.793 4.605l-12.63-7.292c-4.38-2.529-8.505-1.7-11.061 2.728l-7.692 13.322c-2.597 4.498-1.284 8.541 3.097 11.07l12.614 7.283c4.394 2.537 8.515 1.686 11.071-2.742l7.756-13.433c2.57-4.419 1.238-8.399-3.155-10.935z"
            stroke="#FFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4}
          />
        </g>
        <g>
          <path
            fill="#FFE4FD"
            d="M43.406 19.877L117.59 0l23.605 88.092L67.01 107.97z"
          />
          <path
            fill="#FC86F6"
            d="M49.285 23.271l64.91-17.392 17.393 64.91-64.91 17.393z"
          />
          <path
            d="M71.565 47.007l36.346-9.739m-7.001 10.367l.018-.005m-9.067 2.43l.018-.006m-9.086 2.435l.02-.005m20.21 3.034l.018-.005m-9.068 2.43l.019-.005m-9.087 2.435l.02-.005M93.95 24.91l1.79 6.676m-18.262-2.262L79.266 36m15.939-7.993l-17.266 4.627c-5.989 1.604-8.84 5.925-7.206 12.024l4.92 18.358c1.66 6.195 6.31 8.588 12.298 6.984l17.248-4.622c6.008-1.61 8.834-5.944 7.2-12.044l-4.96-18.51c-1.615-6.105-6.226-8.426-12.234-6.816z"
            stroke="#FFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4}
          />
        </g>
      </g>
    </svg>
  )
}

export default EmptyAgendaListIcon
