import React from 'react'
import { useIntl } from 'react-intl'

import { SSteper, SStep } from './Steper.styled'

function Steper({ steps, step }) {
  const activeStepIndex = steps.findIndex((o) => o.key === step)
  const { formatMessage } = useIntl()

  return (
    <SSteper>
      {steps.map((step, index) => (
        <SStep
          key={step.key}
          done={activeStepIndex > index}
          active={activeStepIndex === index}
        >
          <span className="icon">
            <span>{index + 1}</span>
          </span>
          <span className="name">{formatMessage({ id: step.label })}</span>
        </SStep>
      ))}
    </SSteper>
  )
}

export default Steper
