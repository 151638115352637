import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SPattern = styled.div`
  ${({ theme }) => css`
    align-self: center;
    margin-top: ${theme.space.xxl}px;
    svg {
      height: 106px;

      margin: 0 ${-theme.space.xxl - theme.space.md}px;
      margin-bottom: ${theme.space.md}px;
    }
  `}
`
export const SSocials = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-top: ${theme.space.lg}px;
    justify-content: center;

    & > a {
      margin-right: ${theme.space.md}px;
    }

    ${respondTo.md`
     justify-content: flex-start;
   `}
  `}
`
export const SFollow = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-top: auto;
  `}
  ${respondTo.xl`
     margin-top: auto;
   `}
`
export const SSeparator = styled.span`
  ${({ theme }) => css`
    margin: 0 ${theme.space.sm}px;
  `}
`
export const SLang = styled.span`
  ${({ theme }) => css`
    a {
      text-decoration: none;
      color: inherit;

      ${({ active }) =>
        active &&
        css`
          border-bottom: 2px solid white;
        `}
    }
  `}
`
export const SLangs = styled.div`
  ${({ theme }) => css`
    font-size: 1.25rem;
    font-weight: ${theme.weight.bold};
    line-height: 1.6;
    color: #ffffff;

    margin-top: ${theme.space.xl}px;
  `}
`
export const SList = styled.ul`
  ${({ theme }) => css`
    display: inline-flex;
    flex-direction: column;
    padding-right: ${theme.space.xxl}px;

    li {
      display: inline-flex;
      flex-direction: column;

      color: white;

      margin-top: ${theme.space.md}px;

      a {
        font-size: 20px;
        text-decoration: none;
        line-height: 1.6;
        color: inherit;
        margin-right: auto;
        outline: none;
        position: relative;
        cursor: pointer;
        
        &.active {
          color: ${theme.colors.navigation.link.hover};
        }
        &.bold {
          font-weight: ${theme.weight.bold};
        }
        &.disable-link {
        cursor: not-allowed;
        color: ${theme.colors.disabledLink};
          &:hover {
            color: ${theme.colors.disabledLink};
          }
        }
        &.inactive {
          font-weight: ${theme.weight.normal};
        }
        &:hover::after, :active::after, :focus::after {
            position: absolute;
            content: '';
            color: ${theme.colors.pink};
            border-radius: 4px;
            color: ${theme.colors.pink};
            width: 100%;
            top: ${theme.space.xl - theme.space.sm}px;
            left: 0;
            z-index: -1;
          }   
        &:hover {
          color: ${theme.colors.navigation.link.hover};
        }
        }
      }

      span {
        font-size: 0.625rem;
        line-height: 1;
        text-align: right;
      }
    }
  `}
`
export const SSectionTitle = styled.p`
  ${({ theme }) => css`
    font-size: 20px;
    line-height: 1.33;
    color: ${theme.colors.navigation.link.hover};
    font-weight: ${theme.weight.bold};
    margin-top: ${theme.space.md}px;
  `}
`
export const SContent = styled.div`
  ${({ theme }) => css`
    flex: 1;
    display: flex;
    grid-template-columns: repeat(2, 400px);
    grid-template-rows: repeat(1, 1fr);
    grid-template-areas: '. . ';
    max-width: 1140px;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    flex-direction: column;
    > div {
      padding: 20px 0 0 0;
      display: flex;
      flex-direction: column;
      ${respondTo.md`
        padding: 20px 0 50px 0;
      `}
      ${respondTo.xl`
        border-left: 1px solid ${theme.colors.disabledLink};
        padding-left: 40px;
        padding-top: 52px;
        padding-bottom: 50px;
      `}
      &:nth-child(3) {
        border-right: 1px solid ${theme.colors.disabledLink};
      }
      &:nth-child(2) {
        ${respondTo.xl`
         padding-bottom: 50px;
         `}
      }
    }

    .sign-in-button {
      margin-top: 40px;
      margin-bottom: 64px;

      ${respondTo.lg`
          margin-top: ${theme.space.xxl}px;            
      `}
    }

    .sign-out-link {
      color: ${theme.colors.white};
      text-decoration: none;
      margin-top: -40px;
      margin-bottom: 20px;
      font-size: 1rem;
      letter-spacing: -0.8px;
      text-align: center;
    }

    .col__close {
      display: none;
      > button {
        margin: 16px 40px 0 auto;
        width: 24px;
        height: 24px;
        > div {
          width: 24px;
          height: 24px;
        }
        &:hover > span {
          background-color: ${theme.colors.navigation.link.hover};
        }
        span {
          background-color: ${theme.colors.white};
        }
      }
      ${respondTo.xl`
        display: block;
      `}
    }
    ${respondTo.md`
      display: grid;
      padding: 0 0 0 0;
    `}
    ${respondTo.xl`
      grid-template-columns: repeat(3,380px);
      grid-template-areas: ". . . ";
    `}
  `}
`
export const SContainer = styled.div`
  ${({ theme, headerHeight }) => css`
    display: flex;
    flex-direction: column;

    width: 100%;
    height: calc(100% - ${headerHeight}px);
    position: fixed;
    top: ${headerHeight}px;
    left: 0;
    padding: 0 ${theme.space.md}px 0 ${theme.space.md}px;

    background-color: ${theme.colors.darkGray};

    overflow-y: auto;
    overflow-x: hidden;
    ${respondTo.xl`
      z-index: 5;
      top: 0;
    `}
  `}
`
