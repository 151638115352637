import React from 'react'
import { SCompanies } from './CompaniesGrid.styled'
function CompaniesGrid({ companies }) {
  return (
    <SCompanies>
      <div className="companies-grid">
        {companies.map((company) => (
          <div className="company" key={company.company_name}>
            <a href={company.company_link} target="_blank">
              <img
                src={company.company_logo}
                alt={`${company.company_name} logo`}
              />
            </a>
          </div>
        ))}
      </div>
    </SCompanies>
  )
}

export default CompaniesGrid
