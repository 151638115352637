import styled, { css } from "styled-components"
import respondTo from "../../theme/respondTo"

export const SLegend = styled.p`
  ${({ theme }) => css`
    font-size: 0.75rem;
    text-align: right;
    color: ${theme.colors.lightGray};

    margin-top: ${theme.space.md}px;

    ${respondTo.lg`
      margin-top: ${theme.space.xl}px;
    `}
  `}
`
export const SGraphDetails = styled.div`
  flex: 1;
`
export const SGraph = styled.aside`
  ${({ theme }) => css`
    margin-top: ${theme.space.xl}px;

    ${respondTo.lg`
      display: flex;

      margin-top: ${theme.space.xxxl}px;

      & > div {
        flex: 1;
      }
    `}
  `}
`
export const SGraphData = styled.ul`
  ${({ theme }) => css`
    li {
      font-size: 1rem;
      line-height: 1.5;

      position: relative;
      
      &.pie-chart-data {
        &::before {
          background-color: ${theme.colors.darkBlue};
        }
        &:nth-child(2) {
          &::before {
            background-color: ${theme.colors.blue};
          }
        }
        &:nth-child(3) {
          &::before {
            background-color: ${theme.colors.green};
          }
        }
        &:nth-child(4) {
          &::before {
            background-color: ${theme.colors.pink};
          }
        }
      }

      em {
        font-weight: ${theme.weight.bold};
      }

      padding-left: ${theme.space.xxxl}px;
      margin-top: ${theme.space.md}px;

      &::before {
        content: "";
        width: 48px;
        height: 10px;

        position: absolute;
        top: ${theme.space.sm + 2}px;
        left: 0;

        border-radius: 5px;
        box-shadow: 0 12px 17px 2px rgba(0, 0, 0, 0.14);
        background-color: ${theme.colors.blue};
      }

      ${respondTo.lg`
        font-size: 1.5rem;
        line-height: 1.33;

        margin-top: ${theme.space.xl}px;

        &::before {
          top: ${theme.space.sm + 4}px;
        }
      `}

      &:nth-child(2) {
        &::before {
          background-color: ${theme.colors.darkBlue};
        }
      }
      &:nth-child(3) {
        &::before {
          background-color: ${theme.colors.pink};
        }
      }
      &:nth-child(4) {
        &::before {
          background-color: ${theme.colors.green};
        }
      }
      &:nth-child(5) {
        &::before {
          background-color: ${theme.colors.lightTurquoise};
        }
      }
      &:nth-child(6) {
        &::before {
          background-color: ${theme.colors.darkYellow};
        }
      }
      &:nth-child(7) {
        &::before {
          background-color: ${theme.colors.lightestBlue};
        }
      }
    }
  `}
`
export const SGraphHeading = styled.p`
  ${({ theme }) => css`
    font-size: 1rem;
    font-weight: ${theme.weight.bold};
    line-height: 1.5;

    ${respondTo.lg`
      font-size: 1.5rem;
      line-height: 1.33;
    `}
  `}
`
export const SGraphs = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    color: white;

    margin-top: ${theme.space.xl}px;

    .bar-graph {
      width: 289px;
      height: 386px;

      display: block;
      margin: 0 auto;

      ${respondTo.lg`
        width: 369px;
        height: 447px;
        `}
    }

    .pie-chart {
      width: 288px;
      height: 303px;

      display: block;
      margin: 0 auto;

      ${respondTo.lg`
        width: 370px;
        height: 389px;
      `}
    }
  `}
`
export const SContainer = styled.section`
  ${({ theme }) => css`
    margin-top: ${theme.space.xl}px;
    ${respondTo.lg`
      margin-top: ${theme.space.xxxl}px;
    `}
  `}
`
