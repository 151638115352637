import React from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { Button, TicketProcessBox, TicketProcessInput } from 'components'
import useLocalizeRoute from 'utils/useLocalizeRoute'
import { AppRoute } from 'const'
import client from 'api/client'

function ForgotPassword() {
  const history = useHistory()

  const { locale, formatMessage } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()

  const { register, handleSubmit, formState, setError } = useForm({
    defaultValues: { email: '' },
  })

  const handlePrev = () => {
    history.replace(localizeRouteKey(AppRoute.SignIn))
  }

  const onSubmit = async (values) => {
    try {
      const response = await client({
        endpoint: 'api/reset-password/form',
        method: 'POST',
        body: JSON.stringify({ ...values, locale }),
      })

      if (!response.ok) {
        const data = await response.json()
        Object.keys(data).forEach((key) => {
          const error = data[key].flat()
          if (!Array.isArray(error)) return

          const [errorMessage] = error
          setError(key, {
            type: 'required',
            message: errorMessage,
          })
        })

        throw new Error()
      }

      history.replace(localizeRouteKey(AppRoute.SignIn), {
        forgotPassword: true,
      })
    } catch (error) {
      console.log('[POST] forgot password', error)
    }
  }
  return (
    <TicketProcessBox margin>
      <h2 className="heading">
        {formatMessage({ id: 'user.forgotPasswordHeading' })}
      </h2>
      <p className="informations">
        {formatMessage({ id: 'user.forgotPasswordDescription' })}
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <TicketProcessInput
          {...{
            label: 'labels.email',
            htmlFor: 'email',
            error: formState.errors.email,
          }}
        >
          <input
            id="email"
            type="text"
            {...register('email', {
              required: {
                value: true,
                message: 'errors.emailError',
              },
            })}
          />
        </TicketProcessInput>
        <div className="actions">
          <Button
            disabled={formState.isSubmitting}
            loading={formState.isSubmitting}
          >
            {formatMessage({ id: 'user.forgotPasswordSubmit' })}
          </Button>
          <Button type="button" onClick={handlePrev} bordered>
            {formatMessage({ id: 'general.back' })}
          </Button>
        </div>
      </form>
    </TicketProcessBox>
  )
}

export { ForgotPassword }
