import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SContainer = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundBlack};
    padding: ${theme.space.xl + 8}px ${theme.space.lg}px;

    ${respondTo.lg`
      padding: ${theme.space.xl * 2}px ${theme.space.lg}px;
    `}

    .wrapper {
      max-width: 1135px;
      margin: 0 auto;
    }
    .heading {
      color: white;
      font-weight: ${theme.weight.bold};
      font-size: 1.75rem;
      line-height: 2.188rem;
      margin: 0 auto ${theme.space.xl}px;
      text-align: center;

      text-shadow: 1px 1px 0 ${theme.colors.azure};

      ${respondTo.lg`
        font-size: 3.25rem;
        line-height: 4.125rem;
        margin-bottom: ${theme.space.xxl + 4}px;
        text-shadow: 2px 2px 0 ${theme.colors.azure};
      `}
    }

    .content {
      display: flex;
      flex-direction: column;

      ${respondTo.lg`
        flex-direction: row;
        gap: ${theme.space.xl + 8}px;
      `}
    }

    img {
      max-height: 312px;
      max-width: 326px;
      margin: 0 auto;

      ${respondTo.lg`
        max-height: 620px;
        max-width:639px;
      `}
    }

    .description {
      &-subheading {
        color: ${theme.colors.pink};
        font-weight: 500;
        line-height: 1.375rem;
        margin: ${theme.space.md + 4}px 0;

        ${respondTo.lg`
          font-size: 1.25rem;
          line-height: 1.75rem;
        `}
      }
      &-schedule {
        font-size: 1.5rem;
        line-height: 1.875rem;
        color: ${theme.colors.white};
        font-weight: ${theme.weight.bold};
        margin: ${theme.space.md + 4}px 0;

        ${respondTo.lg`
          font-size: 2.25rem;
          line-height: 3.125rem;
        `}
      }
      &-text {
        font-size: 1.125rem;
        line-height: 1.563rem;
        color: ${theme.colors.lightGray2};
        margin-bottom: ${theme.space.xl + 8}px;

        ${respondTo.lg`
          font-size: 1.375rem;
          line-height: 1.875rem;
        `}
      }
    }

    button {
      width: 100%;

      ${respondTo.lg`
        width: auto;
      `}
      i {
        padding-top: ${theme.space.xs}px;
        margin-left: ${theme.space.sm}px;
      }
    }
  `}
`
