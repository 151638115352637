import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const MWrapper = styled.div`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 12;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`
export const MContent = styled.div`
  ${({ theme }) => css`
    width: 80%;
    background-color: ${theme.colors.darkestGray};
    display: flex;
    flex-direction: column;
    position: relative;
    .close {
      position: absolute;
      right: -25px;
      top: -25px;
      &:hover {
        cursor: pointer;
      }
    }
    ${respondTo.md`
      flex-direction: row;
      align-items: center;
    `}
    ${respondTo.lg`
    width: 559px;
    `}
  `}
`
export const MWidthSmall = styled.div`
  ${({ theme }) => css`
    display: none;
  `}
  img {
    width: 100%;
    ${respondTo.md`
      max-width: 221px; 
   `}
  }
  ${respondTo.md`
     display: block;
     width: 221px; 
   `}
`

export const MWidthBig = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: ${theme.space.md}px;
    font-size: 18px;
    line-height: 20px;
    font-weight: ${theme.weight.bold};

    ${respondTo.md`
     width: calc(100% - 221px);
   `}
  `}
`
export const MTopQuestion = styled.div`
  ${({ theme }) => css`
    width: 100%;
    color: ${theme.colors.white};
  `}
`
export const MInfo = styled.div`
  ${({ theme }) => css`
   margin-top: ${theme.space.lg}px;
   p {
     font-size: 18px;
     line-height: 20px;
     font-weight: ${theme.weight.bold};
     color: ${theme.colors.white};
     span {
      color: ${theme.colors.lightGray};
      font-size: 12px;
      line-height: 14px;
      font-weight: ${theme.weight.normal}
      margin-bottom: ${theme.space.xs}px;
     }
    }
    .contact__details {
      margin-top: ${theme.space.md}px;
      a {
       font-size: 14px;
       line-height: 12px;
       font-weight: ${theme.weight.normal};
       color: ${theme.colors.white};
       text-decoration: none;
       transition: 0.3s color ease-in;
       display: block;
       margin-bottom: ${theme.space.sm}px;
       &:hover {
        color: ${theme.colors.pink};
        text-decoration: none;
       }
       &.mail {
        color: ${theme.colors.pink};
       }
      }  
    }
  `}
`
