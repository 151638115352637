import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SJoinPartnersAction = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;

    margin-top: ${theme.space.xl}px;
  `}
`
export const SJoinPartnersDataInformation = styled.p`
  ${({ theme }) => css`
    font-size: 0.75rem;
    line-height: 1.33;
    text-align: right;
    color: ${theme.colors.lightGray};

    margin-top: ${theme.space.sm}px;
  `}
`
export const SJoinPartner = styled.div`
  ${({ theme }) => css`
    width: 140px;
    height: 70px;

    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: white;

    margin: ${theme.space.xs}px;
    padding: ${theme.space.md}px;

    a {
      flex: 1;
    }

    img {
      width: auto;
      max-width: 100%;
      max-height: 100%;
    }
  `}
`
export const SJoinPartnersGrid = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    margin-top: ${theme.space.xl}px;
  `}
`
export const SJoinPartners = styled.section`
  ${({ theme }) => css`
    padding: 0 0 ${theme.space.xxxl}px 0;
    h2 {
      color: ${theme.colors.white};
    }
  `}
`
export const SCategoryTitle = styled.h2`
  ${({ theme }) => css`
    font-size: 2rem;
    font-weight: ${theme.weight.bold};
    line-height: 1.25;
    color: white;
    text-align: center;

    margin-top: ${theme.space.xl}px;
  }
  `}
`
