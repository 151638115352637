import * as React from 'react'

function DeleteIcon(props) {
  return (
    <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.184 4.494s-.32 4.005-.506 5.692c-.088.806-.582 1.278-1.39 1.293-1.539.028-3.079.03-4.617-.003-.778-.016-1.263-.494-1.35-1.286-.187-1.702-.505-5.696-.505-5.696M11 2.575H1m8.073 0a.974.974 0 01-.952-.788l-.144-.723A.756.756 0 007.248.5H4.752a.756.756 0 00-.73.564l-.143.723a.974.974 0 01-.952.788"
        stroke="currentColor"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DeleteIcon
