import React, { useEffect, useRef, useMemo, useState } from 'react'
import Sticky from 'react-stickynode'
import { useTheme } from 'styled-components'
import { useIntl } from 'react-intl'
import { useBreakpoint } from 'modules/breakpoint'
import { useSelector } from 'react-redux'

import client from 'api/client'

import {
  Filtration,
  SharpShadowText,
  CompanyTile,
  Wrapper,
  Loader,
  ListEmptyState,
  SearchBar,
} from '../../components'

import { SCompaniesList } from './CompaniesList.styled'

import 'simplebar/dist/simplebar.css'

import { FOOTER_DOM_ID } from '../Footer/Footer'

import { breakpoints as staticBreakpoints } from '../../theme'
import parseFiltration from '../../utils/parseFiltration'

function CompaniesList() {
  const theme = useTheme()
  const filtrationRef = useRef()
  const sectionRef = useRef()
  const { formatMessage } = useIntl()

  const { data: dictionary, loading: filtrationLoading } = useSelector(
    ({ app }) => app.dictionary
  )
  const filtrationBuildData = useMemo(() => {
    if (!dictionary) return null
    return {
      // cities: dictionary.cities,
      tags: dictionary.tags,
      employeesCount: dictionary.employeesCount,
    }
  }, [dictionary])

  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [filtration, setFiltration] = useState(
    filtrationBuildData ? parseFiltration(filtrationBuildData) : null
  )
  const [searchValues, setSearchValues] = useState([])
  const breakpoints = useBreakpoint()

  const calculateFiltrationContainer = () => {
    if (typeof window === 'undefined' || !filtrationRef?.current) return
    const windowHeight = window.innerHeight
    const windowWidth = window.innerWidth
    const windowScrollY = window.scrollY

    if (windowWidth <= staticBreakpoints.lg) {
      filtrationRef.current.style.height = 'initial'
      return
    }

    const filtrationTopOffset =
      filtrationRef.current.getBoundingClientRect().top

    const footerYPosition = document
      .getElementById(FOOTER_DOM_ID)
      .getBoundingClientRect().top
    const isFooterVisible = windowScrollY + windowHeight >= footerYPosition
    const footerVisibileHeight = isFooterVisible
      ? windowHeight - footerYPosition
      : 0

    const filtrationHeight =
      windowHeight - footerVisibileHeight - filtrationTopOffset

    filtrationRef.current.style.height = `${filtrationHeight}px`
  }

  const fetchData = async ({ newFiltration }) => {
    setLoading(true)
    const filtersSource = newFiltration || filtration

    const searchQuery = searchValues
      .map((searchValue) => searchValue.label)
      .map((label) => `filters[query][]=${label}`)
      .join('&')

    const filters = filtersSource?.reduce((prev, acc) => {
      const tempCategory = acc.category
      const temp = acc.fields
        .filter((field) => field.value)
        .map((field) => `filters[${tempCategory}][]=${field.id}`)
        .join('&')

      if (!temp.length) {
        return prev
      }

      return `${prev}&${temp}`
    }, '')

    const response = await client({
      endpoint: `api/employer_list/all?filters[is_listed]=1&per_page=10000&${filters}&${searchQuery}`,
    })

    const { data } = await response.json()
    if (!data || (data && !Array.isArray(data))) return

    setItems(data)
    setLoading(false)
  }

  const handleClear = () => {
    const clearFiltration = filtration.reduce((acc, current) => {
      return [
        ...acc,
        {
          ...current,
          fields: current.fields.map((field) => ({ ...field, value: false })),
        },
      ]
    }, [])
    setFiltration(clearFiltration)
    setSearchValues([])
    fetchData({ newFiltration: clearFiltration })
  }

  const handleSubmit = async () => {
    await fetchData({})
  }

  const handleSearchChange = (value) => {
    setSearchValues(value)
  }

  useEffect(() => {
    fetchData({})
  }, [searchValues])

  const handleFiltration = (newFiltration) => {
    setFiltration(newFiltration)
    if (!breakpoints.lg) {
      fetchData({ newFiltration })
    }
  }

  useEffect(() => {
    calculateFiltrationContainer()

    fetchData({})

    window.addEventListener('scroll', calculateFiltrationContainer)

    return () => {
      window.removeEventListener('scroll', calculateFiltrationContainer)
    }
  }, [])

  useEffect(() => {
    if (!filtration && filtrationBuildData) {
      setFiltration(parseFiltration(filtrationBuildData))
    }
  }, [filtrationBuildData])

  useEffect(() => {
    setTimeout(() => {
      calculateFiltrationContainer()
    }, 1000)
  }, [items])

  return (
    <>
      <SCompaniesList ref={sectionRef}>
        <Wrapper>
          <div className="banner-wrapper">
            <SharpShadowText
              as="h1"
              className="heading"
              color={theme.colors.pink}
            >
              {formatMessage({ id: 'companies' })}
            </SharpShadowText>
          </div>
          <div className="content">
            {!breakpoints.lg && (
              <>
                <div className="sidebar">
                  <Sticky enabled={!breakpoints.lg} top={72}>
                    <div className="filtration" ref={filtrationRef}>
                      <Filtration
                        {...{
                          filtration,
                          loading: filtrationLoading,
                          onChange: handleFiltration,
                          onClear: handleClear,
                          onSubmit: handleSubmit,
                        }}
                      />
                    </div>
                  </Sticky>
                </div>
              </>
            )}
            <div className="right-side">
              <SearchBar
                {...{
                  handleSearchChange,
                  searchValues,
                }}
              />
              {breakpoints.lg && (
                <div className="sidebar">
                  <Sticky enabled={!breakpoints.lg} top={72}>
                    <div className="filtration" ref={filtrationRef}>
                      <Filtration
                        {...{
                          filtration,
                          loading: filtrationLoading,
                          onChange: handleFiltration,
                          onClear: handleClear,
                          onSubmit: handleSubmit,
                        }}
                      />
                    </div>
                  </Sticky>
                </div>
              )}
              {loading ? <Loader /> : null}
              {items.length ? (
                <div className="listing">
                  {items.map((item) => (
                    <CompanyTile key={item.employer_id} {...{ item }} />
                  ))}
                </div>
              ) : !loading ? (
                <ListEmptyState
                  companies
                  text={formatMessage({ id: 'companies.empty' })}
                  buttonText={formatMessage({ id: 'companies.emptyButton' })}
                  onClick={handleClear}
                />
              ) : null}
            </div>
          </div>
        </Wrapper>
      </SCompaniesList>
    </>
  )
}

export default CompaniesList
