import styled, { css } from "styled-components"
import respondTo from "../../theme/respondTo"

export const SSpeakersList = styled.section`
  ${({ theme }) => css`
      
    .simplebar-scrollbar::before {
      background-color: #d8d8d8;
      opacity: 1;
    }
  
    padding-top: ${theme.space.md}px;
    .heading {
      font-size: 2.62rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.6;
      color: white;
    }

    .filtration {
      overflow-y: auto;

      margin-top: ${theme.space.md}px;
      margin-bottom: ${theme.space.xl}px; 
      
      ::-webkit-scrollbar {
          display: none;
      }

      scrollbar-width: none;  
    }
    
    .content {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
    }

    .banner-wrapper {
      margin-bottom: ${theme.space.xxxl}px;
    }
    
    .listing {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      grid-gap: 0 ${theme.space.md}px;
    }

    .loading {
      margin: ${theme.space.xl}px 0;
    }

    ${respondTo.lg`
      .heading {
        font-size: 4.25rem;
        padding-top: ${theme.space.xxxl}px;
      }

      .filtration {
        margin-top: 0;
        margin-bottom: 0;
        padding-right: ${theme.space.md}px;
      }

      .content {
        grid-template-columns: 272px 1fr;
        grid-template-rows: 1fr;

        margin-top: ${theme.space.xxxl}px;
        // padding-bottom: ${theme.space.xxxl * 2}px;
      }

      .listing {
        grid-template-columns: repeat(2, minmax(200px, 1fr));
        grid-gap: ${theme.space.xxxl}px ${theme.space.md}px;
        
        margin-top: 0;
        margin-bottom: ${theme.space.xl}px;
      }
    `}

    ${respondTo.xl`
      .listing {
        grid-template-columns: repeat(3, minmax(200px, 1fr));
      }
    `}

    ${respondTo.xxl`
      .listing {
        grid-template-columns: repeat(4, minmax(200px, 1fr));
      }
    `}
  `}
`
