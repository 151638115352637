function EmptySpeakersListIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 185 351" {...props}>
      <defs>
        <linearGradient x1="43.275%" y1="55.161%" x2="50%" y2="26.37%" id="a">
          <stop stopColor="#292929" stopOpacity=".11" offset="0%"/>
          <stop stopColor="#E669DD" stopOpacity=".316" offset="100%"/>
        </linearGradient>
        <linearGradient x1="58.09%" y1="56.034%" x2="50%" y2="25.365%" id="b">
          <stop stopColor="#292929" stopOpacity="0" offset=".055%"/>
          <stop stopColor="#E784E1" stopOpacity=".405" offset="100%"/>
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill="#DA00D5" d="M0 195.65l92.5-46.07 92.5 46.07-92.5 46.071z"/>
        <path fill="#FFE4FD" d="M0 195.607l92.5-46.027V88.845L0 134.872z"/>
        <path fill="#FC86F6" d="M92.5 265.49L0 219.463V134.65l92.5 46.027z"/>
        <path fill="#FFD0FC" d="M184.5 195.607L92 149.58V88.65l92.5 46.028z"/>
        <path fill="#F7B5F3" d="M92.5 265.49l92.5-46.027V134.65l-92.5 46.027z"/>
        <g>
          <path fill="url(#a)" d="M93 131L0 84.916V0l92.497 45.822z" transform="translate(0 219.345)"/>
          <path fill="url(#b)" d="M92.5 122.123L185 76.318V.144l-92.5 46z" transform="translate(0 219.345)"/>
        </g>
        <g>
          <path fill="#F7B5F3" d="M15.386 85.15L73.12 64.134l24.953 68.56L40.34 153.71z"/>
          <path fill="#FFF" d="M20.308 87.444l50.517-18.387 18.387 50.518-50.517 18.387z"/>
          <path d="M59.29 109.986l-.33-1.268c-.953-2.62-.803-4.188 1.328-5.721 4.029-2.981 5.891-7.95 3.924-13.356-2.434-6.688-8.934-9.055-16.171-6.421-7.237 2.634-10.573 8.96-7.977 16.093l8.105-2.95c-.852-2.34.28-4.456 2.711-5.341 2.432-.885 4.598-.159 5.206 1.513.447 1.226.615 2.048-1.026 3.845l-1.971 1.79c-5.262 4.818-4.404 7.535-2.234 13.498l.562 1.184 7.874-2.866zm.89 14.069c2.808-1.022 4.482-3.862 3.478-6.619-.983-2.7-4.09-3.8-6.898-2.778-2.808 1.022-4.422 3.84-3.439 6.54 1.004 2.758 4.051 3.879 6.86 2.857z" fill="#DA00D5" fillRule="nonzero"/>
        </g>
        <g>
          <path fill="#FC86F6" d="M117.403 45.735l53.209 30.72-36.48 63.186-53.209-30.72z"/>
          <path fill="#FFD0FC" d="M118.809 50.981l46.557 26.88-26.88 46.557-46.557-26.88z"/>
          <g stroke="#DA00D5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4">
            <path d="M109.531 84.582s-1.47 3.114-1.953 4.084c-.65 1.3-.968 2.988-.71 4.437.362 2.05 1.446 3.326 3.267 4.382 2.141 1.24 17.725 10.238 19.87 11.471 1.824 1.05 3.471 1.35 5.428.638 1.384-.5 2.686-1.62 3.49-2.832.596-.905 2.557-3.734 2.557-3.734M129.41 72.926l.366-.634c1.204-2.086 3.89-2.787 6.004-1.567l4.474 2.583c2.112 1.22 2.85 3.898 1.646 5.984l-.365.635M124.27 95.829l1.276-2.212M116.434 72.41l-3.422 5.927c2.074 4.081 5.18 7.895 9.204 11.018a5.158 5.158 0 016.218-.76c2.058 1.188 3.016 3.581 2.408 5.763 4.733 1.933 9.605 2.725 14.204 2.474l3.422-5.928c1.672-2.896.657-6.591-2.278-8.286L124.766 70.25c-2.935-1.694-6.66-.736-8.332 2.16z"/>
          </g>
        </g>
        <g>
          <path fill="#FFE4FD" d="M43.406 19.877L117.59 0l23.605 88.092L67.01 107.97z"/>
          <path fill="#FC86F6" d="M49.285 23.271l64.91-17.392 17.393 64.91-64.91 17.393z"/>
          <g stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4">
            <path d="M74.608 58.586s1.162 3.24 1.506 4.269c.46 1.379 1.429 2.797 2.636 3.639 1.705 1.194 3.374 1.33 5.408.789 2.391-.637 19.772-5.294 22.162-5.939 2.032-.548 3.409-1.5 4.289-3.387.624-1.332.754-3.045.465-4.47-.218-1.061-.832-4.45-.832-4.45M80.421 36.288l-.19-.707c-.623-2.327.782-4.722 3.14-5.354l4.988-1.337c2.356-.631 4.772.74 5.395 3.067l.192.707M92.981 56.118l-.66-2.467M70.881 45.098l1.772 6.611c4.352 1.42 9.246 1.92 14.298 1.283a5.158 5.158 0 013.86-4.934c2.296-.615 4.666.4 5.778 2.372 4.714-1.98 8.719-4.865 11.793-8.294l-1.772-6.612c-.865-3.23-4.196-5.125-7.47-4.248l-23.895 6.402c-3.273.878-5.23 4.19-4.364 7.42z"/>
          </g>
        </g>
      </g>
    </svg>

  )
}

export default EmptySpeakersListIcon
