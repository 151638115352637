import React, { useEffect, useRef, useMemo, useState } from 'react'
import Sticky from 'react-stickynode'
import { useTheme } from 'styled-components'
import { useIntl } from 'react-intl'
import { useBreakpoint } from 'modules/breakpoint'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import useLocalizeRoute from 'utils/useLocalizeRoute'

import client from 'api/client'

import {
  AgendaTile,
  Filtration,
  SharpShadowText,
  Wrapper,
  Loader,
  Tabs,
  ListEmptyState,
  PomeraniaBanner,
} from '../../components'

import { SAgendaList } from './AgendaList.styled'

import 'simplebar/dist/simplebar.css'

import { FOOTER_DOM_ID } from '../Footer/Footer'

import { breakpoints as staticBreakpoints } from '../../theme'
import parseFiltration from '../../utils/parseFiltration'

const AgendaDay = {
  FIRSTDAY: 0,
  SECONDDAY: 1,
}

const tabs = [
  { key: AgendaDay.FIRSTDAY, text: 'firstDay' },
  { key: AgendaDay.SECONDDAY, text: 'secondDay' },
]

function AgendaList({ year }) {
  const theme = useTheme()
  const filtrationRef = useRef()
  const location = useLocation()

  const { formatMessage } = useIntl()

  const { data: dictionary, loading: filtrationLoading } = useSelector(
    ({ app }) => app.dictionary
  )

  const [items, setItems] = useState([])
  const [itemsByDay, setItemsByDay] = useState([])
  const [selectedDay, setSelectedDay] = useState(AgendaDay.FIRSTDAY)
  const [loading, setLoading] = useState(false)
  const [filtration, setFiltration] = useState(null)

  const sortEventsByActivity = (events) => {
    const eventsNotFinished = events.filter(
      (event) => new Date() < new Date(event.event_end_timestamp * 1000)
    )
    const eventsFinished = events.filter(
      (event) => new Date() >= new Date(event.event_end_timestamp * 1000)
    )
    return [...eventsNotFinished, ...eventsFinished]
  }

  const itemsByDayAndActivity = useMemo(
    () =>
      itemsByDay.map((item) => {
        const sortedEvents = item.events.map((event) => {
          return {
            ...event,
            data: sortEventsByActivity(event.data),
          }
        })

        return {
          ...item,
          events: sortedEvents,
        }
      }),
    [itemsByDay]
  )

  const breakpoints = useBreakpoint()
  const { localizeRouteKey } = useLocalizeRoute()

  const calculateFiltrationContainer = () => {
    if (typeof window === 'undefined' || !filtrationRef?.current) return
    const windowHeight = window.innerHeight
    const windowWidth = window.innerWidth
    const windowScrollY = window.scrollY

    if (windowWidth <= staticBreakpoints.lg) {
      filtrationRef.current.style.height = 'initial'
      return
    }

    const filtrationTopOffset =
      filtrationRef.current.getBoundingClientRect().top

    const footerYPosition = document
      .getElementById(FOOTER_DOM_ID)
      .getBoundingClientRect().top
    const isFooterVisible = windowScrollY + windowHeight >= footerYPosition
    const footerVisibileHeight = isFooterVisible
      ? windowHeight - footerYPosition
      : 0

    const filtrationHeight =
      windowHeight - footerVisibileHeight - filtrationTopOffset

    filtrationRef.current.style.height = `${filtrationHeight}px`
  }

  // const tricitySelected =
  //   filtration
  //     ?.find((obj) => obj.category === 'cities')
  //     .fields.find((field) => field.name === '3cITy')?.value ?? false

  const fetchData = async ({ newFiltration }) => {
    setLoading(true)
    const filtersSource = newFiltration || filtration

    const filters = filtersSource?.reduce((prev, acc) => {
      const tempCategory = acc.category
      const temp = acc.fields
        .filter((field) => field.value)
        .map((field) => `filters[${tempCategory}][]=${field.id}`)
        .join('&')

      if (!temp.length) {
        return prev
      }

      return `${prev}&${temp}`
    }, '')

    const response = await client({
      endpoint: `api/calendar_event/all?per_page=10000&${filters}`,
    })

    const { data } = await response.json()
    if (!data || (data && !Array.isArray(data))) return

    const currentYear = new Date().getFullYear()
    const dataByYear = data.filter((el) =>
      el.city.includes(year || currentYear.toString())
    )
    setItems(dataByYear)

    setLoading(false)
  }

  const handleClear = () => {
    const clearFiltration = filtration.reduce((acc, current) => {
      return [
        ...acc,
        {
          ...current,
          fields: current.fields.map((field) => ({ ...field, value: false })),
        },
      ]
    }, [])
    setFiltration(clearFiltration)
    fetchData({ newFiltration: clearFiltration })
  }

  const handleSubmit = async () => {
    await fetchData({})
  }

  const handleFiltration = (newFiltration) => {
    setFiltration(newFiltration)
    if (!breakpoints.lg) {
      fetchData({ newFiltration })
    }
  }

  const handleChangeDay = (day) => () => {
    setSelectedDay(day)
    filterByDay(items, day)
  }

  const [warsawEvents, cracowEvents] = useMemo(
    () =>
      items.reduce(
        (acc, event) => {
          acc[event.city === 'Warszawa 2023' ? 0 : 1].push(event)
          return acc
        },
        [[], []]
      ),
    [items]
  )

  const filterByDay = (items, selectedDay) => {
    if (!items.length) {
      setItemsByDay([])
    } else if (selectedDay === AgendaDay.FIRSTDAY) {
      items.length >= 4
        ? setItemsByDay([
            ...cracowEvents.filter((event) => event.day === 0),
            ...warsawEvents.filter((event) => event.day === 0),
          ])
        : setItemsByDay(items.filter((event) => event.day === 0))
    } else {
      items.length >= 4
        ? setItemsByDay([
            ...cracowEvents.filter((event) => event.day === 1),
            ...warsawEvents.filter((event) => event.day === 1),
          ])
        : setItemsByDay(items.filter((event) => event.day === 1))
    }
  }

  useEffect(() => {
    calculateFiltrationContainer()

    fetchData({})

    window.addEventListener('scroll', calculateFiltrationContainer)
    return () => {
      window.removeEventListener('scroll', calculateFiltrationContainer)
    }
  }, [location])

  useEffect(() => {
    setFiltration(
      parseFiltration({
        ...(year
          ? {
              cities: dictionary.cities.filter((city) =>
                city.name.includes(year)
              ),
            }
          : {}),
        // tags: dictionariesByYear.tags,
        tracks: dictionary.tracks,
        languages: dictionary.lecturesLanguages,
      })
    )
  }, [dictionary, location])

  useEffect(() => {
    setTimeout(() => {
      calculateFiltrationContainer()
    }, 1000)
  }, [items])

  useEffect(() => {
    filterByDay(items, selectedDay)
  }, [items, selectedDay])

  return (
    <>
      <SAgendaList>
        <Wrapper>
          {/*<div className="banner-wrapper">*/}
          <SharpShadowText
            as="h1"
            className="heading"
            color={theme.colors.pink}
          >
            {formatMessage({ id: `agenda` }, { year })}
          </SharpShadowText>
          {/*{tricitySelected && (*/}
          {/*  <Link to={localizeRouteKey(AppRoute.BuyTicket)}>*/}
          {/*    <PomeraniaBanner*/}
          {/*      className="pomerania-banner"*/}
          {/*      heading={formatMessage(*/}
          {/*        { id: 'tickets.bannerHeading' },*/}
          {/*        { strong: (chunk) => <strong>{chunk}</strong> }*/}
          {/*      )}*/}
          {/*    />*/}
          {/*  </Link>*/}
          {/*)}*/}
          {/*</div>*/}
          <div className="content">
            {!breakpoints.lg && (
              <>
                <div className="sidebar">
                  <Sticky enabled={!breakpoints.lg} top={72}>
                    <div className="filtration" ref={filtrationRef}>
                      <Filtration
                        {...{
                          filtration,
                          loading: filtrationLoading,
                          onChange: handleFiltration,
                          onClear: handleClear,
                          onSubmit: handleSubmit,
                        }}
                      />
                    </div>
                  </Sticky>
                </div>
              </>
            )}
            <div className="right-side">
              {breakpoints.lg && (
                <div className="sidebar">
                  <Sticky enabled={!breakpoints.lg} top={72}>
                    <div className="filtration" ref={filtrationRef}>
                      <Filtration
                        {...{
                          filtration,
                          loading: filtrationLoading,
                          onChange: handleFiltration,
                          onClear: handleClear,
                          onSubmit: handleSubmit,
                        }}
                      />
                    </div>
                  </Sticky>
                </div>
              )}
              <div className="tabs-wrapper">
                <Tabs
                  style={{ marginLeft: 0, maxWidth: '100%' }}
                  selected={selectedDay}
                  onChange={handleChangeDay}
                  tabs={tabs}
                />
              </div>
              {loading ? <Loader /> : null}
              {itemsByDayAndActivity.length ? (
                <div className="listing">
                  {itemsByDayAndActivity.map((item) => (
                    <AgendaTile key={item.event_date} {...{ item }} />
                  ))}
                </div>
              ) : !loading ? (
                <ListEmptyState
                  agenda
                  text={formatMessage({ id: 'agenda.empty' })}
                  buttonText={formatMessage({ id: 'agenda.emptyButton' })}
                  onClick={handleClear}
                />
              ) : null}
            </div>
          </div>
        </Wrapper>
      </SAgendaList>
    </>
  )
}

export default AgendaList
