import styled, { css } from 'styled-components'

export const SContainer = styled.div`
  ${({ theme, show, isBigger }) => css`
    height: ${isBigger ? '450px' : '300px'};
    max-width: ${isBigger ? '260px' : '175px'};
    position: fixed;
    right: 16px;
    bottom: 24px;
    display: ${show ? 'block' : 'none'};
    width: 100%;
    animation: 0.5s forwards popIn;

    @keyframes popIn {
      0% {
        transform: scale(0.95);
      }
      50% {
        transform: scale(1.05);
      }
      75% {
        transform: scale(0.95);
      }
      100% {
        transform: scale(1);
      }
    }

    .wrapper {
      position: absolute;
      z-index: 20;
      cursor: pointer;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;

      &:hover .resize-icon {
        transform: scale(1.3);
      }
    }
    .close-button {
      position: absolute;
      z-index: 21;
      color: white;
      right: -8px;
      top: -8px;
      background-color: black;
      border: none;
      border-radius: 50%;
      width: 28px;
      height: 28px;
      cursor: pointer;
      padding: 0;

      .close-icon {
        display: block;
        margin: 0 auto;
        width: 20px;
        height: 20px;
      }
    }

    .video {
      position: relative;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      border-radius: 8px;

      &:focus {
        box-shadow: none;
        border: none;
        outline: unset;
      }

      &-wrapper {
        position: relative;
        padding: 8px;
        background-color: white;
        border-radius: 8px;
        display: flex;
        align-items: center;

        &:focus {
          border: none;
          outline: none;
        }
      }
    }

    .resize-icon {
      position: absolute;
      z-index: 10;
      width: 18px;
      height: 18px;
      color: white;
      bottom: 16px;
      right: 16px;
      border: none;
      outline: none;
      background-color: transparent;
      box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
        0 4px 6px -4px rgb(0 0 0 / 0.1);
      transition: all 0.2s ease-in-out;
    }

    .hello-wrapper {
      position: absolute;
      top: 24px;
      left: -38px;
      z-index: 10;
      padding: 6px 16px;
      border-radius: 24px 24px 8px 24px;
      background-color: ${theme.colors.pink};
      color: white;
    }

    .cta-button {
      position: absolute;
      z-index: 20;
      bottom: 24px;
      left: 50%;
      transform: translate(-50%, 0);
      padding: 4px 20px;

      background-color: ${theme.colors.pink};
      color: ${theme.colors.white};
      border: 1px solid transparent;
      border-radius: 8px;
      white-space: nowrap;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover,
      &:focus {
        color: ${theme.colors.pink};
        background-color: ${theme.colors.darkestGray};
        border: 1px solid ${theme.colors.pink};
      }
    }

    .sound-button {
      border: none;
      outline: none;
      background-color: ${theme.colors.lightGray};
      position: absolute;
      color: ${theme.colors.darkestGray};
      top: 16px;
      left: 16px;
      border-radius: 50%;
      width: 26px;
      height: 26px;
      cursor: pointer;
      padding: 0;

      .sound-icon {
        display: block;
        margin: 0 auto;
        width: 16px;
        height: 16px;
      }
    }

    .tooltip {
      font-weight: 400;
      margin-top: -6px;
    }
    .arrow {
      display: none;
    }
  `}
`
