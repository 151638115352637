import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

import speakersHeroBackground from '../../assets/images/speakers-hero.png'
import speakersHeroBackgroundMobile from '../../assets/images/speakers-hero-mobile.png'

export const SSubHeading = styled.p`
  ${({ theme }) => css`
    font-size: 1rem;
    line-height: 1.5rem;
    color: white;

    margin-top: ${theme.space.xl}px;

    ${respondTo.lg`
      font-size: 1.25rem;
      line-height: 2rem;
      margin-top: ${theme.space.xl}px;
    `}
  `}
`
export const SHeroTop = styled.div`
  text-align: left;

  ${respondTo.md`
    text-align: center;
  `}
`
export const SHeroSection = styled.section`
  ${({ theme }) => css`
    background-image: url(${speakersHeroBackgroundMobile});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    padding-top: ${theme.space.xxxl + theme.space.md}px;
    padding-bottom: ${theme.space.xxl}px;

    ${respondTo.sm`
       background-position: left;
       background-image: url(${speakersHeroBackground});
     `}

    ${respondTo.lg`
      padding: 170px 0 180px;
  `}
  `}
`
