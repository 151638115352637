import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Helmet } from 'react-helmet'

import client from '../api/client'

import { CompanyDetails } from 'sections'
import useLocalizeRoute from 'utils/useLocalizeRoute'
import { AppRoute } from 'const'

function Company() {
  const { slug } = useParams()

  const history = useHistory()
  const { localizeRouteKey } = useLocalizeRoute()
  const { formatMessage, locale } = useIntl()

  const [employer, setEmployer] = useState(null)
  const [loading, setLoading] = useState(true)

  const fetchEmployer = async () => {
    setLoading(true)

    try {
      const response = await client({
        endpoint: `api/employer/get_one_by_slug?employer_slug=${slug}&locale=${locale}`,
      })

      if (!response.ok) {
        throw new Error(await response.json())
      }

      const { data } = await response.json()
      if (data && Array.isArray(data) && data.length) {
        const [employer] = data
        setEmployer(employer)
        setLoading(false)
        return
      }

      setLoading(false)
      history.replace(localizeRouteKey(AppRoute.NotFound))
    } catch (error) {
      setLoading(false)
      history.replace(localizeRouteKey(AppRoute.NotFound))
      console.log('[GET] employer details error: ', error)
    }
  }

  useEffect(() => {
    fetchEmployer()
  }, [locale, slug])

  return (
    <>
      <Helmet>
        <title>
          {formatMessage(
            { id: 'seoTitleCompany' },
            { company: employer?.employer_name ?? '' }
          )}
        </title>
        <meta
          name="description"
          content={formatMessage(
            { id: 'seoDescriptionCompany' },
            { company: employer?.employer_name ?? '' }
          )}
        />
        <meta
          name="keywords"
          content={formatMessage(
            { id: 'seoKeywordsCompany' },
            { company: employer?.employer_name ?? '' }
          )}
        />
        <meta
          property="og:title"
          content={formatMessage(
            { id: 'seoTitleCompany' },
            { company: employer?.employer_name ?? '' }
          )}
        />
        <meta
          property="og:site_name"
          content={formatMessage(
            { id: 'seoTitleCompany' },
            { company: employer?.employer_name ?? '' }
          )}
        />
        <meta
          property="og:description"
          content={formatMessage(
            { id: 'seoDescriptionCompany' },
            { company: employer?.employer_name ?? '' }
          )}
        />
        <meta name="twitter:image:src" content={''} />
        <meta
          name="twitter:title"
          content={formatMessage(
            { id: 'seoTitleCompany' },
            { company: employer?.employer_name ?? '' }
          )}
        />
        <meta
          name="twitter:description"
          content={formatMessage(
            { id: 'seoDescriptionCompany' },
            { company: employer?.employer_name ?? '' }
          )}
        />
      </Helmet>
      <CompanyDetails {...{ employer, loading }} />
    </>
  )
}

export { Company }
