import React, { useMemo, useState } from 'react'
import { useBreakpoint } from 'modules/breakpoint'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import SimpleBar from 'simplebar-react'

import { Button, FiltrationGroup } from '..'
import { SFiltration, SFiltrationButton, SMobile } from './Filtration.styled'
import FiltrationIcon from '../../assets/icons/FiltrationIcon'
import CloseIcon from '../../assets/icons/CloseIcon'
import useBlockScroll from '../../utils/useBlockScroll'

import {
  setHeaderVisibility,
  setFooterVisibility,
} from '../../state/reducers/app/appActions'
import CloseICon from '../../assets/icons/CloseIcon'

function Filtration({ filtration, loading, onChange, onClear, onSubmit }) {
  const [visible, setVisible] = useState(false)
  const breakpoints = useBreakpoint()
  const { formatMessage } = useIntl()

  const dispatch = useDispatch()

  const prevFiltration = useMemo(() => filtration, [visible])
  const activeFiltersCount = useMemo(
    () =>
      filtration?.reduce((acc, current) => {
        let tempCount = 0
        for (let i = 0; i < current.fields.length; i++) {
          const field = current.fields[i]
          if (field.value) {
            tempCount += 1
          }
        }
        return acc + tempCount
      }, 0),
    [filtration]
  )

  useBlockScroll(visible, 'no-scroll')

  if (!filtration) return null

  const handleChange = (category) => (e) => {
    const { name, checked } = e.target
    const newFiltration = filtration.reduce((acc, current) => {
      if (current.category === category) {
        return [
          ...acc,
          {
            ...current,
            fields: current.fields.reduce((prev, field) => {
              if (field.name === name) {
                return [...prev, { ...field, value: checked }]
              }

              return [...prev, field]
            }, []),
          },
        ]
      }

      return [...acc, { ...current }]
    }, [])

    onChange(newFiltration)
  }

  const close = () => {
    dispatch(setHeaderVisibility(true))
    dispatch(setFooterVisibility(true))
    setVisible(false)
  }

  const handleClose = () => {
    close()
    onChange(prevFiltration)
  }

  const handleClear = () => {
    close()
    onClear()
  }

  const handleSubmit = () => {
    close()
    onSubmit()
  }

  const showFiltration = () => {
    dispatch(setHeaderVisibility(false))
    dispatch(setFooterVisibility(false))
    setVisible(true)
  }

  const renderFiltration = () => (
    <SimpleBar
      style={{ minHeight: '100px', height: '100%' }}
      autoHide={true}
    >
      <SFiltration>
        {!breakpoints.lg && activeFiltersCount > 0 ? (
          <button type="button" className="desktop-clear" onClick={handleClear}>
            {formatMessage({ id: 'filtrationClearDesktop' })} <CloseICon />
          </button>
        ) : null}
        {filtration.map((group) => (
          <FiltrationGroup
            key={group.category}
            {...{
              group,
              onChange: handleChange,
            }}
          />
        ))}
      </SFiltration>
    </SimpleBar>
  )

  return breakpoints.lg ? (
    <>
      <SFiltrationButton type="button" onClick={showFiltration}>
        {formatMessage({ id: 'filtrationLabel' })}{' '}
        {activeFiltersCount > 0 ? `(${activeFiltersCount})` : null}{' '}
        <FiltrationIcon />
      </SFiltrationButton>
      {visible ? (
        <SMobile>
          <div className="header">
            {formatMessage({ id: 'filtration' })} ({activeFiltersCount})
            <button type="button" onClick={handleClose}>
              <CloseIcon />
            </button>
          </div>
          <div className="content">{renderFiltration()}</div>
          <div className="footer">
            <Button disabled={activeFiltersCount === 0} onClick={handleClear}>
              {formatMessage({ id: 'filtrationClear' })}
            </Button>
            <Button type="button" onClick={handleSubmit}>
              {formatMessage({ id: 'filtrationSubmit' })}
            </Button>
          </div>
        </SMobile>
      ) : null}
    </>
  ) : (
    renderFiltration()
  )
}

export default Filtration
