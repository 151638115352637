import styled, { css } from 'styled-components'
import respondTo from 'theme/respondTo'

export const STicketSummary = styled.div`
  ${({ theme }) => css`
    display: flex;
    color: white;

    margin-top: ${theme.space.md}px;

    strong {
      font-weight: ${theme.weight.bold};
    }

    .icon {
      width: 32px;
      height: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      color: ${theme.colors.black};

      border-radius: 50%;
      background-color: ${theme.colors.pink};

      margin-right: ${theme.space.md}px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .name {
      line-height: 1.25;
    }

    .date-and-place {
      font-size: 0.875rem;
      color: ${theme.colors.lightGray};

      margin-top: ${theme.space.md}px;
    }

    .count {
      font-size: 0.875rem;
      margin-top: ${theme.space.md}px;
    }

    .price {
      font-size: 0.875rem;
      margin-top: ${theme.space.sm}px;

      span {
        color: ${theme.colors.green};
      }
    }

    .actions {
      display: flex;
      align-items: flex-end;
      flex-shrink: 0;

      margin-left: auto;

      .price-loader {
        width: 24px;
        height: 24px;
      }

      .remove {
        width: 24px;
        height: 24px;
        min-height: 24px;
        max-height: 24px;

        flex-shrink: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        margin: 0;
        padding: 0;

        border: none;
        border-radius: 50%;
        background-color: ${theme.colors.lightGray};

        cursor: pointer;

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }

    ${respondTo.lg`
      .icon {
        width: 48px;
        height: 48px;

        svg {
          width: 32px;
          height: 32px;
        }
      }

      .name {
        font-size: 1.25rem;
      }

      .date-and-place {
        font-size: 1rem;
      }
    `}
  `}
`
