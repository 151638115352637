import React, { useState } from 'react'
import { useBreakpoint } from 'modules/breakpoint'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Sticky from 'react-stickynode'

import { Burger, MobileNavigation, Logo } from '..'
import { SHeader } from './Header.styled'
import { Navigation } from 'modules/navigation'

import useBlockScroll from '../../utils/useBlockScroll'

export const HEADER_DOM_ID = 'header'
export const HEADER_HEIGHT = '72px'

function Header() {
  const [mobileNavigationVisible, toggleMobileNavigation] = useState(false)

  const { locale } = useIntl()
  const breakpoints = useBreakpoint()
  const headerVisibility = useSelector(({ app }) => app.headerVisibility)

  const closeMobileNav = () => {
    toggleMobileNavigation(false)
  }

  useBlockScroll(mobileNavigationVisible, 'no-scroll')

  if (!headerVisibility) return null

  return (
    <div id={HEADER_DOM_ID}>
      <Sticky innerZ={11}>
        <SHeader>
          <div className="nav">
            <Link onClick={closeMobileNav} to={`/${locale}/`}>
              <Logo className="logo" />
            </Link>
            <div className="nav-wrapper">
              <Navigation
                mobile={breakpoints.xl}
                closeMobileNav={closeMobileNav}
              />
              <Burger
                {...{ mobileNavigationVisible }}
                onClick={() => toggleMobileNavigation(!mobileNavigationVisible)}
              />
            </div>
          </div>
          {mobileNavigationVisible ? (
            <MobileNavigation
              {...{
                mobileNavigationVisible,
                closeMobileNav,
              }}
            />
          ) : null}
        </SHeader>
      </Sticky>
    </div>
  )
}

export default Header
