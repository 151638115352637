import React from 'react'
import { useIntl } from 'react-intl'
import { SIcons } from './ProfessionsIconsGrid.styled'

import { SlideTop } from '../../components'

const professions = [
  {
    text: 'softwareDevelopers',
    icon: 'ri-code-s-slash-fill',
  },
  {
    text: 'softwareArchitects',
    icon: 'ri-command-fill',
  },
  {
    text: 'devOpsEngineers',
    icon: 'ri-database-2-line',
  },
  {
    text: 'securityExperts',
    icon: 'ri-shield-keyhole-line',
  },
  {
    text: 'productOwners',
    icon: 'ri-compass-discover-line',
  },
  {
    text: 'dataScientists',
    icon: 'ri-file-search-line',
  },
  {
    text: 'qASpecialists',
    icon: 'ri-bug-2-line',
  },
]
function ProfessionsIconsGrid() {
  const { formatMessage } = useIntl()

  return (
    <SIcons>
      {professions.map(({ icon, text }, index) => (
        <SlideTop key={icon} delay={50 * index}>
          <div className="icons-item">
            <i className={icon}></i>
            <p>
              {formatMessage(
                { id: text },
                {
                  br: <br />,
                }
              )}
            </p>
          </div>
        </SlideTop>
      ))}
    </SIcons>
  )
}

export default ProfessionsIconsGrid
