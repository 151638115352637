import React from 'react'

import { Loader } from 'components'
import { SPageLoader } from './PageLoader.styled'

function PageLoader() {
  return (
    <SPageLoader>
      <Loader />
    </SPageLoader>
  )
}

export default PageLoader
