import * as React from "react"

function LinkedinIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 16 15"
      {...props}
    >
      <path
        fill="currentColor"
        d="M17.354 10.585c2.65 0 4.8 2.136 4.8 4.77v4.829a.74.74 0 01-.74.74h-1.722a.74.74 0 01-.739-.74v-4.73c0-.836-.601-1.597-1.433-1.68a1.596 1.596 0 00-1.766 1.581v4.829a.74.74 0 01-.74.74h-1.721a.74.74 0 01-.74-.74v-4.829c0-2.634 2.15-4.77 4.801-4.77zm-7.016.738c.408 0 .739.33.739.738v8.123c0 .408-.33.739-.739.739H8.124a.74.74 0 01-.74-.739v-8.123a.74.74 0 01.74-.738zm-1.107-5.17a1.848 1.848 0 010 3.693 1.847 1.847 0 010-3.692z"
        transform="translate(-7 -6)"
      />
    </svg>
  )
}

export default LinkedinIcon
