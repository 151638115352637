import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SSkillLevelCircle = styled.span`
  ${({ theme, active }) => css`
    width: 12px;
    height: 12px;

    flex-shrink: 0;

    display: block;

    border: solid 3px ${theme.colors.lightGray};
    border-radius: 50%;

    ${active &&
    css`
      border-color: ${theme.colors.pink};
    `}

    ${respondTo.lg`
      width: 16px;
      height: 16px;
    `}
  `}
`

export const SSpeakerDetails = styled.section`
  ${({ theme }) => css`
    padding: ${theme.space.xl}px 0;

    .thumbnail {
      max-width: 275px;
      width: 100%;
      height: auto;

      margin-bottom: 26px;

      span {
        display: block !important;
      }

      .overlay {
        position: relative;
        box-shadow: 13px 13px 0 ${theme.colors.pink};
        background-color: #d4e6f7;

        &::before {
          background-color: #2383cd;
          bottom: 0;
          content: '';
          height: 100%;
          left: 0;
          mix-blend-mode: color-dodge;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          z-index: 10;
        }
      }

      img {
        width: 100%;
        height: auto;

        display: block;

        mix-blend-mode: multiply;
        filter: grayscale(1) contrast(1.1) brightness(0.7);
      }
    }

    .details {
      .name {
        font-size: 2rem;
        font-weight: ${theme.weight.bold};
        line-height: 1.25;
        color: white;

        margin-top: ${theme.space.md}px;
      }

      .position {
        margin-top: ${theme.space.md}px;
      }

      .position,
      .company {
        font-size: 1rem;
        line-height: 1.5;
        color: ${theme.colors.lightGray};
      }

      .company {
        font-weight: ${theme.weight.bold};
      }

      .tags {
        margin-top: ${theme.space.lg}px;
        .tag {
          font-size: 1rem;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }

    .socials {
      li {
        min-height: 25px;

        display: flex;
        align-items: center;

        margin-top: ${theme.space.md}px;
      }

      .icon {
        margin-right: ${theme.space.md}px;
      }

      a {
        font-size: 0.75rem;
        line-height: 1.33;
        text-decoration: none;
        color: white;
      }
    }

    .skills {
      font-size: 0.875rem;
      line-height: 2rem;
      color: white;

      .skill {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-level {
          display: grid;
          grid-template-columns: repeat(5, 12px);
          grid-gap: 0 ${theme.space.sm}px;
        }
      }
    }

    .bio {
      font-size: 0.875rem;
      line-height: 1.71;
      color: #ffffff;

      .section-more {
        display: block;
        margin-left: auto;
      }
    }

    .section {
      &-heading {
        font-size: 1.25rem;
        font-weight: ${theme.weight.bold};
        line-height: 1.6;
        color: white;

        margin-top: ${theme.space.lg}px;
        margin-bottom: ${theme.space.md}px;
      }

      &-more {
        color: ${theme.colors.pink};

        border: none;
        box-shadow: none;
        background: none;
        border-bottom: 1px solid transparent;

        margin: ${theme.space.md}px 0 0 0;
        padding: 0;

        cursor: pointer;

        &:hover,
        &:focus {
          outline: 0;
          border-bottom: 1px solid ${theme.colors.pink};
        }

        svg {
          margin-bottom: 2px;
        }
      }
    }

    ${respondTo.lg`
      .about {
        display: grid; 
        grid-template-columns: minmax(auto, 362px) 1fr; 
        grid-template-rows: auto auto; 
        gap: ${theme.space.xxxl}px; 
        grid-template-areas: 
          "thumbnail details"
          "skills bio";

        .thumbnail {
          max-width: 100%;

          grid-area: thumbnail;

          padding-right: 13px;
        }

        .details {
          grid-area: details;
        
          .name {
            font-size: 2.62rem;
            line-height: 1.14;
          }
        }
        .skills {
          grid-area: skills;

          .skill {
            &-level {
              grid-template-columns: repeat(5, 16px);
              grid-gap: 0 10px;
            }
          }
        }
        .bio { grid-area: bio; }
      }

      .socials {
        display: flex;
        flex-wrap: wrap;

        li {
          width: 50%;
        }

        a {
          font-size: 0.75rem;
          line-height: 1.33;
          text-decoration: none;
          color: white;
        }
      }
    `}

    ${respondTo.xl`
      .about {
        gap: 84px 112px;
      }
    `}
  `}
`
