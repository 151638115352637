import React from 'react'

import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { Reset } from 'styled-reset'

import GlobalStyles from './GlobalStyles'

import ogImage from 'assets/images/ogImage.png'

export default function ({ script }) {
  const { formatMessage } = useIntl()

  return (
    <>
      <Helmet {...{ script }}>
        <link rel="stylesheet" href="https://use.typekit.net/tyt2mfk.css" />
        <title>{formatMessage({ id: 'seoTitle' })}</title>
        <meta
          name="description"
          content={formatMessage({ id: 'seoDescription' })}
        />
        <meta name="keywords" content={formatMessage({ id: 'seoKeywords' })} />
        <meta property="og:title" content={formatMessage({ id: 'seoTitle' })} />
        <meta
          property="og:site_name"
          content={formatMessage({ id: 'seoTitle' })}
        />
        <meta
          property="og:description"
          content={formatMessage({ id: 'seoDescription' })}
        />
        <meta name="twitter:image:src" content={''} />
        <meta
          name="twitter:title"
          content={formatMessage({ id: 'seoTitle' })}
        />
        <meta
          name="twitter:description"
          content={formatMessage({ id: 'seoDescription' })}
        />
      </Helmet>
      <Reset />
      <GlobalStyles />
    </>
  )
}
