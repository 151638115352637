import styled, { css, keyframes } from 'styled-components'

export const loading = keyframes`
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 360deg);
  }
`

export const LoaderComponent = styled.div`
  display: block;
  margin: 0 auto;
  position: relative;
  text-indent: -9999em;
  ${({ theme, size = 6 }) =>
    theme &&
    css`
      border-top: ${size}px solid ${theme.colors.lightestGray};
      border-right: ${size}px solid ${theme.colors.lightestGray};
      border-bottom: ${size}px solid ${theme.colors.lightestGray};
      border-left: ${size}px solid ${theme.colors.pink};
    `}
  transform: translateZ(0);
  animation: ${loading} 1.1s infinite linear;

  &,
  &:after {
    border-radius: 50%;
    width: ${({ width }) => width || 32}px;
    height: ${({ height }) => height || 32}px;
  }
`
