import React, { useEffect, useState, useMemo } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import TagManager from 'react-gtm-module'
import { hotjar } from 'react-hotjar'

import { AppLanguage } from 'const'
import { LocalizedRouter, appStrings } from 'modules/i18n'
import { BreakpointProvider, defaultQueries } from 'modules/breakpoint'

import createStore from 'state/createStore'
import theme from 'theme'
import ThemeCustomization from 'theme/ThemeCustomization'
import {
  getDictionary,
  setPolicyPermissions,
} from 'state/reducers/app/appActions'
import { GdprCookie } from 'components'
import config from 'const/config'

import { App } from './App'

const store = createStore()

export const Root: React.FC = () => {
  const [callpage, setCallpage] = useState<boolean>(false)
  const handleGdprConfirm = ({
    analytics,
    marketing,
  }: {
    analytics: boolean
    marketing: boolean
    date: Date
  }) => {
    store.dispatch(setPolicyPermissions({ analytics, marketing }))

    if (analytics) {
      hotjar.initialize(Number(config.HJID), Number(config.HJSV))
      TagManager.initialize({
        gtmId: config.GTM_TRACKING_ID as string,
      })
    }

    if (analytics && marketing) {
      setCallpage(true)
    }
  }

  useEffect(() => {
    store.dispatch(getDictionary())
  }, [])

  const script = useMemo(() => {
    // if (callpage) {
    //   return [
    //     {
    //       type: 'text/javascript',
    //       innerHTML: `var __cp = {"id":"pAotf5y6Ijx5utwggajV2ZFS43UgwzIkoeoZlrC_b8A","version":"1.1"};(function (window, document) {var cp = document.createElement('script');cp.type = 'text/javascript';cp.async = false;cp.src = "++cdn-widget.callpage.io+build+js+callpage.js".replace(/[+]/g, '/').replace(/[=]/g, '.');var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(cp, s);if (window.callpage) {alert('You could have only 1 CallPage code on your website!');} else {window.callpage = function (method) {if (method == '__getQueue') {return this.methods;}else if (method) {if (typeof window.callpage.execute === 'function') {return window.callpage.execute.apply(this, arguments);}else {(this.methods = this.methods || []).push({arguments: arguments});}}};window.callpage.__cp = __cp;window.callpage('api.button.autoshow');}})(window, document);`,
    //     },
    //   ]
    // }
    return null
  }, [callpage])

  return (
    <LocalizedRouter
      RouterComponent={BrowserRouter}
      languages={AppLanguage}
      appStrings={appStrings}
    >
      <Provider store={store}>
        <BreakpointProvider queries={defaultQueries}>
          <ThemeProvider theme={theme}>
            <ThemeCustomization {...{ script }} />
            <App />
            <GdprCookie locale="pl" onConfirm={handleGdprConfirm} />
          </ThemeProvider>
        </BreakpointProvider>
      </Provider>
    </LocalizedRouter>
  )
}
