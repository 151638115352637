import React from 'react'

function EditIcon(props) {
  return (
    <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.971 10.69H11M6.437 1.437c.466-.582 1.218-.551 1.812-.095l.878.675c.594.457.805 1.164.339 1.747l-5.237 6.549a.942.942 0 01-.727.352l-2.02.025-.458-1.929a.906.906 0 01.175-.775l5.238-6.549zm-.981 1.226l3.029 2.328"
        stroke="currentColor"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default EditIcon
