import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'
import background from '../../assets/images/top-partners-background.svg'

export const SContainer = styled.div`
  ${({ theme }) => css`
    background-color: white;
    position: relative;
    padding: ${theme.space.xl + 8}px  ${theme.space.lg}px;

    ${respondTo.lg`
       background-image: url(${background});
       background-repeat: no-repeat;
       background-size: contain;
       background-position: right 0 bottom 0;
       margin: 0 auto;
       padding: 80px ${theme.space.lg}px;
    `}

    .triangle {
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 102px 102px 0 0;
      border-color: ${
        theme.colors.backgroundBlack
      } transparent transparent transparent;
      
      display: none;

      &:first-of-type {
        display: block;
        border-width: 50px 50px 0 0;
        transform: rotate(90deg);
        right: 0;
        top: 0;
      }
      
      ${respondTo.lg` 
          &:first-of-type {
            display: block;
            border-width: 102px 102px 0 0;
            transform: rotate(180deg);
            right: 0;
            top: -102px;
            border-color: ${theme.colors.white} transparent transparent transparent;
          }
          &:nth-of-type(2) {
            display: block;
            transform: rotate(-90deg);
            left: 0;
            bottom: 0;
          }
      `}
    }

    .heading {
      text-align: center;
      font-size: 1.5rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.875rem;
      color: ${theme.colors.black};

      ${respondTo.lg`
        font-size: 2.25rem;
        line-height: 3.125rem;
      `}
    }
    
    .subheader {
      font-size: 1.125rem;
      line-height: 1.56rem;
      margin: ${theme.space.md + 4}px auto ${theme.space.xl + 8}px;
      max-width: 659px;
      text-align: center;
      
      ${respondTo.lg`
        font-size: 1.25rem;
        line-height: 1.75rem;
      `}
    }

    .button-wrapper {
      display: flex;
      justify-content: center;
        
      i {
        padding-top: 4px;
        margin-left: 8px;
      }
    }
    
    .grid-wrapper {
      position: relative;
      padding: 1px 0;
      max-width: 399px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      ${respondTo.xl`
        justify-content: flex-start;
        max-width: 1027px;
      `}
      
      ${respondTo.xxl`
       &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            90deg,
            white 0%,
            transparent 10%,
            transparent 90%,
            white 100%
          )
       }
     `}
    }
    
    .row-wrapper {
      max-width: 399px;

      ${respondTo.xl`
        max-width: 892px;
        margin-left: 66px;
        
        &:nth-of-type(even) {
          margin-left: 215px;
        }
      `}
    }
    
    .row {
      display: flex;
      align-items: center;
      position: relative;
      max-width: 309px;
      margin: 0 auto;
      height: 41px;
      
      ${respondTo.xxs`
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: -23px;
          width: calc(100% + 46px);
          height: calc(100% + 1px);
          border-top: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0;
        }
      `}

      ${respondTo.xxs`
        height: 51px;
      `}
      ${respondTo.xl`
        max-width: 755px;
        height: 75px;
        
        &:before {
          width: calc(100% + 132px);
          left: -66px;
        }
      `}
    }

    .logo {
        border: 1px solid #e0e0e0;
        margin: 0 -1px -1px 0;

        width: 90px;
        height: 41px;

        display: inline-flex;
        justify-content: center;
        align-items: center;
      
        ${respondTo.xxs`
          border-top: none;
          border-bottom: none;
          width: 103px;
          height: 100%;
       `}
        ${respondTo.xl`
          width: 150px;
        `}

         img {
          max-width: 70px;
          max-height: 24px;

          ${respondTo.xxs`
            max-width: 79px;
            max-height: 27px;
         `}

          ${respondTo.xl`
            max-width: 115px;
            max-height: 39px;
          `}
        }
      }
    }
  
  .rating {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: ${theme.space.xl + 8}px auto;

    ${respondTo.lg`
      margin-top: ${theme.space.xl * 2}px;
    `}
    
    &-title {
      color: ${theme.colors.darkestGray};
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-weight: 500;
      margin-right: ${theme.space.md - 4}px;
    }
    &-number {
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-weight: 700;
      color: ${theme.colors.darkPink};
      padding: ${theme.space.xs}px ${theme.space.sm}px;
      border-radius: ${theme.space.sm}px;
      background: linear-gradient(90.23deg, rgba(14, 101, 229, 0.2) 14.22%, rgba(2, 179, 251, 0.2) 127.9%);
    }
    &-stars {
      margin: ${theme.space.sm}px 0 ${theme.space.md - 2}px;
    }
    &-text {
      color: ${theme.colors.lightGray2};
      line-height: 1.375rem;
      font-weight: 500;
    }
    i {
      font-size: 22px;
      color: ${theme.colors.green};
      margin-right: ${theme.space.sm - 1}px;
    }
  }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  
    .positionLeft {
      position: absolute;
      top: 616px;
      left: 0;
    }
    .positionRight {
      position: absolute;
      top: 114px;
      right: 0;
    }
  `}
`
