import styled, { css } from 'styled-components'
import respondTo from 'theme/respondTo'

export const SContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: ${theme.space.xxl}px;
    justify-content: center;
    padding: ${theme.space.xl}px ${theme.space.md}px;

    .item {
      font-size: 0.9rem;
      line-height: 1.4;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: ${theme.space.xl}px;
      text-align: center;
      max-width: 400px;
    }

    .text {
      font-style: italic;
    }

    ${respondTo.lg` 
      flex-wrap: nowrap;
      margin-top: ${theme.space.xxxl}px;
      margin-bottom: ${theme.space.xxxl}px;
      
      gap: ${theme.space.xxxl * 1.4}px;
      
      .item {
        font-size: 1rem;
        line-height: 1.5;
      }
    `}
  `}
`
