import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.72 8.58l2.139 2.14m9.801 9.802l1.79 1.789m-4.826-4.826l-4.26-4.26m13.394 5.159c1.546-1.546 1.51-4.086-.08-5.677l-2.292-2.291c-1.023 1.023-2.694.999-3.747-.053-1.052-1.053-1.077-2.725-.054-3.748l-2.293-2.292c-1.59-1.59-4.132-1.625-5.677-.08L4.243 15.615c-1.545 1.545-1.51 4.087.08 5.677L6.69 23.66c1.023-1.023 2.621-1.073 3.674-.02 1.052 1.052 1.076 2.723.053 3.746l2.291 2.292c1.59 1.59 4.131 1.626 5.677.08l11.374-11.374z"
        stroke="currentColor"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgComponent
