import { Loader } from 'components'
import React from 'react'
import { SButton } from './Button.styled'

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean
}

function Button({ children, loading, ...res }: IButton) {
  const renderButton = () => (
    <SButton {...res}>{loading ? <Loader /> : children}</SButton>
  )

  return renderButton()
}

export default Button
