import React from 'react'
import { useIntl } from 'react-intl'

import { STicketProcessInput } from './TicketProcessInput.styled'

function TicketProcessInput({
  label,
  optional,
  htmlFor,
  preDescription,
  count,
  maxCount,
  description,
  children,
  error,
}) {
  const { formatMessage } = useIntl()

  return (
    <STicketProcessInput {...{ error }}>
      <label {...{ htmlFor }}>
        <span>
          {formatMessage({ id: label })}
          {optional ? <span className="optional"> (Optional)</span> : null}
        </span>
        {maxCount ? (
          <span className="count">
            ({count}/{maxCount})
          </span>
        ) : null}
      </label>
      {preDescription ? (
        <p className="description pre">
          {formatMessage({ id: preDescription })}
        </p>
      ) : null}
      {children}
      {error ? (
        <p className="description error">
          {formatMessage({ id: error.message })}
        </p>
      ) : description ? (
        <p className="description">{formatMessage({ id: description })}</p>
      ) : null}
    </STicketProcessInput>
  )
}

export default TicketProcessInput
