import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { Tags } from 'components'
import { AppRoute } from 'const'
import { defaultTimezone } from 'const/defaultTimezone'
import useLocalizeRoute from 'utils/useLocalizeRoute'
import sanitizeText from 'utils/sanitizeText'

import ChevronIcon from 'assets/icons/ChevronIcon/ChevronIcon'

import {
  SAgendaEventDetails,
  SSkillLevelCircle,
} from './AgendaEventDetails.styled'

function AgendaEventDetails({ event, eventDuration, agendaRoute }) {
  const [detailsVisible, setDetailsVisible] = useState(!!agendaRoute)

  const { formatMessage, formatTime } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()

  const toggleDetails = () => setDetailsVisible(!detailsVisible)

  return (
    <SAgendaEventDetails>
      {event.room_name ? <span className="room">{event.room_name}</span> : null}
      <h3 className="title">{event.event_name}</h3>
      {event.speaker_list?.length ? (
        <div className="speakers">
          {event.speaker_list?.map((speaker, index) => (
            <div key={index} className="speaker">
              <Link
                to={localizeRouteKey(AppRoute.Speaker).replace(
                  ':slug',
                  speaker.speaker_slug
                )}
              >
                {speaker.speaker_name}
              </Link>
              <span className="company">@{speaker.speaker_company_name}</span>
            </div>
          ))}
        </div>
      ) : null}
      <div className="duration-type-track">
        <div className="duration-type">
          {eventDuration ? (
            <span className="duration">{eventDuration} min</span>
          ) : null}
          {event.event_type ? (
            <span className="type">
              {formatMessage({
                id: `event.type_${event.event_type}`,
              })}
            </span>
          ) : null}
        </div>
        {event.event_track ? (
          <span className="track">
            <span
              className="dot"
              style={{ backgroundColor: event.event_track.color }}
            ></span>
            <span>{event.event_track.name}</span>
          </span>
        ) : null}
      </div>
      {!agendaRoute ? (
        <button type="button" onClick={toggleDetails} className="toggler">
          {formatMessage({ id: detailsVisible ? 'showLess' : 'showMore' })}
          <ChevronIcon type={detailsVisible ? 'up' : 'down'} />
        </button>
      ) : null}
      {detailsVisible ? (
        <div className="details">
          <div className="hours-lang">
            <p className="hours">
              {formatTime(event.event_start_timestamp * 1000, {
                timeZone: defaultTimezone,
              })}
              -
              {formatTime(event.event_end_timestamp * 1000, {
                timeZone: defaultTimezone,
              })}
            </p>
            <p>
              {formatMessage({ id: 'spDetailsLanguage' })}:{' '}
              {event.event_language_name}
            </p>
          </div>
          {event.event_description ? (
            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: sanitizeText(event.event_description),
              }}
            />
          ) : null}
          {event.tag_list?.length ? (
            <div className="tags">
              <span className="tags-label">
                {formatMessage({ id: 'agenda.technologies' })}:
              </span>
              <Tags tags={event.tag_list.map((tag) => tag.tag_name)} />
            </div>
          ) : null}
          <div className="skill">
            <span className="skill-label">
              {formatMessage({ id: 'agenda.level' })}:
            </span>
            <div className="skill-level">
              <SSkillLevelCircle active={event.difficulty_rating >= 1} />
              <SSkillLevelCircle active={event.difficulty_rating >= 2} />
              <SSkillLevelCircle active={event.difficulty_rating >= 3} />
            </div>
            <span className="skill-name">
              ({formatMessage({ id: `skill.${event.difficulty_rating_text}` })})
            </span>
          </div>
        </div>
      ) : null}
    </SAgendaEventDetails>
  )
}

export default AgendaEventDetails
