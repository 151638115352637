import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'
import mobileBannerBackground from '../../assets/images/speakers-mobile-banner.svg'
import desktopBannerBackground from '../../assets/images/speakers-desktop-banner.svg'

export const SSpeakersBanner = styled.section`
  ${({ theme, home }) => css`
    width: 100%;
    display: flex;
    justify-content: center;

    ${home &&
    css`
      background-color: ${theme.colors.backgroundBlack};
      padding: ${theme.space.xl + 8}px 0;
    `}

    .wrapper {
      max-width: 1140px;
      width: 100%;
      height: 284px;

      background-image: url(${mobileBannerBackground});
      background-repeat: no-repeat;
      background-size: cover;

      padding: 0 ${theme.space.md}px;
      margin: 0 ${-theme.space.md}px;
    }

    p {
      font-size: 2rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.25;
      color: #ffffff;

      display: inline-block;

      margin: ${theme.space.xl}px 0;
    }

    ${respondTo.lg`
        .wrapper {
             width: 100%;
             height: initial;
             min-height: 112px;
      
             display: flex;
             align-items: center;
      
             background-image: url(${desktopBannerBackground});
             box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
                 0 6px 30px 5px rgba(0, 0, 0, 0.12),
                 0 16px 24px 2px rgba(0, 0, 0, 0.14);
      
             padding: 0 ${theme.space.xxl * 2}px;
             margin: 0;
         }
  
         p {
           margin: 0;
           margin-right: ${theme.space.md}px;
         }
  
         .link {
           width: 40%;
  
           flex-shrink: 0;
           align-self: center;
         }
     `}
  `}
`
