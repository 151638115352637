import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SContainer = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundBlack};
    overflow: hidden;
    position: relative;
    padding: 20px ${theme.space.lg}px ${theme.space.xl + 8}px;

    ${respondTo.lg`
      padding: ${theme.space.md + 4}px ${theme.space.lg}px 80px;
    `}

    .content {
      max-width: 1240px;
      margin: 0 auto;
    }

    .heading {
      font-size: 1.5rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.875rem;
      color: white;
      margin: 0 auto ${theme.space.lg + 4}px;

      ${respondTo.lg`
        font-size: 2.25rem;
        line-height: 3.125rem;  
        margin: 0 auto 0 105px;
      `}

      span {
        color: ${theme.colors.lightPink};
        text-shadow: 1px 1px 0 ${theme.colors.azure};
        font-size: 1.75rem;
        line-height: 2.188rem;

        ${respondTo.lg`
          color: ${theme.colors.white};
          font-size: 3.25rem;
          line-height: 4.125rem;
          text-shadow: 2px 2px 0 ${theme.colors.azure}; 
        `}
      }
    }

    .subheader {
      color: ${theme.colors.lightGray2};
      font-size: 1.125rem;
      line-height: 1.56rem;
      margin: ${theme.space.md + 4}px 0 ${theme.space.xl + 8}px;

      ${respondTo.lg`
         font-size: 1.375rem;
         line-height: 1.875rem;
         font-weight: 500;
         margin: ${theme.space.md + 4}px 0 0 105px;
         max-width: 820px;
      `}
    }

    .more {
      color: ${theme.colors.lightGray2};
      line-height: 1.375rem;
      text-align: center;
      margin-top: ${theme.space.md + 4}px;

      ${respondTo.xl`
        margin-top: 0;
      `}
    }

    a {
      i {
        padding-top: 4px;
        margin-left: 8px;
      }
    }

    .technologies {
      margin: ${theme.space.xl + 8}px auto 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      ${respondTo.lg`
        margin ${theme.space.xl * 2}px auto 0;
        padding-bottom: 1px;
        
         &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            90deg,
            #121212 0%,
            transparent 10%,
            transparent 90%,
            #121212 100%
          );
        }
      `}

      &-grid {
        position: relative;
        display: grid;
        grid-template-columns: repeat(4, 100px);

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            90deg,
            #121212 0%,
            transparent 20%,
            transparent 80%,
            #121212 100%
          );
          ${respondTo.xl`
            display: none;
          `}
        }

        ${respondTo.xl`
          margin: 0 auto;
          grid-template-columns: repeat(6, 180px);
        `}
      }
    }

    .technology {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 50px;
      position: relative;
      margin: 0 -1px -1px 0;

      ${respondTo.xl`
        width: 180px;
        height: 90px;
        
        &:nth-last-child(1) {
          width: 360px;
        }
      `}

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-top: 1px solid ${theme.colors.darkestGray};
        border-bottom: 1px solid ${theme.colors.darkestGray};
        margin: 0 -1px 0 0;

        ${respondTo.xxl`
          top: 0;
          left: -80px;
          width: calc(100% + 160px);
        `}
      }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border-left: 1px solid ${theme.colors.darkestGray};
        border-right: 1px solid ${theme.colors.darkestGray};
      }

      img {
        max-width: 76px;
        max-height: 38px;

        ${respondTo.xl`
          max-width: 120px;
          max-height: 60px;
        `}
      }
    }
  `}
`
