import styled, { css } from "styled-components"

export default styled.div`
  position: absolute;

  ${({ position }) =>
    position === "topleft" &&
    css`
      top: 136px;
      left: 0;
    `}
  ${({ position }) =>
    position === "topright" &&
    css`
      top: 136px;
      right: 0;
    `}
  ${({ theme, position }) =>
    position === "bottomright" &&
    css`
      bottom: ${theme.space.xxxl * 2}px;
      right: 0;
    `}
  ${({ theme, position }) =>
    position === "bottomleft" &&
    css`
      bottom: ${theme.space.xxxl * 2}px;
      left: 0;
    `}
  ${({ position }) =>
    position === "abovetopright" &&
    css`
      top: -52px;
      right: 0;
    `}
  ${({ position }) =>
    position === "justbottomleft" &&
    css`
      bottom: 0;
      left: 0;
    `}
  ${({ position }) =>
    position === "underbottomleft" &&
    css`
      bottom: -65px;
      left: 0;
    `}
  ${({ position }) =>
    position === "justtopright" &&
    css`
      top: 0;
      right: 0;
    `}
`
