import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { random } from 'lodash'

import client from 'api/client'

import {
  ContactModal,
  HeroSectionHomePage,
  JoinPartners,
  Newsletter,
  WhyAttendHome,
  Partner,
  Impressions,
  CeInNumbers,
  PlaceToBeFor,
  Partners,
  Speakers,
  SpeakersOfAllTime,
  Tracks,
  Tweets,
  Activities,
  ActivitiesModal,
  FaqSection,
  Aftermovie,
} from 'sections'
import SpeakersBanner from '../components/SpeakersBanner/SpeakersBanner'

const patterns = ['md,sm;lg', 'xl;sm,lg', 'xl;sm,xl', 'sm,md;lg']

export const Home = () => {
  const [partnersData, setPartnersData] = useState([])
  const [speakers, setSpeakers] = useState([])
  const [companies, setCompanies] = useState([])
  const [partnerships, setPartnerships] = useState([])

  const contactModalVisible = useSelector(
    ({ app }) => app.contactModalVisibility
  )
  const activitiesModalVisible = useSelector(
    ({ app }) => app.activitiesModalVisibility
  )

  const fetchData = async () => {
    const getPartners = client({ endpoint: 'api/patronage_list/all' })
    const getSpeakers = client({ endpoint: 'api/speaker/featured' })
    const getCompanies = client({ endpoint: 'api/speaker/companies/featured' })
    const getPartnerships = client({ endpoint: 'api/partner_list/all' })

    const [
      partnersResponse,
      speakersResponse,
      companiesResponse,
      partnershipsResponse,
    ] = await Promise.all([
      getPartners,
      getSpeakers,
      getCompanies,
      getPartnerships,
    ])

    const { data: partnersData } = await partnersResponse.json()
    const { data: speakersData } = await speakersResponse.json()
    const { data: companiesData } = await companiesResponse.json()
    const { data: partnershipsData } = await partnershipsResponse.json()

    setPartnersData(partnersData)
    setSpeakers(
      speakersData.map((speaker, index) => ({
        ...speaker,
        pattern: patterns[random(index % patterns.length)],
      }))
    )
    setCompanies(companiesData)
    setPartnerships(partnershipsData)
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (window?.callpage) {
      window.callpage('api.button.hide')
    }
  }, [])

  return (
    <div className="new-design-bg">
      <HeroSectionHomePage />
      <Aftermovie />
      <Newsletter />
      <CeInNumbers />
      <Impressions />
      <PlaceToBeFor />
      <Tracks />
      <SpeakersBanner home />
      <Speakers speakersDataList={speakers} companiesList={companies} />
      <SpeakersOfAllTime />
      <SpeakersBanner home />
      <Activities />
      <WhyAttendHome />
      <Partners patronagesList={partnersData} partnersList={partnerships} />
      {/*<JoinPartners partner={false} partnersList={partnerships} />*/}
      {/*<Partners patronagesList={patron} />*/}
      <Tweets />
      <FaqSection
        paddings={{ desktop: '0 24px 120px', mobile: '0 24px 80px' }}
      />
      {contactModalVisible ? <ContactModal /> : null}
      {activitiesModalVisible ? <ActivitiesModal /> : null}
    </div>
  )
}
