import { useIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { SpeakersList } from 'sections'
import { useEffect } from 'react'

function Speakers() {
  const { formatMessage } = useIntl()

  useEffect(() => {
    if (window?.callpage) {
      window.callpage('api.button.hide')
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'seoTitleSpeakers' })}</title>
        <meta
          name="description"
          content={formatMessage({ id: 'seoDescriptionSpeakers' })}
        />
        <meta
          name="keywords"
          content={formatMessage({ id: 'seoKeywordsSpeakers' })}
        />
        <meta
          property="og:title"
          content={formatMessage({ id: 'seoTitleSpeakers' })}
        />
        <meta
          property="og:site_name"
          content={formatMessage({ id: 'seoTitleSpeakers' })}
        />
        <meta
          property="og:description"
          content={formatMessage({ id: 'seoDescriptionSpeakers' })}
        />
        <meta name="twitter:image:src" content={''} />
        <meta
          name="twitter:title"
          content={formatMessage({ id: 'seoTitleSpeakers' })}
        />
        <meta
          name="twitter:description"
          content={formatMessage({ id: 'seoDescriptionSpeakers' })}
        />
      </Helmet>
      <SpeakersList />
    </>
  )
}

export { Speakers }
