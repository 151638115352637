import React, { useEffect } from "react"

function PersonalDataProcessing() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.location.href = "/personal-data-processing.pdf"
    }
  }, []);

  return null;
}

export { PersonalDataProcessing }
