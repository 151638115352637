import React from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { Tags } from '../index'
import PinIcon from '../../assets/icons/PinIcon'

import { SOfferTile } from './OfferTile.styled'

import { AppRoute } from 'const'
import useLocalizeRoute from 'utils/useLocalizeRoute'
import { useBreakpoint } from 'modules/breakpoint'

function OfferTile({ item: offer, ...res }) {
  const { locale, formatMessage } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()
  const breakpoints = useBreakpoint()

  const {
    offer_slug,
    offer_title,
    offer_area,
    offer_salary,
    location_name,
    employer_name,
    offer_tag_list,
  } = offer

  return (
    <SOfferTile {...res}>
      <Link to={localizeRouteKey(AppRoute.Offer).replace(':slug', offer_slug)}>
        <div className="top">
          <div>
            <p className="name">{offer_title}</p>
            <p className="category">{offer_area?.name}</p>
          </div>
          {!breakpoints.lg && <p className="salary">{offer_salary}</p>}
        </div>
        <div className="bottom">
          <div className="offer-location">
            <PinIcon className="offer-pin-icon" />
            <span className="offer-location-name">{location_name}</span>
          </div>
          <p className="company">@{employer_name}</p>
          <div className="tags-container">
            <p className="requirements">{formatMessage({ id: 'requirements' })}</p>
            <div className="tags">
              <Tags
                {...{
                  tags:
                    offer_tag_list?.map((tag) =>
                      locale === 'pl' ? tag.name : tag.name_en || tag.name
                    ) ?? [],
                }}
              />
            </div>
          </div>
          {breakpoints.lg && <p className="salary">{offer_salary}</p>}
        </div>
      </Link>
    </SOfferTile>
  )
}

export default OfferTile
