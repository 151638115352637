import * as React from "react"

function ArrowIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
      <path
        fill="currentColor"
        d="M1.25 11.309h14.825l-4.537 5.709a1.353 1.353 0 00.162 1.846c.532.463 1.32.386 1.763-.17l6.25-7.857a1.46 1.46 0 00.112-.196c0-.066.063-.105.088-.17.056-.15.086-.31.087-.472a1.35 1.35 0 00-.087-.471c0-.066-.063-.105-.088-.17a1.477 1.477 0 00-.113-.197l-6.25-7.856c-.237-.3-.59-.472-.962-.472a1.22 1.22 0 00-.8.301 1.357 1.357 0 00-.163 1.847l4.538 5.709H1.25C.56 8.69 0 9.276 0 10c0 .722.56 1.309 1.25 1.309z"
      />
    </svg>
  )
}

export default ArrowIcon
