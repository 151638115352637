import React, { useEffect } from "react"

function UserRegulations() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.location.href = "/regulamin-code-europe.pdf"
    }
  }, []);

  return null;
}

export { UserRegulations }
