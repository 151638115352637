import client from 'api/client'
import { useIntl } from 'react-intl'
import { components } from 'react-select'
import debounce from 'debounce-promise'
import { useBreakpoint } from 'modules/breakpoint'

import SearchIcon from '../../assets/icons/SearchIcon'

import { SContainer } from './SearchBar.styled'

import Select from 'react-select/async'
import SelectCreatable from 'react-select/async-creatable'
import selectStylesCreator from './selectStylesCreator'
import { useRef } from 'react'

function SearchBar({
  handleSearchChange,
  searchValues,
  offersPage,
  customOptionsGetter,
  creatable,
}) {
  const { formatMessage } = useIntl()
  const breakpoint = useBreakpoint()

  function Control({ children, ...props }) {
    return (
      <components.Control {...props} className="selectStyles">
        <span className="search-icon">
          <SearchIcon />
        </span>
        {children}
      </components.Control>
    )
  }

  const getSearchOptions = async (inputValue, callback) => {
    const companiesEndpoint = `api/employer_list/all?per_page=10000&is_listed=1&current_page=1&city_slug=&keywords_phrase=&filters[query][]=${encodeURIComponent(
      inputValue
    )}`

    try {
      const response = await client({
        endpoint: companiesEndpoint,
      })

      if (!response.ok) return
      const options = await response.json()
      callback(
        options.data.map((option) => ({
          label: option.employer_name,
          value: option.employer_id,
        }))
      )
    } catch (error) {
      console.log(`[getSearchOptions] error: `, error)
    }
  }

  const debouncedLoadOptions = useRef(
    debounce(customOptionsGetter || getSearchOptions, 1500, { leading: true })
  )

  const offersPlaceholder = breakpoint.lg
    ? formatMessage({ id: 'offers.search' })
        .split(', ')
        .slice(0, 2)
        .join(', ')
        .concat('...')
    : formatMessage({ id: 'offers.search' })

  const renderCreatable = () => (
    <SelectCreatable
      isMulti
      styles={selectStylesCreator({
        isMultiSelect: true,
        withoutIndicator: true,
      })}
      classNamePrefix="react-select"
      placeholder={
        offersPage
          ? offersPlaceholder
          : formatMessage({ id: 'companies.search' })
      }
      noOptionsMessage={() => formatMessage({ id: 'noResults' })}
      loadingMessage={() => formatMessage({ id: 'loading' })}
      hideSelectedOptions
      cacheOptions
      components={{ Control }}
      loadOptions={debouncedLoadOptions.current}
      value={searchValues}
      onChange={(value) => {
        handleSearchChange(value)
      }}
      allowCreateWhileLoading
      createOptionPosition="first"
      formatCreateLabel={(inputValue) => `"${inputValue}"`}
    />
  )
  const renderDefault = () => (
    <Select
      isMulti
      styles={selectStylesCreator({
        isMultiSelect: true,
        withoutIndicator: true,
      })}
      classNamePrefix="react-select"
      placeholder={
        offersPage
          ? offersPlaceholder
          : formatMessage({ id: 'companies.search' })
      }
      noOptionsMessage={() => formatMessage({ id: 'noResults' })}
      loadingMessage={() => formatMessage({ id: 'loading' })}
      hideSelectedOptions
      cacheOptions
      components={{ Control }}
      loadOptions={debouncedLoadOptions.current}
      value={searchValues}
      onChange={(value) => {
        handleSearchChange(value)
      }}
    />
  )

  return (
    <SContainer>
      <div className="content-wrapper">
        <div className="filtration-container">
          <div className="filter-group max-width">
            <div className="filter-field">
              {creatable ? renderCreatable() : renderDefault()}
            </div>
          </div>
        </div>
      </div>
    </SContainer>
  )
}

export default SearchBar
