import React from "react";

import { Container } from "./ChevronIcon.styled";

const ChevronIcon = ({ type }) => {
    return (
        <Container
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 8 5"
            {...{ type }}
        >
            <g fill="currentColor">
                <path d="M4 5L8 1.111 6.719 0 4 2.643 1.282 0 0 1.111z" />
            </g>
        </Container>
    );
};

export default ChevronIcon;
