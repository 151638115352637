import React from 'react'
import { useIntl } from 'react-intl'

import FacebookIcon from '../../assets/icons/FacebookIcon'
import LinkedinIcon from '../../assets/icons/LinkedinIcon'
import TwitterIcon from '../../assets/icons/TwitterIcon'

import { SlideTop, Social } from '../../components'
import { SContainer } from './FollowUs.styled'

function FollowUs() {
  const { formatMessage } = useIntl()

  return (
    <SContainer>
      <hr />
      <h2 className="heading">{formatMessage({ id: 'followUsHeading' })}</h2>
      <SlideTop>
        <div className="socials-container">
          <Social large href="https://twitter.com/code_europe" target="_blank">
            <TwitterIcon />
          </Social>
          <Social
            large
            href="https://www.linkedin.com/showcase/codeeurope/"
            target="_blank"
          >
            <LinkedinIcon />
          </Social>
          <Social
            large
            href="https://www.facebook.com/codeeurope"
            target="_blank"
          >
            <FacebookIcon />
          </Social>
        </div>
      </SlideTop>
    </SContainer>
  )
}

export default FollowUs
