import config from 'const/config'

const defaultHeaders = {
  'Content-Type': 'application/json',
}

export interface IClient {
  endpoint: string
  method?: 'GET' | 'POST' | 'PATCH'
  headers?: { [key: string]: string }
  withoutHeaders?: boolean
  body?: any
  credentials?: any
}

const client = ({
  endpoint,
  method = 'GET',
  headers,
  withoutHeaders,
  body,
  credentials,
}: IClient) => {
  return fetch(`${config.CE_API_URL}/${endpoint}`, {
    method,
    headers: withoutHeaders ? undefined : { ...defaultHeaders, ...headers },
    credentials,
    body,
  })
}

export default client
