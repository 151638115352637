import React from 'react'

function ProfileIcon(props) {
  return (
    <svg viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        transform="translate(1 1.333)"
        stroke="currentColor"
        strokeWidth={1.333}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <ellipse cx={6.004} cy={3.811} rx={3.785} ry={3.811} />
        <path d="M0 12.923a1.777 1.777 0 01.174-.774c.363-.73 1.385-1.117 2.233-1.292a13.21 13.21 0 011.856-.263 19.708 19.708 0 013.473 0c.624.044 1.245.132 1.857.263.848.175 1.87.526 2.233 1.292a1.822 1.822 0 010 1.556c-.363.766-1.385 1.117-2.233 1.285-.612.136-1.232.227-1.857.27-.94.08-1.885.095-2.827.044-.218 0-.428 0-.646-.044a12.138 12.138 0 01-1.849-.27c-.855-.168-1.87-.519-2.24-1.285A1.829 1.829 0 010 12.923z" />
      </g>
    </svg>
  )
}

export default ProfileIcon
