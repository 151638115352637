import React, { useRef, useState } from "react"
import { useIntl } from "react-intl"
import { useBreakpoint } from 'modules/breakpoint'

import CheckIcon from "../../assets/icons/CheckIcon"
import { Button, Wrapper, Loader } from "../../components"

import { SNewsletter } from "./NewsletterNav.styled"

import config from 'const/config'

const mailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
const IDLE_STATUS = "idle"
const PENDING_STATUS = "pending"
const SUCCESS_STATUS = "success"
const ERROR_STATUS = "error"

export const SUBSCRIBED_RESULT = "subscribed"
export const UNSUBSCRIBED_RESULT = "unsubscribed"
export const FAILED_RESULT = "failed"

function NewsletterNav() {
  const [status, setStatus] = useState(IDLE_STATUS)
  const [errorMessage, setErrorMessage] = useState(null)

  const newsletterInputRef = useRef()
  const breakpoint = useBreakpoint()
  const { formatMessage } = useIntl()

  const handleInput = () => {
    if (status === ERROR_STATUS) {
      setStatus(IDLE_STATUS)
    }
  }

  const handleSubmit = async e => {
    try {
      e.preventDefault()
      setStatus(PENDING_STATUS)
      const { value } = newsletterInputRef.current

      if (!mailRegex.test(value)) {
        setStatus(ERROR_STATUS)
        return
      }

      const response = await fetch(config.ABSOLVENT_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `
            mutation JoinNewsletter($input: NewsletterInputType) {
              joinNewsletter(input: $input){
                subscriber_email,
                subscription_status,
                error
              }
            }
          `,
          variables: {
            input: {
              mailing_list: config.MAILING_LIST,
              subscriber_email: value,
            },
          },
        }),
      })

      const {
        data: { subscription_status, error },
        errors: graphqlErrors,
      } = await response.json()
      if (subscription_status === FAILED_RESULT || graphqlErrors) {
        throw new Error(error || "Subscription failed without error")
      }

      setStatus(SUCCESS_STATUS)
    } catch (error) {
      console.log("NewsletterNav join error", error)
      if (typeof error === "string") setErrorMessage(error)
      setStatus(ERROR_STATUS)
    }
  }

  const error = status === ERROR_STATUS
  const loading = status === PENDING_STATUS
  const success = status === SUCCESS_STATUS

  return (
    <SNewsletter {...{ error, loading, success }}>
          <h2>
            {formatMessage({ id: "newsletterHeadingNav" })}
          </h2>
          <p>
             {formatMessage({ id: "newsletterHeadingNavText" })}
          </p>
          <form onSubmit={handleSubmit}>
            {success ? (
              <div className="success">
                <p>{formatMessage({ id: "newsletterSuccess" })}</p>
                <span>
                  <CheckIcon />
                </span>
              </div>
            ) : (
              <div>
                <input
                  ref={newsletterInputRef}
                  type="text"
                  onChange={handleInput}
                  placeholder={formatMessage({
                    id: "newsletterPlaceholder",
                  })}
                />
              </div>
            )}
            {!success ? (
              <p>
                {formatMessage(
                  {
                    id: error ? "newsletterError" : "newsletterPolicy",
                  },
                  {
                    a: ([chunk]) => {
                      const [link, text] = chunk.split("#")
                      return (
                        <a href={link} target="_blank">
                          {text}
                        </a>
                      )
                    },
                  }
                )}
              </p>
            ) : null}
            <button disabled={error} type="submit" wide>
              {status === PENDING_STATUS ? <Loader /> : <span>{formatMessage({ id: "newsletterSend" })}</span>}
            </button>
          </form>
        </SNewsletter>
  )
}

export default NewsletterNav
