const LongGreenPattern = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 255 38"
    fill="none"
    {...props}
  >
    <rect width={59} height={13} x={13} y={25} fill="#FC86F6" rx={6} />
    <rect width={13} height={13} fill="#0051D3" rx={6} />
    <rect
      width={163}
      height={13}
      x={87}
      y={25}
      fill="#18E5EF"
      className="blue-line"
      rx={6}
    />
    <rect width={172} height={13} x={28} fill="#1ED760" rx={6} />
  </svg>
)
export default LongGreenPattern
