import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { changeStep, setFields } from 'state/reducers/tickets/ticketsActions'

import client from 'api/client'
import Button from 'components/Button/Button'
import { TicketStep } from 'state/reducers/tickets/ticketsReducer'

import {
  TicketProcessBox,
  TicketUserSteper,
  TicketProcessInput,
  TicketProcessCheckbox,
} from '../'
import useLocalizeRoute from 'utils/useLocalizeRoute'
import { AppRoute } from 'const'

export const mailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
export const withoutDigits =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôōöõøùúûüųūÿýżźñçčšśžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŚŠŽ∂ð ,.'-]+$/i

function TicketUserRegistration({ fields }) {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()

  const [loading, setLoading] = useState(false)

  const { register, handleSubmit, formState, setError } = useForm({
    defaultValues: {
      name: fields.name,
      surname: fields.surname,
      phone: fields.phone,
      email: fields.email,
      password: fields.password,

      terms: fields.terms,
    },
  })

  const handlePrev = () => {
    dispatch(changeStep(TicketStep.SELECTION))
  }

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      const body = JSON.stringify({ step: 1, ...values })
      const response = await client({
        endpoint: 'api/buy/validate',
        method: 'POST',
        body,
      })

      if (!response.ok) {
        const data = await response.json()
        Object.keys(data).forEach((key) => {
          const error = data[key].flat()
          if (!Array.isArray(error)) return

          const [errorMessage] = error
          setError(key, {
            type: 'required',
            message: errorMessage,
          })
        })

        throw new Error()
      }

      dispatch(
        setFields({
          type: 'base',
          fields: { ...values },
        })
      )

      window.scrollTo({ top: 0 })
      dispatch(changeStep(TicketStep.BADGE))
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('[POST] validate user registration step error: ', error)
    }
  }

  return (
    <TicketProcessBox>
      <header>
        <p className="heading">
          {formatMessage({ id: 'tickets.userRegistrationHeading' })}
        </p>
        <p className="sign-in">
          {formatMessage({ id: 'tickets.haveAccount' })}?{' '}
          <Link
            to={{
              pathname: localizeRouteKey(AppRoute.SignIn),
              state: { from: AppRoute.BuyTicket },
            }}
          >
            {formatMessage({ id: 'signIn' })}
          </Link>
        </p>
        <span className="or-separator">
          <span>{formatMessage({ id: 'general.or' })}</span>
        </span>
      </header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TicketProcessInput
          {...{
            label: 'labels.name',
            htmlFor: 'name',
            error: formState.errors.name,
          }}
        >
          <input
            id="name"
            type="text"
            {...register('name', {
              required: {
                value: true,
                message: 'errors.nameError',
              },
            })}
          />
        </TicketProcessInput>
        <TicketProcessInput
          {...{
            label: 'labels.surname',
            htmlFor: 'surname',
            error: formState.errors.surname,
          }}
        >
          <input
            id="surname"
            type="text"
            {...register('surname', {
              required: 'errors.surnameError',
            })}
          />
        </TicketProcessInput>
        <TicketProcessInput
          {...{
            label: 'labels.phone',
            htmlFor: 'phone',
            error: formState.errors.phone,
          }}
        >
          <input id="phone" type="text" {...register('phone')} />
        </TicketProcessInput>
        <TicketProcessInput
          {...{
            label: 'labels.email',
            htmlFor: 'email',
            error: formState.errors.email,
          }}
        >
          <input
            id="email"
            type="text"
            {...register('email', {
              required: {
                value: true,
                message: 'errors.emailError',
              },
            })}
          />
        </TicketProcessInput>
        <TicketProcessInput
          {...{
            label: 'labels.password',
            htmlFor: 'password',
            description: 'labels.passwordRequirements',
            error: formState.errors.password,
          }}
        >
          <input
            id="password"
            type="password"
            {...register('password', {
              required: {
                value: true,
                message: 'errors.passwordError',
              },
            })}
          />
        </TicketProcessInput>
        <TicketProcessCheckbox {...{ error: formState.errors.terms }}>
          <input
            id="terms"
            type="checkbox"
            {...register('terms', { required: true })}
          />
          <label htmlFor="terms">
            {formatMessage(
              { id: 'labels.terms' },
              {
                a: ([chunk]) => (
                  <Link
                    target="_blank"
                    to={localizeRouteKey(AppRoute.UserRegulations)}
                  >
                    {chunk}
                  </Link>
                ),
              }
            )}
          </label>
        </TicketProcessCheckbox>
        <div className="actions">
          <Button loading={loading}>
            {formatMessage({ id: 'general.next' })}
          </Button>
          <Button type="button" onClick={handlePrev} bordered>
            {formatMessage({ id: 'general.back' })}
          </Button>
        </div>
      </form>
    </TicketProcessBox>
  )
}

export default TicketUserRegistration
