import styled, { css } from "styled-components"
import checkIcon from "../../assets/icons/checkbox-check-icon.svg"
import { SDotsContainer } from "../../components/LevelSelect/LevelSelect.styled"
import { Button } from "../../components"

export const SFormGroup = styled.div`
  ${({ theme, error }) =>
    error &&
    css`
      & > input,
      .form-row input,
      .textarea {
        border-color: ${theme.colors.lightRed} !important;
      }

      [type="checkbox"],
      .checkbox-as-radio {
        & + label {
          &::before {
            border-color: ${theme.colors.lightRed} !important;
          }
        }
      }

      .error {
        font-size: 0.81rem;
        color: ${theme.colors.lightRed};

        margin-top: ${theme.space.sm}px;
      }
    `}
`

export const SInlineLabel = styled.label`
  ${({ theme }) => css`
    margin-bottom: 0;
  `}
`

export const SFormContainer = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    max-width: 512px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${theme.space.xxxl}px 0;

    form {
      width: 100%;
      padding: 0 ${theme.space.md}px 0 ${theme.space.md}px;
      ${SFormGroup} {
        display: flex;
        flex-direction: column;

        margin-top: ${theme.space.md}px;

        & > input,
        .form-row input,
        textarea {
          height: 48px;

          font-family: sofia-pro, sans-serif;
          font-size: 1rem;
          color: white;

          border: 1px solid ${theme.colors.lightGray};
          background-color: transparent;

          &:focus {
            outline: 0;
            border-width: 2px;
            border-color: white;
          }
        }

        & > input,
        .form-row input {
          padding: 0 ${theme.space.md}px;
        }

        .textarea {
          width: 100%;

          padding: ${theme.space.md}px;
          padding-right: 5px;

          border: 1px solid ${theme.colors.lightGray};

          textarea {
            width: 100%;
            height: initial;

            border: none;

            resize: none;

            &::-webkit-scrollbar {
              width: 3px;
              background-color: #e0e0e0;
            }

            &::-webkit-scrollbar-thumb {
              background-color: ${theme.colors.pink};
            }
          }
        }
      }

      .form-row {
        display: flex;
        flex-direction: column;

        margin-top: ${theme.space.sm}px;

        & > div:not(${SDotsContainer}) {
          flex: 1;

          margin-top: ${theme.space.sm}px;

          &:first-child {
            margin-top: 0;
          }
        }

        &.inline {
          flex-direction: row;
        }
      }

      label {
        white-space: nowrap;
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        font-size: 1rem;
        color: white;

        margin-bottom: ${theme.space.sm}px;

        span {
          font-size: 0.75rem;
          color: ${theme.colors.lightGray};
        }
      }

      input {
        height: 34px;

        &[type="checkbox"],
        &.checkbox-as-radio {
          visibility: hidden;
          position: absolute;
          top: 0;
          left: 0;
          width: 1px;
          height: 1px;
          pointer-events: none;

          & + label {
            font-size: 1rem;
            line-height: 1.5;
            color: white;

            display: block;

            position: relative;

            padding-left: 28px;

            cursor: pointer;

            &:hover {
              &::before {
                border-width: 2px;
                border-color: white;
              }
            }

            &::before {
              content: "";
              width: 20px;
              height: 20px;

              position: absolute;
              top: 2px;
              left: 0;

              border: 1px solid white;
            }
          }

          &:checked {
            & + label {
              &::before {
                background-image: url(${checkIcon});
                background-size: 80%;
                background-position: 1px 1px;
                background-repeat: no-repeat;

                border: 1px solid ${theme.colors.pink};
              }
            }
          }
        }
      }
    }

    .add-button,
    .remove-button {
      height: 32px;
      font-size: 1rem;
      color: ${theme.colors.purple};

      display: flex;
      align-items: center;

      margin-top: ${theme.space.md}px;
      margin-left: auto;
      padding: ${theme.space.sm}px ${theme.space.md}px;

      background: none;
      border-radius: 16px;
      border: solid 1px ${theme.colors.purple};
      box-shadow: none;

      cursor: pointer;

      &:focus {
        outline: 0;
      }
    }

    .remove-button {
      color: ${theme.colors.lightRed};
      border-color: ${theme.colors.lightRed};
    }
  `}
`

export const SHeader = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.pink};
    font-size: 32px;
    font-weight: ${theme.weight.bold};
    margin: ${theme.space.xxxl}px 0 ${theme.space.xxl}px 0;
  `}
`

export const SHeaderDescription = styled.h2`
  ${({ theme }) => css`
    font-size: 20px;
    text-align: center;
    color: #fff;
    line-height: 1.6;
  `}
`

export const SLabelDisclaimer = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.lightGray};
    line-height: 1.14;
    font-size: 14px;
    margin-top: ${theme.space.xs}px;
    margin-bottom: ${theme.space.md}px;
  `}
`
export const SSend = styled(Button)`
  ${({ theme }) => css`
    width: 100%;

    margin-top: ${theme.space.lg}px;
  `}
`
