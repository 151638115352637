import styled, { css } from "styled-components"

import checkIcon from "../../assets/icons/checkbox-check-icon.svg"

export const SMobile = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;

    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;

    background-color: ${theme.colors.darkGray};

    z-index: 12;

    .header,
    .footer {
      flex-shrink: 0;

      background-color: ${theme.colors.darkGray};

      padding: 0 ${theme.space.md}px;
    }

    .header {
      height: 64px;

      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;

      color: white;

      button {
        width: 28px;
        height: 28px;

        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 50%;
        right: 16px;

        transform: translateY(-50%);

        padding: 0;
        margin: 0;

        border: 0;
        border-radius: 50%;

        background-color: ${theme.colors.pink};

        svg {
          width: 10px;
          height: 10px;
        }
      }
    }

    .content {
      height: calc(100% - 136px);

      padding: 0 ${theme.space.md}px;

      overflow-y: auto;
      
      ::-webkit-scrollbar {
          display: none;
      }

      scrollbar-width: none;  
    }

    .footer {
      height: 72px;

      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0 ${theme.space.md}px;

      align-items: center;
      justify-content: center;
    }
  `}
`

export const SFiltrationButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    color: ${theme.colors.pink};

    border: none;
    box-shadow: none;
    background: none;

    margin: 0;
    padding: 0;
    margin-left: auto;

    svg {
      width: 20px;
      height: 20px;

      margin-top: 4px;
      margin-left: 8px;
    }
  `}
`

export const SFiltration = styled.form`
  ${({ theme }) => css`
    .desktop-clear {
      height: 32px;

      font-size: 0.875rem;
      line-height: 1.71;
      color: ${theme.colors.lightGray};

      padding: 0;
      margin: 0;
      margin-bottom: ${theme.space.md}px;

      border: none;
      box-shadow: none;
      background: none;

      cursor: pointer;

      &:hover {
        color: ${theme.colors.pink};
      }

      svg {
        width: 8px;
        height: 8px;
      }
    }

    .group {
      margin-top: ${theme.space.xl}px;

      &:first-of-type {
        margin-top: 0;
      }

      &-name {
        font-size: 1.25rem;
        font-weight: ${theme.weight.bold};
        line-height: 1.6;
        color: #ffffff;
      }

      .filters {
        .filter {
          position: relative;
          margin-top: 20px;

          input {
            width: 1px;
            height: 1px;

            position: absolute;
            top: 0;
            left: 0;

            opacity: 0;
            visibility: hidden;

            & + label {
              line-height: 1.2;
              color: #ffffff;

              display: block;

              padding-left: 28px;

              cursor: pointer;

              &::before {
                content: "";
                width: 20px;
                height: 20px;

                position: absolute;
                top: 1px;
                left: 0;

                border: 1px solid ${theme.colors.lightGray};
              }

              &:hover {
                &::before {
                  border-color: white;
                }
              }
            }

            &:checked + label {
              &::before {
                background-image: url(${checkIcon});
                background-size: 80%;
                background-position: 1px 1px;
                background-repeat: no-repeat;
                border-color: ${theme.colors.pink};
              }
            }
          }
        }
      }

      .more {
        color: ${theme.colors.pink};

        border: none;
        box-shadow: none;
        background: none;
        border-bottom: 1px solid transparent;

        margin: ${theme.space.md}px 0 0 0;
        padding: 0;

        cursor: pointer;

        &:hover,
        &:focus {
          outline: 0;
          border-bottom: 1px solid ${theme.colors.pink};
        }

        svg {
          margin-bottom: 2px;
        }
      }
    }
  `}
`
