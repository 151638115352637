function parseFiltration(data) {
  const keys = Object.keys(data)
  return keys.reduce((acc, current) => {
    return [
      ...acc,
      {
        category: current,
        fields: data[current].map((o) => ({
          ...o,
          value: false,
        })),
      },
    ]
  }, [])
}

export default parseFiltration
