import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SContainer = styled.section`
  ${({ theme, paddings }) => css`
    background-color: ${theme.colors.backgroundBlack};
    padding: ${paddings?.mobile
      ? paddings.mobile
      : `50px ${theme.space.lg}px 80px`};

    ${respondTo.lg`
      padding: ${
        paddings?.desktop ? paddings.desktop : `100px ${theme.space.lg}px 120px`
      };
    `}

    .content {
      max-width: 820px;
      margin: 0 auto;
    }
    .heading {
      color: ${theme.colors.white};
      font-weight: ${theme.weight.bold};
      font-size: 1.5rem;
      line-height: 1.875rem;
      text-align: center;
      margin-bottom: ${theme.space.md + 4}px;

      ${respondTo.lg`
        font-size: 2.25rem;
        line-height: 3.125rem;
        margin-bottom: ${theme.space.xl + 8}px;
      `}
    }

    .accordion {
      .Collapsible__trigger {
        display: inline-block;
        font-size: 1.125rem;
        line-height: 1.6rem;
        color: ${theme.colors.lightGray2};
        padding: ${theme.space.md + 4}px 0 ${theme.space.md}px;
        border-top: 1px solid ${theme.colors.darkestGray};
        width: 100%;

        ${respondTo.lg`
          font-size: 1.375rem;
          line-height: 1.875rem;
        `}
      }
      &-text {
        line-height: 1.375rem;
        color: ${theme.colors.white};
        padding: ${theme.space.xs}px 0 ${theme.space.md}px;

        ${respondTo.lg`
          max-width: 84%;
        `}
      }
    }

    .bold {
      font-weight: ${theme.weight.bold};
    }
    .link {
      color: ${theme.colors.pink};
      text-decoration: none;
    }
  `}
`
