import { useMemo, useEffect, useRef, useState, useLayoutEffect } from 'react'
import { useTheme } from 'styled-components'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import Countdown from 'react-countdown'
import DateDiff from 'date-diff'

import {
  SectionHeader,
  Tabs,
  Ticket,
  TicketSummary,
  Button,
  Loader,
  TicketSelectionQuotes,
  EarlyBirds2022,
} from 'components'
import { SContainer, STicketsSelector, SList } from './TicketsSelector.styled'
import { TicketCity, TicketStep } from 'state/reducers/tickets/ticketsReducer'
import CheckIcon from 'assets/icons/CheckIcon'
import DeleteIcon from 'assets/icons/DeleteIcon'
import {
  buyTicket,
  changeCity,
  changeStep,
  selectTicket,
  unselectTicket,
  updateDiscountCode,
} from 'state/reducers/tickets/ticketsActions'
import PomeraniaBanner from 'components/PomeraniaBanner/PomeraniaBanner'

import { Link } from 'react-scroll'

import { PassType } from '../../state/reducers/tickets/ticketsReducer'
import isBetweenTwoDates from '../../utils/isBetweenTwoDates'
import useBlockScroll from '../../utils/useBlockScroll'
import { HEADER_DOM_ID } from '../Header/Header'
import { plQuotes, enQuotes } from '../../const/ticketSelectionQuotes'
import { defaultTimezone } from 'const/defaultTimezone'

function TicketsSelector({
  items,
  price,
  isFree,
  isGroup,
  buyTicketLoading,
  isAuthenticated,
  Wrapper,
}) {
  const { colors } = useTheme()
  const { formatMessage, formatDate, locale } = useIntl()
  const dispatch = useDispatch()
  const discountCodeRef = useRef()

  const [headerHeight, setHeaderHeight] = useState(0)

  const ticketColors = useMemo(
    () => [colors.pink, colors.lightTurquoise, colors.green, colors.lightBlue],
    []
  )

  const { selected } = items

  const entities = useMemo(() =>
    items.tickets.filter(
      (ticket) => ticket.city_en.toLowerCase() === selected.city,
      [items, selected.city]
    )
  )

  const anySelected = entities.some(
    (entity) => entity.id === selected?.entity?.id
  )

  const selectedTicket = selected?.amount > 0 ? selected.entity : null

  const shouldBuy = isFree && isAuthenticated

  const handleSelectTicket =
    (ticket) =>
    (amount = 1) => {
      const today = new Date().getTime()
      const ticketSalesEnd = new Date(
        ticket.pass_price_till?.replace(/-/g, '/')
      ).getTime()

      if (today > ticketSalesEnd && ticket.type !== 'student') {
        dispatch(unselectTicket())
        return
      }

      if (discountCodeRef.current) {
        discountCodeRef.current.value = ''
      }

      dispatch(selectTicket({ ticket, amount }))

      // if (
      //   ticket.place.toLowerCase().includes('3city') &&
      //   !ticket.place.student_ticket
      // ) {
      //   setTimeout(() => {
      //     if (!discountCodeRef?.current) return

      //     discountCodeRef.current.value = '#LiveMorePomeraniaCE2021'
      //     dispatch(updateDiscountCode('#LiveMorePomeraniaCE2021'))
      //   }, 500)
      // }
    }

  const handleRemoveTicket = () => {
    dispatch(unselectTicket())
  }

  const handleChangeCity = (city) => () => {
    if (city === selected.city) return
    dispatch(changeCity(city))
  }

  const handleSubmit = () => {
    if (isFree && isAuthenticated) {
      dispatch(buyTicket({ isFree, locale, isGroup }))
      return
    }

    window.scrollTo({ top: 0 })
    dispatch(
      changeStep(
        isGroup || isAuthenticated
          ? TicketStep.PAYMENT_DATA
          : TicketStep.REGISTRATION
      )
    )
  }

  const handleDiscountCode = (e) => {
    e.preventDefault()

    const value = discountCodeRef.current.value
    dispatch(updateDiscountCode(value))
  }

  const tabs = [
    { key: TicketCity.CRACOW, text: 'cracow' },
    { key: TicketCity.WARSAW, text: 'warsaw' },
  ]

  const activeTabText = tabs.find((tab) => tab.key === selected.city)?.text

  const activeEntity = useMemo(() => {
    return entities?.find((el) =>
      isBetweenTwoDates(
        new Date(),
        new Date(el.pass_price_from?.replace(/-/g, '/')),
        new Date(el.pass_price_till?.replace(/-/g, '/'))
      )
    )
  }, [entities])

  const ticketTypeActive = activeEntity?.pass_type
  const activeTicketTypeSaleEndDate = activeEntity?.pass_price_till
  const daysLeftTillActiveTicketSaleEnd = Math.floor(
    new DateDiff(new Date(activeTicketTypeSaleEndDate), new Date()).days()
  )

  const shouldShowCountdownBanner =
    ticketTypeActive &&
    ticketTypeActive !== PassType.STANDARD_PASS &&
    daysLeftTillActiveTicketSaleEnd <= 20

  //For blocking scroll before city choice, when there are events in multiple cities
  // const unBlockScroll = () => {
  //   setScrollBlocked(false)
  // }

  const getHeaderHeight = () => {
    setHeaderHeight(
      document.getElementById(HEADER_DOM_ID).getBoundingClientRect().height
    )
  }

  useEffect(() => {
    getHeaderHeight()

    window.addEventListener('resize', getHeaderHeight)
    return () => {
      window.removeEventListener('resize', getHeaderHeight)
    }
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  const countdownRenderer = ({ days, hours, minutes, seconds }) => {
    return (
      <span className="countdown-number-wrapper">
        <span className="countdown-number">
          {days}{' '}
          {days !== 1
            ? formatMessage({ id: 'days' })
            : formatMessage({ id: 'day' })}{' '}
          {hours}h {minutes}min {seconds}s
        </span>
      </span>
    )
  }

  return (
    <SContainer>
      <Wrapper>
        <STicketsSelector headerHeight={headerHeight}>
          <div className="choose-city-section">
            <div className="choose-city-content">
              <div className="title-wrapper">
                <SectionHeader
                  heading="tickets.selectHeading"
                  headingUnderlineColor={colors.pink}
                />
                <p className="subheading">
                  {formatMessage(
                    { id: 'tickets.selectSubheading' },
                    {
                      br: <br />,
                    }
                  )}
                </p>
              </div>
              {/* <div className="tabs-wrapper">
                <p className="choose-city-title">
                  {formatMessage({ id: 'tickets.chooseCity' })}
                </p>
                <Tabs
                  selected={selected.city}
                  onChange={handleChangeCity}
                  tabs={tabs}
                />
              </div> */}
              {/* <div className="scroll-button-wrapper">
                <Button
                  onClick={unBlockScroll}
                  as={Link}
                  to="ticketSelection"
                  offset={-100}
                  smooth
                >
                  {formatMessage({ id: 'tickets.selectTicketType' })}
                </Button>
              </div> */}
            </div>
          </div>
          <p className="title" id="ticketSelection">
            {formatMessage({ id: activeTabText })}{' '}
            {formatDate(
              entities[0]?.date_start?.replace(/-/g, '/'),
              {
                month: '2-digit',
                day: 'numeric',
              },
              {
                timeZone: defaultTimezone,
              }
            )
              .split('/')
              .reverse()
              .join('.')}{' '}
            -{' '}
            {formatDate(
              entities[0]?.date_end?.replace(/-/g, '/'),
              {
                month: '2-digit',
                day: 'numeric',
              },
              {
                timeZone: defaultTimezone,
              }
            )
              .split('/')
              .reverse()
              .join('.')}
          </p>
          <p className="subtitle">
            {formatMessage({ id: 'tickets.selectPass' })}
          </p>
          {shouldShowCountdownBanner ? (
            <div className="countdown-info-wrapper">
              <div className="countdown-info">
                <span className="bold-text">
                  {formatMessage({ id: 'hurryUp' })}
                </span>{' '}
                {formatMessage({ id: 'countdownPassPricePlOnly' })}
                <span className="countdown-ticket-type">
                  {ticketTypeActive}
                </span>
                {formatMessage({ id: 'countdownPassPriceInfo' })}
                <Countdown
                  date={activeTicketTypeSaleEndDate?.replace(/-/g, '/')}
                  renderer={countdownRenderer}
                />
              </div>
            </div>
          ) : null}
          {/* <EarlyBirds2022 /> */}
          {/* <PomeraniaBanner
            className="ticket-banner"
            heading={formatMessage(
              { id: 'tickets.bannerHeading' },
              { strong: (chunk) => <strong>{chunk}</strong> }
            )}
          >
            <p className="ticket-banner-code">
              <span>{formatMessage({ id: 'tickets.bannerUseCode' })}:</span>
              <span className="code">#LiveMorePomeraniaCE2021</span>
            </p>
          </PomeraniaBanner> */}
        </STicketsSelector>
      </Wrapper>
      <SList>
        {entities.map((entity, index) => (
          <Ticket
            key={entity.id}
            {...{
              ...entity,
              // color: ticketColors[index % ticketColors.length],
              anySelected,
              isGroup,
              selected: selected?.entity?.id === entity.id,
              amount: selected?.amount ?? 0,
              onSelect: handleSelectTicket(entity),
            }}
          />
        ))}
      </SList>
      {selected.amount > 1 ? (
        <p className="list-information">
          {formatMessage({ id: 'tickets.selectDescription' })}
        </p>
      ) : null}
      <Wrapper>
        <STicketsSelector>
          {selectedTicket?.student_ticket ? (
            <p className="students-information">
              {formatMessage({ id: 'tickets.selectStudentInformation' })}
            </p>
          ) : null}
          <div className="summary" id="summary">
            <div className="summary-content">
              <div className="discount">
                <p className="heading">
                  {formatMessage({ id: 'tickets.selectDiscountCodeHeading' })}:
                </p>

                <form onSubmit={handleDiscountCode}>
                  <label htmlFor="discount-code">
                    {formatMessage({ id: 'tickets.selectDiscountCodeLabel' })}
                  </label>
                  <input
                    defaultValue={price.discountCode}
                    ref={discountCodeRef}
                    type="text"
                    name="discount"
                    id="discount-code"
                    onBlur={handleDiscountCode}
                    disabled={!selectedTicket}
                    placeholder={
                      !selectedTicket
                        ? formatMessage({
                            id: 'tickets.discountDisabledPlaceholder',
                          })
                        : ''
                    }
                  />
                  <button type="submit" disabled={!selectedTicket}>
                    {price.loading ? <Loader size={3} /> : <CheckIcon />}
                  </button>
                </form>
                {price.errors?.discount_code?.length ? (
                  <p className="discount-error">
                    {formatMessage({ id: price.errors?.discount_code[0] })}
                  </p>
                ) : null}
              </div>
              <div className="details">
                <p className="heading">
                  {formatMessage({ id: 'tickets.selectDetailsHeading' })}:
                </p>

                <div className="ticket">
                  {selectedTicket ? (
                    <TicketSummary
                      {...{
                        price,
                        ticket: selectedTicket,
                        amount: selected.amount,
                        onRemove: handleRemoveTicket,
                      }}
                    />
                  ) : (
                    <p className="empty">
                      {formatMessage({ id: 'tickets.selectDetailsEmpty' })}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <Button
              className="submit"
              disabled={
                !selectedTicket ||
                price.loading ||
                items.loading ||
                (price.errors && Object.keys(price.errors).length) ||
                buyTicketLoading
              }
              loading={shouldBuy && buyTicketLoading}
              onClick={handleSubmit}
            >
              {formatMessage({ id: 'general.next' })}
            </Button>
          </div>
          <TicketSelectionQuotes
            quotes={locale === 'pl' ? plQuotes : enQuotes}
          />
        </STicketsSelector>
      </Wrapper>
    </SContainer>
  )
}

export default TicketsSelector
