import styled, { css } from 'styled-components'

import checkIcon from '../../assets/icons/checkbox-check-icon.svg'

export const STicketProcessCheckbox = styled.div`
  ${({ theme, error }) => css`
    margin-top: 22px;
    position: relative;

    input {
      height: 34px;

      &[type='checkbox'] {
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 1px;
        pointer-events: none;

        & + label {
          font-size: 1rem;
          line-height: 1.5;
          color: white;

          display: block;

          position: relative;

          padding-left: 28px;

          cursor: pointer;

          a {
            text-decoration: none;
            color: ${theme.colors.pink};
          }

          &:hover {
            &::before {
              border-width: 2px;
              border-color: white;
            }
          }

          &::before {
            content: '';
            width: 20px;
            height: 20px;

            position: absolute;
            top: 2px;
            left: 0;

            border: 1px solid ${error ? theme.colors.lightRed : 'white'};
          }
        }

        &:checked {
          & + label {
            &::before {
              background-image: url(${checkIcon});
              background-size: 80%;
              background-position: 1px 1px;
              background-repeat: no-repeat;

              border: 1px solid ${theme.colors.pink};
            }
          }
        }
      }
    }
  `}
`
