import { AppRoute } from 'const'
import { ButtonLink } from '../../components'
import { useIntl } from 'react-intl'
import useLocalizeRoute from 'utils/useLocalizeRoute'
import { SSpeakersBanner } from './SpeakersBanner.styled'

function SpeakersBanner({ home }) {
  const { formatMessage } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()

  return (
    <SSpeakersBanner {...{ home }}>
      <div className="wrapper">
        <p>{formatMessage({ id: 'speakers.bannerHeading' })}</p>
        <ButtonLink
          href={localizeRouteKey(AppRoute.BecomeSpeaker)}
          className="link"
        >
          {formatMessage({ id: 'becomeSpeaker' })}
        </ButtonLink>
      </div>
    </SSpeakersBanner>
  )
}

export default SpeakersBanner
