import React from "react"


export const TopRightPattern = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={164} height={38}>
    <g fill="none" fillRule="evenodd">
      <rect width={92} height={13} x={41} y={25} fill="#18E5EF" rx={6} />
      <rect width={50} height={13} x={77} fill="#0051D3" rx={6.5} />
      <rect width={28} height={13} x={31} fill="#1ED760" rx={6.5} />
      <rect width={13} height={13} x={151} y={25} fill="#0051D3" rx={6.5} />
      <rect width={13} height={13} fill="#FC86F6" rx={6.5} />
    </g>
  </svg>
)

export const BottomLeftPattern = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={262} height={38}>
    <g fill="none" fillRule="evenodd">
      <rect width={50} height={13} x={102} y={25} fill="#FC86F6" rx={6} />
      <rect width={13} height={13} x={111} fill="#0051D3" rx={6} />
      <rect width={92} height={13} x={170} y={25} fill="#18E5EF" rx={6} />
      <rect width={92} height={13} x={142} fill="#0051D3" rx={6} />
      <rect width={92} height={13} fill="#1ED760" rx={6} />
    </g>
  </svg>
)
