import styled, { css } from 'styled-components'
import respondTo from 'theme/respondTo'

export const SUserConsent = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.space.xl}px;

    .loader {
      margin: ${theme.space.xl}px 0;
    }

    .label {
      font-weight: ${theme.weight.normal};
      color: white;

      margin-bottom: 0;
    }

    .remove-account-info {
      margin-top: ${theme.space.xxxl}px;
      p:not(.label) {
        font-size: 1rem;
        line-height: 1.5;
        color: white;

        margin-top: ${theme.space.sm}px;
        a {
          color: ${theme.colors.pink};
        }
      }
    }
  `}
`
