import styled, { css } from 'styled-components'
import respondTo from 'theme/respondTo'

export const SListEmptyState = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: ${theme.space.xl}px 0;

    svg {
      max-width: 115px;
    }

    .text {
      font-size: 1rem;
      line-height: 1.6;
      text-align: center;
      color: white;
    }

    button {
      margin-top: ${theme.space.xl}px;
    }

    ${respondTo.lg`
      svg {
        max-width: 185px;
      }
      .text {
        font-size: 1.25rem;
        padding: 0 ${theme.space.xxxl}px;
      }
    `}
  `}
`
