import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SOfferTile = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    border-top: 1px solid ${theme.colors.lightGray};

    :last-of-type {
      margin-bottom: 0;
    }

    .top,
    .bottom {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .top {
      justify-content: space-between;
    }

    .bottom {
      color: ${theme.colors.lightGray};
      flex-wrap: wrap;
      margin-top: ${theme.space.sm}px;
    }

    .offer-location,
    .requirements {
       display: inline-block;
       margin-right: ${theme.space.md}px;
    }

    .requirements {
      font-size: 0.875rem;
      line-height: 1.5rem;
    }

    .offer-location,
    .offer-location-name {
      word-break: break-word;
      font-size: 0.875rem;
      line-height: 0.875rem;
    }
    
    .offer-location, .company  {
      margin-bottom: ${theme.space.xs}px;
    }

    .offer-pin-icon {
      min-width: ${theme.space.sm}px;
      max-width: ${theme.space.sm}px;
      height: ${theme.space.md - theme.space.xs}px;
      display: inline-block;
      margin-right: 6px;
    }

    a {
      text-decoration: none;
      color: lightGray;

      display: flex;
      flex-direction: column;

      padding: ${theme.space.md}px 0px;

      &:hover {
        .name {
          color: ${theme.colors.pink};
        }
      }
    }

    .name {
      font-size: 1rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.5rem;
      color: ${theme.colors.white};
      margin-bottom: ${theme.space.sm}px;
      display: block;
    }

    .category {
      display: inline-block;
      color: ${theme.colors.lightGray};
      font-size: 0.875rem;
      line-height: 1.5rem;
    }

    .salary {
      font-size: 1rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.5rem;
      color: ${theme.colors.lightGray};
      margin-top: ${theme.space.sm}px;
    }

    .position,
    .company {
      font-size: 0.87rem;
      line-height: 1.5;
    }

    .company {
      font-weight: ${theme.weight.bold};
    }

    .tags {
      margin-top: ${theme.space.sm}px;
      display: inline-block;

      &-container {
        width: 100%;
      }

      .tag {
        font-size: 0.75rem;
        font-weight: bold;
        line-height: 1.33;
        color: ${theme.colors.pink};

        display: inline-block;

        padding: 2px ${theme.space.sm}px 5px ${theme.space.sm}px;
        margin-right: ${theme.space.sm}px;

        border: 1px solid ${theme.colors.pink};
        border-radius: 12px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    ${respondTo.lg`
      border: 1px solid ${theme.colors.lightGray};
      margin-bottom: ${theme.space.sm}px;
      padding: ${theme.space.md}px;
      
      :hover {
        border: 1px solid ${theme.colors.pink};
      }
    
      a {
        display: block;

        padding: 0;
        margin: 0;

        border: none;
        
        :last-of-type {
          border-bottom: none;
        }
      }
      
      .name {
        display: inline-block;
        margin: 0 ${theme.space.md}px 0 0;
      }
      
      .offer-location, .company  {
        margin-bottom: 0;
      }
      
      .salary {
        margin-top: 0;
        min-width: 110px;
        margin-left: 20px;
        text-align: right;
      }

      .position {
        font-size: 1rem;
      }

      .tags {
        margin-top: ${theme.space.sm}px;
      }
    `}
  `}
`
