import styled, { css } from 'styled-components'
import respondTo from 'theme/respondTo'

export const SSkillLevelCircle = styled.span`
  ${({ theme, active }) => css`
    width: 12px;
    height: 12px;

    flex-shrink: 0;

    display: block;

    border: solid 3px ${theme.colors.lightGray};
    border-radius: 50%;

    ${active &&
    css`
      border-color: ${theme.colors.pink};
    `}

    ${respondTo.lg`
      width: 16px;
      height: 16px;
    `}
  `}
`

export const SAgendaEventDetails = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.space.md}px;
    margin: 0 ${-theme.space.md}px;
    padding-bottom: ${theme.space.lg}px;

    border-bottom: 1px solid ${theme.colors.gray};

    &:last-of-type {
      padding-bottom: 0;
      border-bottom: 0;
    }

    .room {
      display: inline-block;

      font-size: 0.875rem;
      color: ${theme.colors.lightGray};

      margin-top: ${theme.space.md}px;
    }

    .title {
      font-size: 1rem;
      color: white;

      margin-top: ${theme.space.md}px;
    }

    .speakers {
      font-size: 0.875rem;
      color: ${theme.colors.pink};

      margin-top: ${theme.space.sm}px;

      .speaker {
        margin-top: ${theme.space.sm}px;
      }

      a,
      .company {
        text-decoration: none;
        color: inherit;
      }

      .company {
        font-weight: ${theme.weight.bold};
        margin-left: ${theme.space.sm}px;
      }
    }

    .duration-type-track {
      display: flex;
      flex-direction: column;

      margin-top: ${theme.space.md}px;
    }

    .duration-type {
      display: flex;
      align-items: center;

      margin-right: ${theme.space.sm}px;
    }

    .duration {
      font-size: 0.875rem;
      color: ${theme.colors.lightGray};

      margin-right: ${theme.space.md}px;
    }

    .type {
      height: 24px;

      display: inline-flex;
      align-items: center;
      justify-content: center;

      font-size: 0.75rem;
      font-weight: ${theme.weight.bold};
      color: white;

      padding: 0 8px;

      border-radius: 12px;
      background-color: ${theme.colors.pink};
    }

    .track {
      display: inline-flex;
      align-items: center;

      font-size: 0.875rem;
      color: white;

      margin-top: ${theme.space.md}px;

      .dot {
        width: 12px;
        height: 12px;

        display: block;

        margin-right: ${theme.space.xs}px;
        margin-top: 2px;

        border-radius: 50%;
      }
    }

    .toggler {
      display: flex;
      align-items: baseline;

      font-size: 0.875rem;
      color: ${theme.colors.pink};

      border: 0;
      box-shadow: none;
      background: none;

      margin-left: auto;
      margin-top: ${theme.space.md}px;

      cursor: pointer;

      svg {
        height: 8px;
        margin-left: ${theme.space.sm}px;
      }
    }

    .details {
      margin-top: ${theme.space.md}px;
    }

    .hours-lang {
      display: flex;

      font-size: 0.875rem;
      color: white;

      .hours {
        font-weight: ${theme.weight.bold};

        margin-right: ${theme.space.lg}px;
      }
    }

    .description {
      font-size: 0.875rem;
      line-height: 1.5rem;
      color: white;

      margin-top: ${theme.space.md}px;
    }

    .tags {
      font-size: 0.875rem;
      color: white;

      margin-top: ${theme.space.md}px;

      &::after {
        content: '';
        display: table;
        clear: both;
      }

      &-label {
        display: inline-block;

        line-height: 1.25rem;
        float: left;

        margin-right: ${theme.space.sm}px;
        margin-bottom: ${theme.space.sm}px;
      }
    }

    .skill {
      display: flex;
      align-items: center;

      font-size: 0.875rem;
      color: white;

      margin-top: ${theme.space.md}px;

      &-label {
        margin-right: ${theme.space.sm}px;
      }

      &-level {
        display: grid;
        grid-template-columns: repeat(3, 12px);
        grid-gap: 0 ${theme.space.sm}px;

        margin-top: 3px;
      }

      &-name {
        margin-left: ${theme.space.sm}px;
      }
    }

    ${respondTo.lg`
      flex: 1;

      margin: 0;
      padding-bottom: ${theme.space.xxl}px;
      border: 0;

      .room {
        margin-top: ${theme.space.sm}px;
      }

      .duration-type-track {
        flex-direction: row;
        align-items: center;
      }

      .track {
        margin-top: 0;
      }

      .toggler {
        margin-top: ${-theme.space.lg}px;
      }

      .details {
        margin-top: ${theme.space.xxl}px;
        padding-left: ${theme.space.lg}px;

        border-left: 1px solid ${theme.colors.lightestGray};
      }

      .skill {
        &-level {
          grid-template-columns: repeat(3, 16px);
          grid-gap: 0 10px;
        }
      }
    `}
  `}
`
