import styled, { css } from 'styled-components'
import respondTo from 'theme/respondTo'

export const STicketUserSteper = styled.div`
  ${({ theme }) => css`
    ${respondTo.lg`
      max-width: 562px;
      margin-left: auto;
      margin-right: auto;
      margin-top: ${theme.space.xl}px;
      margin-bottom: ${theme.space.xl}px;
    `}
  `}
`
