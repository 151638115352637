import React, { useEffect } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { LanguageSwitcher } from 'modules/i18n'
import { Link as ScrollLink } from 'react-scroll'

import { Button, Tooltip } from '../../../components/index'

import { SNavigation } from './Navigation.styled'
import useLocalizeRoute from 'utils/useLocalizeRoute'
import preventClick from 'utils/preventClick'

import { AppRoute } from 'const'

export const shouldShowCompanies =
  new Date().getTime() < new Date('2024-08-01').getTime()

export const Navigation = ({ mobile, closeMobileNav }) => {
  const { formatMessage, locale } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()
  const { pathname } = useLocation()
  const tooltipContent = <> {formatMessage({ id: 'soon' })} </>

  if (mobile) return <SNavigation {...{ mobile }} />

  return (
    <SNavigation>
      <ul className="menu">
        <li>
          <NavLink to={localizeRouteKey(AppRoute.Speakers)}>
            {formatMessage({ id: 'speakers' })}
          </NavLink>
        </li>
        <li>
          <NavLink to={localizeRouteKey(AppRoute.Agenda)}>
            {formatMessage({ id: 'agenda' }, { year: 2024 })}
          </NavLink>
        </li>
        <li>
          {pathname === `/${locale}/` ||
          pathname === `/${locale}/?section=faq` ? (
            <ScrollLink to="faq" smooth offset={-140}>
              {formatMessage({ id: 'faqNavigation' })}
            </ScrollLink>
          ) : (
            <NavLink to={`/${locale}/?section=faq`} exact>
              {formatMessage({ id: 'faqNavigation' })}
            </NavLink>
          )}
        </li>
        {/*<li>*/}
        {/*  <NavLink to={localizeRouteKey(AppRoute.Offers)}>*/}
        {/*    {formatMessage({ id: 'jobOffers' })}*/}
        {/*  </NavLink>*/}
        {/*</li>*/}
        {shouldShowCompanies && (
          <li>
            <NavLink to={localizeRouteKey(AppRoute.Companies)}>
              {formatMessage({ id: 'companies' })}
            </NavLink>
          </li>
        )}
        <Button
          height="40px"
          fontSize="16px"
          secondary="true"
          as={Link}
          // className="disable-secondary-button"
          // to="#"
          // onClick={preventClick}
          to={localizeRouteKey(AppRoute.BuyTicket)}
        >
          {formatMessage({
            id: 'getTicketButton',
          })}
          <i className="ri-arrow-right-line" />
        </Button>
      </ul>
      <LanguageSwitcher className="language-picker" />
    </SNavigation>
  )
}
