import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import { TicketSummary, Loader } from 'components'
import { SUserConsent } from './UserConsent.styled'
import TicketProcessCheckbox from 'components/TicketProcessCheckbox/TicketProcessCheckbox'
import { updateUserConsent } from 'state/reducers/user/userActions'

function UserConsent({ user, consent }) {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const handleChangeConsent = (e) => {
    const { checked } = e.target
    dispatch(updateUserConsent({ matchmaking: checked }))
  }

  const { loading } = consent
  return (
    <SUserConsent>
      {loading ? (
        <div className="loader">
          <Loader />
        </div>
      ) : null}
      <p className="label">MatchMaking:</p>
      <TicketProcessCheckbox>
        <input
          id="matchmaking"
          name="matchmaking"
          type="checkbox"
          defaultChecked={user.matchmaking}
          onChange={handleChangeConsent}
        />
        <label htmlFor="matchmaking">
          {formatMessage({ id: 'labels.matchMaking' })}
        </label>
      </TicketProcessCheckbox>

      <div className="remove-account-info">
        <div className="label">
          {formatMessage({ id: 'user.consentRemoveAccountLabel' })}:
        </div>
        <p>
          {formatMessage({ id: 'user.consentRemoveAccount' })}
          <a href="mailto:iod@codeeurope.pl">iod@codeeurope.pl</a>
        </p>
      </div>
    </SUserConsent>
  )
}

export default UserConsent
