import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { Link as ScrollLink } from 'react-scroll'

import TwitterIcon from '../../assets/icons/TwitterIcon'
import FacebookIcon from '../../assets/icons/FacebookIcon'
import LinkedinIcon from '../../assets/icons/LinkedinIcon'

import { LanguageSwitcher } from 'modules/i18n'
import { NavLink } from 'react-router-dom'
import { AppRoute, AppRouteTitles } from 'const'
import { useBreakpoint } from 'modules/breakpoint'
import { Button, Social } from '../'
import { Burger, Tooltip } from '..'
import useLocalizeRoute from 'utils/useLocalizeRoute'

import {
  SContainer,
  SContent,
  SSectionTitle,
  SList,
  SLang,
  SLangs,
  SSeparator,
  SSocials,
  SFollow,
  SPattern,
} from './MobileNavigation.styled'
import { useSelector } from 'react-redux'
import { HEADER_DOM_ID } from '../Header/Header'
import { shouldShowCompanies } from '../../modules/navigation'

function Navigation({ langsMenu, closeMobileNav, mobileNavigationVisible }) {
  const { formatMessage, locale } = useIntl()

  const [partialUrl, setPartialUrl] = useState('')
  const [headerHeight, setHeaderHeight] = useState(0)

  const breakpoints = useBreakpoint()
  const { localizeRouteKey } = useLocalizeRoute()
  const { pathname } = useLocation()

  const isAuthenticated = useSelector(({ user }) => user.isAuthenticated)

  const closeIfActive = (e) => {
    closeMobileNav()
  }
  const preventClick = (e) => {
    e.preventDefault()
  }
  const tooltipContent = <> {formatMessage({ id: 'soon' })} </>

  const getHeaderHeight = () => {
    setHeaderHeight(
      document.getElementById(HEADER_DOM_ID).getBoundingClientRect().height
    )
  }

  useEffect(() => {
    getHeaderHeight()

    window.addEventListener('resize', getHeaderHeight)
    return () => {
      window.removeEventListener('resize', getHeaderHeight)
    }
  }, [])

  return (
    <SContainer headerHeight={headerHeight} className="container">
      <SContent>
        <div className="col">
          <SSectionTitle>Code Europe</SSectionTitle>
          <SList>
            <li>
              <NavLink
                onClick={closeIfActive}
                to={localizeRouteKey(AppRoute.BuyTicket)}
                // to="#"
                // className="disable-link"
              >
                {formatMessage({ id: 'buyTicket' })}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={localizeRouteKey(AppRoute.Speakers)}
                onClick={closeIfActive}
              >
                {formatMessage({ id: 'speakers' })}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={localizeRouteKey(AppRoute.Agenda)}
                onClick={closeIfActive}
              >
                {formatMessage({ id: 'agenda' }, { year: 2024 })}
              </NavLink>
            </li>
            <li>
              {pathname === `/${locale}/` ||
              pathname === `/${locale}/?section=faq` ? (
                <ScrollLink
                  onClick={closeIfActive}
                  to="faq"
                  smooth
                  offset={-140}
                >
                  {formatMessage({ id: 'faqNavigation' })}
                </ScrollLink>
              ) : (
                <NavLink
                  onClick={closeIfActive}
                  to={`/${locale}/?section=faq`}
                  exact
                >
                  {formatMessage({ id: 'faqNavigation' })}
                </NavLink>
              )}
            </li>
            {/*<li>*/}
            {/*  <NavLink*/}
            {/*    to={localizeRouteKey(AppRoute.Offers)}*/}
            {/*    onClick={closeIfActive}*/}
            {/*  >*/}
            {/*    {formatMessage({ id: 'jobOffers' })}*/}
            {/*  </NavLink>*/}
            {/*</li>*/}
            {shouldShowCompanies && (
              <li>
                <NavLink
                  to={localizeRouteKey(AppRoute.Companies)}
                  onClick={closeIfActive}
                >
                  {formatMessage({ id: 'companies' })}
                </NavLink>
              </li>
            )}
            <br />
            <li>
              <NavLink
                exact
                to={localizeRouteKey(AppRoute.BecomePartner)}
                onClick={closeIfActive}
              >
                {formatMessage({
                  id: AppRouteTitles.get(AppRoute.BecomePartner) || '',
                })}
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to={localizeRouteKey(AppRoute.BecomeSpeaker)}
                onClick={closeIfActive}
              >
                {formatMessage({
                  id: AppRouteTitles.get(AppRoute.BecomeSpeaker) || '',
                })}
              </NavLink>
            </li>
            <br />
            {!breakpoints.md && (
              <>
                <Button
                  as={NavLink}
                  to={localizeRouteKey(
                    isAuthenticated ? AppRoute.User : AppRoute.SignIn
                  )}
                  onClick={closeIfActive}
                  className="sign-in-button"
                >
                  {formatMessage({
                    id: isAuthenticated ? 'myAccount' : 'signIn',
                  })}
                </Button>
                {isAuthenticated && (
                  <NavLink
                    to={localizeRouteKey(AppRoute.Logout)}
                    className="sign-out-link"
                  >
                    {formatMessage({ id: 'general.logout' })}
                  </NavLink>
                )}
              </>
            )}
          </SList>
        </div>
        <div className="col">
          <SSectionTitle>{formatMessage({ id: 'organization' })}</SSectionTitle>
          <SList>
            <li>
              <NavLink
                exact
                to={localizeRouteKey(AppRoute.AboutUs)}
                onClick={closeIfActive}
              >
                {formatMessage({
                  id: AppRouteTitles.get(AppRoute.AboutUs) || '',
                })}
              </NavLink>
            </li>
            <li>
              <a
                tabIndex="0"
                role="button"
                href="mailto:kontakt@codeeurope.pl"
                onClick={closeIfActive}
              >
                {formatMessage({ id: 'contact' })}
              </a>
            </li>
          </SList>
          <SLangs>
            <LanguageSwitcher className="language-picker" />
          </SLangs>
          {breakpoints.md && (
            <>
              <Button
                as={NavLink}
                to={localizeRouteKey(
                  isAuthenticated ? AppRoute.User : AppRoute.SignIn
                )}
                onClick={closeIfActive}
                className="sign-in-button"
              >
                {formatMessage({
                  id: isAuthenticated ? 'myAccount' : 'signIn',
                })}
              </Button>
              {isAuthenticated && (
                <NavLink
                  to={localizeRouteKey(AppRoute.Logout)}
                  className="sign-out-link"
                >
                  {formatMessage({ id: 'general.logout' })}
                </NavLink>
              )}
            </>
          )}
          <SFollow>
            <SSocials>
              <Social href="https://twitter.com/code_europe" target="_blank">
                <TwitterIcon />
              </Social>
              <Social
                href="https://www.linkedin.com/showcase/codeeurope/"
                target="_blank"
              >
                <LinkedinIcon />
              </Social>
              <Social
                href="https://www.facebook.com/codeeurope"
                target="_blank"
              >
                <FacebookIcon />
              </Social>
            </SSocials>
          </SFollow>
        </div>
        <div className="col__close">
          <Burger {...{ mobileNavigationVisible }} onClick={closeMobileNav} />
        </div>
      </SContent>
    </SContainer>
  )
}

export default Navigation
