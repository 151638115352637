import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import client from 'api/client'
import config from 'const/config'

const CHANGE_USER_PANEL_TAB = 'user/changePanelTab'
export const changeUserPanelTab = createAction(CHANGE_USER_PANEL_TAB)

const SET_IS_AUTHENTICATED = 'user/isAuthenticated'
export const setIsAuthenticated = createAction(SET_IS_AUTHENTICATED)

const SET_USER = 'user/set'
export const setUser = createAction(SET_USER)

const CLEAR_USER = 'user/clear'
export const clearUser = createAction(CLEAR_USER)

const GET_USER = 'user/get'
export const getUser = createAsyncThunk(GET_USER, async (payload, thunkAPI) => {
  try {
    const token = localStorage.getItem(config.STORAGE_TOKEN_KEY)
    if (!token) return thunkAPI.rejectWithValue()

    const response = await client({
      endpoint: `api/me?locale=${payload}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (!response.ok) {
      return thunkAPI.rejectWithValue()
    }

    const user = await response.json()

    return user
  } catch (error) {
    console.log('[GET] user error', error)
  }
})

const GET_USER_TICKETS = 'user/getTickets'
export const getUserTickets = createAsyncThunk(
  GET_USER_TICKETS,
  async (_, thunkAPI) => {
    try {
      const token = localStorage.getItem(config.STORAGE_TOKEN_KEY)
      if (!token) return thunkAPI.rejectWithValue()

      const response = await client({
        endpoint: 'api/participant/tickets',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (!response.ok) {
        return thunkAPI.rejectWithValue()
      }

      const data = await response.json()
      return data || []
    } catch (error) {
      console.log('[GET] user tickets error', error)
    }
  }
)

const UPDATE_USER_CONSENT = 'user/updateConsent'
export const updateUserConsent = createAsyncThunk(
  UPDATE_USER_CONSENT,
  async (payload, thunkAPI) => {
    try {
      const token = localStorage.getItem(config.STORAGE_TOKEN_KEY)
      if (!token) return thunkAPI.rejectWithValue()

      const response = await client({
        endpoint: 'api/participant/consents',
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ...payload, job_alert: false }),
      })

      if (!response.ok) {
        return thunkAPI.rejectWithValue()
      }

      const data = await response.json()
      return payload
    } catch (error) {
      console.log('[PATCH] use consent', error)
    }
  }
)
