import { useBreakpoint } from 'modules/breakpoint'
import React from 'react'
import { useIntl } from 'react-intl'
import { useTheme } from 'styled-components'

import {
  CustomUnderline,
  YoutubeBackground,
  Wrapper,
  Button,
  SlideTop,
} from '../../components'
import {
  SData,
  SDataDescription,
  SDataGrid,
  SDataTitle,
  SDescription,
  SHeader,
  SHeading,
  SNumbers,
  SStaticBackground,
} from './Numbers.styled'

function Numbers() {
  const theme = useTheme()
  const breakpoints = useBreakpoint()
  const { formatMessage } = useIntl()

  if (typeof window === 'undefined') return null

  const renderContent = () => (
    <Wrapper>
      <SNumbers>
        <SHeader>
          <SlideTop>
            <SHeading>
              {formatMessage(
                { id: 'numbersHeading' },
                {
                  span: (chunk) => (
                    <CustomUnderline color={theme.colors.green}>
                      {chunk}
                    </CustomUnderline>
                  ),
                }
              )}
            </SHeading>
          </SlideTop>
          <SlideTop>
            <SDescription>
              {formatMessage({ id: 'numbersDescription' })}
            </SDescription>
          </SlideTop>
        </SHeader>
        <SDataGrid>
          <SlideTop>
            <SData>
              <SDataTitle>
                {formatMessage({ id: 'numbersUsersHeading' })}
              </SDataTitle>
              <SDataDescription>
                {formatMessage({ id: 'numbersUsersDescription' })}
              </SDataDescription>
            </SData>
          </SlideTop>
          <SlideTop>
            <SData>
              <SDataTitle>
                {formatMessage({ id: 'numbersSpeakersHeading' })}
              </SDataTitle>
              <SDataDescription>
                {formatMessage({ id: 'numbersSpeakersDescription' })}
              </SDataDescription>
            </SData>
          </SlideTop>
          <SlideTop>
            <SData>
              <SDataTitle>
                {formatMessage({ id: 'numbersExhibitorsHeading' })}
              </SDataTitle>
            </SData>
          </SlideTop>
          <SlideTop>
            <SData>
              <SDataTitle>
                {formatMessage({ id: 'numbersLecturesHeading' })}
              </SDataTitle>
              <SDataDescription>
                {formatMessage({ id: 'numbersLecturesDescription' })}
              </SDataDescription>
            </SData>
          </SlideTop>
          <div className="action">
            <SlideTop>
              <Button as="a" href="https://youtu.be/4Aw6o6-vpMU">
                {formatMessage({ id: 'watchVideo' })}
              </Button>
            </SlideTop>
          </div>
        </SDataGrid>
      </SNumbers>
    </Wrapper>
  )

  const renderYoutubeWrapper = () => (
    <YoutubeBackground
      url={'https://youtu.be/4Aw6o6-vpMU'}
      overlay={'rgba(0, 0, 0, .82)'}
      youtubeConfig={{
        playerVars: {
          start: 5,
        },
      }}
    >
      {renderContent()}
    </YoutubeBackground>
  )

  const renderStaticImageWrapper = () => (
    <SStaticBackground>{renderContent()}</SStaticBackground>
  )

  return !breakpoints.xl ? renderYoutubeWrapper() : renderStaticImageWrapper()
}

export default Numbers
