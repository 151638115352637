import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'
export const SContainer = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.space.xl}px;

    ${respondTo.lg`
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: ${theme.space.xl + 6}px;
      max-width: 1029px;
      margin: 0 auto;
    `}

    .benefit {
      max-width: 315px;

      &-title {
        color: ${theme.colors.white};
        font-weight: ${theme.weight.bold};
        font-size: 1.125rem;
        line-height: 1.56rem;

        ${respondTo.xl`
          font-size: 1.375rem;
          line-height: 1.875rem;
        `}
      }
      &-divider {
        height: 1px;
        width: 80px;
        background-color: ${theme.colors.lightGray};
        margin: ${theme.space.sm}px 0;
        border: none;

        ${respondTo.lg`
          margin: ${theme.space.md + 4}px 0;
        `}
      }
      &-text {
        color: ${theme.colors.lightGray2};
        font-size: 1rem;
        line-height: 1.375rem;
        max-width: 95%;

        ${respondTo.xl`
          max-width: 295px;
        `};
      }

      i {
        display: inline-block;
        color: ${theme.colors.pink};
        font-size: 36px;
        padding: ${theme.space.md - 4}px;
        background-color: ${theme.colors.black};
        border: 1px solid ${theme.colors.pink};
        margin-bottom: ${theme.space.md}px;

        appearance: none;
        box-shadow: 4px 4px 0 ${theme.colors.black},
          4px 4px 0 1px ${theme.colors.pink};

        ${respondTo.lg`
          margin-bottom: ${theme.space.xl + 4}px;
        `}
      }
    }
  `}
`
