import * as React from "react"

function Dino(props) {
  return (
    <svg viewBox="0 0 112 122" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M28 59.581h5.6v19.86h5.6v8.512h5.6v5.675h5.6v22.698h-5.6V122H56v-17.023h5.6v-5.675h5.6v5.675h5.6v5.674h5.6v5.675h-5.6V122H84V99.302h5.6v-5.674h5.6v-5.675h5.6V82.28h5.6v-5.674h5.6V42.558h-5.6v11.349h-5.6v5.674h-5.6v5.675H84V59.58h-5.6v-5.674H70v-5.674h-8.4v-5.675H56V5.674h-5.6V0H5.6v5.674H0V31.21h28v5.675H11.2v5.674h22.4v11.349H22.4v11.349H28V59.58zm16.8-51.07v5.675h-5.6V8.512h5.6z" fill="#292929"/>
    </svg>
  )
}

export default Dino;
