import * as React from 'react'

function CalendarIcon(props) {
  return (
    <svg viewBox="0 0 11 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.2.362A.422.422 0 007.781 0a.42.42 0 00-.423.419V.88h-3.71V.42L3.642.362A.422.422 0 003.224 0a.42.42 0 00-.423.419v.473C1.067 1.009 0 2.112 0 3.892v5.05C0 10.852 1.197 12 3.114 12h4.772C9.806 12 11 10.872 11 8.985V3.89c.006-1.78-1.058-2.882-2.796-3V.42L8.2.362zm-.842 1.357v.536l.004.057a.422.422 0 00.419.362.42.42 0 00.423-.419v-.524c1.264.103 1.954.85 1.95 2.16v.242H.846V3.89c0-1.307.694-2.056 1.955-2.16v.524l.004.057a.422.422 0 00.42.362.42.42 0 00.422-.419V1.72h3.711zM.846 4.969v3.973c0 1.442.812 2.22 2.268 2.22h4.772c1.46 0 2.268-.762 2.268-2.177V4.97H.846zm7.588 1.762a.42.42 0 00-.423-.419l-.063.004a.42.42 0 00-.365.415c0 .231.19.419.428.419l.057-.004a.42.42 0 00.366-.415zm-2.926-.419a.42.42 0 01.423.419.42.42 0 01-.366.415l-.057.004a.422.422 0 01-.428-.419.42.42 0 01.365-.415l.063-.004zm-2.086.419A.42.42 0 003 6.312l-.062.004a.42.42 0 00-.366.415c0 .231.19.419.423.419l.063-.004a.42.42 0 00.365-.415zm4.589 1.75a.42.42 0 01.423.42.42.42 0 01-.366.414l-.057.004a.422.422 0 01-.428-.419.42.42 0 01.365-.415l.063-.003zm-2.08.42a.42.42 0 00-.423-.42l-.063.004a.42.42 0 00-.365.415c0 .231.189.419.428.419l.057-.004a.42.42 0 00.366-.415zm-2.932-.42a.42.42 0 01.423.42.42.42 0 01-.365.414l-.063.004a.42.42 0 01-.423-.419.42.42 0 01.366-.415l.062-.003z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default CalendarIcon
