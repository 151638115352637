const getFiltersFromQuery = (queryString, filtration) => {
  const params = new URLSearchParams(queryString)
  if (!Array.from(params.entries()).length) return filtration

  return filtration.map((filter) => {
    const queryCategoryKey = `filters[${filter.category}][]`
    if (!params.has(queryCategoryKey)) return filter

    return {
      ...filter,
      fields: filter.fields.map((field) =>
        String(field.id) === params.get(queryCategoryKey)
          ? { ...field, value: true }
          : field
      ),
    }
  })
}

export default getFiltersFromQuery
