import styled, { css } from 'styled-components'

export const SSocialLinkIcon = styled.span`
  ${({ theme }) => css`
    width: 25px;
    height: 25px;

    flex-shrink: 0;

    color: ${theme.colors.darkestGray};

    svg {
      width: 100%;
    }
  `}
`
