import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import LinesEllipsis from 'react-lines-ellipsis'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { Speeches, Tags, Wrapper, SocialLinkIcon } from '../../components'
import { SSpeakerDetails, SSkillLevelCircle } from './SpeakerDetails.styled'

import { breakpoints as staticBreakpoints } from '../../theme'
import { HEADER_DOM_ID } from '../../components/Header/Header'
import { FOOTER_DOM_ID } from '../Footer/Footer'
import ChevronIcon from '../../assets/icons/ChevronIcon/ChevronIcon'
import { LoaderComponent } from '../../components/Loader/Loader.styled'

import sanitizeText from '../../utils/sanitizeText'

import imagePlaceholder from '../../assets/images/image-placeholder.svg'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'

function SpeakerDetails({ speaker, loading }) {
  const [moreDescription, setMoreDescription] = useState(false)
  const [isDescriptionClapmed, setDescriptionClapmed] = useState(false)

  const { formatMessage } = useIntl()
  const sectionRef = useRef()

  const calculateSectionHeight = () => {
    if (typeof window === 'undefined') return
    const windowHeight = window.innerHeight
    const windowWidth = window.innerWidth

    if (windowWidth <= staticBreakpoints.lg) {
      sectionRef.current.style.height = 'initial'
      return
    }

    const headerHeight = document
      .getElementById(HEADER_DOM_ID)
      .getBoundingClientRect().height
    const footerHeight = document
      .getElementById(FOOTER_DOM_ID)
      .getBoundingClientRect().height
    const sectionHeight = windowHeight - (headerHeight + footerHeight)

    sectionRef.current.style.minHeight = `${sectionHeight}px`
  }

  const handleReflow = (rleState) => {
    const { clamped } = rleState
    if (isDescriptionClapmed) return
    setDescriptionClapmed(clamped)
  }

  const toggleDescription = () => setMoreDescription(!moreDescription)

  useEffect(() => {
    calculateSectionHeight()

    window.addEventListener('resize', calculateSectionHeight)
    return () => {
      window.removeEventListener('resize', calculateSectionHeight)
    }
  }, [])

  return (
    <SSpeakerDetails ref={sectionRef}>
      {loading ? <LoaderComponent /> : null}
      {speaker ? (
        <Wrapper>
          <div className="about">
            <div className="thumbnail">
              <div className="overlay">
                <LazyLoadImage
                  alt={`${speaker.speaker_name} - profile picture`}
                  src={speaker.speaker_image}
                  placeholderSrc={imagePlaceholder}
                />
              </div>
            </div>
            <div className="details">
              <p className="name">{speaker.speaker_name}</p>
              <p className="position">{speaker.speaker_title}</p>
              <p className="company">@{speaker.speaker_company_name}</p>
              <div className="tags">
                <Tags
                  {...{
                    tags: speaker.speaker_tag_list.map((tag) => tag.tag_name),
                  }}
                />
              </div>
              {speaker.links?.length ? (
                <ul className="socials">
                  {speaker.links.map(({ link }) => (
                    <li key={link}>
                      <SocialLinkIcon {...{ link }} className="icon" />
                      <a href={link} target="_blank">
                        {link}
                      </a>
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
            {speaker.skills ? (
              <div className="skills">
                <p className="section-heading">
                  {formatMessage({ id: 'speaker.mySkills' })}:
                </p>
                {speaker.skills.map((skill) => (
                  <div key={skill.skill_name} className="skill">
                    <span className="skill-name">
                      {formatMessage({ id: `skill.${skill.skill_name}` })}
                    </span>
                    <div className="skill-level">
                      <SSkillLevelCircle active={skill.skill_value >= 1} />
                      <SSkillLevelCircle active={skill.skill_value >= 2} />
                      <SSkillLevelCircle active={skill.skill_value >= 3} />
                      <SSkillLevelCircle active={skill.skill_value >= 4} />
                      <SSkillLevelCircle active={skill.skill_value >= 5} />
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
            {speaker.speaker_description ? (
              <div className="bio">
                <p className="section-heading">
                  {formatMessage({ id: 'speaker.bio' })}:
                </p>
                <HTMLEllipsis
                  unsafeHTML={sanitizeText(speaker?.speaker_description)}
                  maxLine={moreDescription ? 10000 : 6}
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                  onReflow={handleReflow}
                />
                {isDescriptionClapmed ? (
                  <button
                    type="button"
                    className="section-more"
                    onClick={toggleDescription}
                  >
                    {moreDescription ? (
                      <>
                        {formatMessage({ id: 'filtrationShowLess' })}{' '}
                        <ChevronIcon type="up" />
                      </>
                    ) : (
                      <>
                        {formatMessage({ id: 'filtrationShowMore' })}{' '}
                        <ChevronIcon type="down" />
                      </>
                    )}
                  </button>
                ) : null}
              </div>
            ) : null}
          </div>
          <Speeches {...{ speeches: speaker.lectures_list }} />
        </Wrapper>
      ) : null}
    </SSpeakerDetails>
  )
}

export default SpeakerDetails
