import React from "react"

import { STags } from "./Tags.styled"

function Tags({ tags, color, ...res }) {
  return (
    <STags color={color} {...res}>
      {Array.isArray(tags) && tags.length ?
          tags.map((tag, index) => (
            <div key={index} className="tag">
              {tag}
            </div>
          ))
         :
        <div className="tag">
          {tags}
        </div>
      }
    </STags>
  )
}

export default Tags
