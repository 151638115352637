import React from 'react'
import { useIntl } from 'react-intl'

import { TimeLocationInformation } from '../index'
import { SContainer } from './HeroTimeLocationGrid.styled'

function HeroTimeLocationGrid() {
  const { formatMessage } = useIntl()

  return (
    <SContainer>
      <TimeLocationInformation
        date={formatMessage({ id: 'codeEuropeDateFirst' })}
        location={formatMessage({ id: 'cracow' })}
        place="ICE Kraków"
      />
      {/*<TimeLocationInformation*/}
      {/*  date={formatMessage({ id: 'codeEuropeDateSecond' })}*/}
      {/*  location={formatMessage({ id: 'warsaw' })}*/}
      {/*  place="PGE Narodowy"*/}
      {/*/>*/}
    </SContainer>
  )
}

export default HeroTimeLocationGrid
