import React, { useState, useMemo } from 'react'
import { useIntl } from 'react-intl'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'

import { Link } from 'react-router-dom'

import { useBreakpoint } from 'modules/breakpoint'
import _ from 'lodash'

import {
  Tags,
  Wrapper,
  ButtonLink
} from '../../components'

import ChevronIcon from '../../assets/icons/ChevronIcon/ChevronIcon'
import PinIcon from '../../assets/icons/PinIcon'

import { LoaderComponent } from '../../components/Loader/Loader.styled'
import { SOfferDetails } from './OfferDetails.styled'

import benefitsIcons from '../../const/benefitsIcons'
import { AppRoute } from 'const'

import sanitizeText from '../../utils/sanitizeText'
import useLocalizeRoute from '../../utils/useLocalizeRoute'


function OfferDetails({ offer, loading }) {
  const { locale, formatMessage, formatDate } = useIntl()
  const breakpoints = useBreakpoint()
  const { localizeRouteKey } = useLocalizeRoute()

  const [areBenefitsCollapsed, setAreBenefitsCollapsed] = useState(true)
  const [moreDescription, setMoreDescription] = useState(false)
  const [isDescriptionClamped, setDescriptionClamped] = useState(false)

  const handleReflow = (rleState) => {
    const { clamped } = rleState
    if (isDescriptionClamped) return
    setDescriptionClamped(clamped)
  }

  const toggleDescription = () => setMoreDescription(!moreDescription)
  const toggleBenefitsCollapse = () => setAreBenefitsCollapsed(!areBenefitsCollapsed)

  let profileNames = [
    formatMessage({ id: `profile.meetings` }),
    formatMessage({ id: `profile.client_support` }),
    formatMessage({ id: `profile.maintenance_bug_fixing` }),
    formatMessage({ id: `profile.document_writing` }),
    formatMessage({ id: `profile.new_features` })
  ]

  const profileValues= [
    offer?.offer_meetings_level,
    offer?.offer_support_level,
    offer?.offer_maintenance_level,
    offer?.offer_documentation_level,
    offer?.offer_features_level
  ]

  const offer_profile = useMemo(() => profileNames.map((name, i) => {
    return {
      profile_name: name,
      profile_value: profileValues[i]
    }
  }), [offer])

  const renderClauseSection = () => {
    return (
      <>
      {offer?.offer_clause ? (
        <div className="clause">
          <HTMLEllipsis
            unsafeHTML={sanitizeText(offer?.offer_clause)}
            maxLine={moreDescription ? 10000 : 6}
            ellipsis="..."
            trimRight
            basedOn="letters"
            onReflow={handleReflow}
          />
          {isDescriptionClamped ? (
            <button
              type="button"
              className="clause-more"
              onClick={toggleDescription}
            >
              {moreDescription ? (
                <>
                  {formatMessage({ id: 'filtrationShowLess' })}{' '}
                  <ChevronIcon type="up"/>
                </>
              ) : (
                <>
                  {formatMessage({ id: 'filtrationShowMore' })}{' '}
                  <ChevronIcon type="down"/>
                </>
              )}
            </button>
          ) : null}
        </div>
        ) : null}
      </>
    )
  }

  const renderApplySection = () => (
    <>
      <p className="section-heading apply-heading">
        {formatMessage({ id: 'offer.interested' })}
      </p>
      {!breakpoints.lg &&
        <>
        <ButtonLink
          href={offer?.offer_endpoint.includes('@') ? `mailto:${offer?.offer_endpoint}` : offer?.offer_endpoint}
          className="link"
        >
          {formatMessage({ id: 'apply' })}
        </ButtonLink>
        {renderClauseSection()}
          </>
      }
    </>
  )

  const renderNameSection = () => (
    <>
     <span className="details-name">
       {offer?.offer_title}
     </span>
     <div className="area">
       <Tags
         {...{
           tags: offer?.offer_area,
         }}
       />
     </div>
     <div className="info">
       <Link className="company" to={localizeRouteKey(AppRoute.Company).replace(':slug', offer?.employer_slug)}>
        <span>@{offer?.employer_name}</span>
       </Link>
       <div className="location">
         <PinIcon className="pin-icon" />
         <span className="location-name">{offer?.location_name}</span>
       </div>
       <span className="endtime">
       {formatMessage({ id: 'offer.endtime' })}
       {formatDate(offer?.offer_expires_at * 1000, {
         year: 'numeric',
         day: 'numeric',
         month: 'short'
       }).replace(/\//g, ".")}
       </span>
     </div>
    </>
  )

  return (
    <SOfferDetails>
      {loading ? <LoaderComponent /> : null}
      {offer ? (
        <Wrapper>
          <div className="container">
            <div className="left-side">
              {!breakpoints.lg && renderNameSection()}
              {offer.offer_project?.length ? (
                <div className="description-text">
                  <p className="section-heading">
                    {formatMessage({ id: 'offer.projectHeading' })}
                  </p>
                  <div dangerouslySetInnerHTML = {{
                        __html: sanitizeText(offer?.offer_project)
                      }}
                   />
                </div>
              ) : null}
              {offer.offer_responsibility?.length ? (
                <div className="description-text">
                  <p className="section-heading">
                    {formatMessage({ id: 'offer.responsibilitiesHeading' })}
                  </p>
                  <div dangerouslySetInnerHTML = {{
                        __html: sanitizeText(offer?.offer_responsibility)
                      }}
                   />
                </div>
              ) : null}
              {offer?.offer_description?.length ? (
                <div className="description-text">
                  <p className="section-heading">
                    {formatMessage({ id: 'offer.basicInfoHeading' })}
                  </p>
                  <div dangerouslySetInnerHTML = {{
                       __html: sanitizeText(offer?.offer_description)
                     }}
                  />
                </div>
              ) : null}
              {offer_profile ? (
                <div className="profile">
                  <p className="section-heading">
                    {formatMessage({ id: 'offer.profileHeading' })}
                  </p>
                  {offer_profile.map((profile) => (
                    <div key={profile.profile_name} className="profile-container">
                      <span className="profile-name">
                        {profile.profile_name}
                      </span>
                      <div className="profile-level">
                        <span className={`profile-circle ${profile.profile_value >= 1 ? 'active' : null}`} />
                        <span className={`profile-circle ${profile.profile_value >= 2 ? 'active' : null}`} />
                        <span className={`profile-circle ${profile.profile_value >= 3 ? 'active' : null}`} />
                        <span className={`profile-circle ${profile.profile_value >= 4 ? 'active' : null}`} />
                        <span className={`profile-circle ${profile.profile_value >= 5 ? 'active' : null}`} />
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
              {offer.offer_tag_list?.length ?
                <div className="skills">
                  <p className="section-heading">
                    {formatMessage({ id: 'offer.skillsHeading' })}
                  </p>

                     <div className="tags">
                       <Tags
                         {...{
                           tags: offer?.offer_tag_list?.map(tag => tag.tag_name),
                         }}
                       />
                     </div>
                </div>
                : null}
              {offer.offer_language_list?.length ?
                <div className="skills">
                  <p className="section-heading lang-heading">
                    {formatMessage({ id: 'offer.languagesHeading' })}
                  </p>
                  <div className="tags">
                    <Tags
                      {...{
                        tags: offer?.offer_language_list.map(language => language.language_name),
                      }}
                    />
                  </div>
                </div>
                : null}
              {breakpoints.lg && renderApplySection()}
            </div>
            <div className="right-side">
              {breakpoints.lg && renderNameSection()}
              <div className="right-container">
                  <div className="about">
                    <p className="section-heading">
                      {formatMessage({ id: 'offer.descriptionHeading' })}
                    </p>
                    {offer.employer_employees_count?.length ?
                      <p className="about-employees">
                        {formatMessage({ id: 'offer.employeesCount' })} <span className="bold">{offer.employer_employees_count}</span>
                      </p>
                      : null}
                    {offer.offer_team_size?.length ?
                      <p className="about-team">
                        {formatMessage({ id: 'offer.teamCount' })} <span className="bold">{offer.offer_team_size}</span>
                      </p>
                      : null}
                    {offer.offer_salary?.length ?
                      <p className="about-salary">
                        {formatMessage({ id: 'offer.salary' })} <span className="bold">{offer.offer_salary}</span>
                      </p>
                      : null}
                    {offer.offer_contract_type?.length ?
                      <p className="about-contract">
                        {formatMessage({ id: 'offer.contract' })} <span className="bold">{offer.offer_contract_type}</span>
                      </p>
                      : null}
                    {offer.employer_category_name?.length || offer.employer_category_name_en?.length ?
                      <p className="about-industry">
                        {formatMessage({ id: 'offer.industry' })} <span className="bold">{locale === 'pl' ? offer?.employer_category_name : offer?.employer_category_name_en}</span>
                      </p>
                      : null}
                    <p className="section-heading add-margin">
                      {formatMessage({ id: 'offer.benefitsHeading' })}
                    </p>
                    <div className="benefits-list">
                      {areBenefitsCollapsed ?
                        <div>
                          {offer?.offer_benefit_list?.slice(0, 4).map(benefit => (
                              <div key={benefit?.benefit_slug} className="benefits-container">
                                <img
                                  src={benefitsIcons[_.camelCase(formatMessage({ id: benefit?.benefit_slug }))]}
                                  className="benefits-icon"
                                  alt="" />
                                <p>{benefit.benefit_name}</p>
                              </div>
                            ))}
                          {offer?.offer_benefit_list.length > 4 ?
                            <div className="show-less-more">
                              <span onClick={toggleBenefitsCollapse}
                                    className="show-less-more-text">{formatMessage({ id: 'filtrationShowMore' })}</span><ChevronIcon
                              type="down"/>
                            </div>
                            : null}
                        </div>
                        :
                        <div>
                          {offer?.offer_benefit_list.map(benefit => (
                            <div key={benefit?.benefit_slug} className="benefits-container">
                              <img
                                src={benefitsIcons[_.camelCase(formatMessage({ id: benefit?.benefit_slug }))]}
                                className="benefits-icon"
                                alt="" />
                              <p>{benefit.benefit_name}</p>
                            </div>
                          ))}
                          <div className="show-less-more">
                            <span
                              onClick={toggleBenefitsCollapse}
                              className="show-less-more-text"
                            >
                              {formatMessage({ id: 'filtrationShowLess' })}
                            </span>
                            <ChevronIcon type="up"/>
                          </div>
                        </div>
                      }
                    </div>
                    {!breakpoints.lg && renderApplySection()}
                  </div>
              </div>
            </div>
            {breakpoints.lg &&
              <div className="button-wrapper-mobile">
                <ButtonLink
                  href={offer?.offer_endpoint.includes('@') ? `mailto:${offer?.offer_endpoint}` : offer?.offer_endpoint}
                  className="link"
                >
                  {formatMessage({ id: 'apply' })}
                </ButtonLink>
              </div>
            }
          </div>
          {breakpoints.lg && renderClauseSection()}
        </Wrapper>
      ) : null}
    </SOfferDetails>
  )
}

export default OfferDetails
