import React, { useEffect } from "react"

function ListOfEntitiesUserOutsideEog() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.location.href = "/wykaz-podmiotow-uzytkownik-poza-eog.pdf"
    }
  }, []);

  return null;
}

export { ListOfEntitiesUserOutsideEog }
