import * as React from "react"

function SearchIcon(props) {
  return (
    <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
          <circle stroke="currentColor" strokeWidth="2" cx="4.694" cy="4.694" r="4.694"/>
          <path fill="currentColor" fillRule="nonzero" d="M7.876 9.903l1.401-1.401 3.098 3.097L10.974 13z"/>
        </g>
      </svg>
  )
}

export default SearchIcon
