import {
  bikeParking,
  privateHealthCare,
  internalExternalTrainings,
  hackDays,
  conferenceBudget,
  trainingBudget,
  teamEvents,
  sportCard,
  kitchen,
  canteen,
  freeCoffee,
  freeLunch,
  freeBeverages,
  freeSnacks,
  fruits,
  freeParking,
  shower,
  kindergarten,
  gym,
  playRoomChillRoom,
  mobilePhone
} from '../assets/images/icons'

const benefitsIcons = {
  bikeParking,
  privateHealthCare,
  internalExternalTrainings,
  hackDays,
  conferenceBudget,
  trainingBudget,
  teamEvents,
  sportCard,
  kitchen,
  canteen,
  freeCoffee,
  freeLunch,
  freeBeverages,
  freeSnacks,
  fruits,
  freeParking,
  shower,
  kindergarten,
  gym,
  playRoomChillRoom,
  mobilePhone
}

export default benefitsIcons
