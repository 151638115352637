import React from 'react'
import { useIntl } from 'react-intl'

import { useDispatch } from 'react-redux'

import { setContactModalVisibility } from '../../state/reducers/app/appActions'

import {
  MContent,
  MInfo,
  MTopQuestion,
  MWidthBig,
  MWidthSmall,
  MWrapper,
} from './ContactModal.styled'
import agata from '../../assets/images/agata.jpeg'
import CloseIcon from '../../assets/icons/closeIcon.svg'

function ContactModal() {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const closeModal = () => dispatch(setContactModalVisibility(false))

  return (
    <MWrapper>
      <MContent>
        <img src={CloseIcon} alt="" className="close" onClick={closeModal} />
        <MWidthSmall>
          <img src={agata} alt="" />
        </MWidthSmall>
        <MWidthBig>
          <MTopQuestion>
            <h6>{formatMessage({ id: 'contactUsModalTitle' })}</h6>
            <p>{formatMessage({ id: 'contactUsModalDescription' })}</p>
          </MTopQuestion>
          <MInfo>
            <p>Agata Sławska</p>
            <div className="contact__details">
              <a href="tel:+48577583700">+48 577 583 700</a>
              <a href="mailto:agata.slawska@codeeurope.pl" className="mail">
                agata.slawska@codeeurope.pl
              </a>
              <a href="mailto:kontakt@codeeurope.pl" className="mail">
                kontakt@codeeurope.pl
              </a>
            </div>
          </MInfo>
        </MWidthBig>
      </MContent>
    </MWrapper>
  )
}

export default ContactModal
