import * as React from 'react'

function PinIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 20" {...props}>
      <path
        d="M6.374 0C2.854 0 0 2.985 0 6.667 0 12.667 6.374 20 6.374 20s6.375-7.333 6.375-13.333C12.749 2.985 9.895 0 6.374 0zm0 9.333c-1.408 0-2.55-1.194-2.55-2.666C3.825 5.194 4.967 4 6.375 4s2.55 1.194 2.55 2.667c0 1.472-1.142 2.666-2.55 2.666z"
        fill="currentColor"
      />
    </svg>
  )
}

export default PinIcon
