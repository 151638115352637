import React from 'react'
import { useTheme } from 'styled-components'
import { useIntl } from 'react-intl'

import { SectionHeader, Wrapper } from '../../components'

import { SSubHeading, SHeroSection, SHeroTop } from './HeroSectionBasic.styled'

function HeroSectionBasic() {
  const theme = useTheme()
  const { formatMessage } = useIntl()

  return (
    <SHeroSection>
      <Wrapper>
        <SHeroTop>
          <SectionHeader
            heading="speakersHeroHeading"
            headingUnderlineColor={theme.colors.pink}
          />
          <SSubHeading>
            {formatMessage(
              {
                id: 'speakersHeroSubHeading',
              },
              {
                br: <br />,
              }
            )}
          </SSubHeading>
        </SHeroTop>
      </Wrapper>
    </SHeroSection>
  )
}

export default HeroSectionBasic
