import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SContainer = styled.div`
  ${({ theme }) => css`
    align-items: center;
    width: 100%;

    ${respondTo.xxs`
      display: grid;
      grid-template-columns: 244px 1fr; 
    `}
    ${respondTo.xs`
      grid-gap: ${theme.space.xl + 8}px;
    `}
  `}
`

export const STimePlace = styled.div`
  ${({ theme, locale }) => css`
    display: grid;
    grid-template-columns: ${locale === 'pl'
      ? '165px 16px 108px'
      : '130px 16px 108px'};
    align-items: center;
    white-space: nowrap;

    ${respondTo.lg`
      grid-template-columns: ${
        locale === 'pl' ? '186px 16px 108px' : '148px 16px 108px'
      };
    `}

    .divider-vertical {
      display: inline-block;
      height: 1px;
      width: 14px;
      border: none;
      transform: rotate(90deg);
      background-color: ${theme.colors.lightGray2};
    }
  `}
`
export const SLocation = styled.p`
  ${({ theme }) => css`
    display: inline-flex;
    align-items: flex-start;
    font-weight: ${theme.weight.bold};
    margin-top: ${theme.space.xs}px;

    i {
      font-size: 13px;
      color: ${theme.colors.pink};
      margin-right: ${theme.space.xs}px;

      ${respondTo.lg`
        font-size: 15px;
      `}
    }

    ${respondTo.xxs`
      margin-top: 0;
    `}
  `}
`
