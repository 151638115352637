import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import DateDiff from 'date-diff'
import Sticky from 'react-stickynode'

import { AgendaEventDetails } from '../'
import PinIcon from 'assets/icons/PinIcon'

import { SAgendaTile } from './AgendaTile.styled'
import { useBreakpoint } from 'modules/breakpoint'
import { defaultTimezone } from 'const/defaultTimezone'

function AgendaTile({ item, agendaRoute }) {
  const { city, city_en, event_date, place, events } = item
  const { formatMessage, formatDate, formatTime, locale } = useIntl()

  const breakpoint = useBreakpoint()
  const mobile = breakpoint.lg

  const formattedDate = formatDate(new Date(event_date * 1000), {
    day: 'numeric',
    month: '2-digit',
    year: 'numeric',
    timeZone: defaultTimezone,
  }).replace(/\//g, '.')

  const [month, day, year] = formattedDate.split('.')

  return (
    <SAgendaTile>
      <p className="agenda-place-data">
        <p>
          <PinIcon />
          <span>{locale === 'pl' ? city : city_en || city}</span>
          <span>
            , {locale === 'pl' ? formattedDate : `${day}.${month}.${year}`}
          </span>
          <span>&nbsp;- {place}</span>
        </p>
        {agendaRoute ? (
          <Link to={agendaRoute} className="agenda-link">
            {formatMessage({ id: 'agenda.seeFull' })}
          </Link>
        ) : null}
      </p>
      <div className="events">
        {events.map((timeGroup) => {
          return (
            <div key={`event-${timeGroup.start_time}`} className="time-group">
              {timeGroup.start_time ? (
                <Sticky enabled={mobile} top={52}>
                  <span className="time">
                    {formatTime(timeGroup.start_time * 1000, {
                      timeZone: defaultTimezone,
                    })}
                  </span>
                </Sticky>
              ) : null}
              <div className="agenda">
                {timeGroup.data?.map((event) => {
                  const eventDuration =
                    event.event_start_timestamp && event.event_end_timestamp
                      ? new DateDiff(
                          new Date(event.event_end_timestamp * 1000),
                          new Date(event.event_start_timestamp * 1000)
                        ).minutes()
                      : null

                  return (
                    <AgendaEventDetails
                      {...{ event, eventDuration, agendaRoute }}
                    />
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    </SAgendaTile>
  )
}

export default AgendaTile
