import { useTheme } from 'styled-components'
import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import VisibilitySensor from 'react-visibility-sensor'

import {
  Button,
  CompaniesGrid,
  CustomUnderline,
  SlideTop,
} from '../../components'

import { SContainer } from './Speakers.styled'

import { AppRoute } from '../../const'
import useLocalizeRoute from '../../utils/useLocalizeRoute'
import { useBreakpoint } from '../../modules/breakpoint'
import { animated, Spring } from 'react-spring'
import greenBluePattern from '../../assets/patterns/green-blue-pattern.svg'
import pinkTopPattern from '../../assets/patterns/pink-top-pattern.svg'

function Speakers({ speakersDataList, companiesList }) {
  const theme = useTheme()
  const { formatMessage } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()
  const breakpoint = useBreakpoint()
  const isMobile = breakpoint.lg

  const companiesToDisplay = isMobile
    ? companiesList.slice(0, 8)
    : companiesList

  const speakersSorted = [...speakersDataList].sort((a, b) =>
    a.order_value > b.order_value ? 1 : b.order_value > a.order_value ? -1 : 0
  )

  return (
    <VisibilitySensor
      minTopValue={15}
      scrollCheck
      offset={{ top: 400 }}
      scrollDelay={5}
      partialVisibility="top"
    >
      {({ isVisible }) => (
        <SContainer>
          <SlideTop>
            <h2 className="heading">
              {formatMessage(
                { id: 'speakersHeading' },
                {
                  span: (chunk) => <span className="shadow">{chunk}</span>,
                  br: <br />,
                }
              )}
            </h2>
          </SlideTop>
          <div className="speakers">
            {!isMobile ? (
              <Spring from={{ opacity: 0 }} to={{ opacity: isVisible ? 0 : 1 }}>
                {({ opacity }) => (
                  <div className="background">
                    <animated.div
                      style={{
                        opacity,
                        height: '100%',
                      }}
                    >
                      <div className="positionRight">
                        <img src={greenBluePattern} alt="" />
                      </div>
                      <div className="positionLeft">
                        <img src={pinkTopPattern} alt="" />
                      </div>
                    </animated.div>
                  </div>
                )}
              </Spring>
            ) : null}
            <div className="speakers-grid">
              {speakersSorted?.map((speaker) => {
                return (
                  <SlideTop key={speaker.id}>
                    <div className="speaker-avatar">
                      <img src={speaker.path} alt={speaker.name} />
                    </div>
                    <div className="speaker-details">
                      <p className="speaker-name">{speaker.name}</p>
                      <p className="speaker-position">
                        {speaker.title}{' '}
                        {speaker.company_name
                          ? `@ ${speaker.company_name}`
                          : null}
                      </p>
                    </div>
                  </SlideTop>
                )
              })}
            </div>
            <div className="button-wrapper">
              <Button
                secondary="true"
                height="52px"
                as={Link}
                to={localizeRouteKey(AppRoute.Speakers)}
              >
                {formatMessage({
                  id: 'showAllSpeakers',
                })}
                <i className="ri-arrow-right-line" />
              </Button>
            </div>
          </div>
          {companiesToDisplay.length ? (
            <div className="companies">
              <h2 className="heading-companies">
                {formatMessage(
                  { id: 'speakersCompaniesHeading' },
                  {
                    span: (chunk) => (
                      <CustomUnderline color={theme.colors.blue}>
                        {chunk}
                      </CustomUnderline>
                    ),
                    br: <br />,
                  }
                )}
              </h2>
              <CompaniesGrid companies={companiesToDisplay} />
            </div>
          ) : null}
        </SContainer>
      )}
    </VisibilitySensor>
  )
}

export default Speakers
