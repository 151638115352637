import React from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'

import {
  Gallery,
  CallForPapers,
  Impressions,
  FollowUs,
  ProfessionsAboutUs,
  ContactUs,
  PartnersAboutUs,
  HeroSectionAboutUs,
  VolunteersBannerSection,
  TeamCE,
  FaqSection,
} from '../sections'

import desktopBackground from '../assets/images/aboutUsGalleryDesktop.jpg'
import mobileBackground from '../assets/images/aboutUsGalleryMobile.jpg'

const imgAlt = ''

function AboutUs() {
  const { formatMessage } = useIntl()

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'seoTitleAboutUs' })}</title>
        <meta
          name="description"
          content={formatMessage({ id: 'seoDescriptionAboutUs' })}
        />
        <meta
          name="keywords"
          content={formatMessage({ id: 'seoKeywordsAboutUs' })}
        />
        <meta
          property="og:title"
          content={formatMessage({ id: 'seoTitleAboutUs' })}
        />
        <meta
          property="og:site_name"
          content={formatMessage({ id: 'seoTitleAboutUs' })}
        />
        <meta
          property="og:description"
          content={formatMessage({ id: 'seoDescriptionAboutUs' })}
        />
        <meta name="twitter:image:src" content={''} />
        <meta
          name="twitter:title"
          content={formatMessage({ id: 'seoTitleAboutUs' })}
        />
        <meta
          name="twitter:description"
          content={formatMessage({ id: 'seoDescriptionAboutUs' })}
        />
      </Helmet>
      <HeroSectionAboutUs />
      {/*<Gallery {...{ desktopBackground, mobileBackground, imgAlt }} />*/}
      <ProfessionsAboutUs />
      <TeamCE />
      <ContactUs />
      <PartnersAboutUs />
      <VolunteersBannerSection />
      <CallForPapers />
      <Impressions />
      <FaqSection />
      <FollowUs />
    </>
  )
}

export { AboutUs }
