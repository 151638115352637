import React, { useEffect, useMemo } from 'react'
import Helmet from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import {
  CeInNumbersPartners,
  Tweets,
  TopPartners,
  FollowUs,
  ContactModal,
  Tracks,
  Impressions,
  WhyAttendPartners,
  PartnersBannerSection,
  SkilledProfessionals,
  HeroSectionPartners,
  TechnologySkillset,
  LatestEditions,
} from '../sections'

// import { VideoPopup } from '../components'
import { setContactModalVisibility } from '../state/reducers/app/appActions'
function BecomePartner() {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()

  const { policyPermissions: policy } = useSelector((state) => state.app)
  const contactModalVisible = useSelector(
    ({ app }) => app.contactModalVisibility
  )
  const withCallpage = policy.analytics && policy.marketing

  const script = useMemo(() => {
    if (withCallpage && !window?.callpage) {
      return [
        {
          type: 'text/javascript',
          innerHTML: `var __cp = {"id":"pAotf5y6Ijx5utwggajV2ZFS43UgwzIkoeoZlrC_b8A","version":"1.1"};(function (window, document) {var cp = document.createElement('script');cp.type = 'text/javascript';cp.async = false;cp.src = "++cdn-widget.callpage.io+build+js+callpage.js".replace(/[+]/g, '/').replace(/[=]/g, '.');var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(cp, s);if (window.callpage) {alert('You could have only 1 CallPage code on your website!');} else {window.callpage = function (method) {if (method == '__getQueue') {return this.methods;}else if (method) {if (typeof window.callpage.execute === 'function') {return window.callpage.execute.apply(this, arguments);}else {(this.methods = this.methods || []).push({arguments: arguments});}}};window.callpage.__cp = __cp;}})(window, document);`,
        },
      ]
    }
    return null
  }, [withCallpage])

  const openCallpageWidget = () => {
    if (typeof window === 'undefined') return
    window.callpage('api.widget.open')
  }
  const openContactModal = () => {
    dispatch(setContactModalVisibility(true))
  }

  const openContactModalOrCallpage = (e) => {
    e.stopPropagation()
    window.callpage ? openCallpageWidget() : openContactModal()
  }

  const waitUntilElementExists = (selector, callback) => {
    const el = document.querySelector(selector)
    if (el) {
      return callback(el)
    }
    setTimeout(() => waitUntilElementExists(selector, callback), 500)
  }

  waitUntilElementExists('.cp-callpage__button', (el) => el.remove())

  return (
    <div style={{ position: 'relative' }}>
      <Helmet {...{ script }}>
        <title>{formatMessage({ id: 'seoTitlePartners' })}</title>
        <meta
          name="description"
          content={formatMessage({ id: 'seoDescriptionPartners' })}
        />
        <meta
          name="keywords"
          content={formatMessage({ id: 'seoKeywordsPartners' })}
        />
        <meta
          property="og:title"
          content={formatMessage({ id: 'seoTitlePartners' })}
        />
        <meta
          property="og:site_name"
          content={formatMessage({ id: 'seoTitlePartners' })}
        />
        <meta
          property="og:description"
          content={formatMessage({ id: 'seoDescriptionPartners' })}
        />
        <meta name="twitter:image:src" content={''} />
        <meta
          name="twitter:title"
          content={formatMessage({ id: 'seoTitlePartners' })}
        />
        <meta
          name="twitter:description"
          content={formatMessage({ id: 'seoDescriptionPartners' })}
        />
      </Helmet>
      <HeroSectionPartners />
      <TopPartners />
      <Tracks partners />
      <CeInNumbersPartners />
      <LatestEditions />
      <SkilledProfessionals />
      <TechnologySkillset />
      <Impressions />
      <WhyAttendPartners />
      <Tweets />
      <PartnersBannerSection />
      <FollowUs />
      {/*<VideoPopup {...{ openContactModalOrCallpage }} />*/}
      {contactModalVisible ? <ContactModal /> : null}
    </div>
  )
}

export { BecomePartner }
