import styled, { css } from "styled-components"

import respondTo from "../../theme/respondTo"

export const SNewsletter = styled.section`
  ${({ theme, error }) => css`
    
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: auto;
   

    h2 {
      font-size: 24px;
      font-weight: ${theme.weight.bold};
      color: ${theme.colors.white};
    }
    p {
      font-size: 16px;
      color: ${theme.colors.white};
      margin-top: ${theme.space.md}px;
      margin-bottom: ${theme.space.sm}px;
      max-width: 256px;
    }
    form {
      margin-top: ${theme.space.sm}px;

      div {
        display: flex;
        flex-direction: column;

        &.success {
          height: 48px;

          display: flex;
          flex-direction: row;
          align-items: center;

          color: ${theme.colors.pink};

          background-color: ${theme.colors.darkGray};
          border: 1px solid ${theme.colors.black};

          margin-bottom: ${theme.space.md}px;

          p {
            flex: 1;
            padding-left: ${theme.space.md}px;
          }

          span {
            width: 48px;
            height: 48px;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;

            color: black;

            background-color: ${theme.colors.pink};
            border: 1px solid ${theme.colors.black};

            svg {
              width: 24px;
              height: 24px;
            }
          }
        }
      }

      [type="submit"] { 
        ${({ theme }) => css`
          min-height: 48px;
          width: 100%;
          max-width: 275px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
      
          font-family: sofia-pro, sans-serif;
          font-size: 1.25rem;
          font-weight: ${theme.weight.bold};
          text-decoration: none;
          color: ${theme.colors.black};
      
          margin-bottom: 4px;
          padding: 0 ${theme.space.xl}px 0 ${theme.space.xl}px;
      
          background-color: ${theme.colors.pink};
          border: 1px solid ${theme.colors.black};
      
          appearance: none;
          box-shadow: 4px 4px 0 white, 4px 4px 0 1px ${theme.colors.black};
      
          cursor: pointer;
          margin-top: ${theme.space.lg}px;

    &:hover,
    &:focus {
      outline: 0;
      background-color: ${theme.colors.darkPink};
    }

    &:disabled {
      color: ${theme.colors.lightGray};
      cursor: not-allowed;
      background-color: white;
    }
  `}
        ${error &&
        css`
          border: 2px solid ${theme.colors.lightRed};
        `}

        &:disabled {
          background-color: ${theme.colors.lightGray};
        }
        padding: 0;

        &:focus {
          outline: 0;
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }

      & > p {
        font-size: 0.75rem;
        line-height: 1.33;
        color: white;

        margin-top: ${theme.space.md}px;

        ${error &&
        css`
          color: ${theme.colors.lightRed};
        `}

        a {
          text-decoration: none;
          color: ${theme.colors.pink};
        }
      }
    }

    input {
      width: 100%;
      height: 48px;
      max-width: 280px;

      font-size: 1rem;
      color: ${theme.colors.lightGray};

      padding: 0 ${theme.space.md}px;
      margin-bottom: ${theme.space.md}px;
      border: 1px solid ${theme.colors.black};

      ${error &&
      css`
        border: 2px solid ${theme.colors.lightRed};
      `}

      &:focus {
        outline: 0;
      }

      &::placeholder {
        font-size: 1rem;
        color: ${theme.colors.lightGray};
      }
    }

    ${respondTo.lg`
      min-height: 142px;

      flex-direction: column;
   
      h2 {
        flex: 1;
      
      }

      form {
        flex: 1.2;
        margin-top: ${theme.space.sm}px;

        div {
          flex-direction: row;
        }

        & > p {
          margin-top: ${theme.space.sm / 2}px;
        }
      }

      input {
        font-size: 1.25rem;

        margin-bottom: 0;

        &::placeholder {
          font-size: 1.25rem;
        }
      }
    `}
  `}
`
