import tweet1 from "../../assets/tweets/tweet_1.png"
import tweet2 from "../../assets/tweets/tweet_2.png"
import tweet3 from "../../assets/tweets/tweet_3.png"
import tweet4 from "../../assets/tweets/tweet_4.png"
import tweet5 from "../../assets/tweets/tweet_5.png"
import tweet6 from "../../assets/tweets/tweet_6.png"
import tweet7 from "../../assets/tweets/tweet_7.png"
import tweet8 from "../../assets/tweets/tweet_8.png"
import tweet9 from "../../assets/tweets/tweet_9.png"
import tweet10 from "../../assets/tweets/tweet_10.png"
import tweet11 from "../../assets/tweets/tweet_11.png"
import tweet12 from "../../assets/tweets/tweet_12.png"
import tweet13 from "../../assets/tweets/tweet_13.png"
import tweet14 from "../../assets/tweets/tweet_14.png"
import tweet15 from "../../assets/tweets/tweet_15.png"

export type Tweet = {
  image: string
  url: string
  author: string
}

const base: Tweet[] = [
  {
    image: tweet1,
    url: "https://twitter.com/KHNilsson/status/866933450145755136?s=20",
    author: "Karl-Henrik Nilsson",
  },
  {
    image: tweet2,
    url: "https://twitter.com/RyanTownsend/status/940908103914217473?s=20",
    author: "Ryan Townsend",
  },
  {
    image: tweet3,
    url: "https://twitter.com/m_lukaszewski/status/1007122509991497729?s=20",
    author: "Michał Łukaszewski",
  },
  {
    image: tweet4,
    url: "https://twitter.com/clementplop/status/868159252149436416?s=20",
    author: "Clement Escoffier",
  },
  {
    image: tweet5,
    url: "https://twitter.com/ameijers/status/941222318654873601?s=20",
    author: "Alexander Meijers",
  },
  {
    image: tweet6,
    url: "https://twitter.com/softwaregarden/status/938096897478324225?s=20",
    author: "Software Gardener",
  },
  {
    image: tweet7,
    url: "https://twitter.com/larsklint/status/806789359257010177?s=20",
    author: "Lars Klint",
  },
  {
    image: tweet8,
    url: "https://twitter.com/slobodan_/status/868032933457780736?s=20",
    author: "Slobodan Stojanovic",
  },
  {
    image: tweet9,
    url: "https://twitter.com/JannaLoeffler/status/937998170243649536?s=20",
    author: "Janna Loeffler",
  },
]

export const partnerTweets: Tweet[] = [
  {
    image: tweet10,
    url: "https://twitter.com/totollygeek/status/993924200799891456?s=20",
    author: "todor:latest",
  },
  {
    image: tweet11,
    url: "https://twitter.com/RyanTownsend/status/1006454263709650945?s=20",
    author: "ryan townsend",
  },
  {
    image: tweet12,
    url: "https://twitter.com/Rapchik/status/857281482888552450",
    author: "umar nizamani",
  },
  {
    image: tweet13,
    url: "https://twitter.com/faguo/status/866931828904665088",
    author: "damian seguy",
  },
  {
    image: tweet14,
    url: "https://twitter.com/Ocramius/status/806567610007646208",
    author: "mudan grotto abomination",
  },
  {
    image: tweet15,
    url: "https://twitter.com/k33g_org/status/867981561248989186",
    author: "Senior Enterprise Geek",
  },
]

export default base
