import React from "react"
import { animated, Spring } from "react-spring"

import { VSensor } from ".."

const defaultAnimation = {
  from: { opacity: 0.2 },
  to: isVisible => ({
    opacity: isVisible ? 1 : 0.2,
  }),
}
function Opacity(props) {
  const { children, customAnimation, className, ...res } = props

  const { from, to } = customAnimation || defaultAnimation
  return (
    <VSensor once partialVisibility>
      {({ isVisible }) => (
        <Spring {...{ from, to: to(isVisible), ...res }}>
          {props => (
            <animated.div className={className} style={props}>
              {children}
            </animated.div>
          )}
        </Spring>
      )}
    </VSensor>
  )
}

export default Opacity
