import styled, { css } from "styled-components";

export const Container = styled.svg`
    width: 8px;
    height: 5px;
    display: inline-block;

    transform-origin: center;

    ${({ type }) =>
        type === "down" ||
        (!type &&
            css`
                transform: none;
            `)}
    ${({ type }) =>
        type === "up" &&
        css`
            transform: rotate3d(0, 0, 1, -180deg);
        `}
    ${({ type }) =>
        type === "right" &&
        css`
            transform: rotate3d(0, 0, 1, -90deg);
        `}
    ${({ type }) =>
        type === "left" &&
        css`
            transform: rotate3d(0, 0, 1, 90deg);
        `}
`;
