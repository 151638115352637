import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

import hash from '../../assets/images/hash.svg'

export const SPagination = styled.div`
  ${({ theme }) => css`
    height: 40px;
    display: flex;
    justify-content: center;

    margin-top: ${theme.space.xl}px;

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;

      display: inline-block;

      margin-right: ${theme.space.sm}px;
      background-color: ${theme.colors.lightGray};

      border-radius: 50%;

      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }

    .swiper-pagination-bullet-active {
      background-color: ${theme.colors.pink};
    }
  `}
`

export const STweetsSlider = styled.div`
  ${({ theme }) => css`
    margin: ${theme.space.xl}px -16px 0 -16px;
  `}
`
export const STweet = styled.a`
  display: block;
  img {
    width: 100%;
    height: auto;
  }
`
export const STweets = styled.section`
  ${({ theme, speakers }) => css`
    background-color: ${speakers
      ? theme.colors.darkGray
      : theme.colors.backgroundBlack};
    padding: ${theme.space.xl + 8}px 0;

    ${respondTo.lg`
      padding: 91px 0 179px;
    `}

    .heading {
      font-size: 1.5rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.875rem;
      color: ${theme.colors.white};
      text-align: center;
      margin: 0 auto ${theme.space.xl * 2}px;

      ${respondTo.lg`
        font-size: 2.25rem;
        line-height: 3.125rem;
      `};
    }

    .tweets-content {
      position: relative;
      margin-top: ${theme.space.xl}px;

      &::before {
        content: '';
        width: 130px;
        height: 124px;

        position: absolute;
        top: -60px;
        left: 0;

        background-image: url(${hash});
        background-repeat: no-repeat;

        ${respondTo.lg`
          width: 260px;
          height: 224px;
          top: -160px;
          left: -100px;
       `}
      }

      ${respondTo.lg`
        &::after {
          content: "";
          width: 260px;
          height: 224px;
  
          position: absolute;
          bottom: -64px;
          right: 0;
  
          background-image: url(${hash});
          background-repeat: no-repeat;
  
        }
      `}
    }
  `}
`
