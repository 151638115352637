import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SContainer = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundBlack};
    overflow: hidden;
    position: relative;
    padding: ${theme.space.xl + 8}px 0 43px;

    ${respondTo.lg`
      padding: 100px 0 50px;
    `}

    h2 {
      font-size: 1.5rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.875rem;
      color: white;
      text-align: center;
      margin: 0 auto ${theme.space.lg + 4}px;

      ${respondTo.lg`
        font-size: 2.25rem;
        line-height: 3.125rem;  
        margin: 0 auto;
      `}
    }

    .heading {
      &-companies {
        margin-bottom: 20px;
        ${respondTo.lg`
            margin-bottom: 10px;
        `}
      }

      .shadow {
        color: ${theme.colors.lightPink};
        text-shadow: 1px 1px 0 ${theme.colors.azure};
        font-size: 1.75rem;
        line-height: 2.188rem;

        ${respondTo.lg`
          font-size: 3.25rem;
          line-height: 4.125rem;
          text-shadow: 2px 2px 0 ${theme.colors.azure};
        `}
      }
    }

    a {
      i {
        padding-top: 4px;
        margin-left: 8px;
      }
    }

    .speakers {
      position: relative;
      max-width: 1140px;
      margin: 0 auto;
      padding: 0 ${theme.space.lg}px 0;

      .button-wrapper {
        ${respondTo.lg`
          display: flex;
          justify-content: center;
        `}
      }

      &-grid {
        display: grid;
        grid-template-columns: repeat(2, 144px);
        grid-gap: 40px 24px;
        justify-content: center;
        margin-bottom: ${theme.space.xl + 8}px;

        ${respondTo.sm`
          grid-template-columns: repeat(3, 144px);
        `}
        ${respondTo.lg`
          grid-template-columns: repeat(3, 240px);
          grid-template-rows: repeat(1, 1fr);

          grid-template-areas:
            ". . ."
          justify-content: center;

          margin: ${theme.space.xxxl}px 0 ${theme.space.xl * 2}px;
          grid-gap: 60px 60px;
        `}

        ${respondTo.xl`
          grid-template-columns: repeat(4, 240px);
          grid-template-rows: repeat(1, 1fr);
          grid-template-areas:
            ". . . ."
        `}
      }
    }

    .speaker {
      &-avatar {
        width: 136px;
        height: 136px;

        position: relative;

        margin: 0 auto ${theme.space.md - 4}px auto;
        background-color: #d4e6f7;

        box-shadow: ${theme.space.sm}px -${theme.space.sm}px 0 ${theme.colors.darkGray},
          ${theme.space.sm}px -${theme.space.sm}px 0 1px ${theme.colors.pink};

        ${respondTo.lg`
          box-shadow: ${theme.space.md + 4}px -${theme.space.md + 4}px 0 ${
          theme.colors.darkGray
        }, ${theme.space.md + 4}px -${theme.space.md + 4}px 0 1px ${
          theme.colors.pink
        };
          width: 220px;
          height: 220px;
          margin: 0 auto ${theme.space.md + 4}px auto;
        `}

        img {
          max-width: 136px;
          height: 136px;
          width: 136px;
          mix-blend-mode: multiply;
          filter: grayscale(1) contrast(1.1) brightness(0.7);

          ${respondTo.lg`
            max-width: 220px;
            width: 220px;
            height: 220px;
          `}
        }

        &::before {
          background-color: #2383cd;
          bottom: 0;
          content: '';
          height: 100%;
          left: 0;
          mix-blend-mode: color-dodge;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          z-index: 10;
        }
      }

      &-details {
        max-width: 220px;
        width: 100%;

        margin: 0 auto;
      }
      &-name {
        font-weight: ${theme.weight.bold};
        line-height: 1.375rem;
        color: ${theme.colors.pink};

        margin-bottom: ${theme.space.xs}px;

        ${respondTo.lg`
          font-size: 1.25rem;
          line-height: 1.75rem;

          margin-bottom: ${theme.space.sm + 3}px;
        `}
      }
      &-position {
        font-weight: 500;
        line-height: 1.375rem;
        color: ${theme.colors.white};
      }
    }

    .companies {
      margin-top: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;

      ${respondTo.lg`
        margin-top: ${theme.space.xxxl * 2}px;
      `}

      &-grid-wrapper {
        position: relative;
        padding: 0 80px;

        ${respondTo.lg`
           &::after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: linear-gradient(
                90deg,
                #121212 0%,
                transparent 5%,
                transparent 95%,
                #121212 100%
              ), linear-gradient(
                #121212 0%,
                transparent 30%,
                transparent 70%,
                #121212 100%
              );
           }
         `}
      }

      &-grid {
        position: relative;
        display: grid;
        grid-template-columns: repeat(4, 100px);

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            90deg,
            #121212 0%,
            transparent 50%,
            #121212 100%
          );

          ${respondTo.lg`
            background: linear-gradient(
              90deg,
              #121212 0%,
              transparent 20%,
              transparent 80%,
              #121212 100%
            );
          `}

          ${respondTo.xl`
            display: none;
          `}
        }

        ${respondTo.lg`
          margin: 80px auto 80px;
          grid-template-columns: repeat(6, 180px);
        `}
      }
    }

    .company {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 50px;
      position: relative;
      margin: 0 -1px -1px 0;

      ${respondTo.lg`
        width: 180px;
        height: 90px;
      `}

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-top: 1px solid ${theme.colors.darkestGray};
        border-bottom: 1px solid ${theme.colors.darkestGray};
        margin: 0 -1px 0 0;

        ${respondTo.lg`
          top: -2px;
          left: -80px;
          width: calc(100% + 160px);
        `}
      }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border-left: 1px solid ${theme.colors.darkestGray};
        border-right: 1px solid ${theme.colors.darkestGray};
        margin: 0 0 -1px 0;

        ${respondTo.lg`
          left: -2px;
          top: -80px;
          height: calc(100% + 160px);
        `}
      }

      img {
        width: 100%;
        max-width: 70px;
        max-height: 26px;

        ${respondTo.lg`
          max-width: 138px;
          max-height: 47px;
        `}
      }
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .positionLeft {
      position: absolute;
      top: ${theme.space.xxxl * 1.5}px;
      left: 0;
      z-index: 1;

      ${respondTo.lg`
        top: 40%;
        left: -50px;
        z-index: 0;
      `}

      img {
        width: 149px;
        height: 17px;
        margin-left: -100px;

        ${respondTo.lg`
          width: 100%;
          height: 100%;
          margin-left: 0;
        `}
      }
    }
    .positionRight {
      position: absolute;
      top: ${theme.space.md}px;
      right: 0;
      z-index: 1;

      ${respondTo.lg`
        top: 218px;
        right: -50px;
      `}

      img {
        width: 113px;
        height: 17px;
        margin-right: -55px;

        ${respondTo.lg`
          width: 100%;
          height: 100%;
          margin-right: 0;
        `}
      }
    }
  `}
`
