import React, { useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useBreakpoint } from 'modules/breakpoint'

import ArrowIcon from '../../assets/icons/ArrowIcon'
import CheckIcon from '../../assets/icons/CheckIcon'
import { Button, Wrapper, SlideTop, Loader } from '../../components'

import { SNewsletter } from './Newsletter.styled'

import config from 'const/config'
import { AppRoute } from 'const'

const mailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
const IDLE_STATUS = 'idle'
const PENDING_STATUS = 'pending'
const SUCCESS_STATUS = 'success'
const ERROR_STATUS = 'error'

export const SUBSCRIBED_RESULT = 'subscribed'
export const UNSUBSCRIBED_RESULT = 'unsubscribed'
export const FAILED_RESULT = 'failed'

function Newsletter() {
  const [status, setStatus] = useState(IDLE_STATUS)
  const [errorMessage, setErrorMessage] = useState(null)

  const newsletterInputRef = useRef()
  const breakpoint = useBreakpoint()
  const { formatMessage } = useIntl()

  const handleInput = () => {
    if (status === ERROR_STATUS) {
      setStatus(IDLE_STATUS)
    }
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      setStatus(PENDING_STATUS)
      const { value } = newsletterInputRef.current

      if (!mailRegex.test(value)) {
        setStatus(ERROR_STATUS)
        return
      }

      const response = await fetch(config.ABSOLVENT_API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `
            mutation JoinNewsletter($input: NewsletterInputType) {
              joinNewsletter(input: $input){
                subscriber_email,
                subscription_status,
                error
              }
            }
          `,
          variables: {
            input: {
              mailing_list: config.MAILING_LIST,
              subscriber_email: value,
            },
          },
        }),
      })

      const {
        data: { subscription_status, error },
        errors: graphqlErrors,
      } = await response.json()
      if (subscription_status === FAILED_RESULT || graphqlErrors) {
        throw new Error(error || 'Subscription failed without error')
      }

      setStatus(SUCCESS_STATUS)
    } catch (error) {
      console.log('Newsletter join error', error)
      if (typeof error === 'string') setErrorMessage(error)
      setStatus(ERROR_STATUS)
    }
  }

  const error = status === ERROR_STATUS
  const loading = status === PENDING_STATUS
  const success = status === SUCCESS_STATUS

  return (
    <SlideTop>
      <Wrapper>
        <SNewsletter {...{ error, loading, success }}>
          <h2>
            {formatMessage(
              {
                id: 'newsletterHeading',
              },
              {
                span: (chunk) => <span>{chunk}</span>,
              }
            )}
          </h2>
          <form onSubmit={handleSubmit}>
            {success ? (
              <div className="success">
                <p>{formatMessage({ id: 'newsletterSuccess' })}</p>
                <span>
                  <CheckIcon />
                </span>
              </div>
            ) : (
              <div>
                <input
                  ref={newsletterInputRef}
                  type="text"
                  onChange={handleInput}
                  placeholder={formatMessage({
                    id: 'newsletterPlaceholder',
                  })}
                />
                {breakpoint.lg ? (
                  <Button disabled={error}>
                    {formatMessage({ id: 'newsletterSubscribe' })}
                  </Button>
                ) : (
                  <button disabled={error} type="submit">
                    {status === PENDING_STATUS ? <Loader /> : <ArrowIcon />}
                  </button>
                )}
              </div>
            )}
            {!success ? (
              <p>
                {formatMessage(
                  {
                    id: error ? 'newsletterError' : 'newsletterPolicy',
                  },
                  {
                    a: ([chunk]) => {
                      return (
                        <a
                          href={formatMessage({ id: AppRoute.PrivacyPolicy })}
                          target="_blank"
                        >
                          {chunk}
                        </a>
                      )
                    },
                  }
                )}
              </p>
            ) : null}
          </form>
        </SNewsletter>
      </Wrapper>
    </SlideTop>
  )
}

export default Newsletter
