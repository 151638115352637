import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'
export const SContainer = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundBlack};
    position: relative;
    overflow: hidden;
    padding: ${theme.space.xl + 8}px ${theme.space.lg}px;

    ${respondTo.lg`
      padding: 80px ${theme.space.lg}px;
    `}

    .content {
      max-width: 862px;
      margin: 0 auto;
      z-index: 2;
    }

    .heading {
      font-size: 1.75rem;
      line-height: 2.43rem;
      font-weight: ${theme.weight.bold};
      color: ${theme.colors.white};
      text-align: center;

      ${respondTo.lg`
        font-size: 3.25rem;
        line-height: 4.5rem;
        text-align: left;
      `}

      span {
        color: ${theme.colors.lightGray2};
        display: block;
      }

      &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        column-gap: ${theme.space.md + 4}px;
        margin-bottom: ${theme.space.xl + 8}px;

        ${respondTo.lg`
          flex-direction: row;
          margin-bottom: 60px;
          column-gap: ${theme.space.lg + 2}px;
        `}
        .emoticon {
          font-size: 50px;
          line-height: 70px;

          ${respondTo.lg`
            font-size: 80px;
          `}
        }
      }
    }

    .data-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      row-gap: 40px;
      z-index: 2;

      ${respondTo.lg`
         flex-direction: row;
         column-gap: 107px;
         margin-left: 106px;
         border-left: 1px solid ${theme.colors.darkestGray};
         padding-left: ${theme.space.lg - 2}px;
         width: 100%;      
      `}
    }

    .contact-info-container,
    .learn-more-container {
      display: flex;
      flex-direction: column;
      row-gap: 28px;

      ${respondTo.lg`
         row-gap: 48px;
      `}
    }
    .contact-box,
    .learn-more-box {
      &-title {
        line-height: 1.375rem;
        font-weight: ${theme.weight.bold};
        color: ${theme.colors.white};

        ${respondTo.lg`
          font-size: 1.25rem;
          line-height: 1.75rem;
        `}
      }
      &-subtitle {
        font-weight: 500;
        line-height: 1.375rem;
        color: ${theme.colors.lightGray2};

        a {
          color: ${theme.colors.pink};
          display: block;
          margin: ${theme.space.sm + 4}px 0 ${theme.space.xs}px;
          text-decoration: none;
          cursor: pointer;
        }
      }
      &-name {
        font-size: 0.875rem;
        line-height: 1.188rem;
        color: ${theme.colors.lightGray2};
        display: block;
        margin-bottom: ${theme.space.xs}px;

        ${respondTo.lg`
          font-size: 1rem;
          font-weight: ${theme.weight.bold};
          line-height: 1.375rem;
        `}
      }
      &-email {
        font-size: 0.875rem;
        line-height: 1.188rem;
        font-weight: 500;
        color: ${theme.colors.pink};
        text-decoration: none;

        ${respondTo.lg`
          font-size: 1rem;
          line-height: 1.375rem;
        `}
      }
      &-icon-wrapper {
        width: 48px;
        height: 48px;
        background-color: ${theme.colors.darkestGray};
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          color: #a8afbc;
          font-size: 21px;
          ${respondTo.lg`
            font-size: 23px;
          `}
        }
      }
      &-link {
        margin-top: ${theme.space.sm + 4}px;
        color: ${theme.colors.pink};
        font-size: 1.25rem;
        line-height: 1.5rem;
        font-weight: ${theme.weight.bold};
        text-decoration: none;
        display: flex;
        cursor: pointer;

        ${respondTo.lg`
          font-weight: 800;
        `}
        i {
          padding-top: 2px;
          margin-left: ${theme.space.sm}px;
        }
      }
      &-container {
        display: flex;
        align-items: center;
        margin-top: ${theme.space.sm + 4}px;

        img {
          width: 44px;
          height: 44px;

          ${respondTo.lg`
            width: 48px;
            height: 48px;
          `}
        }
      }

      &-personal-info {
        margin-left: ${theme.space.md + 3}px;
      }
    }

    .learn-more-box-subtitle {
      font-size: 0.875rem;
      line-height: 1.188rem;

      ${respondTo.lg`
        font-size: 1rem;
        line-height: 1.375rem;
      `}
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
    }

    .positionRight {
      display: none;

      ${respondTo.xl`
        display: block;
        position: absolute;
        top: 75%;
        right: -78px;
      `}
    }

    .positionLeftMobile {
      position: absolute;
      top: 20%;
      left: 0;

      ${respondTo.lg`
         display: none;
      `}

      img {
        height: 17px;
        margin-left: -40px;
      }
    }

    .positionRightMobile {
      position: absolute;
      top: 56px;
      right: -62px;

      ${respondTo.lg`
         display: none;
      `}

      svg {
        height: 17px;
      }
    }
  `}
`
