import styled, { css } from 'styled-components'

export const STicketSummary = styled.div`
  ${({ theme }) => css`
    color: white;

    margin-top: ${theme.space.md}px;

    .container {
      display: flex;
    }

    strong {
      font-weight: ${theme.weight.bold};
    }

    .icon {
      width: 32px;
      height: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      color: ${theme.colors.black};

      border-radius: 50%;
      background-color: ${theme.colors.pink};

      margin-right: ${theme.space.md}px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .content {
      flex: 1;
    }

    .text {
      line-height: 1.25;
    }

    .info {
      font-size: 0.875rem;
      line-height: 1.71;
      color: ${theme.colors.lightGray};

      margin-top: ${theme.space.lg}px;
      margin-left: ${theme.space.xxl}px;
      margin-right: ${theme.space.xl}px;
    }

    .actions {
      display: flex;
      align-items: flex-end;

      margin-left: 24px;

      .price-loader {
        width: 24px;
        height: 24px;
      }

      .edit {
        width: 24px;
        height: 24px;
        min-height: 24px;
        max-height: 24px;

        display: flex;
        align-items: center;
        justify-content: center;

        margin: 0;
        padding: 0;

        border: none;
        border-radius: 50%;
        background-color: ${theme.colors.lightGray};

        cursor: pointer;

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  `}
`
