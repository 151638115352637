import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export default styled.span`
  ${({ theme, color, bottom, left, right }) => css`
    display: inline-block;
    position: relative;

    z-index: 2;

    &::before {
      content: '';
      width: calc(100% + 6px);
      height: ${theme.space.xs}px;

      position: absolute;
      left: -6px;
      bottom: ${!isNaN(bottom) ? bottom : -4}px;

      border-radius: 4px;
      background-color: ${color};

      z-index: -1;

      ${respondTo.lg`
        height: ${theme.space.sm}px;
      `}
    }
  `}
`
