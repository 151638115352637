import React, { useEffect } from "react"

function ListOfEntitiesEmployerOutsideEog() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.location.href = "/wykaz-podmiotow-pracodawca-poza-eog.pdf"
    }
  }, []);

  return null;
}

export { ListOfEntitiesEmployerOutsideEog }
