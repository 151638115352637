import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'

import client from 'api/client'

import {
  TicketProcessBox,
  TicketUserSteper,
  TicketProcessInput,
  TicketProcessCheckbox,
  TicketSummary,
  TicketPaymentSummary,
  TicketPriceSummary,
  Select,
} from '..'
import Button from 'components/Button/Button'

import { TicketStep } from 'state/reducers/tickets/ticketsReducer'
import {
  buyTicket,
  changeStep,
  unselectTicket,
} from 'state/reducers/tickets/ticketsActions'

function TicketOrderSummary({ items, price, paymentData, isGroup }) {
  const dispatch = useDispatch()
  const { locale, formatMessage } = useIntl()

  const ticket = items.selected.entity
  const amount = items.selected.amount

  const handlePrev = () => {
    dispatch(changeStep(TicketStep.PAYMENT_DATA))
  }

  const handleRemoveTicket = () => {
    dispatch(unselectTicket())
  }

  const handleEditPaymentData = () => {
    dispatch(changeStep(TicketStep.PAYMENT_DATA))
  }

  const submit = () => {
    dispatch(buyTicket({ locale, isGroup }))
  }

  return (
    <TicketProcessBox {...{ locale }}>
      <header>
        <p className="heading">
          {formatMessage({ id: 'tickets.orderSummaryLabel' })}
        </p>
      </header>

      <p className="label">
        {formatMessage({ id: 'tickets.selectDetailsHeading' })}:
      </p>
      <TicketSummary
        withoutVatInformation
        {...{ ticket, amount, price, onRemove: handleRemoveTicket }}
      />

      <p className="label">
        {formatMessage({ id: 'tickets.orderSummaryPaymentLabel' })}:
      </p>
      <TicketPaymentSummary
        {...{ data: paymentData, onEdit: handleEditPaymentData }}
      />

      <span className="or-separator" />
      <TicketPriceSummary {...{ price }} />

      <div className="actions payu">
        <button className="pay" type="button" onClick={submit} />
        <button type="button" className="back" onClick={handlePrev}>
          {formatMessage({ id: 'general.back' })}
        </button>
      </div>
    </TicketProcessBox>
  )
}

export default TicketOrderSummary
