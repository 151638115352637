import styled, { css } from 'styled-components'
import respondTo from 'theme/respondTo'

export const SAgendaTile = styled.div`
  ${({ theme }) => css`
    padding: 0 ${theme.space.md}px;
    margin: 0 ${-theme.space.md}px;

    padding-top: ${theme.space.lg}px;
    margin-top: ${theme.space.lg}px;

    border-top: 1px solid ${theme.colors.gray};

    &:first-of-type {
      padding-top: 0;
      margin-top: 0;
      border-top: 0;
    }

    &:last-of-type {
      .event:last-of-type {
        border-bottom: 0;
      }
    }

    .agenda-link {
      font-size: 1rem;
      font-weight: ${theme.weight.normal};
      line-height: 1.5rem;
      color: ${theme.colors.pink};

      margin-top: ${theme.space.lg}px;
    }

    .agenda-place-data {
      font-size: 0.875rem;
      color: white;

      display: flex;
      flex-direction: column;

      p {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        line-height: 1.5rem;
      }

      svg {
        color: ${theme.colors.pink};
        height: 12px;

        top: 1px;
        position: relative;

        margin-right: ${theme.space.sm}px;
      }
    }

    .time-group {
      display: flex;
      flex-direction: column;

      padding: 0 ${theme.space.md}px;
      margin: 0 ${-theme.space.md}px;

      margin-top: ${theme.space.lg}px;

      .time {
        height: 48px;

        display: flex;
        align-items: center;

        font-size: 1rem;
        font-weight: ${theme.weight.bold};
        color: white;

        padding: 0 ${theme.space.md}px;
        margin: 0 ${-theme.space.md}px;

        background-color: ${theme.colors.darkestGray};
        border-top: 1px solid ${theme.colors.gray};
        border-bottom: 1px solid ${theme.colors.gray};
      }
    }

    .agenda {
      width: 100%;
    }

    ${respondTo.lg`
      padding: 0;
      margin: 0;
      padding-top: ${theme.space.xxl}px;
      margin-top: ${theme.space.xxl}px;

      .agenda-link {
        margin-top: 0;
      }

      .agenda-place-data {
        font-size: 1.25rem;
        font-weight: ${theme.weight.bold};

        flex-direction: row;
        justify-content: space-between;

        svg {
          height: 20px;
        }
      }

      .time-group {
        flex-direction: row;

        margin: 0;
        padding: 0;
        margin-top: ${theme.space.xxl}px;
        padding-top: ${theme.space.xxl}px;
        border-top: 1px solid ${theme.colors.gray};

        &:first-of-type {
          padding-top: 0;
          border-top: 0;
        }

        .time {
          width: 80px;
          height: 32px;

          justify-content: center;

          padding: 0;
          margin: 0;

          color: ${theme.colors.darkGray};

          background-color: #e0e0e0;
          border: 0;
        }
      }

      .agenda {
        display: flex;
        flex-direction: column;

        padding-left: ${theme.space.md}px;
      }
    `}
  `}
`
