import * as React from "react"

function CheckIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M11.307 20.973a1.242 1.242 0 01-.91-.4l-6.06-6.445a1.248 1.248 0 011.82-1.709l5.137 5.474L21.78 6.423a1.246 1.246 0 111.846 1.67L12.228 20.561a1.249 1.249 0 01-.91.412h-.012z"
      />
    </svg>
  )
}

export default CheckIcon
