import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import VisibilitySensor from 'react-visibility-sensor'
import { animated, Spring } from 'react-spring'
import React, { useRef } from 'react'
import { useIntl } from 'react-intl'

import { SlideTop } from '../../components'

import 'swiper/swiper.scss'
import { SContainer, SPagination, SSwiperWrapper } from './Impressions.styled'

import impressions1 from '../../assets/images/impressions-1.png'
import impressions2 from '../../assets/images/impressions-2.png'
import impressions3 from '../../assets/images/impressions-3.png'
import impressions4 from '../../assets/images/impressions-4.png'
import impressions5 from '../../assets/images/impressions-5.png'
import impressions6 from '../../assets/images/impressions-6.png'
import impressions7 from '../../assets/images/impressions-7.png'
import impressions8 from '../../assets/images/impressions-8.png'
import impressions9 from '../../assets/images/impressions-9.png'
import smallGreenPinkPattern from '../../assets/patterns/small-green-pink-pattern.svg'
import smallBlueGreenPattern from '../../assets/patterns/small-blue-green-pattern.svg'

const impressions = [
  [impressions1, impressions2, impressions3],
  [impressions4, impressions5, impressions6],
  [impressions7, impressions8, impressions9],
]
function Impressions() {
  const { formatMessage } = useIntl()
  const swiperRef = useRef(null)

  return (
    <VisibilitySensor
      minTopValue={15}
      offset={{ top: 150 }}
      scrollCheck
      scrollDelay={5}
      partialVisibility="top"
    >
      {({ isVisible }) => {
        return (
          <SContainer>
            <SlideTop>
              <h2 className="heading">
                {formatMessage({ id: 'impressions' })}
              </h2>
              <SSwiperWrapper>
                <Spring
                  from={{ opacity: 0 }}
                  to={{ opacity: isVisible ? 0 : 1 }}
                >
                  {({ opacity }) => {
                    return (
                      <div className="background">
                        <animated.div
                          style={{
                            opacity,
                            height: '100%',
                          }}
                        >
                          <div className="positionLeft">
                            <img src={smallGreenPinkPattern} alt="" />
                          </div>
                          <div className="positionRight">
                            <img src={smallBlueGreenPattern} alt="" />
                          </div>
                        </animated.div>
                      </div>
                    )
                  }}
                </Spring>
                <Swiper
                  ref={swiperRef}
                  slidesPerView={1}
                  modules={[Pagination]}
                  pagination={{
                    el: '.swiper-custom-pagination',
                    clickable: true,
                    renderBullet: (index, className) => {
                      return `<span key=${index} class=${className}></span>`
                    },
                  }}
                >
                  {impressions.map((slide, index) => (
                    <SwiperSlide key={index} className="slide">
                      {slide.map((img, index) => (
                        <img key={index} src={img} alt="" />
                      ))}
                    </SwiperSlide>
                  ))}
                  <SPagination>
                    <div className="swiper-custom-pagination" />
                  </SPagination>
                </Swiper>
                <div className="buttons-wrapper">
                  <button
                    className="prev"
                    onClick={() => swiperRef.current.swiper.slidePrev()}
                  >
                    <i className="ri-arrow-left-line" />
                  </button>
                  <button
                    className="next"
                    onClick={() => swiperRef.current.swiper.slideNext()}
                  >
                    <i className="ri-arrow-right-line" />
                  </button>
                </div>
              </SSwiperWrapper>
            </SlideTop>
          </SContainer>
        )
      }}
    </VisibilitySensor>
  )
}

export default Impressions
