import styled, { css } from 'styled-components'

export default styled.a`
  ${({ theme, small, large }) => css`
    width: 44px;
    height: 44px;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    font-size: 1.25rem;
    font-weight: ${theme.weight.bold};
    color: ${theme.colors.black};

    margin-bottom: 0;
    padding-right: 4px;
    padding-bottom: 4px;

    background: none;
    appearance: none;
    box-shadow: none;
    border: none;

    cursor: pointer;

    ${small &&
    css`
      width: 40px;
      height: 40px;
    `}

    ${large &&
    css`
      width: 55px;
      height: 55px;
    `}

    &:hover,
    &:focus {
      outline: 0;
      &::before {
        background-color: ${theme.colors.lightTurquoise};
      }

      &::after {
        background-color: white;
      }
    }

    &:disabled {
      color: ${theme.colors.lightGray};
      cursor: not-allowed;

      &::before {
        background-color: white;
      }
    }

    &::before {
      content: '';
      width: calc(100% - 4px);
      height: calc(100% - 4px);

      position: absolute;

      background-color: white;
      border: 1px solid ${theme.colors.black};

      top: 0;
      left: 0;

      transition: all 200ms ease-in;
      z-index: 1;
    }

    &::after {
      content: '';
      width: calc(100% - 4px);
      height: calc(100% - 4px);

      position: absolute;

      background-color: ${theme.colors.lightTurquoise};
      border: 1px solid ${theme.colors.black};

      top: 4px;
      left: 4px;

      transition: all 200ms ease-in;
    }

    svg {
      min-width: 11px;
      height: 20px;
      z-index: 2;

      ${small &&
      css`
        min-width: 11px;
        height: 18px;
      `}

      ${large &&
      css`
        min-width: 16px;
        height: 25px;
      `}
    }
  `}
`
