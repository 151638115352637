import React from 'react'
import { useIntl } from 'react-intl'
import { useTheme } from 'styled-components'
import { useDispatch } from 'react-redux'

import {
  Button,
  CustomUnderline,
  IconDescriptionGrid,
  SlideTop,
} from '../../components'
import { SContainer } from './WhyAttendPartners.styled'

import { setContactModalVisibility } from '../../state/reducers/app/appActions'

const items = [
  {
    icon: 'ri-user-follow-line',
    title: 'whyAttendAttract',
    text: 'whyAttendAttractDescription',
  },
  {
    icon: 'ri-star-smile-line',
    title: 'whyAttendEB',
    text: 'whyAttendEBDescription',
  },
  {
    icon: 'ri-bubble-chart-line',
    title: 'whyAttendSpectrum',
    text: 'whyAttendSpectrumDescription',
  },
  {
    icon: 'ri-database-2-line',
    title: 'whyAttendCandidates',
    text: 'whyAttendCandidatesDescription',
  },
  {
    icon: 'ri-medal-line',
    title: 'whyAttendEvent',
    text: 'whyAttendEventDescription',
  },
  {
    icon: 'ri-links-line',
    title: 'whyAttendMarketing',
    text: 'whyAttendMarketingDescription',
  },
]

function WhyAttendPartners() {
  const theme = useTheme()
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()

  const openCallpageWidget = () => {
    if (typeof window === 'undefined') return
    window.callpage('api.widget.open')
  }
  const openContactModal = () => {
    dispatch(setContactModalVisibility(true))
  }

  const openContactModalOrCallpage = () => {
    window.callpage ? openCallpageWidget() : openContactModal()
  }

  return (
    <SContainer>
      <div className="content">
        <SlideTop>
          <h2 className="heading">
            {formatMessage(
              { id: 'whyAttendPartnersHeading' },
              {
                span: (chunk) => (
                  <CustomUnderline color={theme.colors.blue}>
                    {chunk}
                  </CustomUnderline>
                ),
              }
            )}
          </h2>
        </SlideTop>
        <SlideTop>
          <p className="subheader">
            {formatMessage(
              { id: 'whyAttendPartnersDescription' },
              { br: <br /> }
            )}
          </p>
        </SlideTop>
        <IconDescriptionGrid {...{ items }} />
        <Button
          secondary="true"
          height="52px"
          onClick={openContactModalOrCallpage}
        >
          {formatMessage({
            id: 'joinPartnersButton',
          })}
          <i className="ri-arrow-right-line" />
        </Button>
      </div>
    </SContainer>
  )
}

export default WhyAttendPartners
