import React, { useEffect } from "react"

function ListOfEntitiesUser() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.location.href = "/wykaz-podmiotow-uzytkownik.pdf"
    }
  }, []);

  return null;
}

export { ListOfEntitiesUser }
