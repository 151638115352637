export enum AppRoute {
  Home = 'routes.home',
  AboutUs = 'routes.about_us',
  BecomePartner = 'routes.become_partner',
  BecomeSpeaker = 'routes.become_speaker',
  LectureDetailsForm = 'routes.lecture_details_form',
  SpeakerDetailsForm = 'routes.speaker_details_form',
  Partners = 'routes.partners',
  Speaker = 'routes.speaker',
  Speakers = 'routes.speakers',
  Companies = 'routes.companies',
  Company = 'routes.company',

  BuyTicket = 'routes.buy_ticket',
  BuyTicketSuccess = 'routes.buy_ticket_success',
  BuyTicketGroupSuccess = 'routes.buy_ticket_group_success',
  AgendaArchive = 'routes.agenda_archive',
  Agenda = 'routes.agenda',

  Offers = 'routes.offers',
  Offer = 'routes.offer',

  CookiesPolicy = 'routes.cookies_policy',
  PrivacyPolicy = 'routes.privacy_policy',

  CodeOfConduct = 'routes.code_of_conduct',
  PersonalDataProcessing = 'routes.personal_data_processing',
  RecordingAgreement = 'routes.recording_agreement',
  ListOfEntitiesEmployer = 'routes.list_of_entities_employer',
  ListOfEntitiesEmployerOutsideEog = 'routes.list_of_entities_employer_outside_eog',
  ListOfEntitiesUser = 'routes.list_of_entities_user',
  ListOfEntitiesUserOutsideEog = 'routes.list_of_entities_user_outside_eog',

  ClientsRegulationsPl = 'routes.clients_regulations_pl',
  ClientsRegulationsEn = 'routes.clients_regulations_en',
  UserRegulations = 'routes.user_regulations',

  User = 'routes.user',

  Contest = 'routes.contest',
  KubotaContest = 'routes.kubota_contest',
  CovidStatement = 'routes.covid_statement',

  Unsubscribe = 'routes.unsubscribe',
  SignIn = 'routes.sign-in',
  Logout = 'routes.logout',
  ForgotPassword = 'routes.forgot_password',
  ResetPassword = 'routes.reset_password',

  NotFound = 'routes.not-found',
}

export const AppRouteTitles = new Map([
  [AppRoute.Home, 'home.title'],
  [AppRoute.BecomePartner, 'forPartners'],
  [AppRoute.BecomeSpeaker, 'forSpeakers'],
  [AppRoute.AboutUs, 'aboutUs'],
])
