import React from 'react'
import { useMemo } from 'react'
import { TicketStep } from 'state/reducers/tickets/ticketsReducer'

import { Steper } from '../'
import { STicketUserSteper } from './TicketUserSteper.styled'

const ticketSteps = [
  { key: TicketStep.REGISTRATION, label: 'tickets.stepperRegister' },
  { key: TicketStep.BADGE, label: 'tickets.stepperBadge' },
  { key: TicketStep.DATA, label: 'tickets.stepperUserData' },
  { key: TicketStep.PAYMENT_DATA, label: 'tickets.stepperPaymentData' },
  { key: TicketStep.PAYMENT_SUMMARY, label: 'tickets.stepperOrderSummary' },
]

function TicketUserSteper({ simplified, isGroup, isAuthenticated, ...res }) {
  const steps = useMemo(() => {
    if (simplified) {
      return ticketSteps.slice(0, 3)
    }

    if (isGroup || isAuthenticated) {
      return ticketSteps.slice(-2)
    }

    return ticketSteps
  }, [simplified, isGroup])

  return (
    <STicketUserSteper>
      <Steper steps={steps} {...res} />
    </STicketUserSteper>
  )
}

export default TicketUserSteper
