import styled, { css } from 'styled-components'
import respondTo from 'theme/respondTo'

export const SWrapper = styled.button`
  ${({ theme, mobileNavigationVisible }) =>
    theme &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 20px;
      width: 24px;
      margin: 2px auto 0 auto;
      position: relative;

      background: none;
      border: none;
      box-shadow: none;

      cursor: pointer;

      &:hover > span {
        background: ${theme.colors.navigation.link.hover};
      }

      & > span {
        background: ${theme.colors.black};
        display: block;
        position: absolute;
        height: 3px;
        transform: rotate(0deg);
        transition: all 0.25s cubic-bezier(0.17, 0.67, 0.47, 1);
        opacity: 1;
        width: 100%;
        border-radius: 10px;
        left: 0;
        touch-action: none;
        pointer-events: none;

        &:nth-child(1) {
          top: 0;
        }
        &:nth-child(2),
        &:nth-child(3) {
          top: ${theme.space.sm}px;
        }
        &:nth-child(4) {
          width: 16px;
        }
        &:nth-child(4) {
          top: ${theme.space.md}px;
        }

        ${respondTo.lg`
          height: 2px;
        `}
      }

      ${mobileNavigationVisible &&
      css`
        & span {
          background: black;

          &:nth-child(1) {
            top: ${theme.space.sm}px;
            width: 0;
            left: 50%;
          }
          &:nth-child(2) {
            transform: rotate(45deg);
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
          }
          &:nth-child(4) {
            top: ${theme.space.sm}px;
            width: 0;
            left: 50%;
          }
        }
      `}

      ${respondTo.lg`
        margin: 5px auto 0 auto;
      `}
    `}
`
