import React from 'react'

import { SSocialLinkIcon } from './SocialLinkIcon.styled'

import * as icons from './icons'

function SocialLinkIcon({ link, ...res }) {
  const getIconName = () => {
    if (link.includes('facebook')) return 'Facebook'
    if (link.includes('github')) return 'Github'
    if (link.includes('instagram')) return 'Instagram'
    if (link.includes('linkedin')) return 'Linkedin'
    if (link.includes('twitter')) return 'Twitter'

    return 'Link'
  }

  const Icon = icons[getIconName()]

  return (
    <SSocialLinkIcon {...res}>
      <Icon />
    </SSocialLinkIcon>
  )
}

export default SocialLinkIcon
