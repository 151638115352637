import styled, { css } from 'styled-components'

import mobileBackground from '../../assets/images/newsletter-mobile-background.svg'
import desktopBackground from '../../assets/images/newsletter-desktop-background.svg'
import respondTo from '../../theme/respondTo'

export const SNewsletter = styled.section`
  ${({ theme, error }) => css`
    min-height: 388px;

    display: flex;
    flex-direction: column;

    background-image: url(${mobileBackground});
    background-repeat: no-repeat;
    background-size: cover;

    margin: 0 -${theme.space.md}px;
    padding: ${theme.space.lg}px;

    h2 {
      font-size: 1.5rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.1;
      color: ${theme.colors.white};

      span {
        font-size: 3rem;
        line-height: 3rem;
        text-shadow: 2px 2px 0 ${theme.colors.pink};
        display: block;
        margin-bottom: ${theme.space.md}px;
      }
    }

    form {
      margin-top: ${theme.space.xl}px;

      div {
        display: flex;
        flex-direction: column;

        &.success {
          height: 48px;

          display: flex;
          flex-direction: row;
          align-items: center;

          color: ${theme.colors.pink};

          background-color: ${theme.colors.darkGray};
          border: 1px solid ${theme.colors.black};

          margin-bottom: ${theme.space.md}px;

          p {
            flex: 1;
            padding-left: ${theme.space.md}px;
          }

          span {
            width: 48px;
            height: 48px;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;

            color: black;

            background-color: ${theme.colors.pink};
            border: 1px solid ${theme.colors.black};

            svg {
              width: 24px;
              height: 24px;
            }
          }
        }
      }

      [type='submit'] {
        width: 48px;
        height: 48px;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        color: black;

        background-color: ${theme.colors.pink};
        border: 1px solid ${theme.colors.black};

        ${error &&
        css`
          border: 2px solid ${theme.colors.lightRed};
        `}

        &:disabled {
          background-color: ${theme.colors.lightGray};
        }

        margin: 0;
        padding: 0;

        &:focus {
          outline: 0;
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }

      & > p {
        font-size: 0.75rem;
        line-height: 1.33;
        color: white;

        margin-top: ${theme.space.md}px;

        ${error &&
        css`
          color: ${theme.colors.lightRed};
        `}

        a {
          text-decoration: none;
          color: ${theme.colors.pink};
        }
      }
    }

    input {
      width: 100%;
      height: 48px;

      font-size: 1rem;
      color: ${theme.colors.lightGray};

      padding: 0 ${theme.space.md}px;
      margin-bottom: ${theme.space.md}px;
      border: 1px solid ${theme.colors.black};

      ${error &&
      css`
        border: 2px solid ${theme.colors.lightRed};
      `}

      &:focus {
        outline: 0;
      }

      &::placeholder {
        font-size: 1rem;
        color: ${theme.colors.lightGray};
      }
    }

    ${respondTo.lg`
      min-height: 142px;

      flex-direction: row;
      align-items: center;

      box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14);
      background-image: url(${desktopBackground});

      padding: 0 72px;
      margin: ${theme.space.xxl * 2}px 0 ${theme.space.xxxl}px;

      h2 {
        flex: 1;
        margin-right: 60px;
        white-space: nowrap;
        
        span {
            margin-left: -${theme.space.md}px;
            margin-bottom: ${theme.space.xs}px;
        }
      }

      form {
        flex: 1.2;
        margin-top: ${theme.space.md}px;

        div {
          flex-direction: row;
        }

        & > p {
          margin-top: ${theme.space.sm / 2}px;
        }
      }

      input {
        font-size: 1.25rem;

        margin-bottom: 0;
        max-width: 434px;

        &::placeholder {
          font-size: 1.25rem;
        }
      }
    `}
  `}
`
