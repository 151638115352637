import React from 'react'
import { useIntl } from 'react-intl'

import { ButtonLink } from '../../components'
import { SContent } from './VolunteersBanner.styled'
import { useBreakpoint } from '../../modules/breakpoint'

function VolunteersBanner() {
  const { formatMessage } = useIntl()
  const breakpoint = useBreakpoint()
  const isMobile = breakpoint.lg

  return (
    <SContent>
      <h2 className="heading">
        {formatMessage(
          {
            id: isMobile
              ? 'volunteersBannerHeadingMobile'
              : 'volunteersBannerHeading',
          },
          {
            span: (chunk) => <span>{chunk}</span>,
            br: <br />,
          }
        )}
      </h2>
      <div className="button-wrapper">
        <ButtonLink
          href="https://forms.gle/K2imjjYTY1Zyyqcw8"
          target="_blank"
          rel="noreferrer"
          secondary="true"
          height="52px"
        >
          {formatMessage({
            id: 'applyNow',
          })}
          <i className="ri-arrow-right-line" />
        </ButtonLink>
      </div>
    </SContent>
  )
}

export default VolunteersBanner
