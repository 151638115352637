import React from 'react'
import { Header } from 'components'
import { Footer } from 'sections'
import { useRef } from 'react'
import useCalculateSectionHeight from 'utils/useCalculateSectionHeight'

export const AppLayout: React.FC = ({ children }) => {
  const mainRef = useRef(null)

  useCalculateSectionHeight(mainRef)

  return (
    <>
      <Header />
      <main ref={mainRef}>{children}</main>
      <Footer />
    </>
  )
}
