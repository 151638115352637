import styled, { css } from "styled-components"
import respondTo from "../../theme/respondTo"

import { Wrapper, PhotoWithFrame } from "../../components"

import specialistsPhoto from "../../assets/images/join-speakers-specialists.png"
import contentPhoto from "../../assets/images/join-speakers-content.png"

export const SBackground = styled.div`
    width: 100%;

    display: none;

    position: absolute;
    top: 0;
    bottom: 0;

    ${respondTo.lg`
      display: block;
    `}
`

export const SAction = styled.div`
  ${({ theme }) => css`
    display: flex;    
    flex-direction: column;
    margin-top: ${theme.space.xl}px;
    width: 280px;
    
     ${respondTo.md`
      flex-direction: row;
    `}
     
     ${respondTo.lg`
       width: 100%;
      `}
  `}
`

export const SContainer = styled.section`
    position: relative;
`

export const SHeading = styled.h1`
${({ theme }) => css`
      font-size: 2rem;
      font-weight: ${theme.weight.bold};
      color: white;
      line-height: 2.5rem;
      
      ${respondTo.md`
        text-align: center;
      `}
      
       ${respondTo.lg`
          text-align: left;
          font-size: 2.625rem;
          line-height: 3rem;
      `}
    }
  `}
`

export const SSubHeading = styled.h2`
  ${({ theme }) => css`
    font-size: 1rem;
    line-height: 1.5rem;
    color: white;
    width: 280px;
    
    
    ${respondTo.lg`
      width: 100%;
      font-size: 1.25rem;
      line-height: 2rem;
      margin-top: ${theme.space.xl}px;
    `}
  `}
`

export const SWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    position: relative;    
    padding: ${theme.space.xl}px ${theme.space.md}px 0 ;

  ${respondTo.lg`
      flex-direction: row;
      justify-content: space-between;
   `}
 
    ${respondTo.xl`
      max-width: ${theme.breakpoints.xl}px;
      margin: 0 auto;
    `}
  `}
`
export const SBackgroundContent = styled(Wrapper)`
${({ theme }) => css`
  height: 100%;
  margin: 0 ${theme.space.md}px;
  z-index: 0;

  ${respondTo.xl`
    margin: 0 auto;
  `}
 `}
`

export const SPhoto = styled(PhotoWithFrame)`
  ${({ theme, specialists, content }) => css`
  
      height: 377px;
      width: 280px;
    
    ${specialists &&
      css`
        background-image: url(${specialistsPhoto});   
          
         &::before {
          background-color: ${theme.colors.lightBlue};
        } 
    `}
    
    ${content &&
      css`
        background-image: url(${contentPhoto});
        
        &::before {
          background-color: ${theme.colors.lightTurquoise};
        } 
    `}
   
    ${respondTo.lg`
      height: 369px;
      width: 273px;
    `}
  `}
`


export const SPhotoDescription = styled.p`
    color: white;
    font-size: 1rem;
    line-height: 1.5rem;
    width: 280px;
        
    ${respondTo.lg`
      width: 100%;
      font-size: 1.25rem;
      line-height: 2rem;
    `}
`

export const SPhotoDescriptionTitle = styled.p`
  ${({ theme }) => css`
    color: white;
    font-size: 1.5rem;
    font-weight: ${theme.weight.bold};
    line-height: 2rem;
    padding: ${theme.space.xl}px 0 ${theme.space.sm}px 0;
    margin-top: 377px;
    width: 280px;
    
    ${respondTo.lg`
      width: 100%;
      margin-top: 369px;
    `}
  `}
`

export const SColumnContent = styled.div`
  ${({ theme, specialistPhoto, contentPhoto, heading }) => css`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    
    margin-top: ${theme.space.xl}px;
    
    ${respondTo.lg`
      align-items: flex-start;
    `}
    
     ${specialistPhoto &&
        css`
          
           ${respondTo.lg`
              margin-top: -52px;
              margin-right: ${theme.space.md}px;
          `}
           
            ${respondTo.xl`
              margin-right: 0;
          `}
    `}
     
     ${contentPhoto &&
         css`
           
           ${respondTo.lg`
              margin-top: 147px;
          `}
     `}
     
     ${heading &&
        css`
          order: 2;
           ${respondTo.lg`
              width: 350px;
              order: -1;
          `}
           ${respondTo.xl`
               width: 401px;
           `}
    `}
  `}
`



