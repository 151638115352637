import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'
import background from '../../assets/images/whyAttendBackground.svg'

export const SContainer = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundBlack};
    padding: ${theme.space.xl + 8}px ${theme.space.lg}px;
    position: relative;
    color: white;
    overflow: hidden;

    ${respondTo.lg`
      background-image: url(${background});
      background-repeat: no-repeat;
      background-size: cover;
      padding-top: 84px;
      padding-bottom: 76px;
    `}

    .content {
      margin: 0 auto;
      max-width: 1029px;
    }

    .heading {
      color: white;
      font-weight: ${theme.weight.bold};
      font-size: 1.5rem;
      line-height: 1.875rem;
      margin-bottom: ${theme.space.md + 4}px;
      max-width: 60%;

      ${respondTo.lg`
        font-size: 2.25rem;
        line-height: 3.125rem;
        text-align: center;
        max-width: 100%;
      `}
    }

    .subheader {
      color: ${theme.colors.lightGray2};
      font-size: 1.125rem;
      line-height: 1.56rem;
      margin-bottom: ${theme.space.lg + 4}px;

      ${respondTo.lg`
        font-size: 1.375rem;
        line-height: 1.875rem;
        text-align: center;
        margin: 0 auto ${theme.space.xl * 2}px;
      `}
    }

    .button-wrapper {
      margin-top: ${theme.space.xl + 8}px;

      ${respondTo.lg`
        margin-top: ${theme.space.xl * 2}px;
        display: flex;
        justify-content: center;
      `}

      i {
        margin-left: ${theme.space.sm}px;
        padding-top: 4px;
      }
    }

    .position {
      position: absolute;
      top: ${theme.space.xl * 2}px;
      right: -58px;

      svg {
        height: 21px;
      }
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  `}
`
