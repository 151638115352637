import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SContainer = styled.section`
  ${({ theme }) => css`
    display: none;

    ${respondTo.lg`
      display: block;
      background-color: ${theme.colors.backgroundBlack};
      padding-bottom: 120px;
    `}

    .heading {
      font-size: 1.25rem;
      line-height: 2rem;
      font-weight: 500;
      color: ${theme.colors.white};
      text-align: center;
      margin: ${theme.space.md + 4}px auto;
    }

    hr {
      width: 80px;
      height: 1px;
      background-color: white;
      border: none;
      margin: 0 auto;
    }

    .socials-container {
      display: flex;
      justify-content: center;
      gap: ${theme.space.lg + 3}px;
    }
  `}
`
