import styled, { css } from "styled-components"
import respondTo from "../../theme/respondTo"

import partnersBackground from "../../assets/images/partners-desktop.svg"

export const SPartner = styled.aside`
  ${({ theme }) => css`
    width: 95%;
    height: 192px;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    font-size: 1.25rem;
    font-weight: ${theme.weight.bold};
    color: ${theme.colors.black};

    margin-top: ${theme.space.xl}px;
    margin-bottom: 4px;
    margin-left: auto;
    margin-right: auto;
    padding: ${theme.space.md}px;
    padding-right: ${theme.space.lg}px;

    background: none;
    appearance: none;
    box-shadow: none;
    border: none;

    &::before {
      content: "";
      width: calc(100% - 8px);
      height: 100%;

      position: absolute;

      background-color: white;

      top: 0;
      left: 0;

      z-index: 1;
      transition: all 200ms ease-in;
    }

    &::after {
      content: "";
      width: calc(100% - 8px);
      height: 100%;

      position: absolute;

      background-color: ${({ color }) => color};

      top: 8px;
      left: 8px;

      transition: all 200ms ease-in;
    }

    img {
      width: 100%;
      height: auto;
      max-height: 325px;
      z-index: 2;
    }

    ${respondTo.md`
      width: 658px;
      height: 392px;
    `}
  `}
`

export const SCategoryTitle = styled.h2`
  ${({ theme }) => css`
    font-size: 2rem;
    font-weight: ${theme.weight.bold};
    line-height: 1.25;
    color: white;
    text-align: center;

    margin-top: ${theme.space.xl}px;

    ${respondTo.lg`
      font-size: 2.625rem;
    `}
  `}
`
export const SPartnerCategory = styled.section`
  ${({ theme }) => css`
    margin-top: ${theme.space.xl}px;
  `}
`
export const SContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`
export const SContainer = styled.div`
  ${({ theme }) => css`
    padding-bottom: ${theme.space.xl}px;

    ${respondTo.lg`
      padding-bottom: ${theme.space.md}px;
    `}
  `}
`
