const styles = ({ error, isMultiSelect, withoutIndicator }) => ({
  dropdownIndicator: (provided) => {
    return {
      ...provided,
      display: withoutIndicator ? "none" : "block",
      padding: "6px 16px",
      cursor: "pointer",
      margin: "0",
      "> svg": {
        width: "18px",
        height: "18px",
      },
    };
  },
  indicatorSeparator: () => {
    return { display: "none" };
  },
  input: (provided) => {
    return {
      ...provided,
      color: "#FC86F6",
      fontSize: "16px",
      lineHeight: "1.5",
      padding: withoutIndicator ? "0" : "0 16px",
      borderRadius: 0,
      borderColor: error ? "#fe7687" : provided.borderColor,
    };
  },
  placeholder: (provided) => {
    return {
      ...provided,
      color: "#FC86F6",
      fontSize: '1.25rem',
      lineHeight: '2rem',
      padding: withoutIndicator ? "6px 16px 6px 0" : "6px 16px",
    };
  },
  menuList: (provided) => {
    return {
      ...provided,
      color: "#a5a5a5",
      backgroundColor: "#353535",
      borderRadius: 0,
    };
  },
  singleValue: (provided) => {
    return {
      ...provided,
      padding: "10px 16px",
      fontSize: "16px",
      lineHeight: "1.5",
    };
  },
  option: (provided) => {
      return {
          ...provided,
          wordBreak: "break-word",

        ":focus, :hover": {
           color:  "black",
          cursor: 'pointer'
         }
      }
  },
  multiValue: (provided) => {
    return {
      ...provided,
      padding: "4px 12px 4px 0",
      border: "solid 1px #abc2ff",
      borderRadius: 0,
      backgroundColor: "#eef3ff",
      flexDirection: "row-reverse",
      margin: "0 4px 4px 0",
      minHeight: "32px",
      wordBreak: "break-word",
    };
  },
  multiValueLabel: (provided) => {
    return {
      ...provided,
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      paddingLeft: "12px",
      whiteSpace: "normal",
      overflow: "visible",
    };
  },
  multiValueRemove: (provided) => {
    return {
      ...provided,
      padding: "0 8px",
      borderRight: "solid 1px #3d71ff",
      borderRadius: "none",
      ":hover": {
        backgroundColor: "#eef3ff",
        cursor: "pointer",
      },
    };
  },
  control: (provided, { isFocused }) => {
    return {
      ...provided,
      backgroundColor: '#353535',
      borderRadius: 0,
      borderWidth: "2px",
      borderColor: error ? "#fe7687" : "#a5a5a5",
      boxShadow: "none",
      height: isMultiSelect ? provided.height : "80px",
      minHeight: isMultiSelect ? "80px" : provided.minHeight,
      lineHeight: "12px",
      margin: "0",
      outline: "none",
      padding: "0",
      ":hover": {
        boxShadow: "none",
        borderColor:  "white",
      },
      ":focus": {
        boxShadow: "none",
      },
      ">div": {
        padding: isMultiSelect ? "6px 0 4px 4px" : "0",
      },
    };
  },
  container: (provided) => {
    return {
      ...provided,
      boxShadow: '0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14)',
      width: "100%",
    };
  },
});

export default styles;
