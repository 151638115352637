import { createReducer } from '@reduxjs/toolkit'
import {
  changeUserPanelTab,
  clearUser,
  getUser,
  getUserTickets,
  setIsAuthenticated,
  setUser,
  updateUserConsent,
} from './userActions'

export const UserPanel = {
  TICKETS: 'TICKETS',
  DATA: 'DATA',
  CONSENT: 'CONSENT',
}

const initialState = {
  user: null,
  isAuthenticated: false,
  triedAuthenticate: false,

  panel: {
    current: UserPanel.TICKETS,
    tickets: {
      loading: false,
      data: [],
    },
    consent: {
      loading: false,
    },
  },
}

export default createReducer(initialState, {
  [changeUserPanelTab.type]: (state, action) => ({
    ...state,
    panel: {
      ...state.panel,
      current: action.payload,
    },
  }),
  [setIsAuthenticated.type]: (state, action) => ({
    ...state,
    isAuthenticated: action.payload,
    triedAuthenticate: true,
  }),
  [clearUser.type]: (state) => ({
    ...state,
    user: null,
    isAuthenticated: false,
    triedAuthenticate: true,
  }),
  [getUser.fulfilled]: (state, action) => ({
    ...state,
    user: action.payload,
    isAuthenticated: true,
    triedAuthenticate: true,
  }),
  [getUser.rejected]: (state, action) => ({
    ...state,
    user: null,
    isAuthenticated: false,
    triedAuthenticate: true,
  }),
  [getUserTickets.pending]: (state, action) => ({
    ...state,
    panel: {
      ...state.panel,
      tickets: {
        ...state.panel.tickets,
        loading: true,
      },
    },
  }),
  [getUserTickets.fulfilled]: (state, action) => ({
    ...state,
    panel: {
      ...state.panel,
      tickets: {
        ...state.panel.tickets,
        loading: false,
        data: action.payload,
      },
    },
  }),
  [getUserTickets.rejected]: (state, action) => ({
    ...state,
    panel: {
      ...state.panel,
      tickets: {
        ...state.panel.tickets,
        loading: false,
      },
    },
  }),
  [updateUserConsent.pending]: (state, action) => ({
    ...state,
    panel: {
      ...state.panel,
      consent: {
        ...state.panel.consent,
        loading: true,
      },
    },
  }),
  [updateUserConsent.fulfilled]: (state, action) => ({
    ...state,
    user: {
      ...state.user,
      ...action.payload,
    },
    panel: {
      ...state.panel,
      consent: {
        ...state.panel.consent,
        loading: false,
      },
    },
  }),
  [updateUserConsent.rejected]: (state, action) => ({
    ...state,
    panel: {
      ...state.panel,
      consent: {
        ...state.panel.consent,
        loading: false,
      },
    },
  }),
})
