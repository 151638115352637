import { createGlobalStyle, css } from 'styled-components'

export default createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }
  
  html, body, input, button {
    font-family: sofia-pro, sans-serif;
    font-size: 16px;
    font-weight: 400;
  }

  .hidden {
    width: 1px;
    height: 1px;

    visibility: hidden;
    opacity: 0;

    touch-action: none;
    pointer-events: none;
  }
  
  .new-design-bg {
    background-color: #131313;
  }

  ${({ theme }) =>
    css`
      body {
        background-color: ${theme.colors.darkGray};

        &.no-scroll,
        &.gdpr-noscroll {
          overflow: hidden;
        }
      }

      a {
        &.disable-button-link {
          background-color: ${theme.colors.white};
          cursor: not-allowed;
          color: ${theme.colors.disabledLink};
          //pointer-events: none;

          &:hover,
          &:focus {
            background-color: ${theme.colors.white};
            color: ${theme.colors.disabledLink};
          }
        }
      }

      .language-picker {
        display: flex;
        cursor: pointer;

        a {
          font-size: 1rem;
          font-weight: ${theme.weight.bold};
          text-decoration: none;
          text-transform: uppercase;
          color: ${theme.colors.navigation.link.default};

          &:hover {
            color: ${theme.colors.navigation.link.hover};
          }
        }
        .lang-link {
          display: flex;
          align-items: center;

          .flag {
            display: flex;
            align-items: center;
            max-width: 16px;
            margin-right: ${theme.space.sm + 2}px;

            img {
              height: 12px;
              width: 100%;
            }
          }
          .icon {
            display: inline-flex;
            align-items: center;
            margin-left: 2px;
            color: ${theme.colors.lighterGray};
            width: 20px;
            height: 20px;
          }
        }
      }
    `}
`
