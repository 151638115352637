import * as React from "react"

function FacebookIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 14 24"
      {...props}
    >
      <path
        d="M31 12.667c0-.368-.286-.667-.636-.667h-3.182c-3.515 0-6.364 2.687-6.364 6v3.6h-3.182c-.35 0-.636.299-.636.667v3.466c0 .368.285.667.636.667h3.182v8.933c0 .368.285.667.637.667h3.818c.35 0 .636-.299.636-.667V26.4h3.333c.287 0 .537-.2.615-.488l.918-3.467c.113-.424-.192-.845-.612-.845h-4.254V18c0-.663.569-1.2 1.273-1.2h3.182c.35 0 .636-.299.636-.667v-3.466z"
        fill="currentColor"
        transform="translate(-17 -12)"
      />
    </svg>
  )
}

export default FacebookIcon
