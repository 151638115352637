import React from 'react'
import { useIntl } from 'react-intl'
import VisibilitySensor from 'react-visibility-sensor'
import { animated, Spring } from 'react-spring'
import { Link } from 'react-router-dom'

import { Button, IconDescriptionGrid, SlideTop } from '../../components'
import { SContainer } from './WhyAttendHome.styled'

import { AppRoute } from '../../const'
import useLocalizeRoute from '../../utils/useLocalizeRoute'
import { useBreakpoint } from '../../modules/breakpoint'
import LongGreenPattern from '../../assets/patterns/LongGreenPattern'
import preventClick from 'utils/preventClick'

const items = [
  {
    icon: 'ri-book-3-line',
    title: 'whyAttendKnowledge',
    text: 'whyAttendKnowledgeDescription',
  },
  {
    icon: 'ri-links-line',
    title: 'whyAttendConnect',
    text: 'whyAttendConnectDescription',
  },
  {
    icon: 'ri-seedling-line',
    title: 'whyAttendGrowth',
    text: 'whyAttendGrowthDescription',
  },
]
function WhyAttendHome() {
  const { formatMessage } = useIntl()
  const breakpoints = useBreakpoint()
  const { localizeRouteKey } = useLocalizeRoute()

  return (
    <VisibilitySensor
      minTopValue={15}
      offset={{ top: 300 }}
      scrollCheck
      scrollDelay={5}
      partialVisibility="top"
    >
      {({ isVisible }) => {
        return (
          <SContainer>
            {breakpoints.lg ? (
              <Spring from={{ opacity: 0 }} to={{ opacity: isVisible ? 0 : 1 }}>
                {({ opacity }) => {
                  return (
                    <div className="background">
                      <animated.div
                        style={{
                          opacity,
                          height: '100%',
                        }}
                      >
                        <div className="position">
                          <LongGreenPattern />
                        </div>
                      </animated.div>
                    </div>
                  )
                }}
              </Spring>
            ) : null}
            <div className="content">
              <SlideTop>
                <h2 className="heading">
                  {formatMessage({ id: 'whyAttendHeading' })}
                </h2>
              </SlideTop>
              <SlideTop>
                <p className="subheader">
                  {formatMessage(
                    { id: 'whyAttendDescription' },
                    { br: <br /> }
                  )}
                </p>
              </SlideTop>
              <IconDescriptionGrid {...{ items }} />
              <div className="button-wrapper">
                <Button
                  as={Link}
                  to="#"
                  className="disable-button-link"
                  onClick={preventClick}
                  // to={localizeRouteKey(AppRoute.BuyTicket)}
                >
                  {formatMessage({
                    id: 'buyTicket',
                  })}
                  <i className="ri-arrow-right-line" />
                </Button>
              </div>
            </div>
          </SContainer>
        )
      }}
    </VisibilitySensor>
  )
}

export default WhyAttendHome
