import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SContainer = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundBlack};
    overflow: hidden;
    position: relative;
    padding: ${theme.space.xl + 8}px 0;

    ${respondTo.lg`
      padding: 60px 0 100px;
    `}

    .companies {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
    }

    .heading {
      color: ${theme.colors.white};
      font-weight: ${theme.weight.bold};
      font-size: 1.5rem;
      line-height: 1.875rem;
      text-align: center;
      margin-bottom: ${theme.space.xl}px;

      ${respondTo.lg`
        font-size: 2.25rem;
        line-height: 3.125rem;
        max-width: 543px;
        margin: 0 auto;
      `}
    }

    .subheader {
      display: none;

      ${respondTo.lg`
        display: block; 
        color: ${theme.colors.lightGray2};
        font-size: 1.375rem;
        line-height: 1.875rem;
        text-align: center;
        margin: ${theme.space.md + 4}px auto 10px;
        max-width: 880px;
      `}
    }
  `}
`
