import { useIntl } from 'react-intl'
import { useTheme } from 'styled-components'

import {
  CustomUnderline,
  SlideTop,
  Wrapper,
  TopicPaths,
} from '../../components'
import {
  SContainer,
  SDescription,
  SHeader,
  SHeading,
} from './TracksOldDesign.styled'

function TracksOldDesign() {
  const theme = useTheme()
  const { formatMessage } = useIntl()

  return (
    <SContainer>
      <Wrapper>
        <SlideTop>
          <SHeader>
            <SHeading>
              {formatMessage(
                { id: 'topicPathsHeading' },
                {
                  span: (chunk) => (
                    <CustomUnderline color={theme.colors.lightTurquoise}>
                      {chunk}
                    </CustomUnderline>
                  ),
                }
              )}
            </SHeading>
            <SDescription>
              {formatMessage({ id: 'topicPathsDescription' })}
            </SDescription>
          </SHeader>
        </SlideTop>
        <TopicPaths />
      </Wrapper>
    </SContainer>
  )
}

export default TracksOldDesign
