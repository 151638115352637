import styled, { css } from 'styled-components'

export const SButton = styled.button`
  ${({ theme, wide, height, fontSize = '1.25rem', bordered, secondary }) => css`
    ${wide &&
    css`
      flex: 1;
    `}

    min-height: 48px;
    height: ${height};

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    font-family: sofia-pro, sans-serif;
    font-size: ${fontSize};
    font-weight: ${theme.weight.bold};
    text-decoration: none;
    color: ${theme.colors.black};

    margin-bottom: 4px;
    padding: 0 ${theme.space.xl}px 0 ${theme.space.xl}px;

    background-color: ${theme.colors.pink};
    border: 1px solid ${theme.colors.black};

    appearance: none;
    box-shadow: 4px 4px 0 white, 4px 4px 0 1px ${theme.colors.black};

    cursor: pointer;

    &:hover,
    &:focus {
      outline: 0;
      background-color: ${theme.colors.darkPink};
    }

    &:disabled {
      color: ${theme.colors.lightGray};
      cursor: not-allowed;
      background-color: white;
    }

    ${secondary &&
    css`
      color: ${theme.colors.pink};
      background-color: ${theme.colors.darkestGray};
      border-color: ${theme.colors.pink};
      box-shadow: 4px 4px 0 ${theme.colors.darkestGray},
        4px 4px 0 1px ${theme.colors.pink};
      min-height: 40px;
      height: ${height};

      &:hover,
      &:focus {
        outline: 0;
        background-color: ${theme.colors.darkGray};
      }
    `}

    ${bordered &&
    css`
      color: ${theme.colors.pink};
      background-color: transparent;
      border-color: ${theme.colors.pink};
      box-shadow: 4px 4px 0 ${theme.colors.darkestGray},
        4px 4px 0 1px ${theme.colors.pink};

      &:hover {
        color: white;
      }
    `}
  `}
`
