//Add strings
export default {
  description:
    '<p>Nasza strona korzysta z plików cookies 🍪</p> <p>Robimy to, aby zapewnić Ci komfort użytkownika i ulepszać nasze usługi,  a przede wszystkim by umożliwić działanie naszej strony internetowej.  Z plików tych korzystają również współpracujące z nami podmioty badawcze  i reklamowe, szczegółowe informacje znajdziesz w <a href="#">Polityce Plików Cookies</a>.</p><p>Klikając przycisk "Akceptuję" wyrażasz zgodę na zapisywanie informacji zawartej w plikach cookies. Zgodę możesz wycofać w każdym momencie w ustawieniach swojej przeglądarki.</p>',
  see_more: 'Zobacz więcej >',
  settings: 'Otwórz ustawienia cookies',
  settings_close: 'Zamknij ustawienia cookies',
  accept: 'Akceptuję',
  save: 'Zapisz ustawienia',
  cookie_essential_title: 'Niezbędne',
  cookie_essential_desc:
    'Te pliki cookie są niezbędne do świadczenia przez nas usług i korzystania z funkcji naszego serwisu, dlatego też nie można ich nie zaakceptować bez wpływu na funkcjonowanie naszej witryny. Możesz je ewentualnie zablokować lub usunąć, zmieniając ustawienia przeglądarki.',
  cookie_essential_error:
    'Nie można nie zaakceptować niezbędnych plików cookies.',
  cookie_marketing_title: 'Marketingowe',
  cookie_marketing_desc:
    'Te pliki cookie służą do dostosowywania komunikatów reklamowych do Ciebie i Twoich zainteresowań. Pełnią również funkcje, takie jak zapobieganie ciągłemu wyświetlaniu tej samej reklamy, zapewnienie prawidłowego wyświetlania reklam, a w niektórych przypadkach wybieranie reklam na podstawie Twoich zainteresowań. Ponadto używamy tych plików cookie i pikseli, aby wykluczyć Cię z reklam i pomóc w lepszym zdefiniowaniu grup użytkowników.',
  cookie_analytics_title: 'Analityczne',
  cookie_analytics_desc:
    'Te pliki cookie umożliwiają zbieranie informacji zbiorczo, o sposobie korzystania z naszej witryny internetowej lub pomóc nam je dopasować do użytkowników.'
}
