import * as React from "react"

function TwitterIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 21" {...props}>
      <path
        d="M21.263 3.544A5.374 5.374 0 0023.325.8c-.713.457-2.287 1.105-3.075 1.105A5.125 5.125 0 0016.65.38a4.904 4.904 0 00-3.502 1.473 5.072 5.072 0 00-1.448 3.56c-.002.372.036.743.113 1.106C8.1 6.404 3.75 4.536 1.313 1.294.042 3.567.695 6.448 2.813 7.93c-.563.077-1.65-.038-2.138-.533-.038 1.754.788 4.08 3.825 4.919-.6.305-1.613.228-2.063.152.15 1.487 2.213 3.432 4.426 3.432-.788.915-3.488 2.631-6.863 2.098a14.749 14.749 0 007.762 2.211 13.69 13.69 0 0010.054-4.322A14.169 14.169 0 0021.638 5.49V5.3A8.66 8.66 0 0024 2.742a8.554 8.554 0 01-2.737.801z"
        fill="currentColor"
      />
    </svg>
  )
}

export default TwitterIcon
