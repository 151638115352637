import React, { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Link, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import {
  Tabs,
  TicketProcessBox,
  TicketSummary,
  UserConsent,
  UserTickets,
  UserData,
} from 'components'
import { AppRoute } from 'const'
import { useDispatch, useSelector } from 'react-redux'
import { UserPanel } from 'state/reducers/user/userReducer'
import {
  changeUserPanelTab,
  getUserTickets,
} from 'state/reducers/user/userActions'
import useLocalizeRoute from 'utils/useLocalizeRoute'

function User() {
  const dispatch = useDispatch()
  const dictionary = useSelector(({ app }) => app.dictionary.data)
  const { user, panel } = useSelector(({ user }) => user)

  const { formatMessage, locale } = useIntl()

  const {
    employmentPosition,
    tracks,
    experienceLevel,
    technologies,
    languages,
  } = useMemo(() => {
    const isPolish = locale === 'pl'

    const employmentPosition = dictionary.employmentPosition.map(
      (position) => ({
        id: position.id,
        label: isPolish ? position.name : position?.name_en ?? position.name,
        value: isPolish ? position.name : position?.name_en ?? position.name,
      })
    )
    const tracks = dictionary.tracks.map((track) => ({
      label: isPolish ? track.name : track?.name_en ?? track.name,
      value: isPolish ? track.name : track?.name_en ?? track.name,
    }))
    const experienceLevel = dictionary.experienceLevel.map((experience) => ({
      label: isPolish
        ? experience.name
        : experience?.name_en ?? experience.name,
      value: isPolish
        ? experience.name
        : experience?.name_en ?? experience.name,
    }))
    const technologies = dictionary.tags.map((technology) => ({
      label: isPolish
        ? technology.name
        : technology?.name_en ?? technology.name,
      value: isPolish
        ? technology.name
        : technology?.name_en ?? technology.name,
    }))
    const languages = dictionary.languages.map((lang) => ({
      label: lang.name,
      value: lang.name,
    }))

    return {
      employmentPosition,
      tracks,
      experienceLevel,
      technologies,
      languages,
    }
  }, [dictionary, locale])

  const { localizeRouteKey } = useLocalizeRoute()

  const durignRequest = panel.tickets.loading || panel.consent.loading

  const handleChangeTab = (key) => () => {
    if (durignRequest) return
    dispatch(changeUserPanelTab(key))
  }

  useEffect(() => {
    dispatch(getUserTickets())
  }, [])

  useEffect(() => {
    if (window?.callpage) {
      window.callpage('api.button.hide')
    }
  }, [])

  const renderPanel = () => {
    switch (panel.current) {
      case UserPanel.DATA:
        return (
          <UserData
            {...{
              user,
              employmentPosition,
              tracks,
              experienceLevel,
              technologies,
              languages,
            }}
          />
        )
      case UserPanel.CONSENT:
        return <UserConsent {...{ user, consent: panel.consent }} />
      case UserPanel.TICKETS:
      default:
        return <UserTickets {...{ tickets: panel.tickets }} />
    }
  }

  if (!user) {
    return <Redirect to={localizeRouteKey(AppRoute.SignIn)} />
  }

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'titleUser' })}</title>
      </Helmet>
      <TicketProcessBox margin>
        <p className="heading">{formatMessage({ id: 'myAccount' })}</p>
        <p className="sign-in">
          <span>{user.email}</span>
        </p>
        <p className="logout">
          <Link to={localizeRouteKey(AppRoute.Logout)}>
            {formatMessage({ id: 'general.logout' })}
          </Link>
        </p>

        <Tabs
          {...{
            selected: panel.current,
            onChange: handleChangeTab,
            tabs: [
              { key: UserPanel.TICKETS, text: 'user.tabTickets' },
              { key: UserPanel.DATA, text: 'user.tabData' },
              { key: UserPanel.CONSENT, text: 'user.tabConsent' },
            ],
          }}
          className="user-profile-tabs"
        />

        {renderPanel()}
      </TicketProcessBox>
    </>
  )
}

export { User }
