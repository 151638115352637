import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import Sticky from 'react-stickynode'
import { useTheme } from 'styled-components'
import { useIntl } from 'react-intl'

import { useBreakpoint } from 'modules/breakpoint'

import client from 'api/client'
import parseFiltration from 'utils/parseFiltration'

import {
  ButtonLink,
  Filtration,
  SharpShadowText,
  SpeakerTile,
  Wrapper,
  Loader,
  ListEmptyState,
  PromoCodeBar,
} from '../../components'
import { SSpeakersList } from './SpeakersList.styled'
import getFiltersFromQuery from 'utils/getFiltersFromQuery'
import 'simplebar/dist/simplebar.css'

import { FOOTER_DOM_ID } from '../Footer/Footer'
import { HEADER_DOM_ID } from '../../components/Header/Header'

import { breakpoints as staticBreakpoints } from '../../theme'
import { AppRoute } from 'const'
import useLocalizeRoute from 'utils/useLocalizeRoute'
import { useSelector } from 'react-redux'
import { SpeakersBanner } from '../../components'
import { useLocation } from 'react-router-dom'

function SpeakersList() {
  const theme = useTheme()
  const filtrationRef = useRef()
  const { formatMessage } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()
  const location = useLocation()
  const currentYear = new Date().getFullYear()

  const { data: dictionary, loading: filtrationLoading } = useSelector(
    ({ app }) => app.dictionary
  )

  const filtrationBuildData = useMemo(() => {
    if (!dictionary) return null

    return {
      cities: dictionary.cities,
      tags: dictionary.tags,
    }
  }, [dictionary])

  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [filtration, setFiltration] = useState((prevState) => {
    return prevState || parseFiltration(filtrationBuildData)
  })
  const breakpoints = useBreakpoint()

  const calculateFiltrationContainer = () => {
    if (typeof window === 'undefined') return
    if (!filtrationRef?.current) return

    const windowHeight = window.innerHeight
    const windowWidth = window.innerWidth
    const windowScrollY = window.scrollY

    if (windowWidth <= staticBreakpoints.lg) {
      filtrationRef.current.style.height = 'initial'
      return
    }

    const filtrationTopOffset =
      filtrationRef.current.getBoundingClientRect().top

    const footerYPosition = document
      .getElementById(FOOTER_DOM_ID)
      .getBoundingClientRect().top
    const isFooterVisible = windowScrollY + windowHeight >= footerYPosition
    const footerVisibileHeight = isFooterVisible
      ? windowHeight - footerYPosition
      : 0

    const filtrationHeight =
      windowHeight - footerVisibileHeight - filtrationTopOffset

    filtrationRef.current.style.height = `${filtrationHeight}px`
  }

  const handleFiltration = (newFiltration) => {
    setFiltration(newFiltration)
    if (!breakpoints.lg) {
      fetchData({ newFiltration })
    }
  }

  const fetchData = async ({ newFiltration }) => {
    setLoading(true)
    const filtersSource = newFiltration || filtration
    const filters = filtersSource?.reduce((prev, acc) => {
      const tempCategory = acc.category
      const temp = acc.fields
        .filter((field) => field.value)
        .map((field) => `filters[${tempCategory}][]=${field.id}`)
        .join('&')

      if (!temp.length) {
        return prev
      }

      return `${prev}&${temp}`
    }, '')

    const response = await client({
      endpoint: `api/speaker_list/all?is_listed=1&per_page=1200&page=1${filters}`,
    })

    const { data } = await response.json()
    if (!data || (data && !Array.isArray(data))) return

    setItems(data)
    setLoading(false)

    if (newFiltration) {
      window.scrollTo({ top: 0 })
    }
  }

  const handleClear = () => {
    const clearFiltration = filtration.reduce((acc, current) => {
      return [
        ...acc,
        {
          ...current,
          fields: current.fields.map((field) => ({ ...field, value: false })),
        },
      ]
    }, [])
    setFiltration(clearFiltration)
    fetchData({ newFiltration: clearFiltration })
  }

  const handleSubmit = async () => {
    await fetchData({})
  }

  useEffect(() => {
    calculateFiltrationContainer()

    window.addEventListener('scroll', calculateFiltrationContainer)

    return () => {
      window.removeEventListener('scroll', calculateFiltrationContainer)
    }
  }, [])

  useEffect(() => {
    location.search.length &&
      handleFiltration(getFiltersFromQuery(location.search, filtration))
  }, [location.search])

  useEffect(() => {
    const currentYearFiltration = filtration.map((filter) => {
      if (filter.category !== 'cities') return filter

      return {
        ...filter,
        fields: filter.fields.map((field) =>
          field.name.includes(currentYear.toString())
            ? {
                ...field,
                value: true,
              }
            : field
        ),
      }
    })
    if (!location.search.length) {
      setFiltration(currentYearFiltration)
      fetchData({ newFiltration: currentYearFiltration })
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      calculateFiltrationContainer()
    }, 1000)
  }, [items])

  return (
    <SSpeakersList>
      <Wrapper>
        <SharpShadowText as="h1" className="heading" color={theme.colors.pink}>
          {formatMessage({ id: 'speakers' })}
        </SharpShadowText>
        <div className="content">
          <div className="sidebar">
            <Sticky enabled={!breakpoints.lg} top={72}>
              <div className="filtration" ref={filtrationRef}>
                <Filtration
                  {...{
                    filtration,
                    loading: filtrationLoading,
                    onChange: handleFiltration,
                    onClear: handleClear,
                    onSubmit: handleSubmit,
                  }}
                />
              </div>
            </Sticky>
          </div>
          {items.length ? (
            <div>
              <div className="banner-wrapper">
                <SpeakersBanner />
              </div>
              <div className="listing">
                {items.map((item) => (
                  <SpeakerTile key={item.speaker_id} {...{ item }} />
                ))}
              </div>
            </div>
          ) : !loading ? (
            <ListEmptyState
              speakers
              text={formatMessage({ id: 'speakers.empty' })}
              buttonText={formatMessage({ id: 'speakers.emptyButton' })}
              onClick={handleClear}
            />
          ) : null}
        </div>
      </Wrapper>
    </SSpeakersList>
  )
}

export default SpeakersList
