import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SContainer = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundBlack};

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${theme.space.xl + 8}px ${theme.space.lg}px;

    ${respondTo.lg`
      padding: 100px ${theme.space.lg}px 60px;
    `}

    .content-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 0 auto;

      ${respondTo.lg`
        flex-direction: row-reverse;
        max-width: 1030px;
        justify-content: center;
        align-items: flex-end;
      `}
    }

    .desktop-wrapper {
      width: 100%;

      ${respondTo.lg`
        width: auto;
      `};
    }

    .heading {
      font-size: 1.5rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.875rem;
      color: ${theme.colors.white};
      margin-bottom: ${theme.space.xl + 8}px;
      text-align: center;
      max-width: 200px;

      ${respondTo.lg`
        max-width: 300px;
        text-align: left;
        font-size: 2.25rem;
        line-height: 3.125rem;
      `};
    }

    img {
      width: 100%;
      max-width: 312px;
      margin: 0 auto ${theme.space.xl + 8}px;

      ${respondTo.lg`
        max-width: 505px;
        margin: 0 auto 0 122px;
      `}
    }

    button {
      display: flex;
      align-items: center;
      width: 100%;

      ${respondTo.lg`
        width: auto;
        margin-top: 60px;
      `}

      i {
        margin-left: ${theme.space.sm}px;
        padding-top: 4px;
      }
    }
  `}
`
