import { useIntl } from 'react-intl'

import { CustomUnderline, SlideTop } from '..'
import { SHeader, SHeading, SDescription } from './SectionHeader.styled'

function SectionHeader({
  heading,
  headingUnderlineColor,
  description,
  withoutAnimation,
  ...res
}) {
  const { formatMessage } = useIntl()

  if (withoutAnimation) {
    return (
      <SHeader {...res}>
        <SHeading>
          {formatMessage(
            { id: heading },
            {
              span: (chunk) => (
                <CustomUnderline color={headingUnderlineColor}>
                  {chunk}
                  {'\u00a0'}
                </CustomUnderline>
              ),
            }
          )}
        </SHeading>
        {description ? (
          <SDescription>{formatMessage({ id: description })}</SDescription>
        ) : null}
      </SHeader>
    )
  }

  return (
    <SHeader {...res}>
      <SlideTop>
        <SHeading>
          {formatMessage(
            { id: heading },
            {
              span: (chunk) => (
                <CustomUnderline color={headingUnderlineColor}>
                  {chunk}
                  {'\u00a0'}
                </CustomUnderline>
              ),
            }
          )}
        </SHeading>
      </SlideTop>
      {description ? (
        <SlideTop>
          <SDescription>{formatMessage({ id: description })}</SDescription>
        </SlideTop>
      ) : null}
    </SHeader>
  )
}

export default SectionHeader
