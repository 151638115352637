import React from 'react'
import { IntlProvider } from 'react-intl'
import { Route, Redirect } from 'react-router-dom'
import { AppLanguage } from 'const'

import { LanguageStrings } from '../localizations'

interface Props {
  RouterComponent: React.ComponentClass<any>
  languages: { [k: number]: string }
  appStrings: { [prop: string]: LanguageStrings }
  defaultLanguage?: AppLanguage
}

export const LocalizedRouter: React.FC<Props> = ({
  children,
  RouterComponent,
  appStrings,
  defaultLanguage,
}) => (
  <RouterComponent>
    <Route path="/:lang([a-zA-Z]{2})">
      {({ match, location }) => {
        const params = match ? match.params : {}
        const { lang = defaultLanguage || AppLanguage.English } = params

        const { pathname } = location
        if (!pathname.includes(`/${lang}/`)) {
          return <Redirect to={`/${lang}/`} />
        }

        window.scrollTo({ top: 0 })

        return (
          <IntlProvider
            locale={lang}
            timeZone="Europe/Warsaw"
            messages={appStrings[lang]}
          >
            {children}
          </IntlProvider>
        )
      }}
    </Route>
  </RouterComponent>
)
