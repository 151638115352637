import * as React from 'react'

function Linkedin(props) {
  return (
    <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <circle fill="#FFF" cx="12.5" cy="12.5" r="12.5" />
        <g transform="translate(4 4)" fill="#292929" fillRule="nonzero">
          <path d="M11.201 5.413a5 5 0 00-5.016 4.99v5.042a.772.772 0 00.772.772h1.8a.772.772 0 00.772-.772v-5.041a1.663 1.663 0 011.843-1.655 1.715 1.715 0 011.501 1.715v4.981c0 .427.346.772.772.772h1.8a.772.772 0 00.772-.772v-5.041a4.999 4.999 0 00-5.016-4.99z" />
          <rect x=".783" y="6.185" width="3.859" height="10.032" rx=".9" />
          <circle cx="2.712" cy="2.712" r="1.929" />
        </g>
      </g>
    </svg>
  )
}

export default Linkedin
