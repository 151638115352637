import React from 'react'

import { SListEmptyState } from './ListEmptyState.styled'
import EmptyAgendaListIcon from 'assets/icons/EmptyAgendaListIcon'
import EmptySpeakersListIcon from 'assets/icons/EmptySpeakersListIcon'
import EmptyCompaniesListIcon from 'assets/icons/EmptyCompaniesListIcon'
import EmptyOffersListIcon from 'assets/icons/EmptyOffersListIcon'
import Button from 'components/Button/Button'

function ListEmptyState({
  agenda,
  speakers,
  companies,
  offers,
  text,
  buttonText,
  onClick,
}) {
  return (
    <SListEmptyState>
      {agenda && <EmptyAgendaListIcon />}
      {speakers && <EmptySpeakersListIcon />}
      {companies && <EmptyCompaniesListIcon style={{ marginTop: '32px' }} />}
      {offers && <EmptyOffersListIcon style={{ marginTop: '32px' }} />}
      <p className="text">{text}</p>
      {onClick && buttonText && <Button onClick={onClick}>{buttonText}</Button>}
    </SListEmptyState>
  )
}

export default ListEmptyState
