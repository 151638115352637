import styled, { css } from "styled-components";

export const SFormSuccess = styled.div`
  ${({ theme }) => css`
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    font-size: 0.875rem;
    line-height: 1.14;
    text-align: center;
    color: white;

    justify-content: center;

    padding: 0 ${theme.space.xl}px;

    .expect {
      margin-top: ${theme.space.xxxl + theme.space.md}px;
    }

    div:not(.background) > svg {
      display: block;

      margin: 0 auto;
      margin-top: ${theme.space.md}px;
    }

    p {
      margin-top: ${theme.space.lg}px;
    }

    .plane-icon {
      width: 70px;
      height: 47px;
    }

    .play-icon {
      width: 88px;
      height: 88px;
    }

    a {
      font-weight: ${theme.weight.bold};
      text-decoration: underline;
      color: ${theme.colors.pink};

      display: block;

      margin-top: ${theme.space.xl}px;
    }

    .background {
      display: flex;
      justify-content: space-between;

      margin-top: ${theme.space.xxxl + theme.space.md}px;
      margin-left: ${-theme.space.xl}px;
      margin-right: ${-theme.space.xl}px;
    }
  `}
`
