import React from 'react'
import { useIntl } from 'react-intl'

import { STabs, STab } from './Tabs.styled'

function Tabs({ selected, tabs, onChange, ...res }) {
  const { formatMessage } = useIntl()
  return (
    <STabs {...res}>
      {tabs.map((tab) => (
        <STab
          key={tab.key}
          active={selected === tab.key}
          className="tab"
          type="button"
          onClick={onChange(tab.key)}
        >
          {formatMessage({ id: tab.text })}
        </STab>
      ))}
    </STabs>
  )
}

export default Tabs
