import styled, { css } from "styled-components"
import respondTo from "../../theme/respondTo"

import mobileBackground from "../../assets/images/numbers-mobile.jpg"
import tabletBackground from "../../assets/images/numbers-tablet.jpg"

export const SDataDescription = styled.p`
  ${({ theme }) => css`
    font-size: 1rem;
    line-height: 1.6;
    color: white;
    margin-top: ${theme.space.xs}px;
    ${respondTo.lg`
      font-size: 1.25rem;
      margin-top: ${theme.space.md}px;
    `}
  `}
`
export const SDataTitle = styled.h3`
  ${({ theme }) => css`
    font-size: 1.25rem;
    font-weight: ${theme.weight.bold};
    line-height: 1.6;
    color: white;
    ${respondTo.lg`
      font-size: 2.625rem;
      line-height: 1.14;
    `}
  `}
`
export const SData = styled.aside`
  ${({ theme }) => css`
    text-align: center;
    margin-top: ${theme.space.md}px;
    ${respondTo.lg`
      text-align: left;
      margin-top: 0;
    `}
  `}
`
export const SDataGrid = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.space.xl}px;
    ${respondTo.lg`
      display: grid;
      grid-template-columns: minmax(280px, 561px) 1fr;
      grid-template-rows: repeat(1fr, 3);
      gap: ${theme.space.xl}px ${theme.space.md}px;
      grid-template-areas:
        ". ."
        ". ."
        ". action";
    
      margin-top: ${theme.space.xxxl}px;
    `}
    .action {
      grid-area: action;
      display: flex;
      justify-content: center;
      margin-top: ${theme.space.xl}px;
      ${respondTo.lg`
        justify-content: flex-start;
      `}
    }
  `}
`

export const SDescription = styled.p`
  ${({ theme }) => css`
    font-size: 1rem;
    line-height: 1.5;
    color: white;
    margin: ${theme.space.md}px 0 0;
    ${respondTo.lg`
      font-size: 1.25rem;
      line-height: 1.6;
    `}
  `}
`
export const SHeading = styled.h2`
  ${({ theme }) => css`
    font-size: 2.625rem;
    font-weight: ${theme.weight.bold};
    line-height: 1.26;
    color: white;
  `}
`
export const SHeader = styled.div`
  ${({ theme }) => css`
    ${respondTo.lg`
      width: 85%;
      margin: 0 auto;
      text-align: center;
    `}
  `}
`
export const SNumbers = styled.section`
  ${({ theme }) => css`
    padding: ${theme.space.xxxl}px 0 ${theme.space.xxxl + theme.space.xl}px 0;
  `}
`
export const SStaticBackground = styled.div`
  background-image: url(${mobileBackground});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  ${respondTo.md`
    background-image: url(${tabletBackground});
  `}
`
