import React, { useEffect } from "react"

function ListOfEntitiesEmployer() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.location.href = "/wykaz-podmiotow-pracodawca.pdf"
    }
  }, []);

  return null;
}

export { ListOfEntitiesEmployer }
