import styled, { css } from 'styled-components'

import respondTo from 'theme/respondTo'

export const SContainer = styled.div`
  width: 100%;
  height: 414px;

  position: relative;
  overflow: hidden;

  margin-top: ${({ theme }) => theme.space.xl}px;

  .background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -49%) rotateZ(95deg);
  }

  .left-container {
    height: 100%;
    position: relative;

    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;

    padding: ${({ theme }) => theme.space.md}px;

    font-size: 3.125rem;
    font-weight: ${({ theme }) => theme.weight.bold};

    z-index: 1;

    svg {
      max-width: 78px;
      width: auto;
    }

    p {
      color: white;
      &:first-of-type {
        color: ${({ theme }) => theme.colors.pink};
        text-transform: uppercase;
      }
    }

    ${({ theme, swapedBannerSlang }) =>
      swapedBannerSlang &&
      css`
        p {
          color: ${theme.colors.pink};
          text-transform: uppercase;
          &:first-of-type {
            color: white;
            text-transform: capitalize;
          }
        }
      `}
  }

  .right-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    position: absolute;
    bottom: 16px;
    right: 16px;

    z-index: 1;

    svg {
      margin-bottom: 32px;
    }

    p {
      font-size: 1.875rem;
      font-weight: ${({ theme }) => theme.weight.bold};
      text-align: right;
      color: white;

      &:last-of-type {
        font-size: 0.86rem;
        margin-top: ${({ theme }) => theme.space.md}px;
      }

      span {
        font-size: 3rem;
        color: ${({ theme }) => theme.colors.pink};

        display: block;
        & + span {
          font-size: 4.625rem;
        }
      }
    }
  }

  ${respondTo.md`
    height: 182px;
    
    display: flex;
    justify-content: space-between;
    
    .left-container {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      svg {
        max-width: 99px;
        height: 100%;
        width: auto;

        margin-right: 21px;
        margin-left: 30px;
      }
    }

    .right-container {
      display: flex;
      flex-direction: column;
      justify-content: center;

      position: relative;
      
      padding-right: 16px;

      bottom: 0;
      right: 0;

      svg {
        margin-bottom: 16px;
      }

      p {
        font-size: 1.875rem;

        &:last-of-type {
          font-size: 1rem;
        }

        span {
          display: inline;
          font-size: 2rem;
          & + span {
            font-size: 3rem;
          }
        }
      }
    }
  `}

  ${respondTo.xl`
    .right-container {
      p {
        span {
          font-size: 3rem;
        }
      }
    }
  `}
`
