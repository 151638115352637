import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SContainer = styled.div`
  ${({ theme }) => css`
    display: grid;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: ${theme.colors.lightGray2};

    ${respondTo.lg`
      grid-template-columns: minmax(272px, 290px) 1fr;
      grid-gap: ${theme.space.sm}px 100px;
      
      font-size: 1rem;
      line-height: 1.375rem;
    `}
  `}
`
