import { useIntl } from 'react-intl'

import {
  SContainer,
  STimePlace,
  SLocation,
} from './TimeLocationInformation.styled'
import { useBreakpoint } from '../../modules/breakpoint'

interface ITimeLocationInformation {
  date: Date | string
  place?: string
  location: string
}

function TimeLocationInformation(props: ITimeLocationInformation) {
  const { date, place, location } = props
  const { formatDate, locale } = useIntl()
  const breakpoint = useBreakpoint()
  const isMobile = breakpoint.lg

  const formattedDate =
    date instanceof Date
      ? formatDate(date, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      : date

  const renderContent = () => {
    return (
      <>
        <STimePlace {...{ locale }}>
          <span>{formattedDate}</span>
          <span className="divider-vertical" />
          <span>{place}</span>
        </STimePlace>
        <SLocation>
          <i className="ri-map-pin-fill" />
          <span>{location}</span>
        </SLocation>
      </>
    )
  }

  const renderMobile = () => {
    return <SContainer>{renderContent()}</SContainer>
  }

  return isMobile ? renderMobile() : renderContent()
}

export default TimeLocationInformation
