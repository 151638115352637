import React from 'react'
import { SWrapper } from './Burger.styled'

const Burger = (props) => {
  return (
    <SWrapper type="button" {...props}>
      <span />
      <span />
      <span />
      <span />
    </SWrapper>
  )
}

export default Burger
