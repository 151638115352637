import React, { useEffect, useRef, useMemo, useState } from 'react'
import Sticky from 'react-stickynode'
import { useTheme } from 'styled-components'
import { useIntl } from 'react-intl'
import { useBreakpoint } from 'modules/breakpoint'
import { useSelector } from 'react-redux'

import client from 'api/client'

import {
  Filtration,
  SharpShadowText,
  OfferTile,
  Wrapper,
  ListEmptyState
} from '../../components'

import SearchBar from '../../components/SearchBar/SearchBar'

import { SOffersList } from './OffersList.styled'
import 'simplebar/dist/simplebar.css'

import { FOOTER_DOM_ID } from '../Footer/Footer'

import { breakpoints as staticBreakpoints } from '../../theme'
import parseFiltration from '../../utils/parseFiltration'
import { LoaderComponent } from '../../components/Loader/Loader.styled'

function OffersList() {
  const theme = useTheme()
  const filtrationRef = useRef()
  const { formatMessage } = useIntl()

  const { data: dictionary, loading: filtrationLoading } = useSelector(
    ({ app }) => app.dictionary
  )
  const filtrationBuildData = useMemo(() => {
    if (!dictionary) return null
    return {
      jobAreas: dictionary.jobAreas,
      salaries: dictionary.salaries,
      tags: dictionary.tags,
    }
  }, [dictionary])

  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [filtration, setFiltration] = useState(
    filtrationBuildData ? parseFiltration(filtrationBuildData) : null
  )
  const [searchValues, setSearchValues] = useState([])

  const breakpoints = useBreakpoint()

  const calculateFiltrationContainer = () => {
    if (typeof window === 'undefined' || !filtrationRef?.current) return
    const windowHeight = window.innerHeight
    const windowWidth = window.innerWidth
    const windowScrollY = window.scrollY

    if (windowWidth <= staticBreakpoints.lg) {
      filtrationRef.current.style.height = 'initial'
      return
    }

    const filtrationTopOffset =
      filtrationRef.current.getBoundingClientRect().top

    const footerYPosition = document
      .getElementById(FOOTER_DOM_ID)
      .getBoundingClientRect().top
    const isFooterVisible = windowScrollY + windowHeight >= footerYPosition
    const footerVisibileHeight = isFooterVisible
      ? windowHeight - footerYPosition
      : 0

    const filtrationHeight =
      windowHeight - footerVisibileHeight - filtrationTopOffset

    filtrationRef.current.style.height = `${filtrationHeight}px`
  }

  const fetchData = async ({ newFiltration }) => {
    setLoading(true)
    const filtersSource = newFiltration || filtration

    const searchQuery = searchValues
      .map((option) => `filters[${option.type || 'keyword'}][]=${option.value}`)
      .join('&')

    const filters = filtersSource?.reduce((prev, acc) => {
      const tempCategory = acc.category
      const temp = acc.fields
        .filter((field) => field.value)
        .map((field) => `filters[${tempCategory}][]=${field.id}`)
        .join('&')

      if (!temp.length) {
        return prev
      }

      return `${prev}&${temp}`
    }, '')

    const response = await client({
      endpoint: `api/job_offers/all?current_page=1&per_page=1000${filters}&${searchQuery}`,
    })
    const { data } = await response.json()
    if (!data || (data && !Array.isArray(data))) return

    setItems(data)
    setLoading(false)
  }

  const handleClear = () => {
    const clearFiltration = filtration.reduce((acc, current) => {
      return [
        ...acc,
        {
          ...current,
          fields: current.fields.map((field) => ({ ...field, value: false })),
        },
      ]
    }, [])
    setFiltration(clearFiltration)
    setSearchValues([])
    fetchData({ newFiltration: clearFiltration })
  }

  const handleSubmit = async () => {
    await fetchData({})
  }

  const handleSearchChange = (value) => {
    setSearchValues(value)
  }

  useEffect(() => {
    fetchData({})
  }, [searchValues])

  const handleFiltration = (newFiltration) => {
    setFiltration(newFiltration)
    if (!breakpoints.lg) {
      fetchData({ newFiltration })
    }
  }

  useEffect(() => {
    calculateFiltrationContainer()

    fetchData({})

    window.addEventListener('scroll', calculateFiltrationContainer)

    return () => {
      window.removeEventListener('scroll', calculateFiltrationContainer)
    }
  }, [])

  useEffect(() => {
    if (!filtration && filtrationBuildData) {
      setFiltration(parseFiltration(filtrationBuildData))
    }
  }, [filtrationBuildData])

  useEffect(() => {
    setTimeout(() => {
      calculateFiltrationContainer()
    }, 1000)
  }, [items])

  const getSearchOptions = async (inputValue, callback) => {
    const endpoint = `api/job_offers/suggestions?query=${encodeURIComponent(
      inputValue
    )}`

    try {
      const response = await client({
        endpoint,
      })

      if (!response.ok) return

      const { data } = await response.json()
      const options = data.map((option) => {
        if (option.id) {
          return { label: option.name, value: option.id, type: option.type }
        }

        return { label: option.name, value: option.name, type: option.type }
      })
      callback(options)
    } catch (error) {
      console.log(`[getSearchOptions] error: `, error)
    }
  }

  return (
    <>
      <SOffersList>
        <Wrapper>
          <SharpShadowText
            as="h1"
            className="heading"
            color={theme.colors.pink}
          >
            {formatMessage({ id: 'jobOffers' })}
          </SharpShadowText>
          <div className="content">
            {!breakpoints.lg && (
              <>
                <div className="sidebar">
                  <Sticky enabled={!breakpoints.lg} top={72}>
                    <div className="filtration" ref={filtrationRef}>
                      <Filtration
                        {...{
                          filtration,
                          loading: filtrationLoading,
                          onChange: handleFiltration,
                          onClear: handleClear,
                          onSubmit: handleSubmit,
                        }}
                      />
                    </div>
                  </Sticky>
                </div>
              </>
            )}
            <div className="right-side">
              <SearchBar
                creatable
                offersPage
                customOptionsGetter={getSearchOptions}
                {...{
                  handleSearchChange,
                  searchValues,
                }}
              />
              {breakpoints.lg && (
                <div className="sidebar">
                  <Sticky enabled={!breakpoints.lg} top={72}>
                    <div className="filtration" ref={filtrationRef}>
                      <Filtration
                        {...{
                          filtration,
                          loading: filtrationLoading,
                          onChange: handleFiltration,
                          onClear: handleClear,
                          onSubmit: handleSubmit,
                        }}
                      />
                    </div>
                  </Sticky>
                </div>
              )}
              {loading ? <LoaderComponent className="offers-loader" /> : null}
              {items.length ? (
                <div className="listing">
                  {items.map((item) => (
                    <OfferTile
                      key={`offer-tile-${item.offer_id}`}
                      {...{ item }}
                    />
                  ))}
                </div>
              ) : !loading ? (
                <ListEmptyState
                  offers
                  text={formatMessage({ id: 'offers.empty' })}
                  buttonText={formatMessage({ id: 'offers.emptyButton' })}
                  onClick={handleClear}
                />
              ) : null}
            </div>
          </div>
        </Wrapper>
      </SOffersList>
    </>
  )
}

export default OffersList
