import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SCompanies = styled.div`
  ${({ theme }) => css`
        position: relative;
        padding: 0 80px;

        ${respondTo.lg`
           &::after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: linear-gradient(
                90deg,
                #121212 0%,
                transparent 5%,
                transparent 95%,
                #121212 100%
              ), linear-gradient(
                #121212 0%,
                transparent 30%,
                transparent 70%,
                #121212 100%
              );
           }
         `}
      }

      .companies-grid {
        position: relative;
        display: grid;
        grid-template-columns: repeat(4, 100px);

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: calc(100% + 1px);
          background: linear-gradient(
            90deg,
            #121212 0%,
            transparent 50%,
            #121212 100%
          );

          ${respondTo.lg`
            background: linear-gradient(
              90deg,
              #121212 0%,
              transparent 20%,
              transparent 80%,
              #121212 100%
            );
          `}

          ${respondTo.xl`
            display: none;
          `}
        }

        ${respondTo.lg`
          margin: 80px auto 80px;
          grid-template-columns: repeat(6, 180px);
        `}
      }


    .company {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 50px;
      position: relative;
      margin: 0 -1px -1px 0;

      ${respondTo.lg`
        width: 180px;
        height: 90px;
      `}

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-top: 1px solid ${theme.colors.darkestGray};
        border-bottom: 1px solid ${theme.colors.darkestGray};
        margin: 0 -1px 0 0;

        ${respondTo.lg`
          top: -2px;
          left: -80px;
          width: calc(100% + 160px);
        `}
      }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        border-left: 1px solid ${theme.colors.darkestGray};
        border-right: 1px solid ${theme.colors.darkestGray};
        margin: 0 0 -1px 0;

        ${respondTo.lg`
          left: -2px;
          top: -80px;
          height: calc(100% + 160px);
        `}
      }

      a {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      img {
        max-width: 70px;
        max-height: 26px;

        ${respondTo.lg`
          max-width: 138px;
          max-height: 47px;
        `}
      }
  `}
`
