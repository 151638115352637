import { useIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { AppRoute } from 'const'

import { AgendaList } from 'sections'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import useLocalizeRoute from '../utils/useLocalizeRoute'

function AgendaArchive() {
  const history = useHistory()
  const { localizeRouteKey } = useLocalizeRoute()
  const { formatMessage } = useIntl()
  const { year } = useParams()
  const currentYear = new Date().getFullYear()

  useEffect(() => {
    if (Number(year) < 2023 || Number(year) > currentYear) {
      history.replace(localizeRouteKey(AppRoute.NotFound))
    }
  }, [year])

  return (
    <>
      <Helmet>
        <title>
          {formatMessage({ id: `seoTitleAgendaArchive` }, { year })}
        </title>
        <meta
          name="description"
          content={formatMessage(
            { id: `seoDescriptionAgendaArchive` },
            { year }
          )}
        />
        <meta
          name="keywords"
          content={formatMessage({ id: `seoKeywordsAgendaArchive` }, { year })}
        />
        <meta
          property="og:title"
          content={formatMessage({ id: `seoTitleAgendaArchive` }, { year })}
        />
        <meta
          property="og:site_name"
          content={formatMessage({ id: `seoTitleAgendaArchive` }, { year })}
        />
        <meta
          property="og:description"
          content={formatMessage(
            { id: `seoDescriptionAgendaArchive` },
            { year }
          )}
        />
        <meta name="twitter:image:src" content={''} />
        <meta
          name="twitter:title"
          content={formatMessage({ id: `seoTitleAgendaArchive` }, { year })}
        />
        <meta
          name="twitter:description"
          content={formatMessage(
            { id: `seoDescriptionAgendaArchive` },
            { year }
          )}
        />
      </Helmet>
      <AgendaList {...{ year }} />
    </>
  )
}

export { AgendaArchive }
