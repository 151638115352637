import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { SSpeakerTile } from './SpeakerTile.styled'

import imagePlaceholder from '../../assets/images/image-placeholder.svg'
import { AppRoute } from 'const'
import useLocalizeRoute from 'utils/useLocalizeRoute'

function SpeakerTile({ item: speaker, ...res }) {
  const { formatMessage, locale } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()

  const {
    speaker_name,
    speaker_title,
    speaker_city_list,
    speaker_company_name,
    speaker_image,
    speaker_slug,
  } = speaker

  return (
    <SSpeakerTile {...res}>
      <Link
        to={localizeRouteKey(AppRoute.Speaker).replace(':slug', speaker_slug)}
      >
        {speaker_image ? (
          <div className="thumbnail">
            <LazyLoadImage
              alt={`${speaker_name} - profile picture`}
              src={speaker_image}
              placeholderSrc={imagePlaceholder}
            />
          </div>
        ) : null}
        <div>
          <p className="name">{speaker_name}</p>
          <p className="position">{speaker_title}</p>
          <p className="company">@{speaker_company_name}</p>
          <div className="tags">
            {speaker_city_list.map((city) => (
              <span key={city.city_id} className="tag">
                {locale === 'pl'
                  ? city.city_name
                  : city.city_name_en || city.city_name}
              </span>
            ))}
          </div>
        </div>
      </Link>
    </SSpeakerTile>
  )
}

export default SpeakerTile
