import React, { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'

import { SlideTop, Accordion } from '../../components'
import { SContainer } from './FaqSection.styled'

const data = [
  {
    trigger: 'faqLocationQuestion',
    text: 'faqLocationAnswer',
  },
  {
    trigger: 'faqTimeQuestion',
    text: 'faqTimeAnswer',
  },
  {
    trigger: 'faqLineUpQuestion',
    text: 'faqLineUpAnswer',
  },
  {
    trigger: 'faqAgendaQuestion',
    text: 'faqAgendaAnswer',
  },
  {
    trigger: 'faqRecordingsQuestion',
    text: 'faqRecordingsAnswer',
  },
  // {
  //   trigger: 'faqHotelsQuestion',
  //   text: 'faqHotelsAnswer',
  // },
  {
    trigger: 'faqTicketsQuestion',
    text: 'faqTicketsAnswer',
  },
  {
    trigger: 'faqRegistrationQuestion',
    text: 'faqRegistrationAnswer',
  },
  {
    trigger: 'faqTeamQuestion',
    text: 'faqTeamAnswer',
  },
  {
    trigger: 'faqTicketPassQuestion',
    text: 'faqTicketPassAnswer',
  },
  {
    trigger: 'faqSingleTicketQuestion',
    text: 'faqSingleTicketAnswer',
  },
  {
    trigger: 'faqInvoiceQuestion',
    text: 'faqInvoiceAnswer',
  },
  {
    trigger: 'faqStudentsQuestion',
    text: 'faqStudentsAnswer',
  },
  {
    trigger: 'faqTicketsDifferenceQuestion',
    text: 'faqTicketsDifferenceAnswer',
  },
  {
    trigger: 'faqTicketsRefundQuestion',
    text: 'faqTicketsRefundAnswer',
  },
  {
    trigger: 'faqFoodVoucherQuestion',
    text: 'faqFoodVoucherAnswer',
  },
  {
    trigger: 'faqPicturesQuestion',
    text: 'faqPicturesAnswer',
  },
  {
    trigger: 'faqAfterpartyParticipateQuestion',
    text: 'faqAfterpartyParticipateAnswer',
  },
  {
    trigger: 'faqAfterpartyVenueQuestion',
    text: 'faqAfterpartyVenueAnswer',
  },
]
function FaqSection({ paddings }) {
  const { formatMessage } = useIntl()
  const faqRef = useRef()
  const { search } = useLocation()

  const params = new URLSearchParams(search)
  const sectionParam = params.get('section')

  useEffect(() => {
    if (sectionParam) {
      const faqSection = faqRef.current

      if (!faqSection) return

      const timeout = setTimeout(() => {
        const sectionPosition =
          faqSection.getBoundingClientRect().top + window.pageYOffset - 110

        window.scrollTo({ top: sectionPosition, behavior: 'smooth' })
      }, 500)

      return () => clearTimeout(timeout)
    }
  }, [sectionParam])

  return (
    <SContainer {...{ paddings }} id="faq" ref={faqRef}>
      <div className="content">
        <SlideTop>
          <h2 className="heading">{formatMessage({ id: 'faqHeading' })}</h2>
        </SlideTop>
        <div className="accordion">
          {data.map((el, index) => (
            <SlideTop key={el.trigger} delay={50 * index}>
              <Accordion
                className="trigger"
                trigger={formatMessage({ id: el.trigger })}
              >
                <p className="accordion-text">
                  {formatMessage(
                    { id: el.text },
                    {
                      span: (chunk) => <span className="bold">{chunk}</span>,
                      br: <br />,
                      a: ([chunk]) => {
                        const [link, text] = chunk.split('#')
                        return (
                          <a href={link} target="_blank" className="link">
                            {text}
                          </a>
                        )
                      },
                    }
                  )}
                </p>
              </Accordion>
            </SlideTop>
          ))}
        </div>
      </div>
    </SContainer>
  )
}

export default FaqSection
