import config from 'const/config'
import { createRef } from 'react'

export default function AuthenticatedLink({ url, filename, children, ...res }) {
  const link = createRef()

  const token = localStorage.getItem(config.STORAGE_TOKEN_KEY)
  const authHeaders = {
    Authorization: `Bearer ${token}`,
  }

  const handleAction = async () => {
    if (link.current.href) {
      return
    }

    const result = await fetch(url, {
      headers: { ...authHeaders },
    })

    const blob = await result.blob()
    const href = window.URL.createObjectURL(blob)

    link.current.download = filename
    link.current.href = href

    link.current.click()
  }

  return (
    <a role="button" ref={link} onClick={handleAction} {...res}>
      {children}
    </a>
  )
}
