import React from 'react'
import { useIntl } from 'react-intl'
import VisibilitySensor from 'react-visibility-sensor'
import { animated, Spring } from 'react-spring'

import { ButtonLink, SlideTop } from '../../components'
import { SContainer } from './CallForPapers.styled'
import douglasCrockford from '../../assets/images/speakers-of-all-time/douglas-crockford.png'

import { AppRoute } from '../../const'
import useLocalizeRoute from '../../utils/useLocalizeRoute'
import { useBreakpoint } from '../../modules/breakpoint'

import LongGreenPattern from '../../assets/patterns/LongGreenPattern'
import rightPattern from '../../assets/patterns/long-blue-pattern.svg'

function CallForPapers() {
  const { formatMessage } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()
  const breakpoints = useBreakpoint()

  return (
    <VisibilitySensor
      minTopValue={15}
      offset={{ top: 300 }}
      scrollCheck
      scrollDelay={5}
      partialVisibility="top"
    >
      {({ isVisible }) => {
        return (
          <SContainer>
            <div className="content">
              <SlideTop>
                <div className="image-wrapper">
                  <img src={douglasCrockford} alt="Douglas Crockford" />
                  {breakpoints.lg ? (
                    <Spring
                      from={{ opacity: 0 }}
                      to={{ opacity: isVisible ? 0 : 1 }}
                    >
                      {({ opacity }) => {
                        return (
                          <div className="background">
                            <animated.div
                              style={{
                                opacity,
                                height: '100%',
                              }}
                            >
                              <div className="positionLeft">
                                <LongGreenPattern />
                              </div>
                              <div className="positionRight">
                                <img src={rightPattern} alt="" />
                              </div>
                            </animated.div>
                          </div>
                        )
                      }}
                    </Spring>
                  ) : null}
                </div>
              </SlideTop>
              <div>
                <h2 className="heading">
                  {formatMessage(
                    { id: 'callForPapersHeading' },
                    {
                      span: (chunk) => <span>{chunk}</span>,
                      br: <br />,
                    }
                  )}
                </h2>
                <p className="subheader">
                  {formatMessage({ id: 'callForPapersSubheader' })}
                </p>
                <div className="link-wrapper">
                  <ButtonLink
                    height="52px"
                    href={localizeRouteKey(AppRoute.BecomeSpeaker)}
                  >
                    {formatMessage({ id: 'learnMore' })}
                    <i className="ri-arrow-right-line" />
                  </ButtonLink>
                </div>
              </div>
            </div>
          </SContainer>
        )
      }}
    </VisibilitySensor>
  )
}

export default CallForPapers
