import * as React from "react"

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 14" {...props}>
      <path
        d="M15.054 0H3.946C1.774-.007.007 1.832 0 4.109V9.89c.007 2.278 1.774 4.117 3.946 4.11h11.108c2.172.007 3.939-1.832 3.946-4.109V4.11C18.993 1.832 17.226-.007 15.054 0zm-2.669 7.28L7.19 9.862a.198.198 0 01-.201-.011.223.223 0 01-.098-.185V4.344a.22.22 0 01.1-.186.197.197 0 01.203-.008l5.196 2.74a.22.22 0 01.113.197.217.217 0 01-.117.193z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgComponent
