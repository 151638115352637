import styled, { css } from 'styled-components'

export const STags = styled.div`
  ${({ theme, color }) => css`
    .tag {
      font-size: 0.75rem;
      font-weight: bold;
      line-height: 1.33;
      color: ${theme.colors.pink};

      display: inline-block;

      padding: 2px ${theme.space.sm}px 5px ${theme.space.sm}px;
      margin-right: ${theme.space.sm}px;
      margin-bottom: ${theme.space.sm}px;

      border: 1px solid ${theme.colors.pink};
      border-radius: 14px;

      &:last-of-type {
        margin-right: 0;
      }

      ${color &&
      css`
        color: ${color};
        border-color: ${color};
      `}
    }
  `}
`
