import { useIntl } from 'react-intl'
import { SlideTop } from '../index'
import { STopic, STopicCircle, STopics, STopicTitle } from './TopicPaths.styled'

import topics from './topics'

function TopicPaths() {
  const { formatMessage } = useIntl()

  return (
    <STopics>
      {topics.map((topic, index) => (
        <SlideTop key={topic.color} delay={50 * index}>
          <STopic>
            <STopicCircle color={topic.color} />
            <STopicTitle>{topic.title}</STopicTitle>
            {/* <STopicTags>
                  {topic.tags.map((tag) => (
                    <span key={tag}>{tag}</span>
                  ))}
                </STopicTags> */}
            <p className="topic-description">
              {formatMessage({ id: topic.description })}
            </p>
          </STopic>
        </SlideTop>
      ))}
    </STopics>
  )
}

export default TopicPaths
