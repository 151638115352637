import { STicketProcessInput } from 'components/TicketProcessInput/TicketProcessInput.styled'
import styled, { css } from 'styled-components'

export const SFile = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 48px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    color: white;

    padding: 0 0 0 ${theme.space.sm}px;

    border: 1px solid ${theme.colors.lightGray};
    background: none;

    &:focus {
      outline: none;
      border-color: white;
    }

    &:disabled {
      color: ${theme.colors.lightGray};
      background-color: rgba(165, 165, 165, 0.2);
    }

    button {
      width: 32px;
      height: 48px;

      display: flex;
      align-items: center;
      justify-content: center;

      margin: 0;
      padding: 0;

      border: 0;
      background: none;
      box-shadow: none;
      cursor: pointer;

      & > span {
        width: 16px;
        height: 16px;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 50%;
        background-color: white;
      }

      svg {
        width: 8px;
        height: 8px;
      }
    }
  `}
`

export const SDropzoneArea = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A5A5A5FF' stroke-width='3' stroke-dasharray='7%2c 11%2c 9' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    min-height: 104px;
    cursor: pointer;
    position: relative;
    .wrapper {
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
    }
  `}
`

export const SDropzoneLabel = styled.div`
  ${({ theme }) => css`
    font-size: 14px;
    color: #fff;
    margin-top: ${theme.space.md}px;
    cursor: pointer;
    max-width: 90%;
    word-break: break-all;

    > span {
      color: ${theme.colors.pink};
    }
    p {
      line-height: 20px;
    }
    text-align: center;
  `}
`

export const SUploadingProgress = styled.div`
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
