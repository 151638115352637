import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import TwitterIcon from '../../assets/icons/TwitterIcon'
import FacebookIcon from '../../assets/icons/FacebookIcon'
import LinkedinIcon from '../../assets/icons/LinkedinIcon'
import { Button, HeroTimeLocationGrid, Social } from '../../components'

import { SContainer } from './HeroSectionHomePage.styled'
import useLocalizeRoute from 'utils/useLocalizeRoute'
import preventClick from 'utils/preventClick'
import { AppRoute } from 'const'

function HeroSectionHomePage() {
  const { formatMessage } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()

  return (
    <SContainer>
      <div className="content">
        <h1 className="heading">
          {formatMessage(
            { id: 'heroHomePageHeading' },
            {
              span: (chunk) => <span>{chunk}</span>,
              br: <br />,
            }
          )}
        </h1>
        <p className="subheading">
          {formatMessage({
            id: 'heroAdditionalSubHeading',
          })}
        </p>
        <div className="buttons-wrapper">
          <Button
            // onClick={preventClick}
            height="52px"
            as={Link}
            // to="#"
            // className="disable-button-link"
            to={localizeRouteKey(AppRoute.BuyTicket)}
          >
            {formatMessage({
              id: 'buyTicket',
            })}
            <i className="ri-arrow-right-line" />
          </Button>
          <Button
            secondary="true"
            height="52px"
            as={Link}
            to={localizeRouteKey(AppRoute.BecomePartner)}
          >
            {formatMessage({
              id: 'joinPartnersButton',
            })}
            <i className="ri-arrow-right-line" />
          </Button>
          <Button
            secondary="true"
            height="52px"
            as={Link}
            to={localizeRouteKey(AppRoute.BecomeSpeaker)}
          >
            {formatMessage({ id: 'joinSpeakersButton' })}
            <i className="ri-arrow-right-line" />
          </Button>
        </div>
        <p className="cta">
          {formatMessage({
            id: 'heroHomePageCta',
          })}
        </p>
        <HeroTimeLocationGrid />
        <hr className="divider-horizontal" />
        <p className="socials-text">
          {formatMessage({ id: 'followUsHeading' })}
        </p>
        <div className="socials">
          <Social href="https://twitter.com/code_europe" target="_blank">
            <TwitterIcon />
          </Social>
          <Social
            href="https://www.linkedin.com/showcase/codeeurope/"
            target="_blank"
          >
            <LinkedinIcon />
          </Social>
          <Social href="https://www.facebook.com/codeeurope" target="_blank">
            <FacebookIcon />
          </Social>
        </div>
      </div>
    </SContainer>
  )
}

export default HeroSectionHomePage
