import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useBreakpoint } from 'modules/breakpoint'
import { LanguageSwitcher } from 'modules/i18n'

import { Button, Social, Logo } from '../../components'

import TwitterIcon from '../../assets/icons/TwitterIcon'
import LinkedinIcon from '../../assets/icons/LinkedinIcon'
import FacebookIcon from '../../assets/icons/FacebookIcon'

import { SFooter } from './Footer.styled'

import useLocalizeRoute from 'utils/useLocalizeRoute'
import preventClick from 'utils/preventClick'

import { AppRoute } from 'const'

export const FOOTER_DOM_ID = 'footer'

function Footer() {
  const breakpoint = useBreakpoint()
  const { formatMessage, locale } = useIntl()

  const footerVisibility = useSelector(({ app }) => app.footerVisibility)

  const { localizeRouteKey } = useLocalizeRoute()

  const renderLangSelect = () => (
    <LanguageSwitcher className="language-picker" />
  )

  if (!footerVisibility) return null

  return (
    <SFooter id={FOOTER_DOM_ID}>
      <div className="container">
        <div className="left-side">
          <Link to={`/${locale}/`}>
            <Logo className="logo" />
          </Link>
          <p className="text-join-us">
            Join the biggest tech conference in Poland. Connecting the world of
            Software Development.
          </p>
          <div className="button-wrapper">
            <Button
              fontSize="16px"
              secondary="true"
              as={Link}
              to={localizeRouteKey(AppRoute.BuyTicket)}
              // onClick={preventClick}
              // to="#"
              // className="disable-secondary-button"
            >
              <span>
                {formatMessage({
                  id: 'getTicketNowButton',
                })}
              </span>
              <i className="ri-arrow-right-line" />
            </Button>
          </div>
          {renderLangSelect()}
          <hr />
          <ul className="socials-container">
            <p className="text-follow-us">
              {' '}
              {formatMessage(
                { id: 'followUsAndFindOutFirst' },
                { span: (chunk) => chunk }
              )}
            </p>
            <div className="socials-icons">
              <li>
                <Social href="https://twitter.com/code_europe" target="_blank">
                  <TwitterIcon />
                </Social>
              </li>
              <li>
                <Social
                  href="https://www.linkedin.com/showcase/codeeurope/"
                  target="_blank"
                >
                  <LinkedinIcon />
                </Social>
              </li>
              <li>
                <Social
                  href="https://www.facebook.com/codeeurope"
                  target="_blank"
                >
                  <FacebookIcon />
                </Social>
              </li>
            </div>
          </ul>
        </div>
        <div className="right-side">
          <div className="column">
            <p className="column-heading">
              {formatMessage({ id: 'agenda' }, { year: undefined })}
            </p>
            <ul className="column-links">
              <li>
                <Link
                  to={localizeRouteKey(AppRoute.AgendaArchive).replace(
                    ':year',
                    '2024'
                  )}
                >
                  2024
                </Link>
              </li>
              <li>
                <Link
                  to={localizeRouteKey(AppRoute.AgendaArchive).replace(
                    ':year',
                    '2023'
                  )}
                >
                  2023
                </Link>
              </li>
            </ul>
          </div>
          <div className="column">
            <p className="column-heading">
              {formatMessage({ id: 'lectures' })}
            </p>
            <ul className="column-links">
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.youtube.com/watch?v=UOZwhPD_q80&list=PLC4RM9NjfgaZjdTxQHpxSUcbch3gnY1_e&ab_channel=CodeEurope"
                >
                  2022
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.youtube.com/watch?v=Xl9yMJIWYlA&list=PLC4RM9NjfgaZbBI7rHVjd5qZTZKvGkAlP&ab_channel=CodeEurope"
                >
                  2021
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.youtube.com/channel/UChdVVEAilVHULlycMbqRpdg/videos"
                >
                  2018
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UChdVVEAilVHULlycMbqRpdg/videos">
                  2017
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UChdVVEAilVHULlycMbqRpdg/videos">
                  2016
                </a>
              </li>
            </ul>
          </div>
          <div className="column">
            <p className="column-heading">{formatMessage({ id: 'explore' })}</p>
            <ul className="column-links">
              <li>
                <Link to={localizeRouteKey(AppRoute.BecomeSpeaker)}>
                  {formatMessage({ id: 'forSpeakers' })}
                </Link>
              </li>
              <li>
                <Link to={localizeRouteKey(AppRoute.BecomePartner)}>
                  {formatMessage({ id: 'forPartners' })}
                </Link>
              </li>
              <li>
                <Link to={localizeRouteKey(AppRoute.AboutUs)}>
                  {formatMessage({ id: 'organizers' })}
                </Link>
              </li>
              <li>
                <a href="mailto:kontakt@codeeurope.pl">
                  {formatMessage({ id: 'contact' })}
                </a>
              </li>
            </ul>
          </div>
          <div className="column">
            <p className="column-heading">{formatMessage({ id: 'legal' })}</p>
            <ul className="column-links">
              <li>
                <a
                  href={localizeRouteKey(AppRoute.UserRegulations)}
                  target="_blank"
                >
                  {formatMessage({ id: 'regulationsUsers' })}
                </a>
              </li>
              <li>
                <a
                  href={localizeRouteKey(AppRoute.PrivacyPolicy)}
                  target="_blank"
                >
                  {formatMessage({ id: 'privacyPolicy' })}
                </a>
              </li>
              <li>
                <a
                  href={localizeRouteKey(AppRoute.CodeOfConduct)}
                  target="_blank"
                >
                  {formatMessage({ id: 'codeOfConduct' })}
                </a>
              </li>
            </ul>
          </div>
          {breakpoint.lg ? renderLangSelect() : null}
        </div>
      </div>
    </SFooter>
  )
}

export default Footer
