import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { AppRoute } from 'const'
import * as pages from 'pages'
import { LocalizedSwitch } from 'modules/i18n'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from 'state/reducers/user/userActions'
import { AppLayout } from 'modules/layout'

import { PageLoader, ProtectedRoute } from 'components'
import useLocalizeRoute from 'utils/useLocalizeRoute'
import 'remixicon/fonts/remixicon.css'

export const App: React.FC = () => {
  const dispatch = useDispatch()
  const { locale } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()

  const dictionaryLoading = useSelector(
    ({ app }: any) => app.dictionary.loading
  )
  const { triedAuthenticate, isAuthenticated } = useSelector(
    ({ user }: { user: any }) => user
  )
  const authenticationPath = localizeRouteKey(AppRoute.SignIn)

  useEffect(() => {
    dispatch(getUser(locale))
  }, [locale])

  if (!triedAuthenticate || dictionaryLoading) {
    return <PageLoader />
  }

  return (
    <AppLayout>
      <LocalizedSwitch>
        <Route exact path={AppRoute.Home}>
          <pages.Home />
        </Route>
        <Route exact path={AppRoute.BecomePartner}>
          <pages.BecomePartner />
        </Route>
        <Route exact path={AppRoute.BecomeSpeaker}>
          <pages.BecomeSpeaker />
        </Route>
        <Route exact path={AppRoute.AboutUs}>
          <pages.AboutUs />
        </Route>
        <Route exact path={AppRoute.LectureDetailsForm}>
          <pages.LectureDetailsForm />
        </Route>
        <Route exact path={AppRoute.SpeakerDetailsForm}>
          <pages.SpeakerDetailsForm />
        </Route>
        <Route exact path={AppRoute.Speakers}>
          <pages.Speakers />
        </Route>
        <Route exact path={AppRoute.Speaker}>
          <pages.Speaker />
        </Route>
        <Route exact path={AppRoute.Companies}>
          <pages.Companies />
        </Route>
        <Route exact path={AppRoute.Company}>
          <pages.Company />
        </Route>
        <Route exact path={AppRoute.Offers}>
          <pages.Offers />
        </Route>
        <Route exact path={AppRoute.Offer}>
          <pages.Offer />
        </Route>
        <Route exact path={AppRoute.BuyTicket}>
          <pages.BuyTicket />
        </Route>
        <Route exact path={AppRoute.BuyTicketSuccess}>
          <pages.BuyTicketSuccess isGroup={false} />
        </Route>
        <Route exact path={AppRoute.BuyTicketGroupSuccess}>
          <pages.BuyTicketSuccess isGroup />
        </Route>
        <Route exact path={AppRoute.PrivacyPolicy}>
          <pages.PrivacyPolicy />
        </Route>
        <Route exact path={AppRoute.CodeOfConduct}>
          <pages.CodeOfConduct />
        </Route>
        <Route exact path={AppRoute.CookiesPolicy}>
          <pages.CookiesPolicy />
        </Route>
        <Route exact path={AppRoute.ListOfEntitiesEmployer}>
          <pages.ListOfEntitiesEmployer />
        </Route>
        <Route exact path={AppRoute.ListOfEntitiesEmployerOutsideEog}>
          <pages.ListOfEntitiesEmployerOutsideEog />
        </Route>
        <Route exact path={AppRoute.ListOfEntitiesUser}>
          <pages.ListOfEntitiesUser />
        </Route>
        <Route exact path={AppRoute.ListOfEntitiesUserOutsideEog}>
          <pages.ListOfEntitiesUserOutsideEog />
        </Route>
        <Route exact path={AppRoute.ClientsRegulationsPl}>
          <pages.ClientsRegulationsPl />
        </Route>
        <Route exact path={AppRoute.ClientsRegulationsEn}>
          <pages.ClientsRegulationsEn />
        </Route>
        <Route exact path={AppRoute.UserRegulations}>
          <pages.UserRegulations />
        </Route>
        <Route exact path={AppRoute.Contest}>
          <pages.Contest />
        </Route>
        <Route exact path={AppRoute.KubotaContest}>
          <pages.KubotaContest />
        </Route>
        <Route exact path={AppRoute.CovidStatement}>
          <pages.CovidStatement />
        </Route>
        <Route exact path={AppRoute.PersonalDataProcessing}>
          <pages.PersonalDataProcessing />
        </Route>
        <Route exact path={AppRoute.RecordingAgreement}>
          <pages.RecordingAgreement />
        </Route>
        <Route exact path={AppRoute.Unsubscribe}>
          <pages.Unsubscribe />
        </Route>
        <ProtectedRoute
          exact
          path={AppRoute.User}
          {...{ isAuthenticated, authenticationPath }}
        >
          <pages.User />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={AppRoute.Logout}
          {...{ isAuthenticated, authenticationPath }}
        >
          <pages.Logout />
        </ProtectedRoute>

        <Route exact path={AppRoute.SignIn}>
          <pages.SignIn />
        </Route>
        <Route exact path={AppRoute.ForgotPassword}>
          <pages.ForgotPassword />
        </Route>
        <Route exact path={AppRoute.ResetPassword}>
          <pages.ResetPassword />
        </Route>
        <Route exact path={AppRoute.Agenda}>
          <pages.Agenda />
        </Route>
        <Route exact path={AppRoute.AgendaArchive}>
          <pages.AgendaArchive />
        </Route>
        <Route exact path={[AppRoute.NotFound, '*']}>
          <pages.NotFoundPage />
        </Route>
      </LocalizedSwitch>
    </AppLayout>
  )
}
