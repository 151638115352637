import * as React from "react"

function TransportIcon(props) {
  return (
    <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g transform="translate(1)" fill="none" fillRule="evenodd">
        <g strokeWidth={1.5}>
          <ellipse
            stroke="#FC86F6"
            cx={9.894}
            cy={25.945}
            rx={2.96}
            ry={2.894}
          />
          <ellipse
            stroke="#FC86F6"
            cx={35.866}
            cy={25.945}
            rx={2.96}
            ry={2.894}
          />
          <rect
            stroke="#FFF"
            x={0.75}
            y={16.54}
            width={44.26}
            height={19.148}
            rx={6}
          />
          <path
            d="M10.759 35.973v7.377a3.26 3.26 0 01-.958 2.312 3.26 3.26 0 01-2.312.958 3.26 3.26 0 01-2.312-.958 3.26 3.26 0 01-.957-2.312h0v-7.377h6.539zm30.712 0v7.377a3.26 3.26 0 01-.958 2.312 3.26 3.26 0 01-2.311.958 3.26 3.26 0 01-2.312-.958 3.26 3.26 0 01-.958-2.312h0v-7.377h6.54zM32.42.75a3.246 3.246 0 013.025 2.059h0l5.297 13.445H5.018L10.315 2.81A3.246 3.246 0 0113.339.75h0z"
            stroke="#FFF"
          />
        </g>
        <rect
          stroke="#FFF"
          x={16.578}
          y={30.421}
          width={12.398}
          height={1}
          rx={0.5}
        />
        <path
          d="M25.61 3.07h6.671a1 1 0 01.934.643l3.114 8.13h0"
          stroke="#FC86F6"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          stroke="#FC86F6"
          strokeWidth={1.5}
          strokeLinecap="round"
          d="M34.165 13.377l-2.233-5.701"
        />
        <path
          stroke="#FFF"
          strokeWidth={1.5}
          strokeLinecap="round"
          d="M4.282 15.79L.38 14.109m41.087 1.681l3.903-1.681"
        />
      </g>
    </svg>
  )
}

export default TransportIcon
