import React, { useEffect } from "react"

function PrivacyPolicy() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.location.href = "/polityka-prywatnosci.pdf"
    }
  }, []);

  return null;
}

export { PrivacyPolicy }
