import styled, { css } from "styled-components";
import respondTo from "../../theme/respondTo"

export const SContainer = styled.div`
  ${({ theme }) =>
        css`
          margin-bottom: ${theme.space.xl}px;
          display: flex;
          flex-direction: column;

          .react-select__control {
            min-height: 48px;
          }
          
          .react-select__placeholder {
              font-size: 1rem;
              line-height: 1.5rem;
           }
           
           .react-select__menu {
            z-index: 10001;
           }
          
          .content-wrapper {
            display: flex;
            flex-direction: column;
  
            padding: 0;
  
            border-radius: 4px;
          }
          
          .search-button {
            background-color: ${theme.colors.pink};
            border: 1px solid ${theme.colors.black};
            color: ${theme.colors.black};
            cursor: pointer;
            font-size: 1.25rem;
            line-height: 1.25rem;
            font-weight: ${theme.weight.bold};
            height: 48px;
            margin-right: ${theme.space.md}px;
            width: 127px;
            
            &:hover,
            &:focus {
             outline: 0;
             background-color: ${theme.colors.darkPink};
           }
          }
          
          .search-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: ${theme.space.md - 4}px; 
            margin-right: 5px;
            margin-top: 2px;
        
            height: 100%;
        
            color: ${({ theme }) => theme.colors.pink};
               
            svg {
                width: 14px;
                height: 14px;
            }
          }
          
          .max-width {
             margin-bottom: ${theme.space.sm}px;                
          }
          
          .filter {
            &-field {
              margin-top: ${theme.space.lg}px;
              line-height: 1.3rem;
              background-color: ${theme.colors.gray2};
  
              .selectStyles {
                  line-height: 1.3rem;
              }
            }
          }

         ${respondTo.lg`
            .react-select__control {
               min-height: 80px;
             }
             
             .react-select__placeholder {
                 font-size: 1.25rem;
                 line-height: 2rem;
              }

             .max-width {
                 flex: 1;
                 margin-bottom: 0;
             }
             
             .filtration-container {
               display: flex;
               align-items: center;
             }
             
             .search-icon {
               margin-top: 3px;
               margin-left: ${theme.space.xl}px; 
             }
             
             .filter {
               &-group {
                 display: flex;
                 align-items: flex-end;
                 margin-bottom: ${theme.space.md}px;
   
                 &:last-of-type {
                     margin-bottom: 0;
                 }
               }
               
               &-field {
                 flex: 1;
                 margin-top: 0;
               }
             }
 
             .additional {
                 flex: 1;
                 max-width: 560px;
 
                 display: flex;
                 align-items: center;
             }
        `}
  `}
`;
