import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useTheme } from 'styled-components'
import { useBreakpoint } from 'modules/breakpoint'
import { useDispatch } from 'react-redux'

import {
  Button,
  SectionHeader,
  Wrapper,
  CustomUnderline,
  SlideTop,
} from '../../components'

import {
  SJoinPartner,
  SJoinPartners,
  SJoinPartnersGrid,
  SJoinPartnersAction,
  SCategoryTitle,
} from './JoinPartners.styled'

import joinPartners, {
  JoinPartner,
  partnerJoinPartners,
} from './joinPartnersList'

import { setContactModalVisibility } from '../../state/reducers/app/appActions'

function JoinPartners({ partner, partnersList }) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { locale, formatMessage } = useIntl()
  const breakpoints = useBreakpoint()

  const partners = useMemo(
    () => (partner ? partnerJoinPartners : joinPartners),
    []
  )

  const openCallpageWidget = () => {
    if (typeof window === "undefined") return
    window.callpage("api.widget.open")
  }
  const openContactModal = () => {
    dispatch(setContactModalVisibility(true))
  }

  const openContactModalOrCallpage = () => {
    window.callpage ?
      openCallpageWidget()
      : openContactModal()
  }

  return (
    <SJoinPartners {...{ partner }}>
      <Wrapper>
        {partnersList?.map((partner) => {
          return (
            <div key={partner.id}>
              <SlideTop>
                <SCategoryTitle>
                  <CustomUnderline color={partner.color}>
                    {locale === 'pl' ? partner.name : partner.name_en}
                  </CustomUnderline>
                </SCategoryTitle>
              </SlideTop>
              <SJoinPartnersGrid {...{ partner }}>
                {partner.partners?.map((partner) => (
                  <a key={partner.name} href={partner.link} target="_blank">
                    <SJoinPartner>
                      <img src={partner.img} alt={partner.name} />
                    </SJoinPartner>
                  </a>
                ))}
              </SJoinPartnersGrid>
            </div>
          )
        })}
        {!partner ? (
          <SJoinPartnersAction>
            <Button onClick={openContactModalOrCallpage}>
              {formatMessage({ id: 'joinPartnersButton' })}
            </Button>
          </SJoinPartnersAction>
        ) : null}
      </Wrapper>
    </SJoinPartners>
  )
}

export default JoinPartners
