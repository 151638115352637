import React from 'react'
import { useIntl } from 'react-intl'

import { SlideTop } from '../../components'
import VisibilitySensor from 'react-visibility-sensor'

import { SContainer, SSwiperWrapper } from './Aftermovie.styled'
import { animated, Spring } from 'react-spring'
import smallGreenPinkPattern from '../../assets/patterns/small-green-pink-pattern.svg'
import smallBlueGreenPattern from '../../assets/patterns/small-blue-green-pattern.svg'

function Aftermovie() {
  const { formatMessage } = useIntl()

  return (
    <VisibilitySensor
      minTopValue={15}
      offset={{ top: 300 }}
      scrollCheck
      scrollDelay={5}
      partialVisibility="top"
    >
      {({ isVisible }) => {
        return (
          <SContainer>
            <SlideTop>
              <div className="container">
                <h2 className="heading">
                  {formatMessage(
                    { id: 'aftermovieHeading' },
                    {
                      span: (chunk) => <span>{chunk}</span>,
                      br: <br />,
                    }
                  )}
                </h2>
                <SSwiperWrapper>
                  <Spring
                    from={{ opacity: 0 }}
                    to={{ opacity: isVisible ? 0 : 1 }}
                  >
                    {({ opacity }) => {
                      return (
                        <div className="background">
                          <animated.div
                            style={{
                              opacity,
                              height: '100%',
                            }}
                          >
                            <div className="positionLeft">
                              <img src={smallGreenPinkPattern} alt="" />
                            </div>
                            <div className="positionRight">
                              <img src={smallBlueGreenPattern} alt="" />
                            </div>
                          </animated.div>
                        </div>
                      )
                    }}
                  </Spring>
                  <div className="video-container">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/QgLxcBe8nDU?si=9fqzc0_7hWk_1xdh"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                  </div>
                </SSwiperWrapper>
              </div>
            </SlideTop>
          </SContainer>
        )
      }}
    </VisibilitySensor>
  )
}

export default Aftermovie
