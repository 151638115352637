import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SContainer = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundBlack};
    padding: 50px ${theme.space.xl + 8}px ${theme.space.xl + 8}px;

    ${respondTo.lg`
      padding: 100px ${theme.space.lg}px;
    `}

    .content {
      ${respondTo.lg`
        max-width: 1072px;
        margin: 0 auto;
      `}
    }
    .heading {
      color: white;
      font-weight: ${theme.weight.bold};
      font-size: 1.5rem;
      line-height: 1.875rem;
      margin-bottom: ${theme.space.lg + 4}px;
      text-align: center;

      ${respondTo.lg`
        text-align: left;
        font-size: 2.25rem;
        line-height: 3.125rem;
        margin-bottom: ${theme.space.xl + 8}px;
      `}

      span {
        display: block;
        color: ${theme.colors.lightPink};
        font-size: 1.75rem;
        line-height: 2.2rem;
        text-shadow: 1px 1px 0 ${theme.colors.azure};

        ${respondTo.lg`
          font-size: 3.25rem;
          line-height: 4.125rem; 
          text-shadow: 2px 2px 0 ${theme.colors.azure};
        `}
      }
    }
  `}
`
