import React from 'react'
import { useIntl } from 'react-intl'
import { useBreakpoint } from '../../modules/breakpoint'
import { SlideTop, CompaniesGrid, CustomUnderline } from '../../components'
import { SContainer } from './PartnersAboutUs.styled'
import {
  amazon,
  booking,
  disney,
  elastic,
  github,
  google,
  ibm,
  jetBrains,
  microsoft,
  netflix,
  oracle,
  redHat,
} from '../../assets/partners/gray'
import { useTheme } from 'styled-components'

const companies = [
  {
    company_name: 'Amazon Web Services',
    company_link: '',
    company_logo: amazon,
  },
  {
    company_name: 'Google',
    company_link: '',
    company_logo: google,
  },
  {
    company_name: 'IBM',
    company_link: '',
    company_logo: ibm,
  },
  {
    company_name: 'GitHub',
    company_link: '',
    company_logo: github,
  },
  {
    company_name: 'Disney',
    company_link: '',
    company_logo: disney,
  },
  {
    company_name: 'Red Hat',
    company_link: '',
    company_logo: redHat,
  },
  {
    company_name: 'Netflix',
    company_link: '',
    company_logo: netflix,
  },
  {
    company_name: 'Microsoft',
    company_link: '',
    company_logo: microsoft,
  },
  {
    company_name: 'JetBrains',
    company_link: '',
    company_logo: jetBrains,
  },
  {
    company_name: 'Elastic',
    company_link: '',
    company_logo: elastic,
  },
  {
    company_name: 'Booking.com',
    company_link: '',
    company_logo: booking,
  },
  {
    company_name: 'Oracle',
    company_link: '',
    company_logo: oracle,
  },
]

function PartnersAboutUs() {
  const { formatMessage } = useIntl()
  const breakpoint = useBreakpoint()
  const isMobile = breakpoint.lg
  const theme = useTheme()

  const companiesToDisplay = isMobile ? companies.slice(0, 8) : companies

  return (
    <SContainer>
      <SlideTop>
        <h2 className="heading">
          {formatMessage(
            { id: 'partnersAboutUsHeading' },
            {
              span: (chunk) => (
                <CustomUnderline color={theme.colors.blue}>
                  {chunk}
                </CustomUnderline>
              ),
              br: <br />,
            }
          )}
        </h2>
        <p className="subheader">
          {formatMessage({ id: 'partnersAboutUsSubheader' })}
        </p>
      </SlideTop>
      <SlideTop>
        <div className="companies">
          <CompaniesGrid companies={companiesToDisplay} />
        </div>
      </SlideTop>
    </SContainer>
  )
}

export default PartnersAboutUs
