import styled, { css } from "styled-components"

export const SCircle = styled.span`
  ${({ theme, active }) => css`
    flex-shrink: 0;
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin: 0 10px;
    border: solid 3px ${theme.colors.lightGray};
    border-radius: 100%;

    &:hover {
      border-color: ${theme.colors.pink};
    }

    ${active &&
      css`
        border-color: ${theme.colors.pink};
      `}
  `}
`

export const SDotsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    overflow: hidden;
    flex-direction: column;
    position: relative;
    margin-top: 2px;

    .row {
      display: flex;

      margin-top: ${theme.space.md}px;
      &:first-of-type {
        margin-top: 0;
      }
    }

    .label {
      max-width: 217px;
      width: 100%;

      flex-shrink: 1;

      white-space: nowrap;
      display: flex;
      align-items: baseline;
      justify-content: space-between;

      font-size: 1rem;
      color: white;

      margin-bottom: ${theme.space.sm}px;

      span {
        font-size: 0.75rem;
        color: ${theme.colors.lightGray};
      }
    }
  `}
`
