import React, { HTMLProps } from 'react'
import { AppLanguage, AppRoute } from 'const'
import {
  generatePath,
  matchPath,
  NavLink,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import { useIntl } from 'react-intl'

import { appStrings } from '..'
import plFlag from '../../../assets/icons/emoji-flag-pl.png'
import enFlag from '../../../assets/icons/emoji-flag-en.png'

export const LanguageSwitcher: React.FC<HTMLProps<HTMLUListElement>> = (
  props: HTMLProps<HTMLUListElement>
) => {
  const { pathname } = useLocation()
  const { messages, locale } = useIntl()

  function getMatchingRoute(language: string) {
    const route = pathname.substring(3) as string

    const keys = Object.keys(messages)

    let match = null
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      const tempMatch = matchPath(route, {
        path: messages[key] as string,
        exact: true,
      })

      if (tempMatch?.isExact) {
        match = { key, ...tempMatch }
        break
      }
    }

    if (match) {
      const matchingRoute = appStrings[language][match.key]

      if (Object.keys(match.params).length) {
        const path = generatePath(matchingRoute, match.params)
        return `/${language}` + path
      }
      return `/${language}` + matchingRoute
    }

    return `/${language}`
  }

  return (
    <ul {...props}>
      {Object.keys(AppLanguage)
        .filter((appLanguageKey) => AppLanguage[appLanguageKey] !== locale)
        .map((lang) => (
          <li key={lang}>
            <NavLink
              to={getMatchingRoute(AppLanguage[lang])}
              className="lang-link"
            >
              {AppLanguage[lang] === 'pl' ? (
                <span className="flag">
                  <img src={plFlag} alt="" />
                </span>
              ) : (
                <span className="flag">
                  <img src={enFlag} alt="" />
                </span>
              )}{' '}
              <span>{AppLanguage[lang]}</span>
              <i className="ri-arrow-down-s-fill icon" />
            </NavLink>
          </li>
        ))}
    </ul>
  )
}
