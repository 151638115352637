import React from 'react'
import { animated, Spring } from 'react-spring'
import VisibilitySensor from 'react-visibility-sensor'

import { SlideTop, PartnersBanner } from '../../components'
import { SContainer } from './PartnersBannerSection.styled'
import { useBreakpoint } from '../../modules/breakpoint'

import LongGreenPattern from '../../assets/patterns/LongGreenPattern'
import longBluePattern from '../../assets/patterns/long-blue-pattern.svg'

function PartnersBannerSection() {
  const breakpoints = useBreakpoint()

  return (
    <VisibilitySensor
      minTopValue={15}
      offset={{ top: 300 }}
      scrollCheck
      scrollDelay={5}
      partialVisibility="top"
    >
      {({ isVisible }) => (
        <SContainer>
          {!breakpoints.xxl ? (
            <Spring from={{ opacity: 0 }} to={{ opacity: isVisible ? 0 : 1 }}>
              {({ opacity }) => {
                return (
                  <div className="background">
                    <animated.div
                      style={{
                        opacity,
                        height: '100%',
                      }}
                    >
                      <div className="positionRight">
                        <LongGreenPattern />
                      </div>
                      <div className="positionLeft">
                        <img src={longBluePattern} alt="" />
                      </div>
                    </animated.div>
                  </div>
                )
              }}
            </Spring>
          ) : null}
          <SlideTop>
            <PartnersBanner />
          </SlideTop>
        </SContainer>
      )}
    </VisibilitySensor>
  )
}

export default PartnersBannerSection
