import * as React from "react"

function FiltrationIcon(props) {
  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.993 13a2.999 2.999 0 00-2.82 2H1a1 1 0 000 2h13.174a2.998 2.998 0 005.806-1.345A3 3 0 0016.993 13zm0 4a1 1 0 110-2 1 1 0 010 2zM3.007 1a2.999 2.999 0 012.82 2H19a1 1 0 010 2H5.826A2.998 2.998 0 01.02 3.655 3 3 0 013.007 1zm0 4a1 1 0 100-2 1 1 0 000 2z"
        fill="currentColor"
      />
    </svg>
  )
}

export default FiltrationIcon
