import styled, { css } from 'styled-components'
import respondTo from 'theme/respondTo'

export const STab = styled.button`
  ${({ theme, active }) => css`
    width: 100%;
    height: 100%;

    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 0.75rem;
    font-weight: ${theme.weight.bold};
    color: white;

    margin: 0;
    padding: 0;

    box-shadow: none;
    border: none;
    background: transparent;

    cursor: pointer;

    ${active &&
    css`
      color: ${theme.colors.black};
      background-color: ${theme.colors.pink};
    `}

    ${respondTo.lg`
      font-size: 1.25rem;
    `}
  `}
`

export const STabs = styled.div`
  ${({ theme }) => css`
    height: 36px;

    display: flex;

    padding: ${theme.space.xs}px;
    border: solid 1px ${theme.colors.gray};

    margin-top: ${theme.space.xl}px;

    &.user-profile-tabs {
      margin-top: ${theme.space.lg}px;
    }

    ${respondTo.lg`
      max-width: 572px;
      height: 56px;

      margin-top: ${theme.space.xxxl}px;
      margin-left: auto;
      margin-right: auto;

      &.user-profile-tabs {
        margin-top: ${theme.space.xl}px;
      }
    `}
  `}
`
