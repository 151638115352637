import React, { useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'

import useBlockScroll from '../../utils/useBlockScroll'
import styles from './styles.module.css'

import langs from './langs'
import { useIntl } from 'react-intl'
import useLocalizeRoute from 'utils/useLocalizeRoute'
import { AppRoute } from 'const'

function GdprCookie({ locale, onConfirm }) {
  const translation = useMemo(() => langs[locale || 'pl'], [])
  const [cookies, setCookie] = useCookies([])
  const { localizeRouteKey } = useLocalizeRoute()

  const policy = localizeRouteKey(AppRoute.CookiesPolicy)

  const [visibleNotice, setNoticeVisibility] = useState(!!!cookies.gdprcookie)
  const [settings, setSettings] = useState({
    marketing: false,
    analytics: false,
  })
  const [essentialsErrorVisible, showEssentialsError] = useState(false)
  const [settingsVisible, setSettingVisibility] = useState(false)
  useBlockScroll(visibleNotice, 'gdpr-noscroll')

  useEffect(() => {
    if (!visibleNotice && typeof window !== 'undefined') {
      onConfirm && onConfirm(cookies.gdprcookie)
    }
  }, [])

  const toggleSettings = () => setSettingVisibility(!settingsVisible)
  const handleEssentialsSetting = () => {
    if (essentialsErrorVisible) return
    showEssentialsError(true)
  }
  const handleChangeSetting = (name) => () => {
    setSettings({
      ...settings,
      [name]: !settings[name],
    })
  }

  const handleSaveSettings = () => {
    const tempSettings = Object.assign(
      {},
      { date: new Date() },
      settingsVisible ? settings : { marketing: true, analytics: true }
    )

    const expires = new Date()
    expires.setDate(expires.getDate() + 30)

    setCookie('gdprcookie', tempSettings, {
      expires,
      domain: null,
    })

    onConfirm && onConfirm(tempSettings)
    setNoticeVisibility(false)
  }

  if (visibleNotice) {
    return (
      <div>
        <div className={styles.background} />
        <div className={styles.panel}>
          <div>
            <div className={styles.notice}>
              <div
                dangerouslySetInnerHTML={{
                  __html: translation.description.replace(
                    'href="#"',
                    `href="${policy}" target="_blank"`
                  ),
                }}
              />
              {policy ? (
                <a
                  className={styles.noticeSeeMore}
                  href={policy}
                  target="_blank"
                >
                  {translation.see_more}
                </a>
              ) : null}
            </div>

            {settingsVisible ? (
              <div>
                <div className={styles.formGroup}>
                  <div className={styles.formGroupHeader}>
                    <p>{translation.cookie_essential_title}</p>
                    <input
                      type="checkbox"
                      id="essential"
                      name="essential"
                      onChange={handleEssentialsSetting}
                      checked
                    />
                    <label htmlFor="essential">essential</label>
                  </div>
                  {essentialsErrorVisible ? (
                    <p className={styles.formError}>
                      {translation.cookie_essential_error}
                    </p>
                  ) : null}
                  <p>{translation.cookie_essential_desc}</p>
                </div>

                <div className={styles.formGroup}>
                  <div className={styles.formGroupHeader}>
                    <p>{translation.cookie_marketing_title}</p>
                    <input
                      type="checkbox"
                      id="marketing"
                      name="marketing"
                      onChange={handleChangeSetting('marketing')}
                      checked={settings.marketing}
                    />
                    <label htmlFor="marketing">marketing</label>
                  </div>
                  <p>{translation.cookie_marketing_desc}</p>
                </div>

                <div className={styles.formGroup}>
                  <div className={styles.formGroupHeader}>
                    <p>{translation.cookie_analytics_title}</p>
                    <input
                      type="checkbox"
                      id="analytics"
                      name="analytics"
                      onChange={handleChangeSetting('analytics')}
                      checked={settings.analytics}
                    />
                    <label htmlFor="analytics">analytics</label>
                  </div>
                  <p>{translation.cookie_analytics_desc}</p>
                </div>

                <button
                  type="button"
                  className={styles.saveSettings}
                  onClick={handleSaveSettings}
                >
                  {translation.save}
                </button>
              </div>
            ) : null}

            <div className={styles.noticeActions}>
              <button
                type="button"
                className={styles.noticeAccept}
                onClick={handleSaveSettings}
              >
                {translation.accept}
              </button>
              <button
                type="button"
                className={styles.noticeConfigure}
                onClick={toggleSettings}
              >
                {settingsVisible
                  ? translation.settings_close
                  : translation.settings}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return null
}

export default GdprCookie
