import React from 'react'
import { useIntl } from 'react-intl'

import { SlideTop, ProfessionsIconsGrid } from '../../components'
import { SContainer } from './PlaceToBeFor.styled'
import { useBreakpoint } from '../../modules/breakpoint'

function PlaceToBeFor() {
  const { formatMessage } = useIntl()

  const breakpoint = useBreakpoint()
  const isMobile = breakpoint.lg

  return (
    <SContainer>
      <div className="content">
        <SlideTop>
          <h2 className="heading">
            {formatMessage(
              {
                id: isMobile
                  ? 'placeToBeForHeadingMobile'
                  : 'placeToBeForHeadingDesktop',
              },
              {
                span: (chunk) => <span>{chunk}</span>,
                br: <br />,
              }
            )}
          </h2>
        </SlideTop>
        <ProfessionsIconsGrid />
      </div>
    </SContainer>
  )
}

export default PlaceToBeFor
