import styled, { css } from 'styled-components'

export const SContainer = styled.div`
  ${({ isVisible }) => css`
    .trigger-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      cursor: pointer;
    }
    .trigger {
      max-width: 92%;
    }

    .arrow-wrapper {
      transform: ${isVisible ? 'rotate(0deg)' : 'rotate(180deg)'};

      i {
        &:before {
          font-size: 28px;
        }
      }
    }
  `}
`
