import * as React from 'react'

function EmptyOffersListIcon(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 185 351" {...props}>
        <defs>
          <linearGradient id="a" x1="43.223%" x2="50%" y1="55.161%" y2="26.37%">
            <stop offset="0%" stopColor="#292929" stopOpacity=".11"/>
            <stop offset="100%" stopColor="#E669DD" stopOpacity=".316"/>
          </linearGradient>
          <linearGradient id="b" x1="58.153%" x2="50%" y1="56.034%" y2="25.365%">
            <stop offset=".055%" stopColor="#292929" stopOpacity="0"/>
            <stop offset="100%" stopColor="#E784E1" stopOpacity=".405"/>
          </linearGradient>
          <path id="c" d="M22 27.923c1.105 0 2 .894 2 1.995 0 1.1-.895 1.994-2 1.994H10c-1.105 0-2-.893-2-1.994s.895-1.995 2-1.995zm-6-7.978c1.103 0 2 .894 2 1.995 0 1.1-.897 1.994-2 1.994h-6c-1.105 0-2-.893-2-1.994s.895-1.995 2-1.995zm10.888 15.956H5.11c-.612 0-1.11-.446-1.11-.997V4.986c0-.55.498-.997 1.11-.997H16v6.283c0 3.135 2.434 5.684 5.428 5.684H28v18.948c0 .55-.498.997-1.112.997zM20 5.94l5.484 6.027h-4.056c-.788 0-1.428-.76-1.428-1.695V5.94zm11.48 6.681L20.592.654A2.001 2.001 0 0019.11 0h-14C2.292 0 0 2.238 0 4.986v29.918c0 2.748 2.292 4.986 5.11 4.986h21.778c2.818 0 5.112-2.238 5.112-4.986V13.962c0-.497-.186-.974-.52-1.34z"/>
          <path id="d" d="M46.421 48.269c1.383 0 2.5 1.117 2.5 2.493s-1.117 2.493-2.5 2.493h-15c-1.38 0-2.5-1.117-2.5-2.493s1.12-2.493 2.5-2.493zm-7.5-9.973c1.38 0 2.5 1.117 2.5 2.493a2.498 2.498 0 01-2.5 2.494h-7.5c-1.38 0-2.5-1.117-2.5-2.494a2.498 2.498 0 012.5-2.493zm13.61 19.945H25.31c-.765 0-1.387-.558-1.387-1.246V19.598c0-.688.622-1.247 1.387-1.247h13.613v7.853c0 3.92 3.042 7.106 6.785 7.106h8.215v23.685c0 .688-.623 1.246-1.39 1.246zm-8.61-37.452l6.856 7.535h-5.07c-.985 0-1.785-.95-1.785-2.12V20.79zm14.35 8.352l-13.61-14.958a2.496 2.496 0 00-1.852-.818h-17.5c-3.522 0-6.387 2.797-6.387 6.233v37.397c0 3.435 2.865 6.233 6.387 6.233h27.223c3.522 0 6.39-2.798 6.39-6.233V30.817c0-.621-.233-1.217-.65-1.676z"/>
        </defs>
        <g fill="none" fillRule="evenodd">
          <path fill="#DA00D5" d="M0 195.905l92.5-45.892 92.5 45.892-92.5 45.892z"/>
          <path fill="#FFE4FD" d="M0 195.86l92.5-45.847v-60.5L0 135.362z"/>
          <path fill="#FC86F6" d="M92.5 265.473L0 219.623v-84.482l92.5 45.849z"/>
          <path fill="#FFD0FC" d="M184.5 195.86L92 150.014V89.319l92.5 45.849z"/>
          <path fill="#F7B5F3" d="M92.5 265.473l92.5-45.85v-84.482L92.5 180.99z"/>
          <g>
            <path fill="url(#a)" d="M93 130.493L0 84.587V0l92.497 45.644z" transform="translate(0 219.507)"/>
            <path fill="url(#b)" d="M92.5 121.65L185 76.023V.144L92.5 45.966z" transform="translate(0 219.507)"/>
          </g>
          <g>
            <path fill="#F7B5F3" d="M15.434 85.872L73.17 64.858l24.857 68.294-57.735 21.014z"/>
            <path fill="#FFF" d="M20.35 88.153L70.87 69.766l18.316 50.322-50.518 18.387z"/>
            <path fill="#DA00D5" fillRule="nonzero" d="M59.289 110.569l-.33-1.264c-.949-2.608-.797-4.172 1.335-5.703 4.03-2.976 5.898-7.93 3.938-13.315-2.424-6.661-8.918-9.013-16.156-6.379-7.236 2.634-10.58 8.943-7.993 16.049l8.106-2.95c-.849-2.332.285-4.442 2.716-5.327 2.432-.885 4.596-.164 5.202 1.502.445 1.221.612 2.04-1.03 3.833l-1.973 1.786c-5.266 4.809-4.412 7.515-2.25 13.455l.56 1.18 7.875-2.867zm.872 14.02c2.807-1.022 4.483-3.853 3.484-6.6-.98-2.69-4.083-3.782-6.891-2.76-2.809 1.022-4.426 3.832-3.446 6.522 1 2.747 4.044 3.86 6.852 2.837z"/>
          </g>
          <g>
            <path fill="#FC86F6" d="M117.333 46.51l53.208 30.72-36.338 62.94-53.209-30.72z"/>
            <path fill="#FFD0FC" d="M118.746 51.742l46.557 26.88L138.527 125 91.97 98.12z"/>
            <use fill="#DA00D5" fillRule="nonzero" xlinkHref="#c" transform="rotate(30 -56.807 265.876)"/>
          </g>
          <g transform="rotate(-15 100.912 -154.6)">
            <path fill="#FFE4FD" d="M0 0h76.8v90.847H0z"/>
            <path fill="#FC86F6" d="M4.8 4.781H72v66.94H4.8z"/>
            <use fill="#FFF" fillRule="nonzero" xlinkHref="#d"/>
          </g>
        </g>
      </svg>
  )
}

export default EmptyOffersListIcon
