import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'
import background from '../../assets/images/volunteers-banner-bg.svg'
import backgroundMobile from '../../assets/images/volunteers-banner-bg-mobile.svg'
export const SContent = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    background-image: url(${backgroundMobile});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding: ${theme.space.xl + 8}px ${theme.space.xl + 1}px;
    max-width: 360px;

    ${respondTo.lg`
        padding: 40px 320px 40px 298px;
        max-width: 1190px;
        background-image: url(${background});
    `}

    .heading {
      color: ${theme.colors.black};
      font-weight: ${theme.weight.bold};
      font-size: 1.5rem;
      line-height: 1.875rem;
      margin-bottom: ${theme.space.md + 4}px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      ${respondTo.xxs`
        margin-bottom: ${theme.space.xl + 8}px;
      `}

      ${respondTo.lg`
        font-size: 2.25rem;
        line-height: 3.125rem;
      `}

      span {
        text-shadow: 1px 1px 0 ${theme.colors.azure};
        font-size: 1.75rem;
        line-height: 2.188rem;
        text-align: center;

        ${respondTo.xxs`
          white-space: nowrap;
        `}

        ${respondTo.lg`
          font-size: 3.25rem;
          line-height: 4.125rem;
          text-shadow: 2px 2px 0 ${theme.colors.azure};
        `}
      }
    }

    .subheader {
      color: ${theme.colors.darkestGray};
      font-size: 1.125rem;
      line-height: 1.56rem;
      margin-bottom: ${theme.space.lg + 8}px;
      text-align: center;

      ${respondTo.lg`
        font-size: 1.375rem;
        line-height: 1.875rem;
      `}
    }

    .button-wrapper {
      display: flex;
      justify-content: center;

      a {
        width: 100%;

        ${respondTo.lg`
           width: auto;
        `}
      }

      i {
        margin-left: ${theme.space.sm}px;
        padding-top: 4px;
      }
    }
  `}
`
