import React from 'react'
import { useIntl } from 'react-intl'
import { useTheme } from 'styled-components'

import { SectionHeader } from '../../components'

import {
  SContainer,
  SWrapper,
  SSubHeading,
  SPhoto,
  SPhotoWrapper,
  SBackground,
  SBackgroundSlot,
  SBullet,
  SList,
  SListItem,
  SLeftContainer,
} from './Lectures.styled'

import { BottomLeftPattern, TopRightPattern } from './patterns'
import { useBreakpoint } from 'modules/breakpoint'

function Lectures() {
  const theme = useTheme()
  const { formatMessage } = useIntl()
  const breakpoints = useBreakpoint()

  return (
    <SContainer>
      <SectionHeader
        heading="lecturesHeading"
        headingUnderlineColor={theme.colors.lightBlue}
      />
      <SWrapper>
        <SLeftContainer>
          <SSubHeading>
            {formatMessage({ id: 'lecturesSubheading' })}
          </SSubHeading>
          <SList>
            <SListItem>
              <SBullet pink />
              <span>{formatMessage({ id: 'lecturesContent' })}</span>
            </SListItem>
            <SListItem>
              <SBullet turquoise />
              <span>{formatMessage({ id: 'lecturesSubjects' })}</span>
            </SListItem>
            <SListItem>
              <SBullet green />
              <span>{formatMessage({ id: 'lecturesForm' })}</span>
            </SListItem>
            <SListItem>
              <SBullet blue />
              <span>{formatMessage({ id: 'lecturesTimeLimit' })}</span>
            </SListItem>
          </SList>
        </SLeftContainer>
        <SPhotoWrapper>
          {!breakpoints.lg ? (
            <SBackground>
              <SBackgroundSlot position="bottomleft">
                <BottomLeftPattern />
              </SBackgroundSlot>
              <SBackgroundSlot position="topright">
                <TopRightPattern />
              </SBackgroundSlot>
            </SBackground>
          ) : null}
          <SPhoto />
        </SPhotoWrapper>
      </SWrapper>
    </SContainer>
  )
}

export default Lectures
