import React, { useMemo } from "react"
import { useIntl } from "react-intl"
import { useTheme } from "styled-components"
import { useDispatch } from "react-redux"
import VisibilitySensor from "react-visibility-sensor"
import { animated, Spring } from "react-spring"

import { Button, SectionHeader, SlideTop, Wrapper } from "../../components"

import {
  SAction,
  SContainer,
  SContent,
  SBackground,
} from "./PartnerJoin.styled"
import { TopRightPattern, BottomLeftPattern } from "./patterns"

import { setContactModalVisibility } from "../../state/reducers/app/appActions"

function PartnerJoin({ partner }) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const openCallpageWidget = () => {
     if (typeof window === "undefined") return
     window.callpage("api.widget.open")
   }
   const openContactModal = () => {
     dispatch(setContactModalVisibility(true))
   }

   const openContactModalOrCallpage = () => {
     window.callpage ?
       openCallpageWidget()
       : openContactModal()
   }

  return (
    <SContainer>
      <SBackground>
        <VisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <Spring
              from={{ transform: "translateX(100%)" }}
              to={{
                transform: isVisible ? "translateX(50%)" : "translateX(100%)",
              }}
            >
              {props => (
                <animated.div style={props}>
                  <TopRightPattern style={props} />
                </animated.div>
              )}
            </Spring>
          )}
        </VisibilitySensor>
        <VisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <Spring
              from={{ transform: "translateX(-100%)" }}
              to={{
                transform: isVisible ? "translateX(-45%)" : "translateX(-100%)",
              }}
            >
              {props => (
                <animated.div style={props}>
                  <BottomLeftPattern style={props} />
                </animated.div>
              )}
            </Spring>
          )}
        </VisibilitySensor>
      </SBackground>
      <Wrapper>
        <SlideTop>
          <SContent>
            <SectionHeader
              heading="partnerJoinHeading"
              headingUnderlineColor={theme.colors.green}
              description="partnerJoinDescription"
              withoutAnimation
            />
            <SAction>
              <Button onClick={openContactModalOrCallpage}>
                {formatMessage({ id: "contactUs" })}
              </Button>
            </SAction>
          </SContent>
        </SlideTop>
      </Wrapper>
    </SContainer>
  )
}

export default PartnerJoin
