import styled, { css } from "styled-components"

export const SNewsletterUnsubscribe = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    color: white;

    margin-bottom: ${theme.space.xl}px;

    .header {
      align-self: stretch;
      height: 68px;
      text-align: center;

      padding: ${theme.space.md}px 0;
      background-color: ${theme.colors.gray2};

      margin-bottom: 80px;

      img {
        height: 100%;
        width: auto;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 750px;
      text-align: center;
    }

    .heading {
      font-size: 2.62rem;
      font-weight: ${theme.weight.bold};

      margin-bottom: ${theme.space.xl}px;
    }
    .description {
      font-size: 1.25rem;
      line-height: 1.6;

      margin-bottom: ${theme.space.xl}px;

      &.mail {
        a {
          color: ${theme.colors.pink};
        }
      }
    }
  `}
`
