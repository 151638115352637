import styled, { css } from "styled-components"
import respondTo from "../../theme/respondTo"

export const SSocials = styled.ul`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;

    margin-top: ${theme.space.xl}px;

    li {
      margin-right: ${theme.space.sm}px;
      &:last-child {
        margin-right: 0;
      }
    }

    ${respondTo.lg`
      margin-top: ${theme.space.xxxl}px;
    `}
  `}
`
export const SContainer = styled.section`
  ${({ theme }) => css`
    padding-bottom: ${theme.space.xl}px;
    ${respondTo.lg`
      padding: ${theme.space.xl}px;
    `}
  `}
`

export const SOrganiser = styled.p`
  ${({ theme }) => css`
    color: white;
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: center;
    margin: ${theme.space.xl}px auto ${theme.space.sm}px;
    
    a {
      color: ${theme.colors.pink};
    }
    
     ${respondTo.lg`
       font-size: 1.25rem;
       line-height: 1.5rem;
       margin-bottom: 80px;
    `}
  `}
`

