function CleanCodeIcon(props) {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
          <path fill="#FFF" d="M11.739 20.158l-1.49.347a2.77 2.77 0 01-3.127-1.506l-.657-1.38a3.001 3.001 0 00-.848-1.064l-1.2-.948a2.768 2.768 0 01-.772-3.384l.669-1.375c.2-.413.305-.867.303-1.326l-.007-1.53a2.768 2.768 0 012.164-2.713l1.493-.334c.448-.1.867-.302 1.225-.59l1.19-.959a2.77 2.77 0 013.473 0l1.19.959c.359.288.778.49 1.226.59l1.493.334a2.768 2.768 0 012.164 2.713l-.007 1.53c-.002.459.102.913.303 1.326l.67 1.375a2.77 2.77 0 01-.773 3.384l-1.2.948c-.36.285-.65.649-.848 1.063l-.657 1.38a2.769 2.769 0 01-3.128 1.507l-1.49-.347a3.003 3.003 0 00-1.36 0z"/>
          <path d="M16.03 9.786a.772.772 0 011.13-.042c.325.318.342.85.04 1.19l-4.596 4.8a.773.773 0 01-1.17 0l-2.8-3.159a.873.873 0 01.04-1.19.772.772 0 011.13.041l2.215 2.497 4.012-4.137z" fill="#292929" fillRule="nonzero"/>
        </g>
      </svg>
  )
}

export default CleanCodeIcon
