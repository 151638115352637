import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SCompanyTile = styled.div`
  ${({ theme, isSimilar }) => css`
    max-width: 100%;
    position: relative;

    ${isSimilar &&
    css`
      margin-right: 17px;

      :last-of-type {
        margin-right: 0;
      }
    `}

    a {
      text-decoration: none;
      color: lightGray;

      display: flex;

      padding: ${theme.space.md}px ${theme.space.md}px;
      margin: 0 ${-theme.space.md}px;
      border-top: 1px solid ${theme.colors.gray};

      :last-of-type {
        border-bottom: 1px solid ${theme.colors.gray};
      }

      &:hover {
        .thumbnail {
          box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
            0 6px 30px 5px rgba(0, 0, 0, 0.12),
            0 16px 24px 2px rgba(0, 0, 0, 0.14);
        }

        .name {
          color: ${theme.colors.pink};
        }
      }
    }

    .thumbnail,
    .placeholder {
      width: 72px;
      height: 72px;

      flex-shrink: 0;

      position: relative;

      margin-right: ${theme.space.md}px;

      &.diversity {
        max-width: 200px;
        max-height: 200px;
        position: relative;
        background: linear-gradient(
          135deg,
          #5b1fa3,
          #3c61ce 16%,
          #2ec7d4 32%,
          #cde92a 49%,
          #e7a32e 67%,
          #ec6949 85%,
          #da2a44
        );
        padding: 2px;
      }

      span {
        width: 100%;
        height: 100%;
        padding: 11px;
        background-color: ${theme.colors.white};
        display: flex !important;
        align-items: center;
        justify-content: center;
      }

      .img {
        height: 50px;
        width: 50px;
      }
    }

    .name {
      font-size: 1rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.6;
      color: white;
      margin-right: ${theme.space.xs}px;
      vertical-align: bottom;
    }

    .tooltip {
      .arrow {
        display: none;
      }
    }

    .icon-container,
    .tooltip {
      display: inline;
    }

    .offers-count {
      color: ${theme.colors.lightGray};
      font-size: 0.875rem;
      line-height: 1.5rem;
      margin-top: ${theme.space.xs}px;
    }

    .soap {
      display: inline-block;
      font-size: ${theme.space.md - 4}px;
      font-weight: ${theme.weight.bold};
      padding: 6px;
      border-radius: 12px;

      &-container {
        margin-bottom: ${theme.space.sm}px;

        :first-of-type {
          margin-top: ${theme.space.md - 4}px;
        }
        :last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .tooltip-text {
      font-size: 11px;
      line-height: 1rem;
      text-align: center;
    }

    ${respondTo.lg`
      max-width: 200px;
     
      a {
        display: block;

        padding: 0;
        margin: 0;

        border: none;
        
        :last-of-type {
          border-bottom: none;
        }
      }

      .thumbnail, .placeholder {
        width: 200px;
        height: 200px;
        
        margin-right: 0;
        margin-bottom: ${theme.space.sm}px;
        
        span {
          padding: 50px;        
        }
        
        .img {
          height: 100px;
          width: 100px;
        }
        
        &.diversity {
          padding: 4px;
        }     
      }

      .name {
        font-size: 1.25rem;
        line-height: 1.6;
  
        margin-top: ${theme.space.sm}px;
      }
      
      .offers-count {
        color: ${theme.colors.lightGray};
        font-size: 1rem;
        line-height: 1.25rem;
      }
    `}
  `}
`
