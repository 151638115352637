import React from 'react'
import { useIntl } from 'react-intl'

import useLocalizeRoute from 'utils/useLocalizeRoute'

import { SSpeeches } from './Speeches.styled'
import speechesEmptyIcon from '../../assets/icons/speeches-empty.svg'
import AgendaTile from 'components/AgendaTile/AgendaTile'
import { AppRoute } from 'const'

function Speeches({ speeches }) {
  const { formatMessage } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()

  return (
    <SSpeeches>
      {speeches.length ? (
        speeches.map((item) => (
          <AgendaTile
            key={`agenda-tile-${item.city}`}
            {...{ item, agendaRoute: localizeRouteKey(AppRoute.Agenda) }}
          />
        ))
      ) : (
        <div className="empty">
          <p className="heading">
            {formatMessage({ id: 'speeches.emptyHeading' })}
          </p>
          <img src={speechesEmptyIcon} />
          <p className="information">
            {formatMessage({ id: 'speeches.emptyInformation' })}
            <a target="_blank" href="https://www.facebook.com/codeeurope">
              Facebook
            </a>
          </p>
        </div>
      )}
    </SSpeeches>
  )
}

export default Speeches
