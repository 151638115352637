import React, { useState, useEffect } from "react"
import { SCircle, SDotsContainer } from "./LevelSelect.styled"

function LevelSelect({
  config,
  defaultValue,
  levelPoints,
  totalPointsLimit,
  onChange,
}) {
  const [currentlyHovered, setCurrentlyHovered] = useState(null)
  const [levels, setLevels] = useState(
    Object.entries(config).reduce((acc, [name, label]) => {
      return [
        ...acc,
        {
          name,
          label,
          value: defaultValue,
        },
      ]
    }, [])
  )

  const currentTotalPoints = levels.reduce(
    (acc, current) => acc + current.value,
    0
  )
  const futureTotalPoints = currentlyHovered
    ? levels.reduce((acc, current) => {
        if (currentlyHovered.name === current.name) {
          return acc + (currentlyHovered.index + 1)
        }
        return acc + current.value
      }, 0)
    : currentTotalPoints

  useEffect(() => {
    onChange(levels)
    return () => {
      onChange(levels)
    }
  }, [levels])

  const handleMouseEnter = (name, index) => () => {
    setCurrentlyHovered({ name, index })
  }
  const handleMouseLeave = () => {
    setCurrentlyHovered(null)
  }
  const handleClick = (name, index) => () => {
    setLevels(prevState =>
      futureTotalPoints > totalPointsLimit
        ? prevState
        : prevState.reduce((acc, current) => {
            if (current.name === name)
              return [...acc, { ...current, value: index + 1 }]
            return [...acc, current]
          }, [])
    )
  }

  return (
    <SDotsContainer>
      {levels.map(({ name, label, value }) => {
        return (
          <div className="row" key={name}>
            <span className="label">{label}</span>
            {new Array(levelPoints).fill(true).map((_, index) => {
              const isActive =
                (currentlyHovered?.name === name &&
                  currentlyHovered?.index > index) ||
                currentlyHovered?.name === name
                  ? currentlyHovered?.index >= index
                  : value >= index + 1
              return (
                <SCircle
                  key={`${name}-${index}`}
                  active={isActive}
                  onMouseEnter={handleMouseEnter(name, index)}
                  onMouseLeave={handleMouseLeave}
                  onClick={handleClick(name, index)}
                />
              )
            })}
          </div>
        )
      })}
    </SDotsContainer>
  )
}

export default LevelSelect
