import * as React from 'react'

function MinusIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 2" {...props}>
      <path d="M0 0h10v2H0z" fill="currentColor" fillRule="evenodd" />
    </svg>
  )
}

export default MinusIcon
