import * as React from "react"

function PadlockIcon(props) {
  return (
    <svg viewBox="0 0 38 50" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g strokeWidth={1.5} fill="none" fillRule="evenodd">
        <path
          d="M12.327 23.965l-1.696-13.26c-.582-4.703 2.913-9.03 7.768-9.594 4.856-.564 9.322 2.822 9.905 7.524l1.068 8.747"
          stroke="#FFF"
          strokeLinecap="round"
        />
        <path
          d="M5.115 23.965h27.831a4 4 0 014 4v17.018a4 4 0 01-4 4H5.116a4 4 0 01-4-4V27.965a4 4 0 014-4z"
          stroke="#FFF"
        />
        <path
          d="M15.68 33.653c0 1.41.777 2.633 1.943 3.291v4.265a1.942 1.942 0 003.884 0v-4.265h0c1.165-.658 1.942-1.88 1.942-3.291 0-2.07-1.748-3.762-3.884-3.762-2.137 0-3.884 1.692-3.884 3.762z"
          stroke="#FFF"
        />
        <path
          d="M33.936 40.612v4.55a1 1 0 01-1 1h-6.38 0m7.38-8.695v-4.89"
          stroke="#FC86F6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export default PadlockIcon
