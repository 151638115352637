import React from 'react'

function TicketShape({ active, color, ...res }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 261 418"
      preserveAspectRatio="none"
      {...res}
    >
      <path
        d="M260.5.5v321.25L244.17 334l16.33 12.25v71.25H.5v-71.25L16.83 334 .5 321.75V.5z"
        fill="#353535"
        stroke={active ? color : '#353535'}
      />
    </svg>
  )
}

export default TicketShape
