//Add strings
export default {
  description:
    '<p>Our website uses cookies 🍪. We do this to ensure your user experience and to improve our services, and most importantly to make our website work. These files are also used by research and advertising entities cooperating with us, detailed information can be found in the <a href="#">Cookie Files Policy</a>.</p><p>By clicking the "Accept" button, you consent to the saving of information contained in cookies. You can withdraw your consent at any time in your browser settings.</p>',
  see_more: 'See more >',
  settings: 'Open setting',
  settings_close: 'Close cookies setting',
  accept: 'Accept',
  statement: 'Our cookie statement',
  save: 'Save settings',
  cookie_essential_title: 'Essential',
  cookie_essential_desc:
    'These cookies are strictly necessary to provide you with services available and to use some of its features, for example access to secure areas. Because these cookies are strictly necessary to deliver our services safely, you cannot refuse them without impacting how our website functions. You can block or delete them by changing your browser settings.',
  cookie_essential_error:
    'You cannot decline the usage of our essential cookies.',
  cookie_marketing_title: 'Marketing',
  cookie_marketing_desc:
    'These cookies and pixels are used to make advertising messages more relevant to you and your interests. They also perform functions like preventing the same ad from continuously reappearing, ensuring that ads display properly and in some cases selecting advertisements based on your interests. Further, we are using these cookies and pixels in order to exclude you from advertising and to help to better define user groups.',
  cookie_analytics_title: 'Analytical',
  cookie_analytics_desc:
    'These cookies allow us to collect information on an aggregate basis about how our website is used or help us customize it for users.'
}
