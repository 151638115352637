import React from 'react'
import { useBreakpoint } from 'modules/breakpoint'
import { useIntl } from 'react-intl'

import PlaneIcon from '../../assets/icons/PlaneIcon'
import PlayIcon from '../../assets/icons/PlayIcon'

import { SFormSuccess } from './BecomeSpeaker.styled'

function BecomeSpeakerSuccess() {
  const breakpoint = useBreakpoint()
  const { formatMessage } = useIntl()

  return (
    <SFormSuccess>
      <div>
        <h3>
          {formatMessage(
            { id: 'becomeSpeakerSuccessHeading' },
            {
              br: <br />,
            }
          )}
        </h3>
        <PlaneIcon className="plane-icon" />
        <p>{formatMessage({ id: 'becomeSpeakerSuccessSoon' })}</p>

        <h3 className="expect">
          {formatMessage({ id: 'becomeSpeakerSuccessExpect' })}
        </h3>
        <PlayIcon className="play-icon" />
        <p>{formatMessage({ id: 'becomeSpeakerSuccessSee' })}</p>

        <a href="#">{formatMessage({ id: 'seeMore' })}</a>
      </div>

      <div className="background">
        {!breakpoint.lg ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="262" height="38">
            <g fill="none" fillRule="evenodd">
              <rect
                width="50"
                height="13"
                x="102"
                y="25"
                fill="#FC86F6"
                rx="6"
              />
              <rect width="13" height="13" x="111" fill="#0051D3" rx="6" />
              <rect
                width="92"
                height="13"
                x="170"
                y="25"
                fill="#18E5EF"
                rx="6"
              />
              <rect width="92" height="13" x="142" fill="#0051D3" rx="6" />
              <rect width="92" height="13" fill="#1ED760" rx="6" />
            </g>
          </svg>
        ) : null}
        <svg xmlns="http://www.w3.org/2000/svg" width="164" height="38">
          <g fill="none" fillRule="evenodd">
            <rect width="92" height="13" x="41" y="25" fill="#FC86F6" rx="6" />
            <rect width="50" height="13" x="77" fill="#18E5EF" rx="6.5" />
            <rect width="28" height="13" x="31" fill="#1ED760" rx="6.5" />
            <rect
              width="13"
              height="13"
              x="151"
              y="25"
              fill="#0051D3"
              rx="6.5"
            />
            <rect width="13" height="13" fill="#18E5EF" rx="6.5" />
          </g>
        </svg>
      </div>
    </SFormSuccess>
  )
}

export default BecomeSpeakerSuccess
