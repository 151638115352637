import React, { useState } from "react"
import VisibilitySensor from "react-visibility-sensor"

function VSensor(props) {
  const [active, setActive] = useState(true)

  const { once, children, ...rest } = props
  return (
    <VisibilitySensor
      active={active}
      onChange={isVisible => once && isVisible && setActive(false)}
      {...rest}
    >
      {({ isVisible }) => children({ isVisible })}
    </VisibilitySensor>
  )
}

export default VSensor
