import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Helmet } from 'react-helmet'

import client from '../api/client'
import { SpeakerDetails } from 'sections'
import useLocalizeRoute from 'utils/useLocalizeRoute'
import { AppRoute } from 'const'

function Speaker() {
  const { slug } = useParams()
  const history = useHistory()

  const [speaker, setSpeaker] = useState(null)
  const [loading, setLoading] = useState(true)

  const { localizeRouteKey } = useLocalizeRoute()

  const { formatMessage } = useIntl()

  const fetchSpeaker = async () => {
    setLoading(true)
    try {
      const response = await client({
        endpoint: `api/speaker/slug?speaker_slug=${slug}`,
      })

      if (!response.ok) {
        throw new Error(await response.json())
      }

      const { data } = await response.json()
      if (data && Array.isArray(data) && data.length) {
        const [speaker] = data
        setSpeaker(speaker)
        setLoading(false)
        return
      }

      setLoading(false)
      console.log(localizeRouteKey(AppRoute.NotFound))
      history.replace(localizeRouteKey(AppRoute.NotFound))
    } catch (error) {
      setLoading(false)
      history.replace(localizeRouteKey(AppRoute.NotFound))
      console.log('[GET] speaker details error: ', error)
    }
  }

  useEffect(() => {
    fetchSpeaker()
  }, [slug])

  return (
    <>
      <Helmet>
        <title>
          {formatMessage(
            { id: 'seoTitleSpeaker' },
            { name: speaker?.speaker_name ?? '' }
          )}
        </title>
        <meta
          name="description"
          content={formatMessage(
            { id: 'seoDescriptionSpeaker' },
            {
              name: speaker?.speaker_name ?? '',
            }
          )}
        />
        <meta
          name="keywords"
          content={formatMessage(
            { id: 'seoKeywordsSpeaker' },
            { name: speaker?.speaker_name ?? '' }
          )}
        />
        <meta
          property="og:title"
          content={formatMessage(
            { id: 'seoTitleSpeaker' },
            { name: speaker?.speaker_name ?? '' }
          )}
        />
        <meta
          property="og:site_name"
          content={formatMessage(
            { id: 'seoTitleSpeaker' },
            { name: speaker?.speaker_name ?? '' }
          )}
        />
        <meta
          property="og:description"
          content={formatMessage(
            { id: 'seoDescriptionSpeaker' },
            {
              name: speaker?.speaker_name ?? '',
            }
          )}
        />
        <meta name="twitter:image:src" content={''} />
        <meta
          name="twitter:title"
          content={formatMessage(
            { id: 'seoTitleSpeaker' },
            { name: speaker?.speaker_name ?? '' }
          )}
        />
        <meta
          name="twitter:description"
          content={formatMessage(
            { id: 'seoDescriptionSpeaker' },
            {
              name: speaker?.speaker_name ?? '',
            }
          )}
        />
      </Helmet>
      <SpeakerDetails {...{ speaker, loading }} />
    </>
  )
}

export { Speaker }
