import React from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { AppRoute } from 'const'
import useLocalizeRoute from 'utils/useLocalizeRoute'

import CleanCodeIcon from '../../assets/icons/CleanCodeIcon'
import imagePlaceholder from '../../assets/images/image-placeholder.svg'

import { BrandThumbTracks, Tooltip } from '../index'

import { SCompanyTile } from './CompanyTile.styled'

function CompanyTile({ item: employer, ...res }) {
  const { localizeRouteKey } = useLocalizeRoute()
  const { formatMessage } = useIntl()

  const { employer_name, employer_image_url, employer_offer_count } = employer

  const hasDiversity = employer?.employer_soaps_list.some(
    (soap) => soap.soap_name === 'Diversity'
  )
  const hasCleanCode = employer?.employer_soaps_list.some(
    (soap) => soap.soap_name === 'Clean Code'
  )
  const partnershipSoaps = employer?.employer_soaps_list.filter(
    (soap) => soap.soap_name !== 'Diversity' && soap.soap_name !== 'Clean Code'
  )

  const tooltipContent = (
    <p className="tooltip-text">
      Clean Code
      <br />
      Partner
    </p>
  )

  return (
    <SCompanyTile {...res}>
      <Link
        to={localizeRouteKey(AppRoute.Company).replace(
          ':slug',
          employer?.employer_slug
        )}
      >
        {employer_image_url ? (
          <div className={`thumbnail ${hasDiversity ? 'diversity' : ''}`}>
            <span>
              <div
                className="img"
                style={{
                  background: `center / contain no-repeat url("${employer_image_url}`,
                }}
              />
            </span>
          </div>
        ) : (
          <img src={imagePlaceholder} className="placeholder" alt="" />
        )}
        <div>
          <div className="name-container">
            <span className="name">{employer_name}</span>
            {hasCleanCode && (
              <Tooltip
                className="tooltip"
                value={tooltipContent}
                offset={[48, -82]}
                render={({ referenceElement, show, hide }) => (
                  <div
                    className="icon-container"
                    onMouseEnter={show}
                    onMouseLeave={hide}
                    ref={referenceElement}
                  >
                    <CleanCodeIcon width="24px" height="24px" />
                  </div>
                )}
              />
            )}
          </div>
          <div className="offers">
            {employer_offer_count ? (
              <p className="offers-count">
                {formatMessage(
                  { id: 'offersPlural' },
                  { count: employer_offer_count }
                )}
              </p>
            ) : null}
          </div>
          {employer?.employer_tracks_list ? (
            <BrandThumbTracks tracks={employer.employer_tracks_list} />
          ) : null}
          <div>
            {partnershipSoaps?.map((soap) => (
              <div className="soap-container" key={soap.soap_id}>
                <span
                  className="soap"
                  style={{
                    color: `${soap.soap_color}`,
                    border: `1px solid ${soap.soap_color}`,
                  }}
                >
                  {soap.soap_name}
                </span>
              </div>
            ))}
          </div>
        </div>
      </Link>
    </SCompanyTile>
  )
}

export default CompanyTile
