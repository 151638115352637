import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SSpeeches = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    text-align: left !important;

    padding: ${theme.space.md}px;
    margin-top: ${theme.space.xl}px;

    background-color: ${theme.colors.darkestGray};
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
      0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14);

    .heading {
      font-size: 2rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.25;
      color: white;

      padding-right: ${theme.space.xxxl}px;
    }

    img {
      max-width: 138px;
      width: 100%;
      height: auto;

      display: block;

      margin: ${theme.space.xl}px auto;
    }

    .information {
      font-size: 1.25rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.2;
      color: white;

      padding-right: ${theme.space.xxxl}px;

      a {
        color: ${theme.colors.pink};
      }
    }

    ${respondTo.lg`
      text-align: center;
      padding: ${theme.space.xxxl}px;

      .heading {
        padding: 0;
      }

      .information {
        padding: 0;
      }
    `}
  `}
`
