import React from 'react'
import { useIntl } from 'react-intl'
import { useTheme } from 'styled-components'

import { SlideTop } from '../../components'
import { SDataContainer, SData, SBigNumbers } from './Numbers.styled'

function Numbers({ wrap }) {
  const theme = useTheme()
  const { formatMessage } = useIntl()

  return (
    <SDataContainer {...{ wrap }}>
      <SData as={SlideTop} once>
        <h3 className="data-title">
          {formatMessage(
            { id: 'ceInNumbersSpeakers' },
            {
              span: (chunk) => (
                <SBigNumbers color={theme.colors.azure}>{chunk}</SBigNumbers>
              ),
            }
          )}
        </h3>
      </SData>
      <SData as={SlideTop} once delay={50}>
        <h3 className="data-title">
          {formatMessage(
            { id: 'ceInNumbersParticipants' },
            {
              span: (chunk) => (
                <SBigNumbers color={theme.colors.azure}>{chunk}</SBigNumbers>
              ),
            }
          )}
        </h3>
      </SData>
      <SData as={SlideTop} once delay={100}>
        <h3 className="data-title">
          {formatMessage(
            { id: 'ceInNumbersWorkshops' },
            {
              span: (chunk) => (
                <SBigNumbers color={theme.colors.azure}>{chunk}</SBigNumbers>
              ),
            }
          )}
        </h3>
      </SData>
      <SData as={SlideTop} once delay={150}>
        <h3 className="data-title">
          {formatMessage(
            { id: 'ceInNumbersPartners' },
            {
              span: (chunk) => (
                <SBigNumbers color={theme.colors.azure}>{chunk}</SBigNumbers>
              ),
            }
          )}
        </h3>
      </SData>
    </SDataContainer>
  )
}

export default Numbers
