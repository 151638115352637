import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'

import config from 'const/config'

import app from './reducers/app/appReducer'
import tickets from './reducers/tickets/ticketsReducer'
import user from './reducers/user/userReducer'

const reducer = combineReducers({
  app,
  tickets,
  user,
})

const createStore = () =>
  configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware()],
    devTools: config.APP_ENV === 'dev',
  })

export default createStore
