export const plQuotes = [
  {
    text: 'Było dużo ciekawych prelekcji ze świetnymi prelegentami! Zdecydowanie polecam i do zobaczenia za rok!',
    author: 'Paweł Galerczyk',
  },
  {
    text: 'We couldn’t miss it – Code Europe – the largest programming conference in Poland – bigger and more packed with knowledge than ever! It was blast!',
    author: 'Team Order2Cash: a Billtrust solution',
  },
]

export const enQuotes = [
  {
    text: 'We couldn’t miss it – Code Europe – the largest programming conference in Poland – bigger and more packed with knowledge than ever! It was blast!',
    author: 'Team Order2Cash: a Billtrust solution',
  },
  {
    text: 'Thank you, #CodeEurope2022, for the great conference, new knowledge, and experienced speakers who brought to life remarkable technologies. Selfie with the legend as a bonus :)',
    author: 'Yevhenii Parasochka',
  },
  {
    text: "By far, this is the best organized conference I've been to! I highly recommend it to all of my speaker friends. Go there, you won't be sorry.",
    author: 'Todor Todorov',
  },
]
