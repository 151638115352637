import React from 'react'
import { useIntl } from 'react-intl'
import { useTheme } from 'styled-components'
import { Link } from 'react-scroll'

import { Button, PatternBackgroundSlot, SectionHeader } from '../../components'

import {
  SSubHeading,
  SAction,
  SContainer,
  SWrapper,
  SColumnContent,
  SPhoto,
  SPhotoDescriptionTitle,
  SPhotoDescription,
  SBackground,
  SBackgroundContent,
} from './SpeakersJoin.styled'

import { TopRightPattern, BottomLeftPattern } from './patterns'

import { useBreakpoint } from 'modules/breakpoint'

function SpeakersJoin() {
  const theme = useTheme()
  const { formatMessage } = useIntl()
  const breakpoints = useBreakpoint()

  return (
    <SContainer>
      {!breakpoints.lg ? (
        <SBackground>
          <SBackgroundContent>
            <PatternBackgroundSlot position="abovetopright">
              <TopRightPattern />
            </PatternBackgroundSlot>
            <PatternBackgroundSlot position="bottomleft">
              <BottomLeftPattern />
            </PatternBackgroundSlot>
          </SBackgroundContent>
        </SBackground>
      ) : null}
      <SWrapper>
        {breakpoints.lg ? (
          <SectionHeader
            notCentered
            heading="speakersJoinHeading"
            headingUnderlineColor={theme.colors.green}
          />
        ) : null}
        <SColumnContent heading>
          {!breakpoints.lg ? (
            <SectionHeader
              notCentered
              heading="speakersJoinHeading"
              headingUnderlineColor={theme.colors.green}
            />
          ) : null}
          <SSubHeading>
            {formatMessage({ id: 'speakersJoinDescription' })}
          </SSubHeading>
          <SAction>
            <Button as={Link} to="speakersForm" smooth>
              {formatMessage({ id: 'becomeSpeaker' })}
            </Button>
          </SAction>
        </SColumnContent>
        <SColumnContent specialistPhoto>
          <SPhoto specialists />
          <SPhotoDescriptionTitle>
            {formatMessage({
              id: 'speakersJoinPhotoDescriptionTitleSpecialists',
            })}
          </SPhotoDescriptionTitle>
          <SPhotoDescription>
            {formatMessage(
              { id: 'speakersJoinPhotoDescriptionSpecialists' },
              {
                br: <br />,
              }
            )}
          </SPhotoDescription>
        </SColumnContent>
        <SColumnContent contentPhoto>
          <SPhoto content />
          <SPhotoDescriptionTitle>
            {formatMessage({ id: 'speakersJoinPhotoDescriptionTitleContent' })}
          </SPhotoDescriptionTitle>
          <SPhotoDescription>
            {formatMessage(
              { id: 'speakersJoinPhotoDescriptionContent' },
              {
                br: <br />,
              }
            )}
          </SPhotoDescription>
        </SColumnContent>
      </SWrapper>
    </SContainer>
  )
}

export default SpeakersJoin
