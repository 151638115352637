import React, { useMemo } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import { useBreakpoint } from 'modules/breakpoint'
import Masonry from 'react-responsive-masonry'
import { useTheme } from 'styled-components'

import { CustomUnderline, SlideTop, Wrapper } from '../../components'

import { STweet, STweets, SPagination, STweetsSlider } from './Tweets.styled'

import tweetsList, { partnerTweets } from './tweetsList'
import { useIntl } from 'react-intl'

function Tweets({ partner, speakers }) {
  const theme = useTheme()
  const breakpoints = useBreakpoint()
  const { formatMessage } = useIntl()

  const tweets = useMemo(() => (partner ? partnerTweets : tweetsList), [])

  function renderGrid() {
    return (
      <div>
        <Masonry gutter={15}>
          {tweets.map((tweet) => (
            <SlideTop key={tweet.url}>
              <STweet href={tweet.url}>
                <img src={tweet.image} alt={`${tweet.author} tweet`} />
              </STweet>
            </SlideTop>
          ))}
        </Masonry>
      </div>
    )
  }

  function renderSwiper() {
    return (
      <STweetsSlider>
        <Swiper
          slidesPerView={1.1}
          spaceBetween={8}
          centeredSlides={true}
          modules={[Pagination]}
          pagination={{
            el: '.swiper-custom-pagination',
            clickable: true,
            renderBullet: (index, className) => {
              return `<span key=${index} class=${className}></span>`
            },
          }}
        >
          {tweetsList.map((tweet: Tweet) => {
            return (
              <SwiperSlide key={tweet.url}>
                <STweet href={tweet.url}>
                  <img src={tweet.image} alt={`${tweet.author} tweet`} />
                </STweet>
              </SwiperSlide>
            )
          })}
          <SPagination>
            <div className="swiper-custom-pagination" />
          </SPagination>
        </Swiper>
      </STweetsSlider>
    )
  }

  return (
    <STweets {...{ partner, speakers }}>
      <Wrapper>
        <h2 className="heading">
          {formatMessage(
            { id: speakers ? 'tweetsHeadingSpeakers' : 'tweetsHeading' },
            {
              span: (chunk) => (
                <CustomUnderline color={theme.colors.blue}>
                  {chunk}
                </CustomUnderline>
              ),
            }
          )}
        </h2>
        <div className="tweets-content">
          {!breakpoints.lg ? renderGrid() : renderSwiper()}
        </div>
      </Wrapper>
    </STweets>
  )
}

export default Tweets
