import React from 'react'
import { useIntl } from 'react-intl'
import VisibilitySensor from 'react-visibility-sensor'
import { animated, Spring } from 'react-spring'

import { SlideTop } from '../../components'
import { SContainer, SColor } from './SkilledProfessionals.styled'
import diagram from '../../assets/images/experience-diagram.svg'
import graph from '../../assets/images/specializations-graph.svg'
import LongGreenPattern from '../../assets/patterns/LongGreenPattern'
import twoLinesPattern from '../../assets/patterns/two-lines-pattern.svg'

const items = [
  {
    title: 'skilledProfessionalsExperience',
    image: diagram,
    data: [
      { color: '#1ED760', percent: '55%', description: 'moreThanFiveYears' },
      { color: '#DA00D5', percent: '18%', description: 'threeToFiveYears' },
      { color: '#FC86F6', percent: '14%', description: 'twoToThreeYears' },
      { color: '#BDBDBD', percent: '13%', description: 'upToOneYear' },
    ],
  },
  {
    title: 'skilledProfessionalsSpecializations',
    image: graph,
    data: [
      {
        color: '#1ED760',
        percent: '42%',
        description: 'developer',
      },
      { color: '#DA00D5', percent: '13%', description: 'devOps' },
      { color: '#FC86F6', percent: '12%', description: 'analyst' },
      { color: '#BDBDBD', percent: '9%', description: 'engineer' },
      { color: '#18E5EF', percent: '9%', description: 'manager' },
      { color: '#ED3550', percent: '6%', description: 'tester' },
      { color: '#7957AE', percent: '5%', description: 'architect' },
      { color: '#FFFFFF', percent: '4%', description: 'other' },
    ],
  },
]
function SkilledProfessionals() {
  const { formatMessage, locale } = useIntl()

  return (
    <VisibilitySensor
      minTopValue={15}
      offset={{ top: 300 }}
      scrollCheck
      scrollDelay={5}
      partialVisibility="top"
    >
      {({ isVisible }) => {
        return (
          <SContainer>
            <SlideTop>
              <h2 className="heading">
                {formatMessage(
                  { id: 'skilledProfessionalsHeading' },
                  {
                    span: (chunk) => <span>{chunk}</span>,
                    br: <br />,
                  }
                )}
              </h2>
              <p className="subheader">
                {formatMessage({ id: 'skilledProfessionalsSubheader' })}
              </p>
            </SlideTop>
            <Spring from={{ opacity: 0 }} to={{ opacity: isVisible ? 0 : 1 }}>
              {({ opacity }) => {
                return (
                  <div className="background">
                    <animated.div
                      style={{
                        opacity,
                        height: '100%',
                      }}
                    >
                      <div className="background">
                        <div className="positionRight">
                          <LongGreenPattern />
                        </div>
                        {locale === 'pl' ? null : (
                          <div className="positionLeft">
                            <img src={twoLinesPattern} alt="" />
                          </div>
                        )}
                      </div>
                    </animated.div>
                  </div>
                )
              }}
            </Spring>
            <div>
              {items.map((area) => (
                <div className="data" key={area.title}>
                  <img src={area.image} alt="" />
                  <div>
                    <p className="data-title">
                      {formatMessage({ id: area.title })}
                    </p>
                    {area.data.map((piece) => (
                      <div className="data-text" key={piece.description}>
                        <SColor className="data-color" color={piece.color} />
                        <span className="data-percent">{piece.percent}</span>
                        <span className="data-description">
                          {formatMessage({ id: piece.description })}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </SContainer>
        )
      }}
    </VisibilitySensor>
  )
}

export default SkilledProfessionals
