import React from 'react'
import { animated, Spring } from 'react-spring'
import { useIntl } from 'react-intl'
import { useTheme } from 'styled-components'
import VisibilitySensor from 'react-visibility-sensor'

import { Button, CustomUnderline, SlideTop } from '../../components'
import { SContainer } from './Partners.styled'

import getArrayChunks from 'utils/getArrayChunks'
import { useBreakpoint } from '../../modules/breakpoint'
import topGreenPattern from '../../assets/patterns/top-green-pattern.svg'
import bottomGreenPattern from '../../assets/patterns/bottom-green-pattern.svg'
import { NavLink } from 'react-router-dom'
import { AppRoute } from '../../const'
import useLocalizeRoute from '../../utils/useLocalizeRoute'

function Partners({ patronagesList, partnersList }) {
  const theme = useTheme()
  const { locale, formatMessage } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()
  const breakpoint = useBreakpoint()
  const isMobile = breakpoint.xl

  const [patronagesWithoutSocial, socialPatronages] = patronagesList.reduce(
    (patronages, patronage) => {
      patronages[patronage.slug !== 'social-patron' ? 0 : 1].push(patronage)
      return patronages
    },
    [[], []]
  )

  const renderGrid = (list, iterator) => {
    return (
      <>
        {list?.map((el, index) => {
          const rows =
            el[iterator].length > 5
              ? Array.from(getArrayChunks(el[iterator], isMobile ? 3 : 5))
              : el[iterator]

          return (
            <div className="category" key={`${iterator}-${index}`}>
              <SlideTop>
                <h3 className="category-title">
                  {locale !== 'pl' ? el.name_en : el.name}
                </h3>
              </SlideTop>
              <SlideTop>
                <div className="partner-type">
                  {rows.some((row) => Array.isArray(row))
                    ? rows.map((row, index) => (
                        <div key={`row-${index}`} className="row">
                          {row.map((singleEl) => (
                            <a
                              key={singleEl.id}
                              href={singleEl.link}
                              target="_blank"
                              className="partner-type-single"
                            >
                              <div className="partner-type-logo">
                                <img src={singleEl.img} />
                              </div>
                            </a>
                          ))}
                        </div>
                      ))
                    : rows.map((singleEl) => (
                        <div
                          key={`single-element-${singleEl.id}`}
                          className="row"
                        >
                          <a
                            key={singleEl.id}
                            href={singleEl.link}
                            target="_blank"
                            className="partner-type-single"
                          >
                            <div className="partner-type-logo">
                              <img src={singleEl.img} />
                            </div>
                          </a>
                        </div>
                      ))}
                </div>
              </SlideTop>
            </div>
          )
        })}
      </>
    )
  }

  if (!patronagesList.length && !partnersList.length) return null

  return (
    <VisibilitySensor
      minTopValue={15}
      scrollCheck
      offset={{ top: 400 }}
      scrollDelay={5}
      partialVisibility="top"
    >
      {({ isVisible }) => (
        <SContainer>
          {!isMobile ? (
            <Spring from={{ opacity: 0 }} to={{ opacity: isVisible ? 0 : 1 }}>
              {({ opacity }) => {
                return (
                  <div className="background">
                    <animated.div
                      style={{
                        opacity,
                        height: '100%',
                      }}
                    >
                      <div className="positionLeft">
                        <img src={topGreenPattern} alt="" />
                      </div>
                      <div className="positionRight">
                        <img src={bottomGreenPattern} alt="" />
                      </div>
                    </animated.div>
                  </div>
                )
              }}
            </Spring>
          ) : null}
          <div className="content">
            <div className="triangle" />
            <div className="triangle" />
            <div className="triangle" />
            <div className="triangle" />
            <div className="triangle" />
            <SlideTop>
              <h2 className="heading">
                {formatMessage(
                  { id: 'partnersHeading' },
                  {
                    span: (chunk) => (
                      <CustomUnderline color={theme.colors.blue}>
                        {chunk}
                      </CustomUnderline>
                    ),
                  }
                )}
              </h2>
            </SlideTop>
            {renderGrid(patronagesWithoutSocial, 'patronages')}
            {renderGrid(partnersList, 'partners')}
            {renderGrid(socialPatronages, 'patronages')}
            <SlideTop>
              <div className="button-wrapper">
                <Button
                  as={NavLink}
                  to={localizeRouteKey(AppRoute.BecomePartner)}
                  secondary="true"
                  height="52px"
                >
                  {formatMessage({
                    id: isMobile ? 'partnersButtonMobile' : 'partnersButton',
                  })}
                  <i className="ri-arrow-right-line" />
                </Button>
              </div>
            </SlideTop>
          </div>
        </SContainer>
      )}
    </VisibilitySensor>
  )
}

export default Partners
