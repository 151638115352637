import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const STopicTitle = styled.h3`
  ${({ theme }) => css`
    font-size: 1.125rem;
    line-height: 1.56rem;
    font-weight: ${theme.weight.bold};
    color: white;
    margin-top: ${theme.space.md}px;

    ${respondTo.lg`
      font-size: 1.375rem;
      line-height: 1.875rem;
      margin-top: ${theme.space.md + 4}px;
    `}
  `}
`
export const STopicCircle = styled.span`
  ${({ theme, color }) => css`
    width: 32px;
    height: 32px;

    display: block;
    border-radius: 16px;

    ${color &&
    css`
      background-color: ${color};
    `}
  `}
`
export const STopic = styled.aside`
  ${({ theme }) => css`
    margin-top: ${theme.space.xl}px;

    .topic {
      &-description {
        position: relative;
        line-height: 1.375rem;
        color: ${theme.colors.lightGray};
        padding-top: ${theme.space.xl}px;
        padding-right: ${theme.space.xl}px;

        &::before {
          content: '';
          width: 80px;
          height: 1px;
          background-color: #a5a5a5;
          position: absolute;
          top: ${theme.space.md}px;
          left: 0;
        }

        ${respondTo.lg`
          padding-top: ${theme.space.xl + 4}px;
          
          &::before {
            top: ${theme.space.md + 4}px;
          }
        `}
      }
    }
  `}
`
export const STopics = styled.div`
  ${({ theme }) => css`
    ${respondTo.lg`
      display: grid;
      grid-template-columns: 1fr 1fr minmax(100px, 324px);
      grid-template-rows: repeat(1fr, 2);
      gap: 0px 0px;
      grid-template-areas:
        ". . ."
        ". . .";
    `}
  `}
`
export const SHeading = styled.h2`
  ${({ theme }) => css`
      font-size: 2rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.25;
      color: white;

      ${respondTo.lg`
        font-size: 2.625rem;
        line-height: 1.14;
      `}
    }
  `}
`
