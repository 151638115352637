import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SCompanyDetails = styled.section`
  ${({ theme }) => {
    return css`
      padding: ${theme.space.xl}px 0;

      .container {
        display: flex;
        flex-direction: column;
      }

      .right-side {
        font-size: 1rem;
        order: -1;
      }

      .single-social {
        display: block;

        a {
          word-break: break-all;

          &:hover {
            color: ${theme.colors.pink};
          }
        }

        :first-of-type {
          margin-top: 0;
        }
      }

      .location {
        &-container {
          color: ${theme.colors.pink};
          display: flex;
          align-items: center;

          :first-of-type {
            margin-bottom: ${theme.space.sm}px;
          }

          svg {
            width: 9px;
            height: 12px;
          }
        }
        &-name {
          color: ${theme.colors.white};
          display: inline-block;
          font-size: 0.875rem;
          margin-left: ${theme.space.sm}px;
        }
      }

      .tracks {
        .track {
          &-list {
            display: flex;
            flex-wrap: wrap;

            &-item {
              display: flex;
              align-items: center;
              margin-right: 24px;
              margin-bottom: 12px;
              color: white;

              span {
                width: 12px;
                height: 12px;
                border-radius: 6px;
                display: block;
                margin-top: 2px;
                margin-right: 8px;
              }
            }
          }
        }
      }

      .tooltip {
        display: inline-block;

        &-text {
          font-size: 11px;
          line-height: 1rem;
          text-align: center;
        }
        .arrow {
          display: none;
        }
      }

      .icon-container {
        display: inline;
        margin-left: ${theme.space.sm}px;
      }

      .clean-code-icon {
        width: ${theme.space.lg}px;
        height: ${theme.space.lg}px;
        vertical-align: baseline;
      }

      .benefits {
        &-list {
          color: ${theme.colors.white};
          display: flex;
          flex-direction: column;
          font-size: 1rem;
        }
        &-container {
          display: flex;
          margin-bottom: ${theme.space.md}px;

          :last-of-type {
            margin-bottom: 0;
          }
        }
        &-icon {
          height: 20px;
          width: 20px;
          margin-right: 10px;
        }
      }

      .details-single-soap {
        display: inline-block;
        font-size: ${theme.space.md - 4}px;
        font-weight: ${theme.weight.bold};
        padding: 6px;
        border-radius: 12px;
        margin-right: ${theme.space.sm}px;
        margin-top: ${theme.space.sm}px;

        :first-of-type {
          margin-top: ${theme.space.lg}px;
        }

        :last-of-type {
          margin-right: 0;
        }
      }

      .show-less-more {
        color: ${theme.colors.pink};
        text-align: right;
        margin-top: ${theme.space.lg}px;

        &-text {
          cursor: pointer;
          margin-right: ${theme.space.xs}px;

          &:hover {
            outline: 0;
            border-bottom: 1px solid ${theme.colors.pink};
          }
        }
      }

      .about {
        color: ${theme.colors.white};

        &-industry {
          margin-bottom: ${theme.space.md}px;
        }

        &-headquarters {
          line-height: 1.25;
        }
      }

      .details-thumbnail {
        max-width: 358px;
        width: 100%;
        height: 172px;

        margin: 0 auto ${theme.space.md}px;

        &.diversity {
          position: relative;
          background: linear-gradient(
            135deg,
            #5b1fa3,
            #3c61ce 16%,
            #2ec7d4 32%,
            #cde92a 49%,
            #e7a32e 67%,
            #ec6949 85%,
            #da2a44
          );
          padding: 3px;
        }

        span {
          display: flex !important;
          align-items: center;
          justify-content: center;
          background-color: ${theme.colors.white};
          width: 100%;
          height: 100%;
        }

        .img {
          width: 120px;
          height: 120px;
          display: block;
        }
      }

      .details-name {
        font-size: 2rem;
        font-weight: ${theme.weight.bold};
        line-height: 1.25;
        color: white;

        margin-top: ${theme.space.md}px;
      }

      .tags {
        margin-top: ${theme.space.lg}px;
      }

      .socials {
        li {
          min-height: 25px;
          display: flex;
          align-items: center;
          margin-top: ${theme.space.md}px;
        }

        .icon {
          margin-right: ${theme.space.md}px;
        }

        a {
          font-size: 0.75rem;
          line-height: 1.33;
          text-decoration: none;
          color: white;
        }
      }

      .description {
        font-size: 0.875rem;
        line-height: 1.71;
        color: #ffffff;

        .section-more {
          display: block;
          margin-left: auto;
        }
      }

      .section {
        &-heading {
          font-size: 1.25rem;
          font-weight: ${theme.weight.bold};
          line-height: 1.6;
          color: ${theme.colors.white};

          margin-top: ${theme.space.xl + theme.space.sm}px;
          margin-bottom: ${theme.space.md}px;
        }

        &-heading-lg {
          color: ${theme.colors.white};
          font-size: 2rem;
          font-weight: ${theme.weight.bold};
          line-height: 2.5rem;
          margin-top: ${theme.space.xxxl}px;
          margin-bottom: ${theme.space.xl}px;
        }

        &-more {
          color: ${theme.colors.pink};

          border: none;
          box-shadow: none;
          background: none;
          border-bottom: 1px solid transparent;

          margin: ${theme.space.md}px 0 0 0;
          padding: 0;

          cursor: pointer;

          &:hover {
            outline: 0;
            border-bottom: 1px solid ${theme.colors.pink};
          }

          svg {
            margin-bottom: 2px;
          }
        }
      }

      .similar-employers {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: ${theme.space.md}px;
      }

      .less-margin-top {
        margin-top: ${theme.space.xl}px !important;
      }

      .bold {
        font-weight: ${theme.weight.bold};
      }

      ${respondTo.lg`
      padding: ${theme.space.xxxl}px 0 ${theme.space.xl}px;
    
      .container {
        flex-direction: row;
      }
      
      .left-side {
        width: 57%;
        margin-right: 96px;
      }
      
      .right-side { 
        order: 1;
      }
      
      .right-container {
        background-color: ${theme.colors.gray2};
        box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14);
        padding: 40px;
        width: 385px;
      }
      
      .details-name {
        font-size: 2.62rem;
        line-height: 1.14;
      }

      .socials {
        display: flex;
        flex-direction: column;
        
        a {
          font-size: 0.75rem;
          line-height: 1.33;
          text-decoration: none;
          color: white;
        }
      }
      
      .LinesEllipsis {
        font-size: 1rem;
        line-height: 1.5rem;
      }
      
      .location {
        &-container {
          display: inline-flex;
          
          :first-of-type {
            margin-right: 42px;
          }
        }
      }
      
      .similar {
        &-employers {
         flex-direction: row;
         margin-bottom: 96px;
        }
      }
      
      .section-heading-lg {
        text-align: center;
        margin-top: 112px;
      }
      
     .details-thumbnail {
        max-width: 306px;
        width: 100%;
        height: 264px;
       
        &.diversity {
          padding: 4px;
        }
      
        span {         
          width: 100%;
          height: 100%;
        }
  
        .img {
          width: 150px;
          height: 150px;
  
          display: block;
        }
    }
      
      .benefits {
        &-list {
          columns: 2 auto;
          width: 658px;
        }
        &-container {
          display: inline-flex;
          max-width: 329px;
          width: 100%;
        }
      }
      
      .clean-code-icon {
        width: ${theme.space.xl}px;
        height: ${theme.space.xl}px;
        vertical-align: bottom;
      } 
      
      .less-margin-bottom {
        margin-bottom: ${theme.space.sm}px;
      } 
    `}
    `
  }}
`
