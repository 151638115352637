import React, { useEffect, useState } from 'react'
import client from 'api/client'
import { Helmet } from 'react-helmet';

import { SpeakerRegistrationForm } from 'sections'

const tshirtSizes = [
  {
    label: 'S',
    value: '1',
  },
  {
    label: 'M',
    value: '2',
  },
  {
    label: 'L',
    value: '3',
  },
  {
    label: 'XL',
    value: '4',
  },
  {
    label: 'XXL',
    value: '5',
  },
]

function SpeakerDetailsForm() {
  const [tags, setTags] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const response = await client({ endpoint: 'api/tag_list/all' })
      const { data } = await response.json()

      setTags(data)
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (window?.callpage) {
      window.callpage('api.button.hide')
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>Your speaker profile at Code Europe</title>
      </Helmet>
      <SpeakerRegistrationForm {...{ tagsList: tags, tshirtSizes }} />
    </>
  )
}

export { SpeakerDetailsForm }
