import React from "react"
import { SButton } from "./Button.styled"

function ButtonLink({ children, ...res }) {
  return (
    <SButton as="a" {...res}>
      {children}
    </SButton>
  )
}

export default ButtonLink
