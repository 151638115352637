import React from 'react'

import { SPicture } from './Gallery.styled'

function Gallery({ mobileBackground, desktopBackground, imgAlt }) {
  return (
    <SPicture>
      <source media="(min-width: 1140px)" srcSet={desktopBackground} />
      <img src={mobileBackground} alt={imgAlt} />
    </SPicture>
  )
}

export default Gallery
