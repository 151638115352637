import styled, { css } from 'styled-components'
import respondTo from 'theme/respondTo'

export const SNotFound = styled.div`
  ${({ theme }) => css`
    position: relative;
    min-height: calc(100vh - 149px);

    overflow: hidden;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      position: relative;
      z-index: 1;

      p {
        font-size: 1.5rem;
        font-weight: ${theme.weight.bold};
        text-align: center;
        color: #6a6a6a;

        margin-top: ${theme.space.xl}px;

        &.message {
          color: white;

          margin-top: ${theme.space.sm}px;
        }
      }

      button {
        margin-top: ${theme.space.md}px;
      }
    }

    .background {
      width: 100%;

      position: absolute;
      bottom: 0;
      left: 0;
    }

    ${respondTo.lg`
        .content {
            p {
                font-size: 2.625rem;
                margin-top: ${theme.space.xxxl}px;
            }

            button {
                margin-top: ${theme.space.xl}px;
            }
        }
    `}
  `}
`
