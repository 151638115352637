import styled, { css } from "styled-components"
import respondTo from "../../theme/respondTo"

import lecturesPhoto from "../../assets/images/lectures.png"

import { PhotoWithFrame } from "../../components"

export const SBackground = styled.div`
    width: 100%;

    display: none;

    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;

    ${respondTo.lg`
      display: block;
    `}
    }
`

export const SContainer = styled.section`
  ${({ theme }) => css`
    min-height: 600px;
    padding: ${theme.space.xl}px ${theme.space.md}px;
    position: relative;

      ${respondTo.lg`
          padding: ${theme.space.xxxl}px ${theme.space.md}px;
      `}
  `}
`

export const SHeading = styled.h1`
${({ theme }) => css`
      font-size: 2rem;
      font-weight: ${theme.weight.bold};
      color: white;
      line-height: 1.2;
      text-align: left;
      margin-bottom: ${theme.space.xl}px;
      
       ${respondTo.lg`
          font-size: 2.625rem;
          text-align: center;
      `}
    }
  `}
`

export const SSubHeading = styled.h2`
  ${({ theme }) => css`
    font-size: 1rem;
    line-height: 1.5rem;
    color: white;
    margin-bottom: ${theme.space.md}px;
    
    ${respondTo.lg`
      font-size: 1.25rem;
      line-height: 2rem;
      margin-bottom: ${theme.space.xl + 2}px;
    `}
  `}
`

export const SWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    position: relative; 
    margin-top: ${theme.space.sm}px;
    
    ${respondTo.lg`
      flex-direction: row;
      justify-content: space-between;
      margin: ${theme.space.xl}px auto 0;
      max-width: ${theme.breakpoints.lg}px;
    `}
    
    ${respondTo.xl`
      max-width: ${theme.breakpoints.xl}px;
    `}
  `}
`

export const SPhoto = styled(PhotoWithFrame)`
  ${({ theme }) => css`   
    height: 157px;
    width: 280px;
    
    background-image: url(${lecturesPhoto});
    
    &::before {
     background-color: ${theme.colors.green};
    }
    
   ${respondTo.sm`
       height: 224px;
       width: 400px;
   `}
   ${respondTo.lg`
     height: 280px;
     width: 500px;
  `}
  ${respondTo.xl`
      height: 360px;
      width: 642px;
  `}
  `}
`

export const SPhotoWrapper = styled.div`
      position: relative;
      margin: 0 auto;
      
      height: 157px;
      width: 280px;
               
     ${respondTo.sm`
       height: 224px;
       width: 400px;
     `}
    
    ${respondTo.lg`
       height: 280px;
       width: 500px;
    `}
   ${respondTo.xl`
       height: 360px;
       width: 642px;
   `}
`

export const SList = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  line-height: 1.5rem;
  
 ${respondTo.lg`
    font-size: 1.25rem;
    line-height: 2rem;
 `}
`

export const SListItem = styled.div`
${({ theme }) => css` 
    display: flex;
    align-items: baseline;
    margin-bottom: ${theme.space.md}px;
    
    :last-child {
      margin-bottom: 0;
    }
   
    ${respondTo.xl`
      margin-bottom: ${theme.space.sm}px;
      height: 48px;
       
         :nth-child(2) {
           height: 74px;
         }
     `}
`}
`

export const SLeftContainer = styled.div`
${({ theme }) => css` 
    margin-bottom: ${theme.space.xl}px;
    padding-right: ${theme.space.sm}px;
    
    ${respondTo.lg`
        margin-bottom: 0;  
        max-width: 400px;
     `}
    ${respondTo.xl`
      max-width: 465px;
    `}
  `}
`

export const SBullet = styled.div`
  ${({ theme, pink, turquoise, green, blue }) => css` 
      max-width: 48px;
      min-width: 48px;
      height: 10px;
      border-radius: 5px;
      margin-right: ${theme.space.md}px;
      
      ${pink &&
          css`
           background-color: ${theme.colors.pink};
      `}
      ${turquoise &&
          css`
           background-color: ${theme.colors.lightTurquoise};
      `}
      ${green &&
          css`
           background-color: ${theme.colors.green};
      `}
      ${blue &&
         css`
          background-color: ${theme.colors.lightBlue};
      `}  

     ${respondTo.lg`
        margin-right: ${theme.space.xl}px; 
     `}  
  `}
`

export const SBackgroundSlot = styled.div`
  position: absolute;

 ${({ theme, position }) =>
    position === "topright" &&
    css`
      top: ${theme.space.md}px;
      right: ${theme.space.md}px;
    `}
  ${({ theme, position }) =>
    position === "bottomleft" &&
    css`
      bottom: ${theme.space.lg}px;
      left: ${theme.space.lg}px;
    `}
`
