import React from 'react'
import { Route, Redirect } from 'react-router'

function ProtectedRoute({
  isAuthenticated,
  authenticationPath,
  ...routeProps
}) {
  if (isAuthenticated) {
    return <Route {...routeProps} />
  } else {
    return <Redirect to={{ pathname: authenticationPath }} />
  }
}

export default ProtectedRoute
