import React, { useEffect } from "react"

function CodeOfConduct() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.location.href = "/code-of-conduct.pdf"
    }
  }, []);

  return null;
}

export { CodeOfConduct }
