import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SContainer = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundBlack};
    overflow: hidden;
    position: relative;
    padding: ${theme.space.xl + 8}px ${theme.space.lg}px 0;

    ${respondTo.lg`
      padding: ${theme.space.xl * 2}px ${theme.space.lg}px 100px;
    `}

    .heading {
      font-size: 1.5rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.875rem;
      color: white;
      margin: 0 auto ${theme.space.lg + 4}px;
      text-align: center;

      ${respondTo.lg`
        font-size: 2.25rem;
        line-height: 3.125rem;  
        margin: 0 auto;
      `}

      span {
        color: ${theme.colors.lightPink};
        text-shadow: 1px 1px 0 ${theme.colors.azure};
        font-size: 1.75rem;
        line-height: 2.188rem;

        ${respondTo.lg`
          color: ${theme.colors.white};
          font-size: 3.25rem;
          line-height: 4.125rem;
          text-shadow: 2px 2px 0 ${theme.colors.azure};
        `}
      }
    }

    .subheader {
      color: ${theme.colors.lightGray2};
      font-size: 1.125rem;
      line-height: 1.56rem;
      margin: ${theme.space.md + 4}px auto ${theme.space.xl + 8}px;
      text-align: center;
      max-width: 679px;

      ${respondTo.lg`
        font-size: 1.375rem;
        line-height: 1.875rem;
        font-weight: 500;
        margin: ${theme.space.md + 4}px auto 60px;
      `}
    }

    .data {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 60px;

      ${respondTo.lg`
        flex-direction: row;
        justify-content: center;
        margin: 0 auto 100px;
        gap: 125px;
        
        &:nth-of-type(2) {
          gap: 177px;
          margin: 0 auto;
          
          img {
            order: 1;
          }
        }
      `}

      ${respondTo.xxl`
        &:nth-of-type(2) {
          margin: 0 auto 0 220px;
        }
      `}

      &-title {
        font-size: 1.125rem;
        line-height: 1.56rem;
        font-weight: ${theme.weight.bold};
        color: ${theme.colors.white};
        margin: ${theme.space.lg + 4}px 0 ${theme.space.md + 4}px;

        ${respondTo.lg`
          font-size: 1.375rem;
          line-height: 1.875rem;
          margin: 0 0 ${theme.space.lg + 4}px;
        `}
      }
      img {
        width: 100%;
        max-width: 294px;

        ${respondTo.lg`
          max-width: 400px;
       `}
      }
      &-text {
        display: flex;
        align-items: center;
        line-height: 2.56rem;

        ${respondTo.lg`
          font-size: 1.25rem;
          line-height: 3.25rem;
        `}
      }
      &-percent {
        color: ${theme.colors.white};
        margin-right: ${theme.space.xl + 8}px;
        width: 30px;
      }
      &-description {
        color: ${theme.colors.lightGray2};
      }
    }
    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    .positionRight {
      position: absolute;
      top: 0;
      right: -80px;

      ${respondTo.lg`
        top: 150px;
        right: -65px;
      `}

      svg {
        width: 113px;

        ${respondTo.xl`
           width: 250px;
        `}
      }
    }
    .positionLeft {
      position: absolute;
      top: 225px;
      left: 0;

      img {
        width: 149px;
        margin-left: -80px;

        ${respondTo.xl`
           width: 100%;
           margin-left: 0;
        `}
      }
    }
  `}
`

export const SColor = styled.span`
  ${({ theme, color }) => css`
    display: inline-block;
    margin-right: ${theme.space.md + 4}px;
    width: 20px;
    height: 20px;
    vertical-align: center;
    background-color: ${color};
    border-radius: ${theme.space.xs}px;
  `}
`
