import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import client from 'api/client'
import config from 'const/config'

const FETCH_TICKETS = 'tickets/fetch'
export const getTickets = createAsyncThunk(
  FETCH_TICKETS,
  async (payload, thunkAPI) => {
    try {
      const response = await client({
        endpoint: 'api/tickets/all',
      })

      if (!response.ok) {
        const errors = await response.json()
        return thunkAPI.rejectWithValue(errors)
      }

      return await response.json()
    } catch (error) {
      console.log('[GET] tickets error: ', error)
      thunkAPI.rejectWithValue()
    }
  }
)

const SELECT_TICKET = 'tickets/select'
export const selectTicket = createAction(SELECT_TICKET)

const UNSELECT_TICKET = 'tickets/unselect'
export const unselectTicket = createAction(UNSELECT_TICKET)

const CHANGE_CITY = 'tickets/changeCity'
export const changeCity = createAction(CHANGE_CITY)

const CHANGE_STEP = 'tickets/changeStep'
export const changeStep = createAction(CHANGE_STEP)

const UPDATE_DISCOUNT_CODE = 'tickets/updateDiscountCode'
export const updateDiscountCode = createAction(UPDATE_DISCOUNT_CODE)

const CALCULATE_PRICE = 'tickets/calculatePrice'
export const calculatePrice = createAsyncThunk(
  CALCULATE_PRICE,
  async (payload, thunkAPI) => {
    try {
      const {
        tickets: {
          items: { selected },
          price: { discountCode },
        },
      } = thunkAPI.getState()

      if (!selected.entity) {
        return thunkAPI.rejectWithValue()
      }

      const body = JSON.stringify({
        ticket_id: selected.entity.id,
        discount_code: discountCode,
        amount: selected.amount,
      })

      const response = await client({
        endpoint: 'api/buy/check-price',
        method: 'POST',
        body,
      })

      if (!response.ok) {
        const errors = await response.json()
        return thunkAPI.rejectWithValue(errors)
      }

      const { price_before_discount, price, discount_price } =
        await response.json()

      return {
        priceBeforeDiscount: price_before_discount,
        priceAfterDiscount: price,
        discountPrice: discount_price,
      }
    } catch (error) {
      console.log('[GET] calculate price error: ', error)
    }
  }
)

const SET_FIELDS = 'tickets/setFields'
export const setFields = createAction(SET_FIELDS)

const BUY_TICKET = 'tickets/buy'
export const buyTicket = createAsyncThunk(
  BUY_TICKET,
  async (payload, thunkAPI) => {
    try {
      const {
        items: { selected },
        fields: { base, payment, vat },
        price: { discountCode },
      } = thunkAPI.getState().tickets

      const {
        dictionary: { data: dictionary },
      } = thunkAPI.getState().app

      const { isAuthenticated, user } = thunkAPI.getState().user
      const userBaseData = isAuthenticated
        ? {
            name: user.name,
            surname: user.surname,
            phone: user.phone || '',
            email: user.email,

            terms: base.terms,

            job_position: user.job_position,
            company: user.company,
            interesting_path: user.interesting_path,
            experience: user.experience,
            technologies_working_with: user.technologies_working_with,
            technologies_interested_in: user.technologies_interested_in,
            known_languages: user.known_languages,
          }
        : base

      let body = {
        ticket_id: selected.entity.id,
        amount: selected.amount,
        discount_code: discountCode,

        terms: userBaseData.terms,
        invoice_required: payment.vat,

        locale: payload.locale,

        authorized: isAuthenticated,
      }

      if (!payload.isGroup) {
        body = {
          ...body,

          name: userBaseData.name,
          surname: userBaseData.surname,
          email: userBaseData.email,
          phone: userBaseData.phone,
          password: userBaseData.password,

          job_position: userBaseData.job_position,
          company: userBaseData.company,
          interesting_path: userBaseData.interesting_path,

          experience: userBaseData.experience,
          technologies_working_with: userBaseData.technologies_working_with,
          technologies_interested_in: userBaseData.technologies_interested_in,
          known_languages: userBaseData.known_languages,

          resume: userBaseData.resume,
        }
      }

      if (!payload.isFree && !payload.isGroup) {
        body = {
          ...body,

          payment_name: payment.name,
          payment_surname: payment.surname,
          payment_email: payment.email,
        }
      }

      if (payload.isGroup) {
        body = {
          ...body,

          name: payment.name,
          surname: payment.surname,
          email: payment.email,
        }
      }

      if (!payload.isFree) {
        body = {
          ...body,

          street: payment.street,
          house_number: payment.house_number,
          apartment_number: payment.apartment_number,
          postcode: payment.postcode,
          city: payment.city,
          country: payment.country,
        }
      }

      if (payment.vat) {
        body = {
          ...body,

          company_company: vat.company,
          company_nip: vat.company_nip,
          company_street: vat.company_street,
          company_house_number: vat.company_house_number,
          company_apartment_number: vat.company_apartment_number,
          company_postcode: vat.company_postcode,
          company_city: vat.company_city,
          company_country: vat.company_country,
        }
      }

      if (isAuthenticated && !payload.isGroup) {
        delete body.email
      }

      const token = localStorage.getItem(config.STORAGE_TOKEN_KEY)

      const response = await client({
        endpoint: payload.isGroup
          ? 'api/buy/ticket/group'
          : 'api/buy/ticket/solo',
        headers: isAuthenticated
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {},
        method: 'POST',
        body: JSON.stringify(body),
      })

      if (!response.ok) {
        return thunkAPI.rejectWithValue()
      }

      const { payment_uri } = await response.json()
      window.location = payment_uri
    } catch (error) {
      console.log('[POST] buy ticket error: ', error)
    }
  }
)
