import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'
import background from '../../assets/images/net-right-background.svg'

export const SContainer = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundBlack};
    padding: ${theme.space.xl + 8}px ${theme.space.lg}px;
    background-image: url(${background});
    background-repeat: no-repeat;
    background-size: 200%;
    background-position: right 5%;

    ${respondTo.lg`
      background-size: contain;
      background-position: right 0;
      padding: 120px ${theme.space.lg}px 90px;
    `}

    .wrapper {
      max-width: 1135px;
      margin: 0 auto;
    }

    .team-ce-heading {
      color: ${theme.colors.white};
      font-weight: ${theme.weight.bold};
      font-size: 1.75rem;
      line-height: 2.188rem;
      margin: 0 auto ${theme.space.lg - 4}px;
      text-align: center;
      text-shadow: 1px 1px 0 ${theme.colors.azure};

      ${respondTo.lg`
        text-align: left;
        font-size: 2rem;
        line-height: 2.5rem;
        text-shadow: 2px 2px 0 ${theme.colors.azure};
      `}
    }

    .socials {
      &-container {
        display: flex;
        gap: ${theme.space.lg + 3}px;
      }
      &-heading {
        line-height: 1.375rem;
        font-weight: 500;
        color: ${theme.colors.white};
        margin-bottom: ${theme.space.md + 4}px;

        ${respondTo.lg`
          font-size: 1.25rem;
          line-height: 2rem;
        `}
      }
    }

    .content {
      display: flex;
      flex-direction: column;

      ${respondTo.lg`
        flex-direction: row;
        gap: ${theme.space.md + 4}px;
      `}
    }

    img {
      max-height: 312px;
      max-width: 326px;
      margin: 0 auto;

      ${respondTo.lg`
        max-height: 610px;
        max-width: 639px;
      `}
    }

    .subheader {
      line-height: 1.375rem;
      font-weight: 500;
      color: ${theme.colors.white};
      margin: ${theme.space.lg + 4}px 0;

      ${respondTo.lg`
        font-size: 1.25rem;
        line-height: 1.75rem;
        margin-top: 0;
      `}
    }

    .description {
      font-size: 0.875rem;
      line-height: 1.188rem;
      color: ${theme.colors.lightGray2};

      ${respondTo.lg`
        font-size: 1rem;
        line-height: 1.375rem;
      `}
    }

    .divider-horizontal {
      border: none;
      background-color: ${theme.colors.lightGray};
      width: 80px;
      height: 1px;
      margin: ${theme.space.xl + 8}px auto ${theme.space.md}px 0;

      ${respondTo.lg`
        margin: ${theme.space.xl + 8}px auto ${theme.space.md + 4}px 0;
      `}
    }
  `}
`
