import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'
import background from '../../assets/images/latest-editions-background.png'
export const SContainer = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundBlack};
    position: relative;

    padding-top: ${theme.space.xl + 8}px;

    ${respondTo.xl`
        padding: ${theme.space.xxxl}px 0 60px;
    `}

    .container {
      background-color: white;
      border: 3px solid ${theme.colors.pink};
      padding: ${theme.space.xl + 8}px ${theme.space.lg}px;
      max-width: 500px;
      margin: 0 auto;

      ${respondTo.xl`
        border: none;
        background-color: ${theme.colors.backgroundBlack};
        background-image: url(${background});
        background-repeat: no-repeat;
        max-width: 1201px;
        padding: 60px 0 64px;
      `}
    }

    .heading {
      font-size: 1.75rem;
      line-height: 2.188rem;
      font-weight: ${theme.weight.bold};
      color: ${theme.colors.black};
      margin-bottom: ${theme.space.xl + 8}px;
      text-align: center;

      ${respondTo.lg`
        font-size: 3.25rem;
        line-height: 4.5rem;
        margin-bottom: ${theme.space.xl}px;
      `}

      span {
        text-shadow: 1px 1px 0 ${theme.colors.pink};
        font-size: 1.75rem;
        line-height: 2.188rem;

        ${respondTo.lg`
          font-size: 3.25rem;
          line-height: 4.125rem;
          text-shadow: 2px 2px 0 ${theme.colors.pink};
        `}
      }
    }

    .tiles-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: ${theme.space.xxl}px;

      ${respondTo.xl`
        gap: 79px;
        flex-direction: row;
      `}
    }

    .tile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 295px;

      ${respondTo.xl`
          &:first-of-type {
            margin-left: 79px;
          }
      `}

      a {
        width: 100%;
      }

      i {
        margin-left: ${theme.space.sm}px;
        padding-top: 4px;
      }

      img {
        max-width: 295px;
        max-height: 350px;

        &:first-of-type,
        &:last-of-type {
          margin-bottom: ${theme.space.md}px;

          ${respondTo.lg`
            margin-bottom: 44px;
          `}
        }
        &:nth-of-type(2) {
          margin-left: ${theme.space.sm + 4}px;
          margin-right: ${theme.space.sm + 4}px;

          ${respondTo.xxs`
             margin-left: ${theme.space.lg}px;
             margin-right: ${theme.space.lg}px;
          `}

          ${respondTo.lg`
             margin-left: 72px;
             margin-right: 72px;
          `}
        }
      }
    }
  `}
`
