import { useIntl } from 'react-intl'
import { Helmet } from 'react-helmet'

import { CompaniesList } from 'sections'
import { useEffect } from 'react'
import { shouldShowCompanies } from '../modules/navigation'

function Companies() {
  const { formatMessage } = useIntl()

  useEffect(() => {
    if (!shouldShowCompanies) {
      window.location.href = '/'
    }

    if (window?.callpage) {
      window.callpage('api.button.hide')
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'seoTitleCompanies' })}</title>
        <meta
          name="description"
          content={formatMessage({ id: 'seoDescriptionCompanies' })}
        />
        <meta
          name="keywords"
          content={formatMessage({ id: 'seoKeywordsCompanies' })}
        />
        <meta
          property="og:title"
          content={formatMessage({ id: 'seoTitleCompanies' })}
        />
        <meta
          property="og:site_name"
          content={formatMessage({ id: 'seoTitleCompanies' })}
        />
        <meta
          property="og:description"
          content={formatMessage({ id: 'seoDescriptionCompanies' })}
        />
        <meta name="twitter:image:src" content={''} />
        <meta
          name="twitter:title"
          content={formatMessage({ id: 'seoTitleCompanies' })}
        />
        <meta
          name="twitter:description"
          content={formatMessage({ id: 'seoDescriptionCompanies' })}
        />
      </Helmet>
      <CompaniesList />
    </>
  )
}

export { Companies }
