import React from 'react'
import { useIntl } from 'react-intl'
import { SlideTop } from '../../components'
import { SContainer } from './IconDescriptionGrid.styled'
function IconDescriptionGrid({ items }) {
  const { formatMessage } = useIntl()

  return (
    <SContainer>
      {items.map((item, index) => (
        <SlideTop key={item.title} delay={50 * index}>
          <div className="benefit">
            <i className={item.icon} />
            <p className="benefit-title">
              {' '}
              {formatMessage({ id: item.title }, { br: <br /> })}
            </p>
            <hr className="benefit-divider" />
            <p className="benefit-text">{formatMessage({ id: item.text })}</p>
          </div>
        </SlideTop>
      ))}
    </SContainer>
  )
}

export default IconDescriptionGrid
