import React from "react"
import { useTheme } from "styled-components"
import { useIntl } from "react-intl"

import { Wrapper, SectionHeader, Opacity } from "../../components"
import {
  SContainer,
  SGraphs,
  SGraph,
  SGraphDetails,
  SLegend,
  SGraphHeading,
  SGraphData,
} from "./Graphs.styled"
import BarGraph from "./BarGraph"
import PieChart from "./PieChart"

function Graphs() {
  const theme = useTheme()
  const { formatMessage } = useIntl()

  return (
    <SContainer>
      <Wrapper>
        <SectionHeader
          heading="graphsHeading"
          headingUnderlineColor={theme.colors.pink}
        />
        <SGraphs>
          <SGraph>
            <Opacity>
              <BarGraph className="bar-graph" />
            </Opacity>
            <SGraphDetails>
              <SGraphHeading>
                {formatMessage({ id: "graphExpHeading" })}:
              </SGraphHeading>
              <SGraphData>
                <li className="pie-chart-data">
                  <Opacity>
                    <em>55%</em> - {formatMessage({ id: "graphExpOver5Years" })}
                  </Opacity>
                </li>
                <li className="pie-chart-data">
                  <Opacity>
                    <em>18%</em> - {formatMessage({ id: "graphExp2To5Years" })}
                  </Opacity>
                </li>
                <li className="pie-chart-data">
                  <Opacity>
                    <em>14%</em> - {formatMessage({ id: "graphExpTo2Years" })}
                  </Opacity>
                </li>
                <li className="pie-chart-data">
                  <Opacity>
                    <em>13%</em> - {formatMessage({ id: "graphExpTo1Year" })}
                  </Opacity>
                </li>
              </SGraphData>
              <SLegend>*{formatMessage({ id: "dataFrom2018" })}</SLegend>
            </SGraphDetails>
          </SGraph>
          <SGraph>
            <Opacity>
              <PieChart className="pie-chart" />
            </Opacity>
            <SGraphDetails>
              <SGraphHeading>
                {formatMessage({ id: "graphSpecHeading" })}:
              </SGraphHeading>
              <SGraphData>
                <li>
                  <Opacity>
                    <em>42%</em> - {formatMessage({ id: "graphSpecDevs" })}
                  </Opacity>
                </li>
                <li>
                  <Opacity>
                    <em>13%</em> - {formatMessage({ id: "graphSpecAdmins" })}
                  </Opacity>
                </li>
                <li>
                  <Opacity>
                    <em>12%</em> - {formatMessage({ id: "graphSpecAnalysts" })}
                  </Opacity>
                </li>
                <li>
                  <Opacity>
                    <em>9%</em> - {formatMessage({ id: "graphSpecEngineers" })}
                  </Opacity>
                </li>
                <li>
                  <Opacity>
                    <em>9%</em> - {formatMessage({ id: "graphSpecManagers" })}
                  </Opacity>
                </li>
                <li>
                  <Opacity>
                    <em>6%</em> - {formatMessage({ id: "graphSpecTesters" })}
                  </Opacity>
                </li>
                <li>
                  <Opacity>
                    <em>9%</em> - {formatMessage({ id: "graphSpecOther" })}
                  </Opacity>
                </li>
              </SGraphData>
              <SLegend>*{formatMessage({ id: "dataFrom2018" })}</SLegend>
            </SGraphDetails>
          </SGraph>
        </SGraphs>
      </Wrapper>
    </SContainer>
  )
}

export default Graphs
