import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SOfferDetails = styled.section`
  ${({ theme }) => {
  return css`
    padding: ${theme.space.xl}px 0;
    
    .button-wrapper-mobile {
      background-color: ${theme.colors.darkGray};
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      padding: 16px 0;
      width: 100%;
    }
  
    .container {
      display: flex;
      flex-direction: column;
    } 
    
    .right-side {
      font-size: 1rem;
      order: -1;
      
      .section-heading {
        :first-of-type {
          margin-top: 0;
        }
        margin-top: 40px;
      }
    }
    
     .details-name {
      display: inline-block;
      font-size: 2rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.25;
      color: white;
    }
    
    .info {
      color: ${theme.colors.lightGray};
      font-size: 0.875rem;
      line-height: 0.875rem;
      display: flex;
      flex-wrap: wrap;
      margin-top: -${theme.space.sm}px;
    }
    
    .company {
      color: inherit;
      font-weight: ${theme.weight.bold};
      margin-right: ${theme.space.md}px;
      text-decoration: none;
      
      :hover {
        color: ${theme.colors.white};
      }
    }
    
    .location {
      display: flex;
      align-items: center;
      width: 100%;
      margin: ${theme.space.md}px 0;
      line-height: 1rem;
      
       &-name {
        word-break: break-word;
      }
    }
    
    .pin-icon {
      min-width: ${theme.space.sm}px;
      max-width: ${theme.space.sm}px;
      height: ${theme.space.md - theme.space.xs}px;
      display: inline-block;
      margin-right: ${theme.space.sm}px;
    }  
    
    .endtime {
      margin: 0 0 ${theme.space.xl}px 0;
      width: 100%;
    }
    
    .description-text {
      color: ${theme.colors.white};
      
      div {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
    
    .profile {
      font-size: 0.875rem;
      line-height: 2rem;
      color: white;

      &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &-level {
        display: grid;
        grid-template-columns: repeat(5, 12px);
        grid-gap: 0 ${theme.space.sm}px;
      }
      &-name {
        margin-right: ${theme.space.xl}px;
      }
      &-circle {
        width: 12px;
        height: 12px;
    
        flex-shrink: 0;
    
        display: block;
    
        border: solid 3px ${theme.colors.lightGray};
        border-radius: 50%;
        
        &.active {
          border-color: ${theme.colors.pink};
        }
      }
    }
    
    .clause {
      color: ${theme.colors.lightGray};
      font-size: 0.625rem;
      line-height: 0.75rem;
      margin-top: 0;
      
      &-more {
       display: block;
       margin-left: auto;
       font-size: 0.625rem;
       line-height: 0.75rem;
        
      color: ${theme.colors.pink};
        
      border: none;
      box-shadow: none;
      background: none;
      border-bottom: 1px solid transparent;

      margin-top: ${theme.space.md}px;
      padding: 0;

      cursor: pointer;

      &:hover {
        outline: 0;
        border-bottom: 1px solid ${theme.colors.pink};
      } 
     }
    }
    
    .right-container {
      background-color: ${theme.colors.gray2};
      box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14);
      padding: 28px 14px;
      width: 100%;
      max-width: 385px;
    }
   
    .benefits {
      &-list {
        color: ${theme.colors.white};
        display: flex;
        flex-direction: column;
        font-size: 1rem;
      }
      &-container {
        display: flex;
        margin-bottom: ${theme.space.md}px;
        
        :last-of-type {
          margin-bottom: 0;
        }
      }
      &-icon {
        height: 20px;
        width: 20px;
        margin-right: 10px;
      }
    } 
    
    .show-less-more {
       color: ${theme.colors.pink};
       text-align: right;
                  
      &-text {
        cursor: pointer;
        margin-right: ${theme.space.xs}px;
        
        &:hover {
           outline: 0;
           border-bottom: 1px solid ${theme.colors.pink};
        }
      }
    }
    
    .about {
      color: ${theme.colors.white};
      
      &-employees, &-team, &-salary, &-contract, &-industry {
        font-size: 1rem;
        line-height: 1.25rem;
        margin-bottom: 10px; 
      } 
      &-industry {
        margin-bottom: 0;
      }
    }
   
    .tags, .area {
      margin: ${theme.space.md}px 0;
      
      .tag {
        font-size: 0.75rem;
        line-height: 1rem;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    
    .section {
      &-heading {
        font-size: 1.25rem;
        font-weight: ${theme.weight.bold};
        line-height: 1.6;
        color: white;
        margin-top: ${theme.space.xxxl}px;
        margin-bottom: ${theme.space.md}px;
      }
    }
    
    .lang-heading, .apply-heading {
      margin-top: 56px;
    }
    
    .apply-heading {
      margin-bottom: 0;
    }
    
    .bold {
      font-weight: ${theme.weight.bold};
    }


    ${respondTo.lg`
      padding: ${theme.space.xxxl}px 0 ${theme.space.xl}px;
    
      .container {
        flex-direction: row;
        justify-content: space-between;
      }
      
      .info {
        flex-wrap: no-wrap;
        margin-top: ${theme.space.md}px;
      }
      
      .endtime {
        margin: 0 0 0 auto;
      }
      
      .apply-heading {
        margin-bottom: ${theme.space.md}px;
      }
          
      .tags, .area {
        margin: ${theme.space.md}px 0 0;
      }
      
      .profile {
         &-level {
           grid-template-columns: repeat(5, 16px);
           grid-gap: 0 10px;
         }
         &-circle {
           width: 16px;
           height: 16px;
         }
      }
      
      .left-side {
        margin-right: 60px;
      }
      
      .right-side { 
        order: 1;
      }
      
      .details-name {
        font-size: 2.61rem;
        line-height: 1.14;
        margin-bottom: ${theme.space.sm}px
      }
    
      .benefits {
        &-container {
          width: 100%;
        }
      }
      
      .clause {
        margin-top: ${theme.space.sm + 4}px;
      }
      
      .right-container {
        padding: 40px;
        width: 385px;
      }
      
      .lang-heading, .apply-heading {
        margin-top: ${theme.space.xxxl}px
      }
    `} 
  `
}}
`
