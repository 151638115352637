import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'
import { HEADER_HEIGHT } from 'components/Header/Header'

export const SHeader = styled.header`
  ${({ theme }) => css`
    width: 100%;
    padding: ${theme.space.md + 2}px ${theme.space.lg}px;

    background-color: ${theme.colors.white};

    z-index: 10;

    ${respondTo.xl`
      padding: ${theme.space.md + 2}px 100px;
    `}

    .container {
      height: 100vh;
      padding-bottom: 160px;

      ${respondTo.xl`
        padding-bottom: 0;
    `}
    }
    .nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: ${theme.colors.black};

      z-index: 1;
      position: relative;

      ${respondTo.xl`
        max-width: 1240px;
        margin-left: auto;
        margin-right: auto;
      `}

      &-wrapper {
        display: flex;
        align-items: center;
      }

      a {
        line-height: 0;
        &.disable-secondary-button {
          cursor: not-allowed;
          background-color: ${theme.colors.disabledLink};
          box-shadow: 4px 4px 0 ${theme.colors.disabledLink},
            4px 4px 0 1px ${theme.colors.pink};
          &:hover, &:focus { {
            background-color: ${theme.colors.disabledLink};
          }
        }
      }

      .logo {
        height: 36px;
        width: 119px;
        color: ${theme.colors.black};
      }
    }
  `}
`
