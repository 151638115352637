import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { animated, Spring } from 'react-spring'
import VisibilitySensor from 'react-visibility-sensor'

import { Button, SlideTop } from '../../components'

import { SContainer } from './SpeakersOfAllTime.styled'

import { AppRoute } from '../../const'
import useLocalizeRoute from '../../utils/useLocalizeRoute'
import { useBreakpoint } from '../../modules/breakpoint'

import bjarneStroustrup from '../../assets/images/speakers-of-all-time/bjarne-stroustrup.png'
import douglasCrockford from '../../assets/images/speakers-of-all-time/douglas-crockford.png'
import simonaCotin from '../../assets/images/speakers-of-all-time/simona-cotin.png'
import miskoHevery from '../../assets/images/speakers-of-all-time/misko-hevery.png'
import michelleMannering from '../../assets/images/speakers-of-all-time/michelle-mannering.png'
import maaretPyhajarvi from '../../assets/images/speakers-of-all-time/maaret-pyhajarvi.png'
import peterZaitsev from '../../assets/images/speakers-of-all-time/peter-zaitsev.png'
import edwardBurns from '../../assets/images/speakers-of-all-time/edward-burns.png'
import joshSawyer from '../../assets/images/speakers-of-all-time/josh-sawyer.png'
import danSaks from '../../assets/images/speakers-of-all-time/dan-saks.png'
import teresaConceicao from '../../assets/images/speakers-of-all-time/teresa-conceicao.png'
import walterBright from '../../assets/images/speakers-of-all-time/walter-bright.png'
import larsKlint from '../../assets/images/speakers-of-all-time/lars-klint.png'
import danielMcGhan from '../../assets/images/speakers-of-all-time/daniel-mcGhan.png'
import svenRuppert from '../../assets/images/speakers-of-all-time/sven-ruppert.png'

import twoLinesPattern from '../../assets/patterns/two-lines-pattern.svg'
import LongGreenPattern from '../../assets/patterns/LongGreenPattern'
import pinkTopPattern from '../../assets/patterns/pink-top-pattern.svg'

const speakersOfAllTime = [
  {
    image: bjarneStroustrup,
    name: 'Bjarne Stroustrup',
    title: 'Creator of C++ programming language',
    company_name: '',
  },
  {
    image: douglasCrockford,
    name: 'Douglas Crockford',
    title: 'Creator of JSON | JavaScript Guru',
    company_name: '',
  },
  {
    image: simonaCotin,
    name: 'Simona Cotin',
    title: 'Engineering Manager',
    company_name: 'Google',
  },
  {
    image: miskoHevery,
    name: 'Misko Hevery',
    title: 'Inventor of Angular & AngularJS - CTO',
    company_name: 'builder.io',
  },
  {
    image: michelleMannering,
    name: 'Michelle Mannering',
    title: 'Hackathon Queen®™ | Developer Advocate',
    company_name: 'GitHub',
  },
  {
    image: maaretPyhajarvi,
    name: 'Maaret Pyhäjärvi',
    title: 'Testing Specialist and Development Manager',
    company_name: 'Vaisala',
  },
  {
    image: peterZaitsev,
    name: 'Peter Zaitsev',
    title: 'Open-Source Advocate | MySQL Rockstar | Founder',
    company_name: 'Percona',
  },
  {
    image: edwardBurns,
    name: 'Edward Burns',
    title: 'Software Stylist | Principal Architect for Java',
    company_name: 'Microsoft',
  },
  {
    image: joshSawyer,
    name: 'Josh Sawyer',
    title: 'JSawyer | Video Game Designer | Director',
    company_name: 'Pentiment Video Game',
  },
  {
    image: danSaks,
    name: 'Dan Saks',
    title:
      "World's leading Expert on the C and C++ programming language | Founder",
    company_name: 'Saks & Associates',
  },
  {
    image: teresaConceicao,
    name: 'Teresa Conceicao',
    title:
      'Solutions Architect Manager | Omniverse, Robotics, AI and Simulation',
    company_name: 'NVIDIA',
  },
  {
    image: walterBright,
    name: 'Walter Bright',
    title: 'Creator of D programming language | CEO',
    company_name: 'Digital Mars',
  },
  {
    image: larsKlint,
    name: 'Lars Klint',
    title: 'Microsoft Azure MVP | YouTube creator | Senior Developer Advocate',
    company_name: 'Pluralsight',
  },
  {
    image: svenRuppert,
    name: 'Sven Ruppert ',
    title: 'DevSecOps, Java & Kotlin Expert | Developer Advocate',
    company_name: 'JFrog',
  },
  {
    image: danielMcGhan,
    name: 'Daniel McGhan',
    title: 'Developer Advocate',
    company_name: 'Oracle',
  },
]

function SpeakersOfAllTime() {
  const { formatMessage } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()
  const breakpoint = useBreakpoint()
  const mobile = breakpoint.lg

  const speakersToDisplay = mobile
    ? speakersOfAllTime.slice(0, 10)
    : speakersOfAllTime

  return (
    <VisibilitySensor
      minTopValue={15}
      scrollCheck
      offset={{ top: 400 }}
      scrollDelay={5}
      partialVisibility="top"
    >
      {({ isVisible }) => (
        <SContainer>
          <Spring from={{ opacity: 0 }} to={{ opacity: isVisible ? 0 : 1 }}>
            {({ opacity }) => (
              <div className="background">
                <animated.div
                  style={{
                    opacity,
                    height: '100%',
                  }}
                >
                  <div className="positionTopRight">
                    <LongGreenPattern />
                  </div>
                  <div className="positionTopLeft">
                    <img src={twoLinesPattern} alt="" />
                  </div>
                  <div className="positionMiddleRight">
                    <img src={pinkTopPattern} alt="" />
                  </div>
                </animated.div>
              </div>
            )}
          </Spring>
          <SlideTop>
            <h2 className="heading">
              {formatMessage(
                { id: 'speakersOfAllTimeHeading' },
                {
                  span: (chunk) => <span className="shadow">{chunk}</span>,
                  br: <br />,
                }
              )}
            </h2>
          </SlideTop>
          <SlideTop>
            <p className="subheading">
              {formatMessage({ id: 'speakersOfAllTimeSubHeading' })}
            </p>
          </SlideTop>
          <div className="speakers">
            <div className="speakers-grid">
              {speakersToDisplay?.map((speaker) => (
                <SlideTop key={speaker.name}>
                  <div className="speaker-avatar">
                    <img src={speaker.image} alt={speaker.name} />
                  </div>
                  <div className="speaker-details">
                    <p className="speaker-name">{speaker.name}</p>
                    <p className="speaker-position">
                      {speaker.title}{' '}
                      {speaker.company_name
                        ? `@ ${speaker.company_name}`
                        : null}
                    </p>
                  </div>
                </SlideTop>
              ))}
            </div>
            {mobile ? (
              <Button
                secondary="true"
                height="52px"
                as={Link}
                to={localizeRouteKey(AppRoute.Speakers)}
              >
                {formatMessage({
                  id: 'showAllSpeakers',
                })}
                <i className="ri-arrow-right-line" />
              </Button>
            ) : null}
          </div>
        </SContainer>
      )}
    </VisibilitySensor>
  )
}

export default SpeakersOfAllTime
