import React from 'react'
import { useIntl } from 'react-intl'
import { useTheme } from 'styled-components'

import {
  SlideTop,
  ProfessionsIconsGrid,
  CustomUnderline,
} from '../../components'
import { SContainer } from './ProfessionsAboutUs.styled'

function ProfessionsAboutUs() {
  const { formatMessage } = useIntl()
  const theme = useTheme()

  return (
    <SContainer>
      <div className="content">
        <SlideTop>
          <h2 className="heading">
            {formatMessage(
              { id: 'proffesionsAboutUsHeading' },
              {
                span: (chunk) => (
                  <CustomUnderline color={theme.colors.blue}>
                    {chunk}
                  </CustomUnderline>
                ),
                br: <br />,
              }
            )}
          </h2>
          <p className="subheader">
            {formatMessage(
              { id: 'proffesionsAboutUsSubheader' },
              {
                br: <br />,
              }
            )}
          </p>
        </SlideTop>
        <ProfessionsIconsGrid />
      </div>
    </SContainer>
  )
}

export default ProfessionsAboutUs
