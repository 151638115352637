import { useEffect } from 'react'
import { SpeechDetailsForm } from 'sections'
import { Helmet } from 'react-helmet';

function LectureDetailsForm() {
  useEffect(() => {
    if (window?.callpage) {
      window.callpage('api.button.hide')
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>Your lecture details at Code Europe</title>
      </Helmet>
      <SpeechDetailsForm />
    </>
  )
}

export { LectureDetailsForm }
