import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SIcons = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: space-between;
    gap: 54px;
    max-width: 240px;

    ${respondTo.sm`
      max-width: 100%;
      justify-content: center;
    `};
    ${respondTo.lg`
      gap: 68px;
    `};

    .icons-item {
      width: 93px;

      p {
        color: ${theme.colors.white};
        font-size: 1.125rem;
        line-height: 1.6rem;
        margin-top: ${theme.space.md}px;

        ${respondTo.lg`
          font-size: 1.375rem;
          line-height: 1.875rem;
          margin-top: ${theme.space.xl + 4}px;
        `}
      }
      i {
        display: inline-block;
        color: ${theme.colors.pink};
        font-size: 36px;
        padding: ${theme.space.md - 4}px;
        background-color: ${theme.colors.black};
        border: 1px solid ${theme.colors.pink};

        appearance: none;
        box-shadow: 4px 4px 0 ${theme.colors.black},
          4px 4px 0 1px ${theme.colors.pink};
      }
    }
  `}
`
