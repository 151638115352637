import styled, { css } from 'styled-components'
import respondTo from 'theme/respondTo'

export default styled.div`
  ${({ theme, margin, locale }) => css`
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
      0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14);

    border: 1px solid ${theme.colors.gray};
    background-color: ${theme.colors.darkestGray};

    padding: ${theme.space.lg}px ${theme.space.md}px;
    margin: ${theme.space.sm}px ${theme.space.md}px ${theme.space.xxxl}px
      ${theme.space.md}px;

    ${margin &&
    css`
      margin-top: ${theme.space.xl}px;
    `}

    .heading {
      font-size: 1.25rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.4;
      text-align: center;
      color: white;

      margin-bottom: ${theme.space.xl}px;
    }

    .sign-in-error,
    .reset-password-success {
      font-size: 1rem;
      color: ${theme.colors.lightRed};
      text-align: center;

      margin: ${theme.space.lg}px 0;
    }

    .reset-password-success {
      color: ${theme.colors.green};
    }

    .sign-in,
    .logout {
      text-align: center;
      color: white;

      a,
      span {
        text-decoration: none;
        color: ${theme.colors.pink};
      }
    }

    .logout {
      font-weight: ${theme.weight.bold};
      margin-top: ${theme.space.lg}px;
    }

    .label {
      font-weight: ${theme.weight.bold};
      color: ${theme.colors.pink};

      margin-top: ${theme.space.xxl}px;
      margin-bottom: ${theme.space.lg}px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    .informations {
      line-height: 1.2;
      text-align: center;
      color: white;

      margin-bottom: ${theme.space.xl}px;
    }

    .forgot-password {
      text-align: right;

      padding: ${theme.space.sm}px 0 0 0;

      a {
        font-size: 0.875rem;
        text-decoration: none;
        color: ${theme.colors.pink};

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .or-separator {
      width: 100%;
      height: 20px;

      display: flex;
      justify-content: center;

      position: relative;

      margin: ${theme.space.xl}px 0;

      &::before {
        content: '';

        width: 100%;
        height: 2px;

        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        background-color: #6c6c6c;
      }

      span {
        display: block;

        color: #6c6c6c;

        position: relative;

        background-color: ${theme.colors.darkestGray};

        padding: 0 ${theme.space.sm}px;

        z-index: 1;
      }
    }

    .actions {
      display: flex;
      flex-direction: column;

      margin-top: ${theme.space.xl}px;

      &.payu {
        align-items: center;
      }

      button {
        display: block;

        cursor: pointer;

        &:not(.back, .pay) {
          flex: 1;
        }

        &:first-of-type {
          margin-bottom: ${theme.space.md}px;
        }
      }

      .back {
        color: white;

        margin: 0;
        padding: 0;

        background: none;
        border: none;
        box-shadow: none;
      }

      .pay {
        max-width: 256px;
        width: 100%;
        height: 44px;

        border: 0px;
        background: url('http://static.payu.com/${locale}/standard/partners/buttons/payu_account_button_long_03.png');
        background-repeat: no-repeat;
        background-size: cover;

        cursor: pointer;
      }
    }

    .success {
      svg {
        width: 71px;

        display: block;

        margin: 0 auto ${theme.space.md}px auto;
      }

      p:not(.heading) {
        font-size: 0.875rem;
        line-height: 1.71;
        text-align: center;
        color: white;

        margin-top: ${theme.space.sm}px;

        a,
        span {
          font-weight: ${theme.weight.bold};
          color: ${theme.colors.pink};
        }

        a {
          text-decoration: none;
        }

        &.edit {
          margin-top: ${theme.space.md}px;
        }
      }

      strong {
        font-weight: ${theme.weight.bold};
      }

      .button {
        width: 100%;
        margin-top: ${theme.space.xl}px;
      }
    }

    ${respondTo.lg`
      max-width: 562px;
      margin-left: auto;
      margin-right: auto;

      padding: ${theme.space.xl}px ${theme.space.lg}px;

      ${
        margin &&
        css`
          margin-top: 105px;
        `
      }

      .heading {
        font-size: 1.5rem;
      }
      
      .actions {
        flex-direction: row-reverse;

        button {
          margin-bottom: 0 !important;
          &:first-of-type {
            margin-left: ${theme.space.md}px;
          }
        }

        &.single {
          button {
            &:first-of-type {
              margin-left: 0;
            }
          }
        }

        .back {
          margin-right: auto;
        }
      }
    `}
  `}
`
