import styled, { css } from 'styled-components'
import respondTo from 'theme/respondTo'

export const SPomeraniaBanner = styled.div`
  ${({ theme }) => css`
    max-width: 320px;
    width: 100%;
    height: 320px;
    order: -1;
    margin: -${theme.space.md}px auto 0;
    padding: 32px ${theme.space.md}px;

    display: flex;
    flex-direction: column;

    background-repeat: no-repeat;
    background-size: cover;

    p {
      color: white;
      font-size: 26px;
      line-height: 38px;
      margin-top: ${theme.space.xxl}px;

      span {
        color: ${theme.colors.pink};
        font-weight: ${theme.weight.bold};
      }

      strong {
        font-weight: ${theme.weight.bold};
      }
    }

    div {
      order: -1;
      display: flex;
      justify-content: center;

      svg {
        color: white;
        height: 64px;
        width: auto;
        align-self: center;

        :first-of-type {
          margin-right: ${theme.space.xl}px;
        }
      }
    }

    ${respondTo.lg`
      max-width: 852px;
      height: 122px;
      order: 1;
      margin: 0 0 10px 0;
      padding: 13px ${theme.space.xl}px;

      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      p {
        color: white;
        font-size: 24px;
        line-height: 34px;
        margin-top: 0;
        max-width: 380px;

        span {
          color: ${theme.colors.pink};
          font-weight: ${theme.weight.bold};
        }

        strong {
          font-weight: ${theme.weight.bold};
        }
      }

      div {
        order: 1;
      }
    `}
  `}
`
