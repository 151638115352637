import styled from "styled-components"
import respondTo from "../../theme/respondTo"

export default styled.div`
      position: absolute;
      
      background-size: contain;
      background-repeat: no-repeat;
   
    &::before {
      content: "";
      width: 100%;
      height: 100%;

      position: absolute;

      top: 8px;
      left: 8px;

       z-index: -1;
    } 

    ${respondTo.md`      
       &::before {
          top: 16px;
          left: 16px;
        } 
    `}
`
