import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'
import line from '../../assets/images/impressions-line.svg'
export const SContainer = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundBlack};
    position: relative;

    padding-top: ${theme.space.xl + 8}px;

    ${respondTo.lg`
      background-image: url(${line});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center 100px;
      padding-top: 80px;
    `}

    ${respondTo.xxl`
      background-position: center 70px;
    `}

    .heading {
      font-size: 1.75rem;
      line-height: 2.188rem;
      font-weight: ${theme.weight.bold};
      color: ${theme.colors.white};
      margin-bottom: ${theme.space.md + 4}px;
      text-align: center;

      ${respondTo.lg`
        font-size: 3.25rem;
        line-height: 4.5rem;
      `}
    }

    .swiper-wrapper {
    }

    .slide {
      display: flex;
      justify-content: center;
      align-items: flex-end;

      img {
        max-width: 90px;
        max-height: 110px;

        ${respondTo.xxs`
           max-width: 103px;
           max-height: 122px;
        `}

        ${respondTo.sm`
          max-width: 160px;
          max-height: 200px;
        `}

        ${respondTo.xl`
          max-width: 295px;
          max-height: 350px;
        `}
        
        &:first-of-type,
        &:last-of-type {
          margin-bottom: ${theme.space.md}px;

          ${respondTo.lg`
            margin-bottom: 44px;
          `}
        }
        &:nth-of-type(2) {
          margin-left: ${theme.space.sm + 4}px;
          margin-right: ${theme.space.sm + 4}px;

          ${respondTo.xxs`
             margin-left: ${theme.space.lg}px;
             margin-right: ${theme.space.lg}px;
          `}

          ${respondTo.lg`
             margin-left: 72px;
             margin-right: 72px;
          `}
        }

        &:last-of-type {
          max-height: 132px;

          ${respondTo.sm`
            max-height: 250px;
          `}

          ${respondTo.lg`
            max-height: 380px;
          `}
        }
      }
    }

    .buttons-wrapper {
      display: none;

      ${respondTo.lg`
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
      `}

      ${respondTo.xxl`
        display: flex;
        width: 100%;
        height: 424px;
        justify-content: space-between;
        align-items: center;
      `}

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 52px;
        height: 52px;
        background-color: ${theme.colors.pink};
        border: 1px solid ${theme.colors.black};

        appearance: none;
        box-shadow: 4px 4px 0 white, 4px 4px 0 1px ${theme.colors.black};
        cursor: pointer;

        &:hover {
          outline: 0;
          background-color: ${theme.colors.darkPink};
        }

        i {
          padding-top: 4px;
          font-size: 24px;
        }
      }

      .prev {
        margin-left: 0;
        top: 200px;
      }
      .next {
      }
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    .positionLeft {
      position: absolute;
      top: -15%;
      left: 0;
      z-index: 2;

      ${respondTo.lg`
        top: 65%;
        left: 12%;
     `}
    }
    .positionRight {
      position: absolute;
      top: 72%;
      right: 0;

      ${respondTo.lg`
        top: 56%;
     `}
    }
  `}
`
export const SSwiperWrapper = styled.div`
  margin: 0 auto;
  max-width: 1350px;
  position: relative;
`

export const SPagination = styled.div`
  ${({ theme }) => css`
    height: 40px;
    display: flex;
    justify-content: center;
    margin-top: ${theme.space.xl}px;

    ${respondTo.lg`
      margin-top: ${theme.space.xl * 2}px;
    `}

    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      margin-right: 22px;

      display: inline-block;

      background-color: ${theme.colors.lightGray};

      border-radius: 50%;

      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }

    .swiper-pagination-bullet-active {
      background-color: ${theme.colors.pink};
    }
  `}
`
