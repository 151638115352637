import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import { useBreakpoint } from 'modules/breakpoint'

import client from '../../api/client'

import {
  SocialLinkIcon,
  Tags,
  Tooltip,
  Wrapper,
  CompanyTile,
  OfferTile,
} from '../../components'

import CleanCodeIcon from '../../assets/icons/CleanCodeIcon'
import ChevronIcon from '../../assets/icons/ChevronIcon/ChevronIcon'
import PinIcon from '../../assets/icons/PinIcon'

import sanitizeText from '../../utils/sanitizeText'
import benefitsIcons from '../../const/benefitsIcons'

import { LoaderComponent } from '../../components/Loader/Loader.styled'
import { SCompanyDetails } from './CompanyDetails.styled'

function CompanyDetails({ employer, loading }) {
  const { locale, formatMessage, formatDate } = useIntl()
  const sectionRef = useRef()
  const breakpoints = useBreakpoint()

  const [similarEmployers, setSimilarEmployers] = useState(null)
  const [similarLoading, setSimilarLoading] = useState(true)

  const [isAddressCollapsed, setIsAddressCollapsed] = useState(true)
  const [areBenefitsCollapsed, setAreBenefitsCollapsed] = useState(true)
  const [areTagsCollapsed, setAreTagsCollapsed] = useState(true)
  const [areOffersCollapsed, setAreOffersCollapsed] = useState(true)
  const [moreDescription, setMoreDescription] = useState(false)
  const [isDescriptionClamped, setDescriptionClamped] = useState(false)

  const hasDiversity = employer?.employer_soaps_list.some(
    (soap) => soap.soap_name === 'Diversity'
  )
  const hasCleanCode = employer?.employer_soaps_list.some(
    (soap) => soap.soap_name === 'Clean Code'
  )
  const partnershipSoaps = employer?.employer_soaps_list.filter(
    (soap) => soap.soap_name !== 'Diversity' && soap.soap_name !== 'Clean Code'
  )

  const linksKeys = [
    'employer_website',
    'employer_twitter_link',
    'employer_facebook_link',
    'employer_github_link',
    'employer_linkedin_link',
  ]

  const employerLinks = useMemo(
    () => linksKeys.map((key) => _.get(employer, key)).filter(Boolean),
    [employer]
  )

  const fetchSimilarEmployers = async () => {
    setSimilarLoading(true)

    const tags = employer?.employer_tag_list
      .map((tag) => tag.tag_id)
      .map((tag_id) => `tag_list[]=${tag_id}`)
      .join('&')

    try {
      const response = await client({
        endpoint: `api/employer/similar_by_tag_list?is_listed=1&current_employer_id=${employer?.employer_id}&locale=pl&${tags}`,
      })

      if (!response.ok) {
        throw new Error(await response.json())
      }

      const { data } = await response.json()
      if (!data || (data && !Array.isArray(data))) return

      setSimilarEmployers(data)

      setSimilarLoading(false)
    } catch (error) {
      setSimilarLoading(false)
      console.log('[GET] similar employers error: ', error)
    }
  }

  const handleReflow = (rleState) => {
    const { clamped } = rleState
    if (isDescriptionClamped) return
    setDescriptionClamped(clamped)
  }

  const toggleDescription = () => setMoreDescription(!moreDescription)
  const toggleTagsCollapse = () => setAreTagsCollapsed(!areTagsCollapsed)
  const toggleBenefitsCollapse = () =>
    setAreBenefitsCollapsed(!areBenefitsCollapsed)
  const toggleOffersCollapse = () => setAreOffersCollapsed(!areOffersCollapsed)
  const toggleAddressCollapse = () => setIsAddressCollapsed(!isAddressCollapsed)

  const tooltipContent = (
    <p className="tooltip-text">
      Clean Code
      <br />
      Partner
    </p>
  )

  const employerOffers = employer?.employer_job_offers?.filter(
    (offer) => offer.employer_name === employer.employer_name
  )

  useEffect(() => {
    employer && fetchSimilarEmployers()
  }, [employer])

  const sanitizedDescription = (
    <div
      dangerouslySetInnerHTML={{
        __html: employer?.employer_description
          ? sanitizeText(employer?.employer_description)
          : null,
      }}
    />
  )

  return (
    <SCompanyDetails ref={sectionRef}>
      {loading ? <LoaderComponent /> : null}
      {employer ? (
        <Wrapper>
          <div className="container">
            <div className="left-side">
              <span className="details-name">{employer.employer_name}</span>
              {hasCleanCode && (
                <Tooltip
                  className="tooltip"
                  placement="top"
                  value={tooltipContent}
                  offset={[48, 25]}
                  render={({ referenceElement, show, hide }) => (
                    <div
                      className="icon-container"
                      onMouseEnter={show}
                      onMouseLeave={hide}
                      ref={referenceElement}
                    >
                      <CleanCodeIcon className="clean-code-icon" />
                    </div>
                  )}
                />
              )}
              <div className="details-soaps">
                {partnershipSoaps.map((soap) => (
                  <span
                    key={soap.soap_id}
                    className="details-single-soap"
                    style={{
                      color: `${soap.soap_color}`,
                      border: `1px solid ${soap.soap_color}`,
                    }}
                  >
                    {soap.soap_name}
                  </span>
                ))}
              </div>
              {employer.employer_city_list?.length ? (
                <div className="locations">
                  <p className="section-heading">
                    {formatMessage({ id: 'employer.cities' })}
                  </p>
                  <div className="location-list">
                    {employer.employer_city_list.map((location) => (
                      <div key={location.name} className="location-container">
                        <PinIcon />
                        <p className="location-name">
                          {locale === 'pl' ? location.name : location.name_en},{' '}
                          {formatDate(location.date * 1000, {
                            day: 'numeric',
                            month: 'long',
                          })}{' '}
                          {location.second_date
                            ? `-
                            ${formatDate(location.second_date * 1000, {
                              day: 'numeric',
                            })}`
                            : null}
                          , {location.location}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
              {employer?.employer_tracks_list ? (
                <div className="tracks">
                  <p className="section-heading">
                    {formatMessage({ id: 'employer.tracks' })}
                  </p>
                  <ul className="track-list">
                    {employer?.employer_tracks_list.map((track) => (
                      <li className="track-list-item">
                        <span style={{ backgroundColor: track.track_color }} />
                        {track.track_name}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
              {employer.employer_tag_list?.length ? (
                <>
                  <p className="section-heading">
                    {formatMessage({ id: 'employer.tags' })}
                  </p>
                  <>
                    {breakpoints.lg && areTagsCollapsed ? (
                      <>
                        <div className="tags">
                          <Tags
                            {...{
                              tags: employer.employer_tag_list
                                .slice(0, 4)
                                .map((tag) => tag.tag_name),
                            }}
                          />
                        </div>
                        {employer.employer_tag_list.length > 4 ? (
                          <div className="show-less-more">
                            <span
                              onClick={toggleTagsCollapse}
                              className="show-less-more-text"
                            >
                              {formatMessage({ id: 'showMore' })}
                            </span>
                            <ChevronIcon type="down" />
                          </div>
                        ) : null}
                      </>
                    ) : (
                      <>
                        <div className="tags">
                          <Tags
                            {...{
                              tags: employer.employer_tag_list.map(
                                (tag) => tag.tag_name
                              ),
                            }}
                          />
                        </div>
                        {breakpoints.lg &&
                          employer.employer_tag_list.length > 4 && (
                            <div className="show-less-more">
                              <span
                                onClick={toggleTagsCollapse}
                                className="show-less-more-text"
                              >
                                {formatMessage({ id: 'showLess' })}
                              </span>
                              <ChevronIcon type="up" />
                            </div>
                          )}
                      </>
                    )}
                  </>
                </>
              ) : null}
              {employer.employer_description ? (
                <div className="description">
                  <p className="section-heading less-margin-top">
                    {formatMessage({ id: 'employer.description' })}{' '}
                    {employer.employer_name}:
                  </p>
                  {breakpoints.lg ? (
                    <>
                      <HTMLEllipsis
                        unsafeHTML={sanitizeText(
                          employer?.employer_description
                        )}
                        maxLine={moreDescription ? 10000 : 6}
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                        onReflow={handleReflow}
                      />
                      {isDescriptionClamped ? (
                        <button
                          type="button"
                          className="section-more"
                          onClick={toggleDescription}
                        >
                          {moreDescription ? (
                            <>
                              {formatMessage({ id: 'filtrationShowLess' })}{' '}
                              <ChevronIcon type="up" />
                            </>
                          ) : (
                            <>
                              {formatMessage({ id: 'filtrationShowMore' })}{' '}
                              <ChevronIcon type="down" />
                            </>
                          )}
                        </button>
                      ) : null}
                    </>
                  ) : (
                    <p>{sanitizedDescription}</p>
                  )}
                </div>
              ) : null}
              {employer.employer_benefit_list?.length ? (
                <div className="benefits">
                  <p className="section-heading">
                    {formatMessage({ id: 'employer.benefits' })}
                  </p>
                  <div className="benefits-list">
                    {breakpoints.lg && areBenefitsCollapsed ? (
                      <div>
                        {employer.employer_benefit_list
                          ?.slice(0, 8)
                          .map((benefit) => (
                            <div className="benefits-container">
                              <img
                                src={
                                  benefitsIcons[
                                    _.camelCase(
                                      formatMessage({
                                        id: benefit?.benefit_slug,
                                      })
                                    )
                                  ]
                                }
                                className="benefits-icon"
                                alt=""
                              />
                              <p>{benefit.benefit_name}</p>
                            </div>
                          ))}
                        {employer.employer_benefit_list.length > 8 ? (
                          <div className="show-less-more">
                            <span
                              onClick={toggleBenefitsCollapse}
                              className="show-less-more-text"
                            >
                              {formatMessage({ id: 'showMore' })}
                            </span>
                            <ChevronIcon type="down" />
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div>
                        {employer.employer_benefit_list.map((benefit) => (
                          <div className="benefits-container">
                            <img
                              src={
                                benefitsIcons[
                                  _.camelCase(
                                    formatMessage({ id: benefit?.benefit_slug })
                                  )
                                ]
                              }
                              className="benefits-icon"
                              alt=""
                            />
                            <p>{benefit.benefit_name}</p>
                          </div>
                        ))}
                        {breakpoints.lg && (
                          <div className="show-less-more">
                            <span
                              onClick={toggleBenefitsCollapse}
                              className="show-less-more-text"
                            >
                              {formatMessage({ id: 'showLess' })}
                            </span>
                            <ChevronIcon type="up" />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="right-side">
              <div className="right-container">
                <div
                  className={`details-thumbnail ${
                    hasDiversity ? 'diversity' : ''
                  }`}
                >
                  <span>
                    <div
                      className="img"
                      style={{
                        background: `center / contain no-repeat url("${employer.employer_image_url}`,
                      }}
                    ></div>
                  </span>
                </div>
                {!breakpoints.lg ? (
                  <div className="about">
                    <p className="section-heading less-margin-bottom">
                      {formatMessage({ id: 'employer.info' })}
                    </p>
                    {employer.employer_category_name?.length && (
                      <p className="about-industry">
                        {formatMessage({ id: 'employer.industry' })}
                        <span className="bold">
                          {locale === 'pl'
                            ? employer.employer_category_name
                            : employer?.employer_category_name_en}
                        </span>
                      </p>
                    )}
                    {employer.employer_employees_count?.length && (
                      <p>
                        {formatMessage({ id: 'employer.employeesCount' })}
                        <span className="bold">
                          {employer.employer_employees_count}
                        </span>
                      </p>
                    )}
                    {employer.employer_address_list?.length ? (
                      <>
                        <p className="section-heading">
                          {formatMessage({ id: 'employer.headquarters' })}
                        </p>
                        {isAddressCollapsed ? (
                          <>
                            {employer.employer_address_list
                              .slice(0, 1)
                              .map((address) => (
                                <div className="about-headquarters">
                                  <p>{address.street}</p>
                                  <span>{address.zipcode} </span>
                                  <span>{address.city}</span>
                                  <br />
                                  <br />
                                </div>
                              ))}
                            {employer.employer_address_list.length > 1 ? (
                              <div className="show-less-more">
                                <span
                                  onClick={toggleAddressCollapse}
                                  className="show-less-more-text"
                                >
                                  {formatMessage({ id: 'showMore' })}
                                </span>
                                <ChevronIcon type="down" />
                              </div>
                            ) : null}
                          </>
                        ) : (
                          <>
                            {employer.employer_address_list.map((address) => (
                              <div className="about-headquarters">
                                <p>{address.street}</p>
                                <span>{address.zipcode} </span>
                                <span>{address.city}</span>
                                <br />
                                <br />
                              </div>
                            ))}
                            {employer.employer_address_list.length > 1 ? (
                              <div className="show-less-more">
                                <span
                                  onClick={toggleAddressCollapse}
                                  className="show-less-more-text"
                                >
                                  {formatMessage({ id: 'showLess' })}
                                </span>
                                <ChevronIcon type="up" />
                              </div>
                            ) : null}
                          </>
                        )}
                      </>
                    ) : null}
                    {employerLinks.length ? (
                      <p className="section-heading">
                        {formatMessage({ id: 'employer.socials' })}
                      </p>
                    ) : null}
                    <ul className="socials">
                      {employerLinks.map((link) => (
                        <li className="single-social">
                          <SocialLinkIcon link={link} className="icon" />
                          <a href={link} target="_blank">
                            {link}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {employerOffers?.length ? (
            <div className="offers">
              <p className="section-heading-lg">
                {formatMessage(
                  { id: 'employer.offers' },
                  { company: employer?.employer_name ?? '' }
                )}
              </p>
              {areOffersCollapsed ? (
                <>
                  {employerOffers.slice(0, 3).map((offer) => (
                    <OfferTile key={offer.offer_id} item={offer} />
                  ))}
                  {employerOffers.length > 3 ? (
                    <div className="show-less-more">
                      <span
                        onClick={toggleOffersCollapse}
                        className="show-less-more-text"
                      >
                        {formatMessage({ id: 'showMore' })}
                      </span>
                      <ChevronIcon type="down" />
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  {employerOffers.map((offer) => (
                    <OfferTile key={offer.offer_id} item={offer} />
                  ))}
                  {employerOffers.length > 3 && (
                    <div className="show-less-more">
                      <span
                        onClick={toggleOffersCollapse}
                        className="show-less-more-text"
                      >
                        {formatMessage({ id: 'showLess' })}
                      </span>
                      <ChevronIcon type="up" />
                    </div>
                  )}
                </>
              )}
            </div>
          ) : null}
          {similarEmployers?.length ? (
            <div className="similar">
              <p className="section-heading-lg">
                {formatMessage({ id: 'employer.similar' })}
              </p>
              {similarLoading ? <LoaderComponent /> : null}
              <div className="similar-employers">
                {similarEmployers?.slice(0, 4).map((employer) => (
                  <CompanyTile
                    isSimilar
                    key={employer.employer_id}
                    item={employer}
                  />
                ))}
              </div>
            </div>
          ) : null}
        </Wrapper>
      ) : null}
    </SCompanyDetails>
  )
}

export default CompanyDetails
