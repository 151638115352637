import styled, { css } from 'styled-components'
import respondTo from 'theme/respondTo'

import desktopBannerBackground from '../../assets/images/banner_tickets_agenda_desktop.jpg'
import mobileBannerBackground from '../../assets/images/banner_tickets_agenda_mobile.jpg'

export const SContainer = styled.div`
  ${({ theme }) => css`
    .list-information {
      display: none;
    }
    ${respondTo.xl`        
     .list-information {
       max-width: 856px;
    
       display: block;
    
       font-size: 1.25rem;
       line-height: 1.6;
       text-align: center;
       color: white;
    
       margin-top: ${theme.space.xxl}px;
       margin-bottom: -${theme.space.lg}px;
       margin-left: auto;
       margin-right: auto;
     }
    `}
  `}
`

export const SList = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: stretch;
    position: relative;
    justify-content: center;
    flex-wrap: wrap;
    gap: ${theme.space.lg}px;

    margin-top: ${theme.space.md}px;
    padding: 0 ${theme.space.md}px;

    ${respondTo.xl`
      margin: ${theme.space.lg}px auto 0;
      padding: 0; 
    `}
  `}
`

export const STicketsSelector = styled.div`
  ${({ theme, headerHeight }) => css`
    color: white;
    margin-left: auto;
    margin-right: auto;
    margin-top: ${theme.space.xxxl}px;

    .choose-city {
      &-section {
        // height: calc(100vh - ${headerHeight}px);
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      &-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &-title {
        font-size: 1.4rem;
        font-weight: ${theme.weight.bold};
        text-align: center;
      }
    }

    .title {
      font-size: 2rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.25;
      margin-bottom: ${theme.space.xxxl}px;
      text-align: center;
    }

    .subheading {
      font-size: 1rem;
      line-height: 1.5rem;
      color: white;
      text-align: center;

      margin-top: ${theme.space.xxl}px;
      margin-bottom: ${theme.space.xxl}px;
    }

    .subtitle {
      font-size: 1.8rem;
      font-weight: ${theme.weight.bold};
      margin-bottom: ${theme.space.xl}px;
      text-align: center;
    }

    .tabs-wrapper {
      width: 100%;
      max-width: 572px;
      margin-left: auto;
      margin-right: auto;
    }

    .ticket-banner {
      width: 100%;
      max-width: initial;
      min-height: 410px;
      height: initial;

      flex-direction: column-reverse;
      justify-content: space-between;

      background-image: url(${mobileBannerBackground});
      background-position: center bottom;

      margin-top: ${theme.space.xl}px;

      .header {
        flex: 1;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      p {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      &-code {
        display: flex;
        flex-direction: column;

        & > span:first-child {
          font-size: 0.75rem;
          font-weight: ${theme.weight.normal};
          color: white;
        }

        & > .code {
          font-size: 1.31rem;
        }
      }
    }

    .scroll-button-wrapper {
      display: flex;
      justify-content: center;
      margin-top: ${theme.space.xxxl}px;
    }

    .countdown {
      &-info {
        font-size: 1rem;
        line-height: 1.4rem;
        margin: ${theme.space.xl}px 0 ${theme.space.md}px;
        text-align: center;
        background-color: ${theme.colors.pink};
        padding: ${theme.space.md}px ${theme.space.md}px ${theme.space.md}px
          ${theme.space.lg}px;
        display: inline-block;
        color: black;
      }
      &-info-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      &-ticket-type {
        text-transform: capitalize;
      }
      &-number {
        position: absolute;
        top: 0;
        left: 0;
        font-weight: ${theme.weight.bold};
      }
      &-number-wrapper {
        position: relative;
        display: inline-block;
        height: 22px;
        width: 180px;
        vertical-align: bottom;
      }
    }

    .bold-text {
      font-weight: ${theme.weight.bold};
    }

    .students-information {
      font-size: 1rem;
      color: ${theme.colors.pink};

      margin-top: ${theme.space.xl}px;
      margin-bottom: ${theme.space.xl}px;
    }

    .summary {
      border-top: 1px solid white;

      padding: ${theme.space.xl}px ${theme.space.md}px;
      margin: ${theme.space.md}px ${-theme.space.md}px;
      margin-bottom: 0;

      .heading {
        max-width: 70%;

        font-size: 1rem;
        line-height: 1.2;
        color: ${theme.colors.pink};
      }
    }

    .discount {
      form {
        margin-top: ${theme.space.md}px;

        label {
          display: block;
          color: #ffffff;
          margin-bottom: ${theme.space.sm}px;
        }

        input {
          width: calc(100% - 52px);
          height: 48px;

          color: white;
          vertical-align: top;

          border: 1px solid ${theme.colors.lightGray};
          background-color: transparent;

          padding: 0 ${theme.space.sm}px;
          margin-right: ${theme.space.xs}px;

          &:focus {
            outline: 0;
          }
        }

        [type='submit'] {
          width: 48px;
          height: 48px;

          display: inline-flex;
          align-items: center;
          justify-content: center;

          color: ${theme.colors.black};

          border: none;
          box-shadow: none;
          background-color: ${theme.colors.pink};

          margin: 0;
          padding: 0;

          cursor: pointer;

          &:hover {
            background-color: ${theme.colors.darkPink};
          }

          &:disabled {
            border-color: ${theme.colors.lightGray};
            background-color: ${theme.colors.lightGray};

            cursor: not-allowed;
            touch-action: none;
          }

          svg {
            width: 24px;
            margin-right: 2px;
          }
        }
      }

      &-error {
        color: ${theme.colors.lightRed};
        margin-top: ${theme.space.sm}px;
      }
    }

    .details {
      margin-top: ${theme.space.xl}px;

      .ticket {
        display: flex;
        color: white;

        margin-top: ${theme.space.md}px;

        strong {
          font-weight: ${theme.weight.bold};
        }

        .icon {
          width: 32px;
          height: 32px;

          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;

          color: ${theme.colors.black};

          border-radius: 50%;
          background-color: ${theme.colors.pink};

          margin-right: ${theme.space.md}px;

          svg {
            width: 24px;
            height: 24px;
          }
        }

        .name {
          line-height: 1.25;
        }

        .date-and-place {
          font-size: 0.875rem;
          color: ${theme.colors.lightGray};

          margin-top: ${theme.space.md}px;
        }

        .count {
          font-size: 0.875rem;
          margin-top: ${theme.space.md}px;
        }

        .price {
          font-size: 0.875rem;
          margin-top: ${theme.space.sm}px;
        }

        .actions {
          display: flex;
          align-items: flex-end;
          flex-shrink: 0;

          margin-left: 66px;

          .price-loader {
            width: 24px;
            height: 24px;
          }

          .remove {
            width: 24px;
            height: 24px;

            flex-shrink: 0;

            display: flex;
            align-items: center;
            justify-content: center;

            margin: 0;
            padding: 0;

            border: none;
            border-radius: 50%;
            background-color: ${theme.colors.lightGray};

            cursor: pointer;

            svg {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }

    .submit {
      width: 100%;

      margin-top: ${theme.space.xxl}px;
    }

    ${respondTo.lg`          
      .title {
        font-size: 2.625rem;
        line-height: 1.2;
      }
      
      .subheading {
        font-size: 1.25rem;
        line-height: 2rem;
        margin-top: ${theme.space.xl}px;
        margin-bottom: 0;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
      }
      
      .title-wrapper {
        width: 100%;
        margin-bottom: ${theme.space.xxxl}px;
      }
      
      .choose-city { 
        // &-section {
        //   height: calc(100vh - ${headerHeight}px});
        // }
        &-content {
        //   height: 70%;
          justify-content: space-between;
        }
        &-title { 
          font-size: 1.8rem;
          margin-bottom: -${theme.space.lg}px;
        }
      }

      .scroll-button-wrapper {
        margin-top: 0;
      }
      
      .countdown {
        &-info {
          font-size: 1.3rem;
          line-height: 1.8rem;
        }
         &-number-wrapper {
           height: 29px;
           width: 225px;
         }
      }

      .ticket-banner {
        min-height: 182px;
        flex-direction: row;

        background-image: url(${desktopBannerBackground});

        .header {
          p:first-child {
            max-width: 441px;
            font-size: 1.25rem;

            margin-bottom: ${theme.space.md}px;
          }
        }

        &-code {
          & > .code {
            font-size: 2rem;
            line-height: 2rem;
          }
        }

        div {
          svg:first-of-type {
            width: 124px;
            height: 88px;
          }

          svg:last-of-type {
            width: 172px;
            height: 88px;
          }
        }
      }

      .students-information {
        margin: ${theme.space.xxxl}px 0;
      }

      .details {
        margin-top: 0;
      }

      .summary {
        margin-top: ${theme.space.xxxl}px;

        &-content {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 0 96px;
        }
      }

      .submit {
        min-width: 248px;
        width: initial;

        margin-top: 96px;
        margin-left: auto;
        margin-right: auto;
      }
    `}
  `}
`
