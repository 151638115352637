const technologies = [
  {
    label: ".net",
    value: ".net",
  },
  {
    label: "android",
    value: "android",
  },
  {
    label: "angularJS",
    value: "angularJS",
  },
  {
    label: "apache spark",
    value: "apache spark",
  },
  {
    label: "application architecture",
    value: "application architecture",
  },
  {
    label: "artificial intelligence",
    value: "artificial intelligence",
  },
  {
    label: "augmented reality",
    value: "augmented reality",
  },
  {
    label: "autonomous cars",
    value: "autonomous cars",
  },
  {
    label: "AWS ",
    value: "AWS ",
  },
  {
    label: "azure",
    value: "azure",
  },
  {
    label: "backend",
    value: "backend",
  },
  {
    label: "big data",
    value: "big data",
  },
  {
    label: "blockchain",
    value: "blockchain",
  },
  {
    label: "c#",
    value: "c#",
  },
  {
    label: "c++, c",
    value: "c++, c",
  },
  {
    label: "cassandra",
    value: "cassandra",
  },
  {
    label: "cloud computing",
    value: "cloud computing",
  },
  {
    label: "database",
    value: "database",
  },
  {
    label: "devops",
    value: "devops",
  },
  {
    label: "docker",
    value: "docker",
  },
  {
    label: "elixir",
    value: "elixir",
  },
  {
    label: "flink",
    value: "flink",
  },
  {
    label: "frontend",
    value: "frontend",
  },
  {
    label: "gamedev",
    value: "gamedev",
  },
  {
    label: "go",
    value: "go",
  },
  {
    label: "golang",
    value: "golang",
  },
  {
    label: "google cloud",
    value: "google cloud",
  },
  {
    label: "hadoop",
    value: "hadoop",
  },
  {
    label: "hardware",
    value: "hardware",
  },
  {
    label: "html/css",
    value: "html/css",
  },
  {
    label: "internet of things",
    value: "internet of things",
  },
  {
    label: "ios",
    value: "ios",
  },
  {
    label: "java",
    value: "java",
  },
  {
    label: "javascript",
    value: "javascript",
  },
  {
    label: "kotlin",
    value: "kotlin",
  },
  {
    label: "laravel ",
    value: "laravel ",
  },
  {
    label: "linux",
    value: "linux",
  },
  {
    label: "lucene",
    value: "lucene",
  },
  {
    label: "machine learning",
    value: "machine learning",
  },
  {
    label: "matlab",
    value: "matlab",
  },
  {
    label: "microservices",
    value: "microservices",
  },
  {
    label: "mixed reality",
    value: "mixed reality",
  },
  {
    label: "mobile",
    value: "mobile",
  },
  {
    label: "mysql",
    value: "mysql",
  },
  {
    label: "node.js",
    value: "node.js",
  },
  {
    label: "objective c",
    value: "objective c",
  },
  {
    label: "performance",
    value: "performance",
  },
  {
    label: "php",
    value: "php",
  },
  {
    label: "postgresql",
    value: "postgresql",
  },
  {
    label: "python",
    value: "python",
  },
  {
    label: "r",
    value: "r",
  },
  {
    label: "react.js",
    value: "react.js",
  },
  {
    label: "ruby",
    value: "ruby",
  },
  {
    label: "ruby-on-rails",
    value: "ruby-on-rails",
  },
  {
    label: "salesforce",
    value: "salesforce",
  },
  {
    label: "sap",
    value: "sap",
  },
  {
    label: "scala",
    value: "scala",
  },
  {
    label: "security",
    value: "security",
  },
  {
    label: "sql",
    value: "sql",
  },
  {
    label: "swift",
    value: "swift",
  },
  {
    label: "testing",
    value: "testing",
  },
  {
    label: "unity",
    value: "unity",
  },
  {
    label: "unreal",
    value: "unreal",
  },
  {
    label: "virtual reality",
    value: "virtual reality",
  },
]

export default technologies
