import * as React from 'react'

function Twitter(props) {
  return (
    <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle fill="#FFF" cx={12.5} cy={12.5} r={12.5} />
        <path
          d="M9.407 20.333A10.147 10.147 0 0019.893 10.25a7.416 7.416 0 001.357-2.603.403.403 0 00-.568-.468c-.667.32-1.463.18-1.98-.348a3.566 3.566 0 00-5.115-.156 3.786 3.786 0 00-1.054 3.575c-3.071.183-5.18-1.274-6.866-3.272a.394.394 0 00-.688.22 8.873 8.873 0 004.217 9.212 6.17 6.17 0 01-4.098 2.09.412.412 0 00-.128.77 10.083 10.083 0 004.437 1.063"
          fill="#292929"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default Twitter
