import React, { useReducer, useRef } from 'react'
import video from '../../assets/videos/partners-popup-video.mp4'
import { IoResize } from 'react-icons/io5'
import { RiCloseLine, RiVolumeMuteFill, RiVolumeUpFill } from 'react-icons/ri'
import { SContainer } from './VideoPopup.styled'
import { useBreakpoint } from '../../modules/breakpoint'
import { Tooltip } from '..'
import { useIntl } from 'react-intl'

const defaultState = {
  isOpen: true,
  isBigger: false,
  closeButtonVisible: true,
  isMuted: true,
}

function VideoPopup(props) {
  const { openContactModalOrCallpage } = props
  const breakpoint = useBreakpoint()
  const isMobile = breakpoint.lg
  const { formatMessage } = useIntl()

  const [popup, updatePopup] = useReducer((prev, next) => {
    return { ...prev, ...next }
  }, defaultState)

  const { isOpen, isBigger, closeButtonVisible, isMuted } = popup
  const videoRef = useRef(null)

  const toggleModalVisibility = (e) => {
    e.stopPropagation()
    updatePopup({ isMuted: true, isOpen: !isOpen })
  }
  const toggleMute = () => updatePopup({ isMuted: !isMuted })

  const showButton = () => updatePopup({ closeButtonVisible: true })
  const hideButton = () => updatePopup({ closeButtonVisible: false })

  const handleEnlarge = (e) => {
    e.preventDefault()
    e.stopPropagation()
    updatePopup({ isMuted: !isMuted })

    if (isBigger) return

    updatePopup({ isBigger: true })
    if (videoRef.current) {
      videoRef.current.currentTime = 0
    }
  }

  return (
    <SContainer show={isOpen} {...{ isBigger }}>
      <div
        onMouseEnter={isBigger && !isMobile ? showButton : undefined}
        onMouseLeave={isBigger && !isMobile ? hideButton : undefined}
        onClick={handleEnlarge}
        className="wrapper"
      >
        <button
          onClick={toggleModalVisibility}
          className={`close-button ${
            closeButtonVisible || isMobile ? 'block' : 'hidden'
          }`}
        >
          <RiCloseLine className="close-icon" />
        </button>
        <div
          className={`video-wrapper ${
            isBigger ? 'h-[450px] max-w-[260px]' : 'h-[300px] max-w-[175px]'
          }`}
        >
          <video
            ref={videoRef}
            autoPlay
            loop
            playsInline
            muted={!isBigger || isMuted}
            className="video"
          >
            <source src={video} type="video/mp4" />
          </video>
          {isBigger ? (
            <>
              <Tooltip
                className="tooltip"
                value={formatMessage({ id: isMuted ? 'soundOn' : 'mute' })}
                offset={[0, 12]}
                render={({ referenceElement, show, hide }) => (
                  <button
                    onMouseEnter={show}
                    onMouseLeave={hide}
                    onClick={toggleMute}
                    ref={referenceElement}
                    className="sound-button"
                  >
                    {isMuted ? (
                      <RiVolumeMuteFill className="sound-icon" />
                    ) : (
                      <RiVolumeUpFill className="sound-icon" />
                    )}
                  </button>
                )}
              />

              <button
                className="cta-button"
                onClick={openContactModalOrCallpage}
              >
                {formatMessage({ id: 'videoPopupContactUs' })}
              </button>
            </>
          ) : (
            <>
              <span className="hello-wrapper">
                {formatMessage({ id: 'hello' })}
              </span>
              <IoResize className="resize-icon" />
            </>
          )}
        </div>
      </div>
    </SContainer>
  )
}

export default VideoPopup
