import React from 'react'
import { useIntl } from 'react-intl'

import { ButtonLink, SlideTop } from '../../components'

import 'swiper/swiper.scss'
import { SContainer } from './LatestEditions.styled'

import photo3 from '../../assets/images/latest-editions-3.png'
import photo2 from '../../assets/images/latest-editions-2.png'
import photo1 from '../../assets/images/latest-editions-1.png'

const tiles = [
  {
    photo: photo1,
    buttonText: 'LecturesAndRecordings',
    link: 'https://www.youtube.com/@CodeEurope',
  },
  {
    photo: photo2,
    buttonText: 'PhotoGalleries',
    link: 'https://codeeurope.pixieset.com/',
  },
  {
    photo: photo3,
    buttonText: 'VideoSummary',
    link: 'https://youtu.be/QgLxcBe8nDU',
  },
]
function LatestEditions() {
  const { formatMessage } = useIntl()

  return (
    <SContainer>
      <SlideTop>
        <div className="container">
          <h2 className="heading">
            {formatMessage(
              { id: 'latestEditionsHeading' },
              {
                span: (chunk) => <span>{chunk}</span>,
                br: <br />,
              }
            )}
          </h2>
          <div className="tiles-container">
            {tiles.map(({ photo, buttonText, link }) => (
              <div key={buttonText} className="tile">
                <img src={photo} alt="" />
                <ButtonLink
                  height="60px"
                  fontSize="18px"
                  secondary="true"
                  target="_blank"
                  rel="noreferrer"
                  href={link}
                >
                  {formatMessage({
                    id: buttonText,
                  })}
                  <i className="ri-arrow-right-line" />
                </ButtonLink>
              </div>
            ))}
          </div>
        </div>
      </SlideTop>
    </SContainer>
  )
}

export default LatestEditions
