import styled, { css } from "styled-components"
import respondTo from "../../theme/respondTo"

export const SDescription = styled.p`
  ${({ theme }) => css`
    font-size: 1rem;
    line-height: 1.5;
    color: white;

    margin: ${theme.space.md}px 0 0;

    ${respondTo.lg`
      font-size: 1.25rem;
      line-height: 1.6;

      margin: ${theme.space.xl}px 0 0;
    `}
  `}
`
export const SHeading = styled.h2`
  ${({ theme }) => css`
      font-size: 2rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.25;
      color: white;

      ${respondTo.lg`
        font-size: 2.625rem;
        line-height: 1.2;
      `}
    }
  `}
`
export const SHeader = styled.div`
  ${({ theme, notCentered }) => css`
    ${respondTo.lg`
      width: 85%;
      margin: 0 auto;
      text-align: center;
      
      ${notCentered &&
         css`
           text-align: left;
           width: 100%;
         `}
    `}
  `}
`
