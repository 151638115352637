import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import config from 'const/config'
import { clearUser } from 'state/reducers/user/userActions'
import useLocalizeRoute from 'utils/useLocalizeRoute'
import { AppRoute } from 'const'
import { Loader } from 'components'

function Logout() {
  const dispatch = useDispatch()
  const { localizeRouteKey } = useLocalizeRoute()

  useEffect(() => {
    dispatch(clearUser())
    localStorage.removeItem(config.STORAGE_TOKEN_KEY)
  }, [])

  return null
}

export { Logout }
