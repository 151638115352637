import * as React from 'react'

function Instagram(props) {
  return (
    <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle fill="#FFF" cx={12.5} cy={12.5} r={12.5} />
        <path
          d="M12.5 5.532c2.27 0 2.539.009 3.435.05 2.304.105 3.38 1.197 3.485 3.484.04.896.049 1.165.049 3.435s-.009 2.538-.05 3.434c-.105 2.285-1.178 3.38-3.484 3.485-.896.04-1.164.05-3.435.05-2.27 0-2.539-.01-3.435-.05-2.309-.106-3.38-1.204-3.484-3.485-.041-.896-.05-1.165-.05-3.435s.01-2.538.05-3.435c.106-2.286 1.179-3.38 3.484-3.484.897-.04 1.165-.049 3.435-.049zM12.5 4c-2.308 0-2.597.01-3.504.051-3.087.142-4.803 1.854-4.944 4.944C4.01 9.903 4 10.192 4 12.5s.01 2.598.051 3.505c.142 3.087 1.854 4.802 4.944 4.944.908.041 1.197.051 3.505.051s2.598-.01 3.505-.051c3.084-.142 4.804-1.854 4.943-4.944.042-.907.052-1.197.052-3.505s-.01-2.597-.051-3.504c-.139-3.084-1.854-4.803-4.943-4.944C15.098 4.01 14.808 4 12.5 4zm0 4.135a4.365 4.365 0 100 8.73 4.365 4.365 0 000-8.73zm0 7.198a2.833 2.833 0 110-5.666 2.833 2.833 0 010 5.666zm4.538-8.39a1.02 1.02 0 10-.001 2.041 1.02 1.02 0 000-2.04z"
          fill="#292929"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default Instagram
