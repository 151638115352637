import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SFooter = styled.footer`
  ${({ theme }) => css`
    color: white;
    background-color: ${theme.colors.darkGray};

    padding: ${theme.space.xl + 8}px ${theme.space.lg}px;

    ${respondTo.xl`
      max-width: 1240px;
      padding: ${theme.space.xxxl}px 24px 80px 100px;
    `}
    ${respondTo.xxl`      
      margin: 0 auto;
      padding: ${theme.space.xxxl}px 0 80px;
    `}

    .container {
      display: flex;
    }
    .socials {
      &-container {
        margin-top: ${theme.space.md}px;
      }
      &-icons {
        display: flex;
        gap: ${theme.space.md + 4}px;
      }
    }
    .column {
      margin-top: 35px;
      margin-right: 20px;

      &-heading {
        font-size: 1rem;
        line-height: 1.375rem;
        font-weight: ${theme.weight.bold};
        color: ${theme.colors.lightGray2};
        text-decoration: none;
        margin-bottom: 4px;
      }
      &-links {
        li {
          a {
            font-size: 1rem;
            line-height: 2.188rem;
            text-decoration: none;
            color: white;

            &:hover {
              color: ${theme.colors.pink};
            }
          }
        }
      }
    }
    p {
      color: ${theme.colors.lightGray};
      font-size: 1rem;
      line-height: 1.375rem;
      font-weight: 500;
      max-width: 350px;

      &.text-join-us {
        margin: ${theme.space.lg + 4}px 0 ${theme.space.xl + 8}px;
      }

      &.text-follow-us {
        margin: 20px 0;
      }
    }

    hr {
      height: 1px;
      width: 80px;
      background-color: ${theme.colors.lightGray};
      margin: 0;
      border: none;
    }

    .left-side {
      width: 100%;

      ${respondTo.lg`
        flex: 1 1 0;
      `}

      .button-wrapper {
        display: flex;

        a {
          &.disable-secondary-button {
            cursor: not-allowed;
            background-color: ${theme.colors.disabledLink};
            box-shadow: 4px 4px 0 ${theme.colors.disabledLink},
              4px 4px 0 1px ${theme.colors.pink};
            &:hover,
            &:focus {
              background-color: ${theme.colors.disabledLink};
            }
          }
        }
      }

      .logo {
        width: 132px;
        height: 40px;
        color: white;
      }

      i {
        margin-left: ${theme.space.sm}px;
        padding-top: 4px;
      }
    }
    .right-side {
      display: none;

      ${respondTo.lg`
        display: flex;
        justify-content: space-between;
        flex: 1 1 0;
        width: 531px;
      `}
    }
    .language-picker {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  `}
`
