import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'
import backgroundDesktop from '../../assets/images/aftermovie-background-desktop.svg'

export const SContainer = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundBlack};
    position: relative;

    padding-bottom: ${theme.space.xl + 8}px;

    ${respondTo.lg`
        padding: ${theme.space.xxl * 2}px 0 60px;
    `}

    .container {
      background-color: white;
      border: 3px solid ${theme.colors.pink};
      padding: ${theme.space.xl + 8}px ${theme.space.lg}px;
      max-width: 478px;

      padding: ${theme.space.lg}px;
      margin: 0 auto;

      ${respondTo.lg`
        background-repeat: no-repeat;
        background-size: contain;
        border: none;
        background-size: auto;
        background-color: ${theme.colors.backgroundBlack};
        background-image: url(${backgroundDesktop});
        max-width: 1201px;
        padding: ${theme.space.xl}px 0 ${theme.space.xxl}px;
      `}
      ${respondTo.xl`
        background-size: contain;
      `}
    }

    .heading {
      font-size: 1.25rem;
      line-height: 1.5rem;
      font-weight: ${theme.weight.bold};
      color: ${theme.colors.black};
      text-align: center;
      max-width: 340px;
      margin: 0 auto ${theme.space.xxl}px;

      ${respondTo.lg`
        max-width: 800px;
        font-size: 3.25rem;
        line-height: 4.5rem;
        margin: 0 auto ${theme.space.xl}px;
      `}

      span {
        text-shadow: 2px 2px 0 ${theme.colors.pink};
        font-size: 1.5rem;
        line-height: 1.88rem;

        ${respondTo.lg`
          font-size: 3.25rem;
          line-height: 4.125rem;
        `}
      }
    }

    .video-container {
      display: flex;
      align-items: center;
      margin: 0 auto;
      height: 200px;
      box-shadow: ${theme.space.sm}px ${theme.space.sm}px 0 1px
        ${theme.colors.pink};

      iframe {
        z-index: 2;
      }

      ${respondTo.xxs`
        height: 216px;
        width: 300px;
      `}

      ${respondTo.xs`
        max-width: 385px;
        width: 100%;
      `}

      ${respondTo.lg`
        max-height: 100%;
        height: 402px;
        max-width: 719px;
        position: relatives;
        
        box-shadow: ${theme.space.md}px ${theme.space.md}px 0 1px
        ${theme.colors.pink};
      `}
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    .positionLeft {
      position: absolute;
      top: -20%;
      right: 17%;
      z-index: 2;

      ${respondTo.xxs`
         top: -18%;
      `}
      ${respondTo.lg`
        top: 15%;
        left: 2%;
        z-index: 0;
         img {
          width: 200px;
          height: 70px;
        }
     `}
      ${respondTo.xl`
        left: 7%;
     `}
    }
    .positionRight {
      display: none;

      ${respondTo.lg`
        position: absolute;
        top: 62%;
        right: 2%;
        display: block;
        z-index: 1;
       
         img { 
          width: 270px;
          height: 70px;
        }
     `}
      ${respondTo.xl`
        right: 7%;
     `}
    }
  `}
`

export const SSwiperWrapper = styled.div`
  margin: 0 auto;
  max-width: 1350px;
  position: relative;
`
