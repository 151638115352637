import { useIntl } from 'react-intl'

import { useBreakpoint } from 'modules/breakpoint'

import { CustomUnderline, SlideTop, Wrapper } from '../../components'
import {
  SContent,
  SCategoryTitle,
  SContainer,
  SPartner,
  SPartnerCategory,
} from './Partner.styled'

function Partners({ partner }) {
  const breakpoints = useBreakpoint()
  const { locale } = useIntl()

  if (!partner) return null

  const [item] = partner.patronages
  return (
    <SContainer>
      <SContainer>
        <Wrapper>
          <SContent>
            <SPartnerCategory>
              <SlideTop>
                <SCategoryTitle>
                  <CustomUnderline color={partner.color}>
                    {locale !== 'pl' ? partner.name_en : partner.name}
                  </CustomUnderline>
                </SCategoryTitle>
              </SlideTop>
              <SlideTop>
                <a href={item.link} target="_blank">
                  <SPartner color={partner.color}>
                    <img src={item.img} />
                  </SPartner>
                </a>
              </SlideTop>
            </SPartnerCategory>
          </SContent>
        </Wrapper>
      </SContainer>
    </SContainer>
  )
}

export default Partners
