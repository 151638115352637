import * as React from "react"

function PlaneIcon(props) {
  return (
    <svg viewBox="0 0 72 50" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M30.097 32.514L27.93 49.131l-9.516-20.595-16.882-6.634L68.976 3.025a1.395 1.395 0 011.635 1.944L52.548 42.847h0L30.097 32.514z"
          stroke="#FFF"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          stroke="#FFF"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M59.133 11.797L30.097 32.514 28.02 49.131 18.6 28.638z"
        />
        <path
          d="M53.259 15.222V2.247a1 1 0 00-1.352-.936L30.793 9.244a4 4 0 00-2.593 3.744v28.315l1.212-9.059L53.26 15.222z"
          fill="#FC86F6"
        />
        <path
          stroke="#FFF"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M28.2 49.13l11.733-11.893-9.558-4.106z"
        />
      </g>
    </svg>
  )
}

export default PlaneIcon
