import styled, { css } from 'styled-components'
import respondTo from 'theme/respondTo'

export const SStep = styled.span`
  ${({ theme, active, done }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    &::before {
      content: '';

      width: 50%;
      height: 2px;

      position: absolute;
      top: 6px;
      left: 0;

      background-color: #6c6c6c;

      z-index: -1;
    }

    &::after {
      content: '';

      width: 50%;
      height: 2px;

      position: absolute;
      top: 6px;
      right: 0;

      background-color: #6c6c6c;

      z-index: -1;
    }

    &:first-child {
      &::before {
        content: none;
      }
    }

    &:last-child {
      &::after {
        content: none;
      }
    }

    .icon {
      width: 14px;
      height: 14px;

      display: block;

      border-radius: 50%;
      border: 2px solid #6c6c6c;

      background-color: ${theme.colors.darkestGray};

      & > span {
        display: none;
      }
    }

    .name {
      display: inline-block;

      font-size: 0.5rem;
      line-height: 1.25;
      text-align: center;
      color: white;

      padding: 0 ${theme.space.sm}px;
      margin-top: ${theme.space.sm}px;
    }

    ${done &&
    css`
      &::after,
      &::before {
        background-color: ${theme.colors.pink};
      }

      .icon {
        border-color: ${theme.colors.pink};
        background-color: ${theme.colors.pink};

        & > span {
          color: ${theme.colors.darkestGray} !important;
        }
      }
    `}

    ${active &&
    css`
      &::before {
        background-color: ${theme.colors.pink};
      }
      .icon {
        border-color: ${theme.colors.pink};
      }
      .name {
        color: ${theme.colors.pink};
      }
    `}

    ${respondTo.lg`
      &::before, &::after {
        top: 14px;
      }

      .icon {
        width: 28px;
        height: 28px;

        display: flex;
        justify-content: center;
        align-items: center;

        & > span {
          display: inline;
          font-size: .875rem;
          color: white;

          margin-bottom: 2px;
        }
      }

      .name {
        font-size: .75rem;
      }
    `}
  `}
`

export const SSteper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;

    position: relative;

    padding: ${theme.space.sm}px 0;
  `}
`
