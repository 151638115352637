import React, { useState } from 'react'
import Collapsible from 'react-collapsible'
import { SContainer } from './Accordion.styled'

const Accordion = ({ open, trigger, children }) => {
  const [isVisible, setVisibility] = useState(Boolean(open))

  const toggleVisibility = () => {
    setVisibility(!isVisible)
  }

  return (
    <SContainer {...{ isVisible }}>
      <Collapsible
        trigger={
          <span
            role="button"
            aria-expanded={isVisible}
            className="trigger-wrapper"
          >
            <span className="trigger">{trigger}</span>
            <div className="arrow-wrapper">
              <i className="ri-arrow-up-s-fill" />
            </div>
          </span>
        }
        tabIndex={0}
        open={open}
        onOpening={toggleVisibility}
        onClose={toggleVisibility}
      >
        <div>{children}</div>
      </Collapsible>
    </SContainer>
  )
}

export default Accordion
