import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'

import client from 'api/client'

import {
  TicketProcessBox,
  TicketUserSteper,
  TicketProcessInput,
  TicketProcessCheckbox,
  Select,
  DropzoneInput,
} from '../'
import Button from 'components/Button/Button'

import { TicketStep } from 'state/reducers/tickets/ticketsReducer'
import {
  buyTicket,
  changeStep,
  setFields,
} from 'state/reducers/tickets/ticketsActions'

function TicketUserData({
  fields,
  experienceLevel,
  technologies,
  languages,
  isFree,
  buyTicketLoading,
}) {
  const dispatch = useDispatch()
  const { locale, formatMessage } = useIntl()

  const [loading, setLoading] = useState(false)

  const { register, handleSubmit, control, formState, watch, setError } =
    useForm({
      defaultValues: {
        experience: experienceLevel.find((o) => o.value === fields.experience),
        technologies_working_with:
          fields.technologies_working_with?.map((key) =>
            technologies.find((o) => o.value === key)
          ) ?? null,
        technologies_interested_in:
          fields.technologies_interested_in?.map((key) =>
            technologies.find((o) => o.value === key)
          ) ?? null,
        known_languages:
          fields.known_languages?.map((key) =>
            languages.find((o) => o.value === key)
          ) ?? null,
        cv: null,
      },
    })

  const technologiesWorkingWithCount =
    watch('technologies_working_with[]')?.length ?? 0
  const technologiesInterestedIn =
    watch('technologies_interested_in[]')?.length ?? 0

  const onDropFile = (onChange) => (file) => {
    onChange(file)
  }

  const handlePrev = () => {
    dispatch(changeStep(TicketStep.BADGE))
  }

  const onSubmit = async (values) => {
    setLoading(true)
    try {
      if (values.cv && values.cv.length) {
        const formData = new FormData()
        formData.append('resume', values.cv[0])

        const response = await client({
          endpoint: 'api/participant/resume',
          method: 'POST',
          withoutHeaders: true,
          body: formData,
        })

        if (!response.ok) {
          const data = await response.json()
          Object.keys(data).forEach((key) => {
            const error = data[key].flat()
            if (!Array.isArray(error)) return

            const [errorMessage] = error
            if (key === 'resume') {
              setError('cv', { type: 'required', message: errorMessage })
              return
            }
            setError(key, { type: 'required', message: errorMessage })
          })

          throw new Error('Resume error')
        }

        const { resume_file_path } = await response.json()
        dispatch(
          setFields({ type: 'base', fields: { resume: resume_file_path } })
        )
      }

      const body = {
        step: 3,
        experience: values?.experience?.value ?? null,
        technologies_working_with:
          values?.technologies_working_with?.map((o) => o.value) ?? null,
        technologies_interested_in:
          values?.technologies_interested_in?.map((o) => o.value) ?? null,
        known_languages: values?.known_languages?.map((o) => o.value) ?? null,
      }

      if (!isFree) {
        const response = await client({
          endpoint: 'api/buy/validate',
          method: 'POST',
          body: JSON.stringify(body),
        })

        if (!response.ok) {
          const data = await response.json()
          Object.keys(data).forEach((key) => {
            const error = data[key].flat()
            if (!Array.isArray(error)) return

            const [errorMessage] = error
            setError(key, { type: 'required', message: errorMessage })
          })

          throw new Error()
        }
      }

      const fields = { ...body }
      delete fields.step

      window.scrollTo({ top: 0 })
      dispatch(
        setFields({
          type: 'base',
          fields,
        })
      )

      isFree
        ? dispatch(buyTicket({ isFree, locale }))
        : dispatch(changeStep(TicketStep.PAYMENT_DATA))
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('[POST] validate user data step error: ', error)
    }
  }

  return (
    <TicketProcessBox>
      <header>
        <p className="heading">
          {formatMessage({ id: 'tickets.userDataHeading' })}
        </p>
        <p className="informations">
          {formatMessage({ id: 'tickets.userDataDescription' })}
        </p>
      </header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TicketProcessInput
          {...{
            label: 'labels.experience',
            htmlFor: 'experience',
            error: formState.errors.experience,
          }}
        >
          <Controller
            name="experience"
            defaultValue={false}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti={false}
                short
                value={value}
                id="experience"
                name="experience"
                options={experienceLevel}
                onChange={onChange}
                error={formState.errors.experience}
              />
            )}
          />
        </TicketProcessInput>

        <TicketProcessInput
          {...{
            label: 'labels.technologiesWorkingWith',
            htmlFor: 'technologiesWorkingWith',
            count: technologiesWorkingWithCount,
            maxCount: 5,
            error: formState.errors.technologies_working_with,
          }}
        >
          <Controller
            name="technologies_working_with[]"
            defaultValue={false}
            control={control}
            rules={{
              validate: (value) => {
                return (value?.length ?? 0) <= 5
                  ? true
                  : 'errors.technologiesWorkingWithOverError'
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Select
                short
                value={value}
                id="technologiesWorkingWith"
                name="technologies_working_with[]"
                options={technologies}
                onChange={onChange}
                error={formState.errors.technologies_working_with}
              />
            )}
          />
        </TicketProcessInput>

        <TicketProcessInput
          {...{
            label: 'labels.technologiesInterestedIn',
            htmlFor: 'technologies-interested-in',
            count: technologiesInterestedIn,
            maxCount: 5,
            error: formState.errors.technologies_interested_in,
          }}
        >
          <Controller
            name="technologies_interested_in[]"
            defaultValue={false}
            control={control}
            rules={{
              validate: (value) => {
                return (value?.length ?? 0) <= 5
                  ? true
                  : 'errors.technologiesInterestedInOverError'
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Select
                short
                value={value}
                id="technologiesInterestedIn"
                name="technologies_interested_in[]"
                options={technologies}
                onChange={onChange}
                error={formState.errors.technologies_interested_in}
              />
            )}
          />
        </TicketProcessInput>

        <TicketProcessInput
          {...{
            label: 'labels.knownLanguages',
            htmlFor: 'known-languages',
            error: formState.errors.knownLanguages,
          }}
        >
          <Controller
            name="known_languages[]"
            defaultValue={false}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                short
                value={value}
                id="known_languages"
                name="known_languages[]"
                options={languages}
                onChange={onChange}
                error={formState.errors.known_languages}
              />
            )}
          />
        </TicketProcessInput>

        <TicketProcessInput
          {...{
            label: 'labels.cv',
            optional: true,
            htmlFor: 'cv',
            error: formState.errors.cv,
            preDescription: 'labels.cvRequirements',
          }}
        >
          <Controller
            name="cv"
            control={control}
            render={({ field: { value, onChange } }) => {
              const isString = typeof value === 'string'
              const fileName = isString
                ? value
                : value?.length
                ? value[0].name
                : null

              return (
                <DropzoneInput
                  maxFiles={1}
                  userName={`${fields.name} ${fields.surname}`}
                  uploadedFile={fileName}
                  onRemove={onChange}
                  onDrop={onDropFile(onChange)}
                />
              )
            }}
          />
        </TicketProcessInput>

        <div className="actions">
          <Button
            disabled={loading || buyTicketLoading}
            loading={loading || buyTicketLoading}
          >
            {formatMessage({ id: 'general.next' })}
          </Button>
          <Button type="button" onClick={handlePrev} bordered>
            {formatMessage({ id: 'general.back' })}
          </Button>
        </div>
      </form>
    </TicketProcessBox>
  )
}

export default TicketUserData
