import { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'

import { AppRoute } from 'const'
import { HEADER_DOM_ID } from 'components/Header/Header'
import { FOOTER_DOM_ID } from 'sections/Footer/Footer'

import { ButtonLink } from '../components'
import PlaneIcon from '../assets/icons/PlaneIcon'
import TicketProcessBox from 'components/TicketProcessBox/TicketProcessBox.styled'
import useLocalizeRoute from 'utils/useLocalizeRoute'

function BuyTicketSuccess({ isGroup }) {
  const location = useLocation()
  const email = new URLSearchParams(location.search).get('email')

  const { locale, formatMessage } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()

  return (
    <div>
      <TicketProcessBox margin>
        <div className="success">
          <div className="header">
            <p className="heading">
              {formatMessage({ id: 'tickets.successHeading' })}
            </p>
          </div>
          <PlaneIcon />
          <p>
            {formatMessage(
              {
                id: isGroup
                  ? 'tickets.successInfoGroup'
                  : 'tickets.successInfo',
              },
              { span: (chunk) => <span>{chunk}</span>, email }
            )}
          </p>
          <p>
            <strong>{formatMessage({ id: 'tickets.successInfoSpam' })}</strong>
          </p>
          {!isGroup ? (
            <p className="edit">
              {formatMessage(
                { id: 'tickets.successInfoEdit' },
                {
                  a: (chunk) => (
                    <Link to={localizeRouteKey(AppRoute.User)}>{chunk}</Link>
                  ),
                }
              )}
            </p>
          ) : null}
          <ButtonLink
            as={Link}
            to={localizeRouteKey(AppRoute.Home)}
            className="button"
          >
            {formatMessage({ id: 'general.ready' })}
          </ButtonLink>
        </div>
      </TicketProcessBox>
    </div>
  )
}

export { BuyTicketSuccess }
