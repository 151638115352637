import React from 'react'
import { useIntl } from 'react-intl'

import { STicketPriceSummary } from './TicketPriceSummary.styled'

function TicketPriceSummary({ price, ...res }) {
  const { formatMessage } = useIntl()

  return (
    <STicketPriceSummary {...res}>
      <p className="value">
        <span>{formatMessage({ id: 'tickets.orderPrice' })}:</span>
        <span>{price.priceBeforeDiscount} zł</span>
      </p>
      <p className="discount">
        <span>{formatMessage({ id: 'tickets.discountCode' })}:</span>
        {price.discountPrice ? (
          <span className="highlight">- {price.discountPrice} zł</span>
        ) : (
          <span>-</span>
        )}
      </p>
      <p className="price">
        <span>{formatMessage({ id: 'tickets.priceSummaryTotal' })}:</span>
        <span className="highlight">{price.priceAfterDiscount} zł</span>
      </p>
    </STicketPriceSummary>
  )
}

export default TicketPriceSummary
