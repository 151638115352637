import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'
import background from '../../assets/images/whyAttendBackground.svg'

export const SContainer = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundBlack};
    padding: ${theme.space.xl * 2}px ${theme.space.lg}px ${theme.space.xl + 8}px;
    position: relative;
    color: white;

    ${respondTo.lg`
      background-image: url(${background});
      background-repeat: no-repeat;
      background-size: cover;
      padding: 160px ${theme.space.lg}px 120px;
    `}

    .content {
      margin: 0 auto;
    }

    .heading {
      color: white;
      font-weight: ${theme.weight.bold};
      font-size: 1.5rem;
      line-height: 1.875rem;
      margin-bottom: ${theme.space.md + 4}px;

      ${respondTo.lg`
        font-size: 2.25rem;
        line-height: 3.125rem;
        text-align: center;
      `};
    }

    .subheader {
      color: ${theme.colors.lightGray2};
      font-size: 1.125rem;
      line-height: 1.56rem;
      margin: 0 auto ${theme.space.lg + 4}px;

      ${respondTo.lg`
        font-size: 1.375rem;
        line-height: 1.875rem;
        text-align: center;
        margin-bottom: 76px;
        max-width: 759px;
      `}
    }

    button {
      width: 100%;
      margin-top: ${theme.space.xl + 8}px;

      ${respondTo.lg`
          width: auto;
          margin: ${theme.space.xl * 2}px auto 0;
      `}

      i {
        margin-left: ${theme.space.sm}px;
        padding-top: 4px;
      }
    }
  `}
`
