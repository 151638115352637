import { useMemo } from 'react'
import { useTheme } from 'styled-components'
import { useIntl } from 'react-intl'
import { Link } from 'react-scroll'

import { TicketShape } from 'components'
import { STicket } from './Ticket.styled'

import TicketBackground from './TicketBackground'
import PinIcon from 'assets/icons/PinIcon'
import CalendarIcon from 'assets/icons/CalendarIcon'
import MinusIcon from 'assets/icons/MinusIcon'
import PlusIcon from 'assets/icons/PlusIcon'
import { defaultTimezone } from 'const/defaultTimezone'

const AmountActionType = {
  DECREASE: 'decrease',
  INCREASE: 'increase',
}

function Ticket(props) {
  const {
    perks,
    perks_en,
    price,
    city,
    city_en,
    date_start,
    date_end,
    color,
    pass_type,
    student_ticket,
    anySelected,
    amount,
    selected,
    onSelect,
    pass_price_till,
    pass_price_from,
  } = props
  const { locale, formatMessage, formatDate } = useIntl()

  const todayTime = new Date().getTime()

  const isBeforeSaleStart =
    todayTime < new Date(pass_price_from?.replace(/-/g, '/')).getTime()
  const isAfterSaleEnd =
    todayTime > new Date(pass_price_till?.replace(/-/g, '/')).getTime()

  const isDisabled = !student_ticket && (isBeforeSaleStart || isAfterSaleEnd)

  const handleChangeAmount = (amountActionType) => () => {
    const tempAmount = !selected ? 0 : amount

    if (amountActionType === AmountActionType.DECREASE) {
      if (tempAmount === 0) return
      onSelect(tempAmount - 1)
      return
    }

    onSelect(tempAmount + 1)
  }

  const renderCounter = () => {
    return (
      <div className="counter">
        <button
          className="decrease"
          type="button"
          onClick={handleChangeAmount(AmountActionType.DECREASE)}
        >
          <MinusIcon />
        </button>
        <p className="amount">{selected ? amount : 0}</p>
        <button
          className="increase"
          type="button"
          onClick={handleChangeAmount(AmountActionType.INCREASE)}
        >
          <PlusIcon />
        </button>
      </div>
    )
  }

  return (
    <STicket {...{ color, anySelected, selected, isDisabled }}>
      <TicketShape className="shape" {...{ active: selected, color }} />
      <TicketBackground className="lines" {...{ color }} />
      <div className="text-content-wrapper">
        <div className="content">
          <p className="pass-type">{pass_type.toUpperCase()} PASS</p>
          <p className="price">
            <span>{price}zł</span>{' '}
            <span className="vat">
              ({formatMessage({ id: 'tickets.ticketPriceIncludingVat' })})
            </span>
          </p>

          <div className="date-and-place">
            <p className="info">
              <PinIcon />
              {locale === 'pl' ? city : city_en || city}
            </p>
            <p className="info">
              <CalendarIcon />
              {formatDate(
                date_start.replace(/-/g, '/'),
                {
                  day: 'numeric',
                  month: '2-digit',
                },
                { timeZone: defaultTimezone }
              )
                .split('/')
                .reverse()
                .join('.')}{' '}
              -{' '}
              {formatDate(
                date_end.replace(/-/g, '/'),
                {
                  day: 'numeric',
                  month: '2-digit',
                },
                { timeZone: defaultTimezone }
              )
                .split('/')
                .reverse()
                .join('.')}
            </p>
          </div>
          <div className="benefits">
            <ul>
              {(locale === 'pl' ? perks : perks_en).map((perk) => (
                <li key={`${price}-${perk}`}>{perk}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="buttons">
          {renderCounter()}
          {isAfterSaleEnd && !student_ticket ? (
            <div className="sold-out">
              <p> {formatMessage({ id: 'tickets.soldOut' })}</p>
            </div>
          ) : (
            <Link to="summary" smooth offset={-200}>
              <button
                type="button"
                className="select"
                onClick={() => onSelect(amount === 0 ? 1 : amount)}
              >
                {formatMessage({
                  id: isBeforeSaleStart ? 'soon' : 'tickets.ticketChoose',
                })}
              </button>
            </Link>
          )}
        </div>
      </div>
    </STicket>
  )
}

export default Ticket
