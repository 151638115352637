import React from 'react'
import { useIntl } from 'react-intl'
import { useTheme } from 'styled-components'
import { useDispatch } from 'react-redux'

import { Button, CustomUnderline, Numbers, SlideTop } from '../../components'
import { SContainer } from './CeInNumbersPartners.styled'
import miskoHevery from '../../assets/images/impressions-9.png'
import { useBreakpoint } from '../../modules/breakpoint'
import { setContactModalVisibility } from '../../state/reducers/app/appActions'

function CeInNumbersPartners() {
  const theme = useTheme()
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const breakpoint = useBreakpoint()
  const isMobile = breakpoint.lg

  const openCallpageWidget = () => {
    if (typeof window === 'undefined') return
    window.callpage('api.widget.open')
  }
  const openContactModal = () => {
    dispatch(setContactModalVisibility(true))
  }

  const openContactModalOrCallpage = () => {
    window.callpage ? openCallpageWidget() : openContactModal()
  }

  return (
    <SContainer>
      {isMobile && (
        <h2 className="heading">
          {formatMessage(
            { id: 'ceInNumbersHeading' },
            {
              span: (chunk) => (
                <CustomUnderline color={theme.colors.blue}>
                  {chunk}
                </CustomUnderline>
              ),
            }
          )}
        </h2>
      )}
      <div className="content-wrapper">
        <SlideTop>
          <img src={miskoHevery} alt="Misko Hevery" />
        </SlideTop>
        <div className="desktop-wrapper">
          {!isMobile && (
            <h2 className="heading">
              {formatMessage(
                { id: 'ceInNumbersHeading' },
                {
                  span: (chunk) => (
                    <CustomUnderline color={theme.colors.blue}>
                      {chunk}
                    </CustomUnderline>
                  ),
                }
              )}
            </h2>
          )}
          <Numbers wrap="true" />
          <Button
            secondary="true"
            height="52px"
            onClick={openContactModalOrCallpage}
          >
            {formatMessage({
              id: 'joinPartnersButton',
            })}
            <i className="ri-arrow-right-line" />
          </Button>
        </div>
      </div>
    </SContainer>
  )
}

export default CeInNumbersPartners
