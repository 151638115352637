import React from "react"
import PropTypes from "prop-types"
import ReactPlayer from "react-player"
import styles from "./YoutubeBackground.module.css"

class YoutubeBackground extends React.Component {
  constructor(props) {
    super(props)

    const aspectRatio = this.props.aspectRatio.split(":")

    this.state = {
      aspectRatio: aspectRatio[0] / aspectRatio[1],
      videoHeight: 10,
      isPlaying: false,
    }

    this.defaultPlayerVars = {
      autoplay: 0,
      controls: 0,
      rel: 0,
      showinfo: 0,
      mute: 1,
      modestbranding: 1,
      iv_load_policy: 3,
      playsinline: 1,
    }
  }

  componentDidMount() {
    this.updateDimensions()
    if (typeof window !== "undefined") {
      this.startVideo()
      window.addEventListener("resize", this.updateDimensions.bind(this))
      window.addEventListener("scroll", this.startVideo.bind(this))
    }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.updateDimensions.bind(this))
      window.removeEventListener("scroll", this.startVideo.bind(this))
    }
  }

  startVideo() {
    if (typeof window === "undefined") return
    const topOffset = this?.container?.offsetTop ?? 0
    const height = this?.container?.clientHeight ?? 0
    const scroll = window.scrollY

    const vision = scroll + window.innerHeight

    if (
      vision >= topOffset + height * 0.25 &&
      scroll <= topOffset + height * 0.75
    ) {
      if (!this.state.isPlaying) {
        this.setState({
          isPlaying: true,
        })
      }
    } else {
      if (this.state.isPlaying) {
        this.setState({
          isPlaying: false,
        })
      }
    }
  }

  updateDimensions() {
    const { aspectRatio } = this.state
    const containerWidth = this?.container?.clientWidth ?? 0
    const containerHeight = this?.container?.clientHeight ?? 0
    const containerAspectRatio = containerWidth / containerHeight

    let videoHeight = containerHeight
    let videoWidth = containerWidth
    let videoY = 0
    let videoX = 0

    if (containerAspectRatio > aspectRatio) {
      videoHeight = containerWidth / aspectRatio
      videoY = (videoHeight - containerHeight) / -2
    } else {
      videoWidth = containerHeight * aspectRatio
      videoX = (videoWidth - containerWidth) / -2
    }

    this.setState({
      videoHeight,
      videoWidth,
      videoY,
      videoX,
    })
  }

  render() {
    const { videoHeight, videoWidth, videoX, videoY } = this.state
    const {
      style,
      children,
      className,
      overlay,
      url,
      youtubeConfig,
    } = this.props

    return (
      <div
        style={style}
        ref={c => (this.container = c)}
        className={[styles.container, className].join(" ")}
      >
        <div>{children}</div>
        <div
          className={styles.videoContainer}
          style={{
            width: videoWidth + "px",
            height: videoHeight + "px",
            top: videoY + "px",
            left: videoX + "px",
          }}
        >
          {overlay && (
            <div
              className={styles.overlay}
              style={{ backgroundColor: overlay }}
            ></div>
          )}
          <ReactPlayer
            url={url}
            playing={this.state.isPlaying}
            controls={false}
            muted={true}
            loop={true}
            width="100%"
            height="100%"
            config={{
              youtube: youtubeConfig,
            }}
          />
        </div>
      </div>
    )
  }
}

YoutubeBackground.propTypes = {
  aspectRatio: PropTypes.string,
  overlay: PropTypes.string,
  className: PropTypes.string,
  onReady: PropTypes.func,
  onEnd: PropTypes.func,
  playerOptions: PropTypes.object,
}

YoutubeBackground.defaultProps = {
  aspectRatio: "16:9",
  overlay: "false",
  nocookie: false,
  playerOptions: {},
  onReady: () => {},
  onPlay: () => {},
  onPause: () => {},
  onError: () => {},
  onEnd: () => {},
}

export default YoutubeBackground
