import React, { useEffect } from 'react'
import Helmet from 'react-helmet'

function ClientsRegulationsPl() {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.location.href = '/regulamin-dla-klientow.pdf'
    }
  }, [])

  return (
    <Helmet>
      <meta name="robots" content="noindex" />
      <meta name="robots" content="nofollow" />
    </Helmet>
  )
}

export { ClientsRegulationsPl }
