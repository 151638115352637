import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'
import { SharpShadowText } from '../../components'

export const SDataContainer = styled.div`
  ${({ theme, wrap }) => css`
    display: grid;
    grid-template-columns: 1fr;

    ${respondTo.lg`
      grid-template-columns: ${
        wrap
          ? 'minmax(122px, auto) minmax(122px, auto)'
          : 'minmax(122px, auto) minmax(122px, auto) minmax(122px, auto) minmax(122px, auto)'
      };
      gap: 49px 60px;
    `}

    .data-title {
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1.55rem;
      color: ${theme.colors.lightGray2};
      text-align: center;

      ${respondTo.lg`
          font-size: 1.375rem;
          line-height: 1.875;
          text-align: center;
        `}
    }
  `}
`
export const SData = styled.aside`
  ${({ theme }) => css`
    text-align: center;
    margin-bottom: ${theme.space.xl + 8}px;
    white-space: nowrap;

    ${respondTo.lg`
      text-align: left;
      margin-bottom: 0;
    `}
  `}
`

export const SBigNumbers = styled(SharpShadowText)`
  ${({ theme }) => css`
    font-size: 3.75rem;
    line-height: 5.25rem;
    font-weight: ${theme.weight.bold};
    display: block;
    color: #fff;
    margin-bottom: ${theme.space.sm}px;

    ${respondTo.lg`
      margin-bottom: 0;
    `}
  `}
`
