import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'

import lecturesMobile from '../../assets/images/home-activities-lectures-mobile.png'
import streetFoodMobile from '../../assets/images/home-activities-street-food-mobile.png'
import networkingMobile from '../../assets/images/home-activities-networking-mobile.png'
import afterpartyMobile from '../../assets/images/home-activities-afterparty-mobile.png'
import lectures from '../../assets/images/home-activities-lectures.png'
import streetFood from '../../assets/images/home-activities-street-food.png'
import networking from '../../assets/images/home-activities-networking.png'
import afterparty from '../../assets/images/home-activities-afterparty.png'

import { useBreakpoint } from '../../modules/breakpoint'
import { AppRoute } from '../../const'
import { Button } from '../../components'
import useLocalizeRoute from '../../utils/useLocalizeRoute'
import { setActivitiesModalVisibility } from '../../state/reducers/app/appActions'

import { SWrapper } from './ActivitiesModal.styled'
import preventClick from 'utils/preventClick'

const activities = [
  {
    image: lectures,
    imageMobile: lecturesMobile,
    title: 'activitiesLecturesTitle',
    text: 'activitiesLecturesText',
  },
  {
    image: streetFood,
    imageMobile: streetFoodMobile,
    title: 'activitiesStreetFoodTitle',
    text: 'activitiesStreetFoodText',
  },
  {
    image: networking,
    imageMobile: networkingMobile,
    title: 'activitiesNetworkingTitle',
    text: 'activitiesNetworkingText',
  },
  {
    image: afterparty,
    imageMobile: afterpartyMobile,
    title: 'activitiesAfterpartyTitle',
    text: 'activitiesAfterpartyText',
  },
]
function ActivitiesModal() {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const breakpoint = useBreakpoint()
  const mobile = breakpoint.lg
  const { localizeRouteKey } = useLocalizeRoute()
  const closeModal = () => dispatch(setActivitiesModalVisibility(false))

  return (
    <SWrapper>
      <div className="content">
        <i className="ri-close-line" onClick={closeModal}></i>
        <h2 className="heading">
          {formatMessage({ id: 'activitiesModalHeading' })}
        </h2>
        <div className="activities">
          {activities.map((activity) => (
            <div key={activity.title} className="activity">
              <div className="image-wrapper">
                <img
                  src={mobile ? activity.imageMobile : activity.image}
                  alt=""
                />
              </div>
              <div className="activity-description">
                <p className="activity-title">
                  {formatMessage({ id: activity.title })}
                </p>
                <hr />
                <p className="activity-text">
                  {formatMessage(
                    { id: activity.text },
                    {
                      a: (chunk) => (
                        <a href="https://de.wikipedia.org/wiki/Bjarne_Stroustrup">
                          {chunk}
                        </a>
                      ),
                      br: <br />,
                    }
                  )}
                </p>
              </div>
            </div>
          ))}
        </div>
        <Button
          as={NavLink}
          // to={localizeRouteKey(AppRoute.BuyTicket)}
          // onClick={closeModal}
          onClick={preventClick}
          to="#"
          className="disable-button-link"
        >
          {formatMessage({
            id: 'getTicketButton',
          })}
          <i className="ri-arrow-right-line" />
        </Button>
      </div>
    </SWrapper>
  )
}

export default ActivitiesModal
