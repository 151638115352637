import { useEffect } from 'react'
import { breakpoints as staticBreakpoints } from 'theme'
import { FOOTER_DOM_ID } from 'sections/Footer/Footer'

const useCalculateSectionHeight = (sectionRef) => {
  const calculate = () => {
    if (typeof window === 'undefined') return
    const windowHeight = window.innerHeight
    const windowWidth = window.innerWidth

    if (windowWidth <= staticBreakpoints.lg) {
      sectionRef.current.style.height = 'initial'
      return
    }

    const footerHeight = document
      .getElementById(FOOTER_DOM_ID)
      .getBoundingClientRect().height
    const sectionTopOffset = sectionRef.current.getBoundingClientRect().y
    const sectionHeight = windowHeight - (sectionTopOffset + footerHeight)

    sectionRef.current.style.minHeight = `${sectionHeight}px`
  }

  useEffect(() => {
    calculate()

    window.addEventListener('resize', calculate)
    return () => {
      window.removeEventListener('resize', calculate)
    }
  }, [])
}

export default useCalculateSectionHeight
