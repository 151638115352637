import * as React from 'react'

function Facebook(props) {
  return (
    <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle fill="#FFF" cx={12.5} cy={12.5} r={12.5} />
        <path
          d="M17.292 4.354a.48.48 0 00-.48-.479h-2.395a4.571 4.571 0 00-4.792 4.313v2.587H7.229a.48.48 0 00-.479.48v2.49c0 .265.215.48.48.48h2.395v6.42c0 .265.215.48.48.48h2.874a.48.48 0 00.48-.48v-6.42h2.51a.48.48 0 00.47-.355l.69-2.491a.48.48 0 00-.46-.604h-3.21V8.187a.958.958 0 01.958-.862h2.395a.48.48 0 00.48-.48v-2.49z"
          fill="#292929"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default Facebook
