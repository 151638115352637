import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { NotFound } from 'sections'

function NotFoundPage() {
  const { formatMessage } = useIntl()

  useEffect(() => {
    if (window?.callpage) {
      window.callpage('api.button.hide')
    }
  }, [])

  return (
    <>
      <NotFound />
    </>
  )
}

export { NotFoundPage }
