import React from 'react'
import { useIntl } from 'react-intl'

import { SlideTop } from '../../components'
import { SContainer } from './TechnologySkillset.styled'
import { useBreakpoint } from '../../modules/breakpoint'

import {
  angular,
  java,
  python,
  aws,
  dotnet,
  javascript,
  node,
  azure,
  docker,
  cPlusPlus,
  cSharp,
  react,
  linux,
  kubernetes,
  mysql,
  go,
  vue,
  kotlin,
  flutter,
  scala,
  php,
  swift,
} from '../../assets/technologies'

const technologiesList = [
  { name: 'Java', logo: java },
  { name: 'Python', logo: python },
  { name: 'AWS', logo: aws },
  { name: 'Microsoft .Net', logo: dotnet },
  { name: 'Javascript', logo: javascript },
  { name: 'Node.js', logo: node },
  { name: 'Microsoft Azure', logo: azure },
  { name: 'Docker', logo: docker },
  { name: 'C++', logo: cPlusPlus },
  { name: 'C#', logo: cSharp },
  { name: 'React', logo: react },
  { name: 'Angular', logo: angular },
  { name: 'Linux', logo: linux },
  { name: 'Kubernetes', logo: kubernetes },
  { name: 'MySQL', logo: mysql },
  { name: 'Go', logo: go },
  { name: 'Vue.js', logo: vue },
  { name: 'Kotlin', logo: kotlin },
  { name: 'Flutter', logo: flutter },
  { name: 'Scala', logo: scala },
  { name: 'PHP', logo: php },
  { name: 'Swift', logo: swift },
  { name: 'and more...', logo: '' },
]

function TechnologySkillset() {
  const { formatMessage } = useIntl()
  const breakpoint = useBreakpoint()
  const isMobile = breakpoint.xl

  const technologiesToDisplay = isMobile
    ? technologiesList.slice(0, 16)
    : technologiesList

  return (
    <SContainer>
      <div className="content">
        <SlideTop>
          <h2 className="heading">
            {formatMessage(
              { id: 'technologySkillsetHeading' },
              {
                span: (chunk) => <span>{chunk}</span>,
                br: <br />,
              }
            )}
          </h2>
        </SlideTop>
        <SlideTop>
          <p className="subheader">
            {formatMessage({ id: 'technologySkillsetSubheader' })}
          </p>
        </SlideTop>
        <div className="technologies">
          <div className="technologies-grid">
            {technologiesToDisplay.map((technology) => (
              <div className="technology" key={technology.name}>
                {technology.logo ? (
                  <img src={technology.logo} alt={technology.name} />
                ) : (
                  <span className="more">
                    {formatMessage({ id: 'andMore' })}
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
        {isMobile ? (
          <p className="more">{formatMessage({ id: 'andMore' })}</p>
        ) : null}
      </div>
    </SContainer>
  )
}

export default TechnologySkillset
