import React, { useState } from 'react'
import { useBreakpoint } from 'modules/breakpoint'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { AppRoute } from 'const'

import CloseIcon from '../../assets/icons/CloseIcon'
import PercentOff from '../../assets/promos/PercentOff'
import Dino from '../../assets/promos/Dino'

import useLocalizeRoute from 'utils/useLocalizeRoute'

import { SPromoCodeBar, SCloseIcon } from './PromoCodeBar.styled'

function PromoCodeBar(props) {
  const breakpoint = useBreakpoint()
  const { localizeRouteKey } = useLocalizeRoute()
  const { formatMessage } = useIntl()

  const [open, setOpen] = useState(true);

  const closeBanner = () => setOpen(false);

  const { banner, mainPage } = props;

  return (
    <>
      {open ? (
        <SPromoCodeBar asBanner={banner} onMainPage={mainPage}>
          <Link to={localizeRouteKey(AppRoute.BuyTicket)}>
            <div className="bannerInner">
              <PercentOff className="offIcon" />
              <div className="textWrapper">
                {!breakpoint.xl && !banner  || banner ? <p>{formatMessage({ id: "promoBannerText" })}</p> : null}
                <span className="promoCode">PROMO CODE: <span className="bold">#CODEREX</span></span>
              </div>
              <Dino className="dino" />
            </div>
          </Link>
          {!banner &&
          <SCloseIcon>
            <CloseIcon className="closeIcon" onClick={closeBanner}/>
          </SCloseIcon>
          }
        </SPromoCodeBar>)
        : null}
    </>
  )
}

export default PromoCodeBar
