import styled, { css } from "styled-components"
import respondTo from "../../theme/respondTo"

import joinFormPartnerBackground from "../../assets/images/join-form-desktop-background.jpg"
import joinFormPartnerMobileBackground from "../../assets/images/join-form-mobile-background.jpg"

export const SBackground = styled.div`
  ${({ theme }) => css`
    width: 100%;

    display: none;

    position: absolute;
    top: 0;
    bottom: 0;

    overflow-x: hidden;

    ${respondTo.lg`
      display: block;
    `}

    & > div {
      &:first-child {
        position: absolute;
        top: ${theme.space.xl}px;
        right: 0;

        transform: translateX(50%);
      }

      &:last-child {
        position: absolute;
        top: 294px;
        left: 0;

        transform: translateX(-45%);
      }
    }
  `}
`

export const SAction = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;

    margin-top: ${theme.space.xl}px;
  `}
`

export const SContent = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.xl}px;
    margin-bottom: ${theme.space.xl}px;
    background-color: ${theme.colors.gray2};

    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
      0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14);
    border: solid 1px ${theme.colors.gray};
  `}
`
export const SContainer = styled.section`
  ${({ theme }) => css`
    min-height: 320px;

    position: relative;

    padding-top: calc(320px - 64px);

    &::before {
      content: "";

      width: 100%;
      height: 320px;

      position: absolute;
      top: 0;
      left: 0;

      background-image: url(${joinFormPartnerMobileBackground});
      background-size: cover;
    }

    ${respondTo.lg`
      min-height: 600px;
      padding-top: calc(600px - 64px);

      
      &::before {
        background-image: url(${joinFormPartnerBackground});
        height: 600px;
      }
    `}
  `}
`
