import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

import heroMobileBackground from '../../assets/images/partners-hero-background-mobile.png'
import heroDesktopBackground from '../../assets/images/partners-hero-background.png'

import { HEADER_HEIGHT } from 'components/Header/Header'

export const SContainer = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundBlack};
    background-image: url(${heroMobileBackground});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right 0;

    padding: calc(54px + ${HEADER_HEIGHT}) ${theme.space.lg}px 80px;
    margin-top: -${HEADER_HEIGHT};

    ${respondTo.lg`
      background-size: cover;
      background-image: url(${heroDesktopBackground});
      padding: calc(140px + ${HEADER_HEIGHT}) ${theme.space.lg}px 80px;
    `}

    ${respondTo.xxl`
      background-size: contain;
     `}

    .content {
      max-width: 1240px;
      margin: 0 auto;
    }

    .preheading {
      color: ${theme.colors.lightGray2};
      font-size: 1.125rem;
      line-height: 1.56rem;
      font-weight: ${theme.weight.bold};
      text-align: center;
      max-width: 259px;
      margin: 0 auto;

      ${respondTo.lg`
        text-align: left;
        max-width: 100%;
      `}
    }
    .heading {
      font-size: 1.75rem;
      line-height: 2.2rem;
      font-weight: ${theme.weight.bold};
      color: white;
      text-align: center;
      margin: ${theme.space.md + 4}px auto ${theme.space.xl + 8}px;

      ${respondTo.lg`
        text-align: left;
        font-size: 3.25rem;
        line-height: 4.125rem;
        margin: ${theme.space.md}px auto ${theme.space.xl + 8}px;
      `}

      span {
        color: ${theme.colors.lightPink};
        text-shadow: 1px 1px 0 ${theme.colors.azure};

        ${respondTo.lg`
          text-shadow: 2px 2px 0 ${theme.colors.azure};
        `}
      }
    }

    .cta {
      color: ${theme.colors.white};
      font-weight: 500;
      line-height: 1.375rem;
      margin-bottom: ${theme.space.md}px;

      ${respondTo.lg`
        font-size: 1.25rem;
        line-height: 1.75rem;
        margin-bottom: ${theme.space.md + 4}px;
      `}
    }

    .button-text {
      color: ${theme.colors.white};
      font-weight: 500;
      line-height: 1.375rem;

      ${respondTo.lg`
        font-size: 1.25rem;
        line-height: 1.75rem;
      `}
    }

    button {
      margin-top: ${theme.space.xl + 8}px;
      width: 100%;

      ${respondTo.lg`
        width: auto;
      `}

      i {
        padding-top: ${theme.space.xs}px;
        margin-left: ${theme.space.sm}px;
      }
    }

    a {
      display: inline-flex;
      width: 100%;
      margin-top: ${theme.space.md + 4}px;

      ${respondTo.lg`
        width: auto;
      `}

      i {
        padding-top: ${theme.space.xs}px;
        margin-left: ${theme.space.sm}px;
      }

      &.disable-link {
        background-color: ${theme.colors.white};
        cursor: not-allowed;
        color: ${theme.colors.disabledLink};
        pointer-events: none;

        &:hover {
          background-color: ${theme.colors.white};
          color: ${theme.colors.disabledLink};
        }
      }
      &.disable-secondary-button {
        cursor: not-allowed;
        background-color: ${theme.colors.disabledLink};
        box-shadow: 4px 4px 0 ${theme.colors.disabledLink},
          4px 4px 0 1px ${theme.colors.pink};
        &:hover,
        &:focus {
          background-color: ${theme.colors.disabledLink};
        }
      }
    }

    .divider-horizontal {
      background-color: ${theme.colors.lightGray};
      width: 80px;
      height: 1px;
      border: none;
      margin: ${theme.space.xl * 2}px auto ${theme.space.md}px 0;

      ${respondTo.lg`
        margin: 120px auto ${theme.space.md + 4}px 0;
      `}
    }
  `}
`
