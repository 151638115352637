import * as React from "react"

function CloseICon(props) {
  return (
    <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <rect
          transform="rotate(45 7 7)"
          x={-1}
          y={6}
          width={16}
          height={2}
          rx={0.943}
        />
        <rect
          transform="rotate(135 7 7)"
          x={-1}
          y={6}
          width={16}
          height={2}
          rx={0.943}
        />
      </g>
    </svg>
  )
}

export default CloseICon
