import Tooltip from 'components/Tooltip/Tooltip'
import React from 'react'
import { SContainer, STrack } from './BrandThumbTracks.styled'

function BrandThumbTracks(props) {
  const { tracks } = props

  return (
    <SContainer>
      {tracks.map((track) => (
        <STrack key={track.track_name} color={track.track_color}>
          <Tooltip
            className="tooltip"
            value={<p>{track.track_name}</p>}
            offset={[48, 12]}
            render={({ referenceElement, show, hide }) => (
              <div
                className="dot"
                onMouseEnter={show}
                onMouseLeave={hide}
                ref={referenceElement}
              />
            )}
          />
        </STrack>
      ))}
    </SContainer>
  )
}

export default BrandThumbTracks
