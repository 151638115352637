import { LanguageStrings } from 'modules/i18n/localizations'

export type Topic = {
  color: string
  title: string
  tags: string[]
  description: keyof LanguageStrings
}
const topics: Topic[] = [
  // {
  //   color: "#02a4fc",
  //   title: "Cloud Computing",
  //   tags: ["IaaS", "PaaS", "SaaS"],
  // },
  {
    color: '#eb76ff',
    title: 'Cybersecurity',
    tags: ['Threats', 'Defenses', 'Tests'],
    description: 'topicCybersecurityDescription',
  },
  {
    color: '#ffd426',
    title: 'Infrastructure/DevOps',
    tags: ['Microservices', 'Automation', 'ASRs'],
    description: 'topicDevOpsDescription',
  },
  {
    color: '#1ed760',
    title: 'Development Execution',
    tags: [],
    description: 'topicDevelopmentDescription',
  },
  {
    color: '#7957ae',
    title: 'Future & Inspire',
    tags: ['Mobility', '5G data networks', 'Diversity', 'Blockchain', 'VR'],
    description: 'topicFutureDescription',
  },
  {
    color: '#ed3550',
    title: 'Programming languages',
    tags: ['C++', 'Python', 'Java', 'Javascript', '.Net'],
    description: 'topicProgrammingDescription',
  },

  {
    color: '#3eecac',
    title: 'Data & AI',
    tags: ['ML', 'AI', 'Big Data', 'Business Analytics'],
    description: 'topicDataScienceDescription',
  },
]

export default topics
