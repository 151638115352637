import React from 'react'

import InvestInPomerania from '../../assets/icons/InvestInPomeraniaLogo'
import LiveMorePomerania from '../../assets/icons/LiveMorePomeraniaLogo'

import { SPomeraniaBanner } from './PomeraniaBanner.styled'

function PomeraniaBanner({ heading, children, ...res }) {
  return (
    <SPomeraniaBanner {...res}>
      <div className="header">
        <p>{heading}</p>
        {children}
      </div>
      <div>
        <InvestInPomerania />
        <LiveMorePomerania />
      </div>
    </SPomeraniaBanner>
  )
}

export default PomeraniaBanner
