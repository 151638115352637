export const breakpoints = {
  xxs: 360,
  xs: 400,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1140,
  xxl: 1400,
  xxxl: 1680,
}

export const space = {
  xs: 4,
  sm: 8,
  md: 16,
  lg: 24,
  xl: 32,
  xxl: 48,
  xxxl: 64,
}

export default {
  breakpoints,
  space,
  colors: {
    lightestGray: '#e8eef6',
    lighterGray: '#9baab4',
    lightGray: '#a5a5a5',
    lightGray2: '#bdbdbd',
    gray: '#727f88',
    gray2: '#353535',
    darkGray: '#292929',
    darkerGray: '#252525',
    darkestGray: '#353535',
    black: '#121212',
    backgroundBlack: '#131313',
    white: '#fff',
    lightPurple: '#d7a8ff',
    lightPink: '#ffe4fd',
    purple: '#eb76ff',
    pink: '#FC86F6',
    darkPink: '#da00d5',
    azure: '#02A4FC',
    lightTurquoise: '#18E5EF',
    lightestBlue: '#6b84aa',
    lightBlue: '#0e65e5',
    blue: '#0051d3',
    darkBlue: '#093272',
    green: '#1ED760',
    disabledLink: '#6c6c6c',
    darkYellow: '#FFD326',
    navigation: {
      link: {
        default: '#fff',
        black: '#121212',
        hover: '#da00d5',
      },
    },
    lightRed: '#fe7687',
  },
  weight: {
    bold: 700,
    normal: 400,
  },
}
