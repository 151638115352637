import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SSpeakerTile = styled.div`
  ${({ theme }) => css`
    a {
      text-decoration: none;
      color: lightGray;

      display: flex;

      padding: ${theme.space.md}px ${theme.space.md}px;
      margin: 0 ${-theme.space.md}px;
      border-top: 1px solid ${theme.colors.gray};

      &:hover {
        .thumbnail {
          box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
            0 6px 30px 5px rgba(0, 0, 0, 0.12),
            0 16px 24px 2px rgba(0, 0, 0, 0.14);
        }

        .name {
          color: ${theme.colors.pink};
        }
      }
    }

    .thumbnail {
      width: 72px;
      height: 72px;

      flex-shrink: 0;

      position: relative;

      margin-right: ${theme.space.md}px;

      overflow: hidden;
      background-color: #d4e6f7;

      &::before {
        background-color: #2383cd;
        bottom: 0;
        content: '';
        height: 100%;
        left: 0;
        mix-blend-mode: color-dodge;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 10;
      }

      span {
        width: 100%;

        display: block !important;
      }

      img {
        width: 100%;
        height: auto;

        display: block;

        filter: grayscale(1) contrast(1.1) brightness(0.7);
      }
    }

    .name {
      font-size: 1rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.6;
      color: white;
    }

    .position,
    .company {
      font-size: 0.87rem;
      line-height: 1.5;
      margin-top: ${theme.space.xs}px;
    }

    .company {
      font-weight: ${theme.weight.bold};
    }

    .tags {
      margin-top: ${theme.space.sm}px;

      .tag {
        font-size: 0.75rem;
        font-weight: bold;
        line-height: 1.33;
        color: ${theme.colors.lightTurquoise};

        display: inline-block;

        padding: 2px ${theme.space.sm}px 5px ${theme.space.sm}px;
        margin-right: ${theme.space.sm}px;

        border: 1px solid ${theme.colors.lightTurquoise};
        border-radius: 12px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    ${respondTo.lg`
      a {
        display: block;

        padding: 0;
        margin: 0;

        border: none;
      }

      .thumbnail {
        width: 200px;
        height: 200px;

        margin-right: 0;
      }

      .name {
        font-size: 1.25rem;
  
        margin-top: ${theme.space.sm}px;
      }

      .position,
      .company {
        font-size: 1rem;
        margin-top: ${theme.space.sm}px;
      }

      .tags {
        margin-top: ${theme.space.md}px;
      }
    `}
  `}
`
