import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

import desktopBannerBackground from '../../assets/images/banner_tickets_agenda_desktop.jpg'
import mobileBannerBackground from '../../assets/images/banner_tickets_agenda_mobile.jpg'

export const SAgendaList = styled.section`
  ${({ theme }) => css`
    padding-top: ${theme.space.md}px;
    .heading {
      display: inline-block;
      font-size: 2.62rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.6;
      color: white;
    }
    
    .simplebar-scrollbar::before {
        background-color: #d8d8d8;
        opacity: 1;
      }
      
       ::-webkit-scrollbar {
          display: none;
      }

      scrollbar-width: none;  
    }

    .banner-wrapper {
      display: grid;
      
      a {
        text-decoration: none;
      }
    }
    
    .pomerania-banner {
      width: 100%;
      max-width: initial;
      min-height: 320px;
      height: initial;

      flex-direction: column-reverse;
      justify-content: space-between;

      background-image: url(${mobileBannerBackground});
      background-position: center bottom;

      margin: ${theme.space.xl}px 0 ${theme.space.md}px;
      
      .header {
        flex: 1;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      p {
        padding-top: 20px;
        font-size: 1.22rem;
        line-height: 2.3rem;
      }
    }
    
    .filtration {
      overflow-y: auto;

      margin-top: ${theme.space.md}px;
      margin-bottom: ${theme.space.xl}px;
    }

    .content {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
    }

    .tabs-wrapper {
      margin-bottom: ${theme.space.xl}px;
  
      ${respondTo.lg`
        margin-left: 0;
        margin-top: -${theme.space.xxxl}px;
        margin-bottom: ${theme.space.xxxl}px;
      `}
    }
    
    .listing {
      display: flex;
      flex-direction: column;

      margin-bottom: ${theme.space.lg}px;
    }

    .loading {
      margin: ${theme.space.xl}px 0;
    }

    ${respondTo.lg`
      .heading {
        font-size: 4.25rem;
        padding-top: ${theme.space.xxxl}px;
      }
      
      .right-side {
        max-width: 850px; 
      }

      .filtration {
        margin-top: 0;
        margin-bottom: 0;
        padding-right: ${theme.space.md}px;
      }
      
      .banner-wrapper {
        grid-template-columns: 272px 1fr;
        align-items: flex-end;
        margin-right: ${theme.space.md + 2}px;
        width: 100%;
      }
      
      .pomerania-banner {
        min-height: 122px;
        max-height: 122px;
        flex-direction: row;

        background-image: url(${desktopBannerBackground});

        .header {
          p:first-child {
            max-width: 330px;
            font-size: 1.25rem;

            margin-bottom: ${theme.space.md}px;
          }
        }

        div {
          svg:first-of-type {
            width: 124px;
            height: 88px;
          }

          svg:last-of-type {
            width: 172px;
            height: 88px;
          }
        }
      }

      .content {
        grid-template-columns: 272px 1fr;
        grid-template-rows: 1fr;

        margin-top: ${theme.space.xxxl}px;
        padding-bottom: ${theme.space.xxxl * 2}px;
      }

      .listing {
        margin-top: 0;
        margin-bottom: ${theme.space.xl}px;
      }
    `}
    
    ${respondTo.xl`
      .pomerania-banner {
        .header {
          p:first-child {
            max-width: 380px;
            font-size: 1.25rem;
          }
        }
      }
    `}
  `}
`
