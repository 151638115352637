import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SContainer = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundBlack};
    position: relative;
    overflow: hidden;

    padding: 50px ${theme.space.lg}px ${theme.space.xl + 8}px;

    ${respondTo.lg`
      padding-bottom: ${theme.space.xl * 2}px;
    `}

    .heading {
      font-size: 1.5rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.875rem;
      color: ${theme.colors.white};
      text-align: center;
      margin: 0 auto ${theme.space.lg + 4}px;

      ${respondTo.lg`
        font-size: 2.25rem;
        line-height: 3.125rem;
        margin: 0 auto;
      `}

      .shadow {
        color: ${theme.colors.white};
        text-shadow: 1px 1px 0 ${theme.colors.azure};
        font-size: 1.75rem;
        line-height: 2.188rem;

        ${respondTo.lg`
          font-size: 3.25rem;
          line-height: 4.125rem;
          text-shadow: 2px 2px 0 ${theme.colors.azure};
        `}
      }
    }

    .subheading {
      color: ${theme.colors.lightGray2};
      font-size: 1.125rem;
      line-height: 1.563rem;
      margin: ${theme.space.md + 4}px auto ${theme.space.lg + 4}px;
      text-align: center;
      max-width: 767px;

      ${respondTo.lg`
          font-size: 1.375rem;
          line-height: 1.875rem;
          margin-bottom: ${theme.space.xl * 2}px;
      `}
    }

    a {
      i {
        padding-top: 4px;
        margin-left: 8px;
      }
    }

    .speakers {
      &-grid {
        display: grid;
        grid-template-columns: repeat(2, 140px);
        grid-gap: ${theme.space.xl}px ${theme.space.xl}px;
        justify-content: center;
        margin-bottom: ${theme.space.xl + 8}px;

        ${respondTo.sm`
          grid-template-columns: repeat(3, 140px);
        `}
        ${respondTo.lg`
          grid-template-columns: repeat(3, 226px);
          grid-template-rows: repeat(1, 1fr);

          grid-template-areas:
            ". . ."
          justify-content: center;

          margin-top: ${theme.space.xxxl}px;
          grid-gap: ${theme.space.xl + 8}px ${theme.space.lg + 4}px;
        `}

        ${respondTo.xxl`
          grid-template-columns: repeat(5, 226px);
          grid-template-rows: repeat(1, 1fr);
          grid-template-areas:
            ". . . ."
        `}
      }
    }

    .speaker {
      &-avatar {
        width: 140px;
        height: 168px;

        position: relative;

        margin: 0 auto ${theme.space.md - 4}px auto;

        ${respondTo.lg`
          height: 272px;
          width: 226px;
          margin: 0 auto ${theme.space.md + 4}px auto;
        `}

        img {
          max-width: 140px;
          height: 168px;
          width: 100%;

          ${respondTo.lg`
             max-width: 226px;
             height: 272px;
          `}
        }
      }

      &-details {
        max-width: 140px;
        width: 100%;
        margin: 0 auto;

        ${respondTo.lg`
          max-width: 226px;
        `}
      }
      &-name {
        font-weight: ${theme.weight.bold};
        line-height: 1.375rem;
        color: ${theme.colors.pink};

        margin-bottom: ${theme.space.xs}px;

        ${respondTo.lg`
          font-size: 1.25rem;
          line-height: 1.75rem;

          margin-bottom: ${theme.space.sm + 3}px;
        `}
      }
      &-position {
        font-weight: 500;
        line-height: 1.375rem;
        color: ${theme.colors.white};
      }
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .positionTopLeft {
      position: absolute;
      top: ${theme.space.xxxl * 1.5}px;
      left: 0;

      ${respondTo.lg`
        top: ${theme.space.xxxl * 2}px;
      `}

      img {
        width: 149px;
        height: 17px;
        margin-left: -100px;

        ${respondTo.lg`
          width: 100%;
          height: 100%;
          margin-left: 0;
        `}
      }
    }
    .positionTopRight {
      position: absolute;
      top: ${theme.space.md}px;
      right: -65px;

      ${respondTo.lg`
        top: 128px;
      `}

      svg {
        height: 17px;
        margin-right: -55px;

        ${respondTo.lg`
          width: 100%;
          height: 100%;
          margin-right: -65px;
        `}
      }
    }
    .positionMiddleRight {
      display: none;
      ${respondTo.lg`
        display: block;
        position: absolute;
        top: ${theme.space.xxxl * 10.2}px;
        right: -5px;
      `}

      img {
        width: 109px;
        height: 30px;
      }
    }
  `}
`
