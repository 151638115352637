import React, { useEffect } from "react"

function CovidStatement() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.location.href = "/oswiadczenie-covid.pdf"
    }
  }, []);

  return null;
}

export { CovidStatement }
