import * as React from 'react'

function TicketBackground({ color, ...res }) {
  return (
    <svg
      viewBox="0 0 261 418"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...res}
    >
      <defs>
        <path
          id="prefix__a"
          d="M0 0h261v322l-16 12 16 12v72H0v-72l16-12-16-12z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <g opacity={0.2} mask="url(#prefix__b)" stroke={color}>
          <path d="M-118.787-97.949c-355.196-68.99-422.3-533.33-643.14-124.919-220.839 408.41 162.87 698.891 518.067 767.882 355.196 68.991 804.13-286.292 768.213-518.043C488.436-204.78 236.41-28.957-118.787-97.95z" />
          <path d="M-135.487-92.828c-347.31-42.6-443.378-486.008-628.861-77.135s204.279 663.214 551.59 705.815c347.31 42.6 756.42-330.404 706.132-551.545-50.287-221.14-281.55-34.534-628.861-77.135z" />
          <path d="M-151.372-86.681c-337.516-17.653-459.965-438.41-611.127-31.963-151.162 406.446 241.528 625.253 579.044 642.906 337.516 17.653 706.567-369.296 643.21-578.98-63.358-209.684-273.611-14.31-611.127-31.963z" />
          <path d="M-166.143-79.109c-326.403 5.725-472.747-391.437-591.005 10.366-118.26 401.803 274.865 586.18 601.268 580.455 326.404-5.725 655.924-403.418 580.743-601.186-75.18-197.769-264.602 4.64-591.006 10.365z" />
          <path d="M-179.948-70.445c-313.33 27.432-480.686-344.45-567.335 49.67-86.65 394.12 303.589 544.913 616.92 517.48 313.33-27.432 603.304-431.796 517.75-616.82-85.553-185.025-254.004 22.238-567.335 49.67z" />
          <path d="M-192.706-61.074c-298.862 47.347-484.437-298.312-541.137 85.728-56.7 384.041 327.933 502.573 626.796 455.226 298.862-47.346 550.006-454.858 455.48-626.682-94.527-171.825-242.276 38.381-541.139 85.728z" />
          <path d="M-204.354-51.103c-283.211 65.388-484.208-253.4-512.8 118.396-28.592 371.797 347.929 459.608 631.141 394.224C197.2 396.132 410.544-11.184 308.447-169.5 206.348-327.814 78.857-116.49-204.354-51.103z" />
          <path d="M-214.716-40.156C-481.639 41.447-695.546-250.472-698.024 107.6-700.5 465.67-333.924 524.582-67 442.976c266.923-81.607 444.006-486.07 335.593-630.887C160.18-332.73 52.208-121.76-214.716-40.156z" />
          <path d="M-223.836-28.58c-249.46 95.749-473.242-168.747-451.688 173.368 21.554 342.115 375.568 373.898 625.029 278.15 249.46-95.749 391.556-493.825 278.348-624.885-113.208-131.06-202.228 77.62-451.689 173.368z" />
          <path d="M-231.745-16.783C-463.085 91.075-693.965-146.06-650.622 178.51c43.344 324.571 382.817 331.275 614.157 223.417C194.874 294.07 303.748-94.729 187.132-212.077 70.515-329.426-.406-124.641-231.745-16.783z" />
          <path d="M-238.43-4.863c-212.831 117.95-448.176-92.213-385.365 213.569 62.811 305.782 386.1 289.589 598.932 171.638 212.83-117.95 290.508-494.932 171.798-598.776C28.225-322.276-25.598-122.814-238.43-4.863z" />
          <path d="M-243.815 7.563c-194.39 126.21-431.982-57.85-351.976 228.523 80.006 286.371 386.116 249.512 580.507 123.302C179.106 233.178 227.86-130.176 108.16-220.96-11.54-311.745-49.424-118.647-243.815 7.563z" />
          <path d="M-247.868 20.119c-175.61 132.298-412.642-26.167-317.97 239.546 94.67 265.713 381.923 210.58 557.533 78.283 175.61-132.298 197.696-479.38 78.408-557.375-119.289-77.994-142.36 107.248-317.97 239.546z" />
          <path d="M-250.714 32.394c-156.92 136.37-390.96 2.527-284.128 246.919C-428.01 523.703-160.69 452.759-3.77 316.39c156.92-136.37 154.851-465.212 37.184-530.914C-84.253-280.227-93.794-103.975-250.714 32.394z" />
          <path d="M-252.4 44.33c-138.608 138.564-367.522 28.079-250.972 250.893 116.55 222.814 363.29 138.52 501.898-.045 138.607-138.565 115.03-447.688.045-501.742-114.986-54.054-112.364 112.329-250.972 250.893z" />
          <path d="M-252.927 56.333c-121.009 139.158-343.137 50.472-219.106 251.968C-348.002 509.796-121.929 414.483-.92 275.325c121.008-139.157 78.58-427.767-32.901-470.96-111.483-43.192-98.097 112.81-219.106 251.968z" />
          <path d="M-252.215 67.996c-103.854 137.768-316.956 69.492-188.043 249.45 128.913 179.96 333.684 76.258 437.537-61.51 103.854-137.768 45.415-404.354-61.451-437.391-106.867-33.037-84.19 111.683-188.043 249.45z" />
          <path d="M-250.457 78.918c-87.462 134.627-289.72 85.076-158.364 243.764C-277.465 481.37-94.108 371.816-6.646 237.189 80.816 102.562 9.257-141.113-92.093-164.847-193.443-188.58-162.995-55.71-250.457 78.918z" />
          <path d="M-247.776 89.729c-72.278 130.337-262.798 97.543-130.87 235.996C-246.718 464.18-84.007 350.853-11.73 220.516 60.548 90.18-21.508-130.85-116.906-146.267c-95.397-15.418-58.592 105.659-130.87 235.996z" />
          <path d="M-243.988 99.804c-57.957 124.233-234.887 106.362-104.94 224.943 129.945 118.582 271.977 4.158 329.934-120.074C38.964 80.44-50.519-117.111-139.047-125.14c-88.528-8.027-46.984 100.71-104.94 224.943z" />
          <path d="M-239.408 108.946c-44.875 116.845-207.17 111.833-81.254 211.566 125.917 99.733 247.997-13.53 292.872-130.375s-49.216-291.083-130.363-292.758c-81.148-1.675-36.38 94.722-81.255 211.567z" />
          <path d="M-234.131 117.715c-33.23 108.627-180.488 114.361-60.168 196.687 120.319 82.325 223.675-27.946 256.905-136.573C-4.164 69.2-100.388-82.592-173.964-78.973c-73.575 3.62-26.937 88.06-60.167 196.688z" />
        </g>
      </g>
    </svg>
  )
}

export default TicketBackground
