import React, { useRef, useState } from 'react'
import { usePopper } from 'react-popper'

import { SContainer, STooltip, SArrow } from './Tooltip.styled'

function Tooltip({
  render,
  value,
  values,
  valuesParser,
  offset,
  placement,
  ...res
}) {
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const referenceElement = useRef(null)
  const popperElement = useRef(null)
  const [arrowElement, setArrowElement] = useState(null)
  const { styles, attributes } = usePopper(
    referenceElement.current,
    popperElement.current,
    {
      placement: placement || 'bottom',
      modifiers: [
        {
          name: 'arrow',
          options: {
            element: arrowElement,
          },
        },
        {
          name: 'offset',
          options: {
            offset: offset || [-45, 20],
          },
        },
      ],
    }
  )

  const renderContent = () => {
    if (!values && !value) return null
    if (Array.isArray(values)) {
      return valuesParser ? valuesParser(values) : values.join(', ')
    }

    return value
  }

  return (
    <SContainer {...res}>
      {render({
        referenceElement,
        show: () => setTooltipVisible(true),
        hide: () => setTooltipVisible(false),
      })}
      {tooltipVisible ? (
        <STooltip
          className="tooltip"
          ref={popperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <SArrow
            className="arrow"
            ref={setArrowElement}
            style={styles.arrow}
          />
          {renderContent()}
        </STooltip>
      ) : null}
    </SContainer>
  )
}

export default Tooltip
