import * as React from 'react'

function PlusIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <path d="M0 4h10v2H0z" />
        <path d="M4 0h2v10H4z" />
      </g>
    </svg>
  )
}

export default PlusIcon
