import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SWrapper = styled.div`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 12;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      overflow-y: auto;
      width: 100%;
      height: 100%;
      max-width: 360px;
      background-color: ${theme.colors.darkGray};
      display: flex;
      flex-direction: column;
      position: relative;
      padding: ${theme.space.md + 4}px ${theme.space.lg + 4}px
        ${theme.space.xl + 4}px;
      border-radius: 20px;

      ${respondTo.lg`
        height: 90%;
        max-width: 880px;
      `}

      .ri-close-line {
        color: #a8afbc;
        font-size: 36px;
        position: absolute;
        top: 20px;
        right: 20px;

        ${respondTo.lg`
            right: 12px;
        `}

        &:hover {
          cursor: pointer;
          color: ${theme.colors.pink};
        }
      }
    }

    .heading {
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-weight: ${theme.weight.bold};
      color: ${theme.colors.white};
      margin-bottom: ${theme.space.xl}px;
      text-align: center;

      ${respondTo.lg`
        font-size: 2.25rem;
        line-height: 3.125rem;
        margin-bottom: ${theme.space.xxl}px;
      `}
    }
    a {
      i {
        padding-top: ${theme.space.xs}px;
        margin-left: ${theme.space.sm}px;
      }
    }

    .activity {
      margin-bottom: ${theme.space.xl + 8}px;
      display: flex;
      flex-direction: column;

      ${respondTo.lg`
        flex-direction: row;
        align-items: center;
      `}

      .image-wrapper {
        flex-shrink: 0;
        width: 292px;
        height: 139px;
        position: relative;
        z-index: 1;

        ${respondTo.lg`
            margin: 0 auto ${theme.space.sm + 4}px;
            height: 172px;
            width: 172px;
        `}

        &::before {
          content: '';
          width: 100%;
          height: 100%;

          position: absolute;
          top: -${theme.space.sm + 4}px;
          left: ${theme.space.sm + 4}px;

          background-color: ${theme.colors.gray2};

          z-index: -1;

          ${respondTo.lg`
            top: 0;
            left: ${theme.space.md}px;
          `}
        }
      }

      img {
        width: 100%;
        margin: 0 auto;
        max-width: 292px;
        height: 139px;

        ${respondTo.lg`
            margin-top: ${theme.space.md}px;
            max-width: 100%;
            height: 172px;
        `}
      }
      &-description {
        ${respondTo.lg`
           margin-left: ${theme.space.xl + 8}px;
        `}
      }
      &-title {
        line-height: 1.375rem;
        color: ${theme.colors.white};
        margin-top: ${theme.space.md + 4}px;

        ${respondTo.lg`
            font-size: 1.25rem;
            line-height: 1.75rem;
            margin-top: 0;
        `}
      }
      hr {
        color: ${theme.colors.lightGray};
        width: 80px;
        height: 1px;
        margin: ${theme.space.md - 4}px 0;
      }
      &-text {
        line-height: 1.375rem;
        color: ${theme.colors.lightGray2};

        a {
          color: inherit;
        }
      }
    }
  `}
`
