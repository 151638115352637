import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SOffersList = styled.section`
  ${({ theme }) => css`
    padding-top: ${theme.space.md}px;
    
     .simplebar-scrollbar::before {
            background-color: #d8d8d8;
        opacity: 1;
      }
      
       ::-webkit-scrollbar {
          display: none;
      }

      scrollbar-width: none;  
    }
    
    .heading {
      display: inline-block;
      font-size: 2.62rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.6;
      color: white;
    }
   
    .filtration {
      overflow-y: auto;

      margin-top: ${theme.space.md}px;
      margin-bottom: ${theme.space.xl}px;
    }

    .content {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
    }

    .offers-loader {
      margin-bottom: ${theme.space.xl}px;
    }

    ${respondTo.lg`
      .heading {
        font-size: 4.25rem;
        padding-top: ${theme.space.xxxl}px;
      }

      .right-side {
        max-width: 850px; 
      }

      .filtration {
        margin-top: 0;
        margin-bottom: 0;
        padding-right: ${theme.space.md}px;
      }

      .content {
        grid-template-columns: 272px 1fr;
        grid-template-rows: 1fr;

        margin-top: ${theme.space.xxxl}px;
        padding-bottom: ${theme.space.xxxl * 2}px;
      }
    `}
  `}
`
