import { useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Helmet } from 'react-helmet'

import {
  Loader,
  TicketsSelector,
  TicketUserBadge,
  TicketUserData,
  TicketUserRegistration,
  TicketUserSteper,
  TicketPaymentData,
  TicketOrderSummary,
  Wrapper,
} from '../components'

import { HEADER_DOM_ID } from 'components/Header/Header'
import { FOOTER_DOM_ID } from 'sections/Footer/Footer'

import { breakpoints as staticBreakpoints } from 'theme'
import {
  calculatePrice,
  getTickets,
} from 'state/reducers/tickets/ticketsActions'
import { TicketStep } from 'state/reducers/tickets/ticketsReducer'

function BuyTicket() {
  const { locale, formatMessage } = useIntl()

  const dispatch = useDispatch()

  const {
    step,
    items,
    price,
    fields,
    loading: buyTicketLoading,
  } = useSelector(({ tickets }) => tickets)
  const { data: dictionary } = useSelector(({ app }) => app.dictionary)
  const isAuthenticated = useSelector(({ user }) => user.isAuthenticated)

  const isFree = price.priceAfterDiscount === '0.00'
  const isGroup = items.selected.amount > 1

  const {
    employmentPosition,
    tracks,
    experienceLevel,
    technologies,
    languages,
  } = useMemo(() => {
    const isPolish = locale === 'pl'

    const employmentPosition = dictionary.employmentPosition.map(
      (position) => ({
        id: position.id,
        label: isPolish ? position.name : position?.name_en ?? position.name,
        value: isPolish ? position.name : position?.name_en ?? position.name,
      })
    )
    const tracks = dictionary.tracks.map((track) => ({
      label: isPolish ? track.name : track?.name_en ?? track.name,
      value: isPolish ? track.name : track?.name_en ?? track.name,
    }))
    const experienceLevel = dictionary.experienceLevel.map((experience) => ({
      label: isPolish
        ? experience.name
        : experience?.name_en ?? experience.name,
      value: isPolish
        ? experience.name
        : experience?.name_en ?? experience.name,
    }))
    const technologies = dictionary.tags.map((technology) => ({
      label: isPolish
        ? technology.name
        : technology?.name_en ?? technology.name,
      value: isPolish
        ? technology.name
        : technology?.name_en ?? technology.name,
    }))
    const languages = dictionary.languages.map((lang) => ({
      label: lang.name,
      value: lang.name,
    }))

    return {
      employmentPosition,
      tracks,
      experienceLevel,
      technologies,
      languages,
    }
  }, [dictionary, locale])

  // useEffect(() => {
  //   // prevent opening buy ticket page
  //   window.location.href = window.location.origin
  //   if (window?.callpage) {
  //     window.callpage('api.button.hide')
  //   }
  // }, [])

  const renderProcessStep = () => {
    if (items.selected.amount === 1) {
      if (step === TicketStep.REGISTRATION) {
        return (
          <Wrapper>
            <TicketUserRegistration {...{ fields: fields.base }} />
          </Wrapper>
        )
      }

      if (step === TicketStep.BADGE) {
        return (
          <Wrapper>
            <TicketUserBadge
              {...{ fields: fields.base, employmentPosition, tracks }}
            />
          </Wrapper>
        )
      }

      if (step === TicketStep.DATA) {
        return (
          <Wrapper>
            <TicketUserData
              {...{
                fields: fields.base,
                experienceLevel,
                technologies,
                languages,
                isFree,
                buyTicketLoading,
              }}
            />
          </Wrapper>
        )
      }
    }

    if (step === TicketStep.PAYMENT_DATA) {
      return (
        <Wrapper>
          <TicketPaymentData
            {...{
              fields: { ...fields.payment, ...fields.vat },
              price,
              isGroup,
              isAuthenticated,
            }}
          />
        </Wrapper>
      )
    }

    if (step === TicketStep.PAYMENT_SUMMARY) {
      return (
        <Wrapper>
          <TicketOrderSummary
            {...{
              items,
              price,
              paymentData: { ...fields.vat, ...fields.payment },
              isGroup,
              isAuthenticated,
            }}
          />
        </Wrapper>
      )
    }

    if (step === TicketStep.SELECTION) {
      return (
        <TicketsSelector
          {...{
            items,
            price,
            isFree,
            isGroup,
            buyTicketLoading,
            isAuthenticated,
            Wrapper,
          }}
        />
      )
    }

    return (
      <TicketsSelector
        {...{
          items,
          price,
          isFree,
          isGroup,
          buyTicketLoading,
          isAuthenticated,
          Wrapper,
        }}
      />
    )
  }

  useEffect(() => {
    if (!items.length) {
      dispatch(getTickets())
    }
  }, [])

  useEffect(() => {
    const promise = dispatch(calculatePrice())
    return () => {
      promise.abort()
    }
  }, [items.selected.entity, items.selected.amount, price.discountCode])

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'seoTitleTickets' })}</title>
        <meta
          name="description"
          content={formatMessage({ id: 'seoDescriptionTickets' })}
        />
        <meta
          name="keywords"
          content={formatMessage({ id: 'seoKeywordsTickets' })}
        />
        <meta
          property="og:title"
          content={formatMessage({ id: 'seoTitleTickets' })}
        />
        <meta
          property="og:site_name"
          content={formatMessage({ id: 'seoTitleTickets' })}
        />
        <meta
          property="og:description"
          content={formatMessage({ id: 'seoDescriptionTickets' })}
        />
        <meta name="twitter:image:src" content={''} />
        <meta
          name="twitter:title"
          content={formatMessage({ id: 'seoTitleTickets' })}
        />
        <meta
          name="twitter:description"
          content={formatMessage({ id: 'seoDescriptionTickets' })}
        />
      </Helmet>
      <div>
        {items.loading && step === TicketStep.SELECTION ? (
          <Loader />
        ) : (
          <>
            {step !== TicketStep.SELECTION ? (
              <TicketUserSteper
                {...{ step, simplified: isFree, isGroup, isAuthenticated }}
              />
            ) : null}
            {renderProcessStep()}
          </>
        )}
      </div>
    </>
  )
}

export { BuyTicket }
