import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'

import {
  HeroSectionBasic,
  BecomeSpeaker as BecomeSpeakerSection,
  TracksOldDesign,
  SpeakersJoin,
  Lectures,
  Tweets,
} from '../sections'

function BecomeSpeaker() {
  const { formatMessage } = useIntl()

  useEffect(() => {
    if (window?.callpage) {
      window.callpage('api.button.hide')
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'seoTitleBecomeSpeaker' })}</title>
        <meta
          name="description"
          content={formatMessage({ id: 'seoDescriptionBecomeSpeaker' })}
        />
      </Helmet>
      <HeroSectionBasic />
      <SpeakersJoin />
      <TracksOldDesign />
      <Lectures />
      <Tweets speakers />
      <BecomeSpeakerSection />
    </>
  )
}

export { BecomeSpeaker }
