import React from 'react'
import { useIntl } from 'react-intl'

import { Button } from '../../components'
import { SContent } from './PartnersBanner.styled'

import { useBreakpoint } from '../../modules/breakpoint'
import { setContactModalVisibility } from '../../state/reducers/app/appActions'
import { useDispatch } from 'react-redux'

function PartnersBanner() {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const breakpoints = useBreakpoint()

  const openCallpageWidget = () => {
    if (typeof window === 'undefined') return
    window.callpage('api.widget.open')
  }
  const openContactModal = () => {
    dispatch(setContactModalVisibility(true))
  }

  const openContactModalOrCallpage = () => {
    window.callpage ? openCallpageWidget() : openContactModal()
  }

  return (
    <SContent>
      <h2 className="heading">
        {formatMessage(
          {
            id: 'partnersBannerHeading',
          },
          {
            span: (chunk) => <span>{chunk}</span>,
            br: <br />,
          }
        )}
      </h2>
      <p className="subheader">
        {formatMessage(
          {
            id: breakpoints.lg
              ? 'partnersBannerSubHeadingMobile'
              : 'partnersBannerSubHeading',
          },
          { br: <br /> }
        )}
      </p>

      <div className="button-wrapper">
        <Button secondary height="52px" onClick={openContactModalOrCallpage}>
          {formatMessage({
            id: 'joinPartnersButton',
          })}
          <i className="ri-arrow-right-line" />
        </Button>
      </div>
    </SContent>
  )
}

export default PartnersBanner
