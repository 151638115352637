import * as React from "react"

function SupperIcon(props) {
  return (
    <svg viewBox="0 0 47 50" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g strokeWidth={1.5} fill="none" fillRule="evenodd">
        <path
          d="M21.976 5.37v15.938h0c0 5.354-4.416 9.784-9.752 9.784s-9.752-4.338-9.752-9.784V5.369a4 4 0 014-4h11.504a4 4 0 014 4zm-.552 43.26c0-2.03-4.14-3.692-9.292-3.692S2.84 46.6 2.84 48.631h18.584zM10.66 30.909V45.03m3.128-13.939V45.03"
          stroke="#FFF"
        />
        <path
          d="M19.584 20.846c0 4.154-3.404 7.662-7.636 7.662m7.636-10.154v-8.216"
          stroke="#FC86F6"
          strokeLinecap="round"
        />
        <path
          d="M21.976 3.4c.552 0 1.104-.092 1.656-.092 12.512 0 22.54 10.154 22.54 22.615 0 12.462-10.12 22.615-22.54 22.615-.736 0-1.472 0-2.208-.092m-11.04-4.154c-5.612-4.061-9.292-10.8-9.292-18.277 0-2.769.46-5.353 1.38-7.846"
          stroke="#FFF"
        />
        <path
          d="M21.976 7.185c.552-.093 1.104-.093 1.656-.093 10.396 0 18.86 8.493 18.86 18.923 0 10.431-8.464 18.923-18.86 18.923a18.815 18.815 0 01-9.844-2.769m-3.312-2.677c-3.128-3.046-5.152-7.2-5.612-11.815"
          stroke="#FFF"
        />
        <path
          d="M38.536 26.292c0 1.754-.276 3.508-.92 5.077-2.024 5.631-7.36 9.6-13.616 9.6"
          stroke="#FC86F6"
          strokeLinecap="round"
        />
      </g>
    </svg>
  )
}

export default SupperIcon
