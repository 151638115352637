import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { omit } from 'lodash'

import { Button, Select, DropzoneInput, TicketProcessInput } from 'components'
import { SUserData } from './UserData.styled'
import {
  mailRegex,
  withoutDigits,
} from 'components/TicketUserRegistration/TicketUserRegistration'
import client from 'api/client'
import config from 'const/config'
import { getUser } from 'state/reducers/user/userActions'

const defaultFormValues = (user) =>
  omit(user, [
    'id',
    'active',
    'job_alert',
    'matchmaking',
    'created_at',
    'updated_at',
    'resume_file_path',
  ])
function UserData({
  user,
  languages,
  employmentPosition,
  experienceLevel,
  technologies,
  tracks,
}) {
  const dispatch = useDispatch()
  const { formatMessage, locale } = useIntl()

  const clearUserData = defaultFormValues(user)
  const {
    control,
    register,
    handleSubmit,
    formState,
    watch,
    setValue,
    setError,
    getValues,
  } = useForm({
    defaultValues: {
      ...clearUserData,
      job_position: employmentPosition.find(
        (o) => o.value === clearUserData.job_position
      ),
      interesting_path: tracks.find(
        (o) => o.value === clearUserData.interesting_path
      ),
      experience: experienceLevel.find(
        (o) => o.value === clearUserData.experience
      ),
      technologies_working_with:
        clearUserData.technologies_working_with?.map((key) =>
          technologies.find((o) => o.value === key)
        ) ?? null,
      technologies_interested_in:
        clearUserData.technologies_interested_in?.map((key) =>
          technologies.find((o) => o.value === key)
        ) ?? null,
      known_languages:
        clearUserData.known_languages?.map((key) =>
          languages.find((o) => o.value === key)
        ) ?? null,
      resume: user.resume_file_path,
    },
  })

  const technologiesWorkingWithCount =
    watch('technologies_working_with[]')?.length ?? 0
  const technologiesInterestedIn =
    watch('technologies_interested_in[]')?.length ?? 0

  const jobPosition = watch('job_position')
  const companyRequired =
    jobPosition && jobPosition.id !== 10 && jobPosition.id !== 11

  const onDropFile = (onChange) => (file) => {
    onChange(file)
  }

  const onSubmit = async (values) => {
    try {
      const token = localStorage.getItem(config.STORAGE_TOKEN_KEY)
      if (!token) return

      let resumeFileName = null

      if (values.resume && typeof values.resume !== 'string') {
        const formData = new FormData()
        formData.append('resume', values.resume[0])

        const response = await client({
          endpoint: 'api/participant/resume',
          method: 'POST',
          withoutHeaders: true,
          body: formData,
        })

        if (!response.ok) {
          const data = await response.json()
          setError('resume', {
            type: 'required',
            message: 'errors.resumeError',
          })
          throw new Error('Resume error')
        }

        const { resume_file_path } = await response.json()
        resumeFileName = resume_file_path
      }

      let body = {
        ...values,
        experience: values?.experience?.value ?? null,
        interesting_path: values?.interesting_path?.value ?? null,
        job_position: values?.job_position?.value ?? null,
        known_languages:
          values?.known_languages?.map((lang) => lang.value) ?? null,
        technologies_interested_in:
          values?.technologies_interested_in?.map((tech) => tech.value) ?? null,
        technologies_working_with:
          values?.technologies_working_with?.map((tech) => tech.value) ?? null,
      }

      if (resumeFileName) {
        body = { ...body, resume: resumeFileName }
      }

      const response = await client({
        endpoint: 'api/participant/details',
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (!response.ok) {
        const data = await response.json()
        Object.keys(data).forEach((key) => {
          const error = data[key].flat()
          if (!Array.isArray(error)) return

          const [errorMessage] = error
          setError(key, {
            type: 'required',
            message: errorMessage,
          })
        })

        throw new Error()
      }

      const data = await response.json()
      dispatch(getUser(locale))
      setValue('resume', resumeFileName)
    } catch (error) {
      console.log('[PATH] user data', error)
    }
  }

  return (
    <SUserData>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="label">
          {formatMessage({ id: 'user.dataRegistrationSection' })}:
        </p>
        <TicketProcessInput
          {...{
            label: 'labels.name',
            htmlFor: 'name',
            error: formState.errors.name,
          }}
        >
          <input
            id="name"
            type="text"
            {...register('name', {
              required: {
                value: true,
                message: 'errors.nameError',
              },
            })}
          />
        </TicketProcessInput>
        <TicketProcessInput
          {...{
            label: 'labels.surname',
            htmlFor: 'surname',
            error: formState.errors.surname,
          }}
        >
          <input
            id="surname"
            type="text"
            {...register('surname', {
              required: 'errors.surnameError',
            })}
          />
        </TicketProcessInput>
        <TicketProcessInput
          {...{
            label: 'labels.phone',
            htmlFor: 'phone',
            error: formState.errors.phone,
          }}
        >
          <input id="phone" type="text" {...register('phone')} />
        </TicketProcessInput>
        <TicketProcessInput
          {...{
            label: 'labels.email',
            htmlFor: 'email',
            error: formState.errors.email,
          }}
        >
          <input
            id="email"
            type="text"
            {...register('email', {
              required: {
                value: true,
                message: 'errors.emailError',
              },
            })}
          />
        </TicketProcessInput>

        <p className="label">
          {formatMessage({ id: 'tickets.stepperBadge' })}:
        </p>
        <TicketProcessInput
          {...{
            label: 'labels.jobPosition',
            htmlFor: 'job_position',
            error: formState.errors.job_position,
          }}
        >
          <Controller
            name="job_position"
            defaultValue={false}
            control={control}
            rules={{
              required: 'errors.jobPositionError',
            }}
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti={false}
                short
                value={value}
                id="job_position"
                name="job_position"
                options={employmentPosition}
                onChange={onChange}
                error={formState.errors.job_position}
              />
            )}
          />
        </TicketProcessInput>

        <TicketProcessInput
          {...{
            label: 'labels.company',
            htmlFor: 'company',
            error: formState.errors.company,
          }}
        >
          <input
            id="company"
            type="text"
            {...register('company', {
              required: {
                value: companyRequired,
                message: 'errors.companyError',
              },
            })}
          />
        </TicketProcessInput>

        <TicketProcessInput
          {...{
            label: 'labels.interestingPath',
            htmlFor: 'interesting_path',
            error: formState.errors.interesting_path,
          }}
        >
          <Controller
            name="interesting_path"
            defaultValue={false}
            control={control}
            rules={{
              required: 'errors.interestingPathError',
            }}
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti={false}
                short
                value={value}
                id="interesting_path"
                name="interesting_path"
                options={tracks}
                onChange={onChange}
                error={formState.errors.interesting_path}
              />
            )}
          />
        </TicketProcessInput>

        <p className="label">
          {formatMessage({ id: 'tickets.stepperUserData' })}:
        </p>
        <TicketProcessInput
          {...{
            label: 'labels.experience',
            htmlFor: 'experience',
            error: formState.errors.experience,
          }}
        >
          <Controller
            name="experience"
            defaultValue={false}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti={false}
                short
                value={value}
                id="experience"
                name="experience"
                options={experienceLevel}
                onChange={onChange}
                error={formState.errors.experience}
              />
            )}
          />
        </TicketProcessInput>

        <TicketProcessInput
          {...{
            label: 'labels.technologiesWorkingWith',
            htmlFor: 'technologiesWorkingWith',
            count: technologiesWorkingWithCount,
            maxCount: 5,
            error: formState.errors.technologies_working_with,
          }}
        >
          <Controller
            name="technologies_working_with[]"
            defaultValue={false}
            control={control}
            rules={{
              validate: (value) => {
                return (value?.length ?? 0) <= 5
                  ? true
                  : 'errors.technologiesWorkingWithOverError'
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Select
                short
                value={value}
                id="technologiesWorkingWith"
                name="technologies_working_with[]"
                options={technologies}
                onChange={onChange}
                error={formState.errors.technologies_working_with}
              />
            )}
          />
        </TicketProcessInput>

        <TicketProcessInput
          {...{
            label: 'labels.technologiesInterestedIn',
            htmlFor: 'technologies-interested-in',
            count: technologiesInterestedIn,
            maxCount: 5,
            error: formState.errors.technologies_interested_in,
          }}
        >
          <Controller
            name="technologies_interested_in[]"
            defaultValue={false}
            control={control}
            rules={{
              validate: (value) => {
                return (value?.length ?? 0) <= 5
                  ? true
                  : 'errors.technologiesInterestedInOverError'
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Select
                short
                value={value}
                id="technologiesInterestedIn"
                name="technologies_interested_in[]"
                options={technologies}
                onChange={onChange}
                error={formState.errors.technologies_interested_in}
              />
            )}
          />
        </TicketProcessInput>

        <TicketProcessInput
          {...{
            label: 'labels.knownLanguages',
            htmlFor: 'known-languages',
            error: formState.errors.knownLanguages,
          }}
        >
          <Controller
            name="known_languages[]"
            defaultValue={false}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                short
                value={value}
                id="known_languages"
                name="known_languages[]"
                options={languages}
                onChange={onChange}
                error={formState.errors.known_languages}
              />
            )}
          />
        </TicketProcessInput>

        <TicketProcessInput
          {...{
            label: 'labels.cv',
            optional: true,
            htmlFor: 'cv',
            error: formState.errors.resume,
            preDescription: 'labels.cvRequirements',
          }}
        >
          <Controller
            name="resume"
            control={control}
            render={({ field: { value, onChange }, ...res }) => {
              const isString = typeof value === 'string'
              const fileName = isString
                ? value
                : value?.length
                ? value[0].name
                : null

              return (
                <DropzoneInput
                  maxFiles={1}
                  userName={`${user.name} ${user.surname}`}
                  uploadedFile={fileName}
                  onRemove={onChange}
                  onDrop={onDropFile(onChange)}
                />
              )
            }}
          />
        </TicketProcessInput>

        <div className="actions single">
          <Button
            disabled={formState.isSubmitting}
            loading={formState.isSubmitting}
          >
            {formatMessage({ id: 'general.saveData' })}
          </Button>
        </div>
      </form>
    </SUserData>
  )
}

export default UserData
