import React from 'react'
import { useIntl } from 'react-intl'
import VisibilitySensor from 'react-visibility-sensor'
import { animated, Spring } from 'react-spring'

import { SContainer } from './HeroSectionAboutUs.styled'
import LongGreenPattern from '../../assets/patterns/LongGreenPattern'
import twoLinesPattern from '../../assets/patterns/two-lines-pattern.svg'
import heroAboutUsImage from '../../assets/images/hero-about-us-image.png'

function HeroSectionAboutUs() {
  const { formatMessage } = useIntl()

  return (
    <VisibilitySensor
      minTopValue={15}
      scrollCheck
      scrollDelay={5}
      partialVisibility="top"
    >
      {({ isVisible }) => (
        <SContainer>
          <Spring from={{ opacity: 1 }} to={{ opacity: isVisible ? 1 : 0 }}>
            {({ opacity }) => (
              <div className="background">
                <animated.div
                  style={{
                    opacity,
                    height: '100%',
                  }}
                >
                  <div className="positionRight">
                    <LongGreenPattern />
                  </div>
                  <div className="positionLeft">
                    <img src={twoLinesPattern} alt="" />
                  </div>
                </animated.div>
              </div>
            )}
          </Spring>
          <div className="content">
            <h1 className="heading">
              {formatMessage(
                { id: 'heroAboutUsHeading' },
                {
                  span: (chunk) => <span>{chunk}</span>,
                  br: <br />,
                }
              )}
            </h1>
            <img className="hero-image" src={heroAboutUsImage} alt="" />
            <p className="subheader">
              {formatMessage(
                {
                  id: 'heroAboutUsSubheader',
                },
                {
                  span: (chunk) => <span>{chunk}</span>,
                }
              )}
            </p>
            <hr className="divider-horizontal" />
            <p className="description">
              {formatMessage({
                id: 'heroAboutUsDescription',
              })}
            </p>
          </div>
        </SContainer>
      )}
    </VisibilitySensor>
  )
}

export default HeroSectionAboutUs
