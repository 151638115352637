import * as React from 'react'

function EmptySpeakersListIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 185 351" {...props}>
      <defs>
        <linearGradient
          id="prefix__a"
          x1="43.275%"
          x2="50%"
          y1="55.161%"
          y2="26.37%"
        >
          <stop offset="0%" stopColor="#292929" stopOpacity={0.11} />
          <stop offset="100%" stopColor="#E669DD" stopOpacity={0.316} />
        </linearGradient>
        <linearGradient
          id="prefix__b"
          x1="58.09%"
          x2="50%"
          y1="56.034%"
          y2="25.365%"
        >
          <stop offset=".055%" stopColor="#292929" stopOpacity={0} />
          <stop offset="100%" stopColor="#E784E1" stopOpacity={0.405} />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#DA00D5"
          d="M0 196.305l92.5-46.07 92.5 46.07-92.5 46.071z"
        />
        <path fill="#FFE4FD" d="M0 196.262l92.5-46.027V89.5L0 135.527z" />
        <path fill="#FC86F6" d="M92.5 266.144L0 220.117v-84.812l92.5 46.027z" />
        <path
          fill="#FFD0FC"
          d="M184.5 196.262L92 150.235v-60.93l92.5 46.027z"
        />
        <path
          fill="#F7B5F3"
          d="M92.5 266.144l92.5-46.027v-84.812l-92.5 46.027z"
        />
        <path
          fill="url(#prefix__a)"
          d="M93 131L0 84.916V0l92.497 45.822z"
          transform="translate(0 220)"
        />
        <path
          fill="url(#prefix__b)"
          d="M92.5 122.123L185 76.318V.144l-92.5 46z"
          transform="translate(0 220)"
        />
        <path
          fill="#F7B5F3"
          d="M15.386 85.804L73.12 64.79l24.953 68.56-57.734 21.014z"
        />
        <path
          fill="#FFF"
          d="M20.308 88.099l50.517-18.387 18.387 50.518-50.517 18.387z"
        />
        <path
          fill="#DA00D5"
          fillRule="nonzero"
          d="M59.291 110.641l-.33-1.268c-.953-2.62-.804-4.188 1.327-5.721 4.028-2.982 5.891-7.95 3.924-13.357-2.435-6.687-8.934-9.054-16.172-6.42-7.236 2.634-10.573 8.96-7.977 16.093l8.106-2.95c-.851-2.34.28-4.457 2.71-5.342 2.433-.885 4.599-.158 5.207 1.514.446 1.225.615 2.048-1.027 3.844l-1.97 1.79c-5.262 4.82-4.405 7.536-2.234 13.499l.562 1.184 7.874-2.866zm.89 14.068c2.808-1.022 4.48-3.86 3.477-6.618-.983-2.7-4.09-3.8-6.897-2.779-2.81 1.023-4.423 3.84-3.44 6.541 1.004 2.757 4.051 3.879 6.86 2.856z"
        />
        <g>
          <path
            fill="#FC86F6"
            d="M117.403 46.39l53.209 30.72-36.48 63.185-53.209-30.72z"
          />
          <path
            fill="#FFD0FC"
            d="M118.809 51.636l46.557 26.88-26.88 46.557-46.557-26.88z"
          />
          <path
            d="M130.023 86.932a9.12 9.12 0 0012.458-3.339 9.12 9.12 0 00-3.338-12.458 9.12 9.12 0 00-12.458 3.338 9.12 9.12 0 003.338 12.459zm6.84-11.848a4.56 4.56 0 11-4.561 7.899 4.56 4.56 0 014.561-7.899zm-8.76 15.173c-7.576-4.374-17.29-1.731-21.698 5.902-.63 1.091-.262 2.48.82 3.106s2.47.248 3.1-.843c3.148-5.453 10.086-7.34 15.498-4.216s7.246 10.077 4.098 15.53c-.63 1.091-.262 2.48.82 3.106s2.47.248 3.1-.843c4.407-7.633 1.838-17.368-5.738-21.742z"
            fill="#DA00D5"
            fillRule="nonzero"
          />
        </g>
        <g>
          <path
            fill="#FFE4FD"
            d="M43.406 20.532L117.59.655l23.605 88.092-74.184 19.877z"
          />
          <path
            fill="#FC86F6"
            d="M49.285 23.926l64.91-17.393 17.393 64.91-64.91 17.393z"
          />
          <path
            d="M90.395 45.211c6.081-1.63 9.69-7.88 8.06-13.962s-7.88-9.69-13.961-8.06-9.69 7.88-8.061 13.961 7.88 9.69 13.962 8.061zm-4.426-16.517a5.7 5.7 0 112.95 11.012 5.7 5.7 0 01-2.95-11.012zm5.668 21.154C81.075 52.678 74.824 63.6 77.675 74.243a2.84 2.84 0 003.47 2.02c1.51-.404 2.402-1.964 1.994-3.485-2.036-7.601 2.429-15.403 9.973-17.424 7.544-2.022 15.313 2.502 17.35 10.104.407 1.52 1.96 2.425 3.469 2.02a2.84 2.84 0 001.995-3.485c-2.852-10.642-13.726-16.975-24.289-14.145z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </g>
      </g>
    </svg>
  )
}

export default EmptySpeakersListIcon
