import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export default styled.div`
  ${({ theme }) => css`
    position: relative;

    padding: 0 ${theme.space.md}px;

    ${respondTo.xl`
      max-width: ${theme.breakpoints.xl}px;

      margin: 0 auto;
      padding: 0;
    `}
  `}
`
