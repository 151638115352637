import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { useBreakpoint } from 'modules/breakpoint'

import PlaneIcon from '../../assets/icons/PlaneIcon'
import PlayIcon from '../../assets/icons/PlayIcon'

import { SFormSuccess } from './FormSendSuccess.styled'

function FormSendSuccess() {
  const { formatMessage, locale } = useIntl()

  return (
    <SFormSuccess>
      <div>
        <h3>
          {formatMessage(
            { id: 'becomeSpeakerSuccessHeading' },
            {
              br: <br />,
            }
          )}
        </h3>
        <PlaneIcon className="plane-icon" />
        <p>{formatMessage({ id: 'becomeSpeakerSuccessSee' })}</p>

        <Link to={`/${locale}/`}>Go back to home page</Link>
      </div>
    </SFormSuccess>
  )
}

export default FormSendSuccess
