import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import ChevronIcon from '../../assets/icons/ChevronIcon/ChevronIcon'

function Filtration({ group, onChange }) {
  const [expanded, setExpanded] = useState(false)
  const { formatMessage, locale } = useIntl()

  const toggleExpanded = () => setExpanded(!expanded)

  const fields = expanded ? group.fields : group.fields.slice(0, 4)
  const visibleMore = group.fields.length > 4
  return (
    <div className="group">
      <p className="group-name">
        {formatMessage({ id: `filtrationGroupName.${group.category}` })}:
      </p>
      <div className="filters">
        {fields.map((field) => (
          <div key={`field-${group.category}-${field.id}`} className="filter">
            <input
              type="checkbox"
              name={field.name}
              value={field.id}
              id={`field-${group.category}-${field.id}`}
              checked={field.value}
              onChange={onChange(group.category)}
            />
            <label htmlFor={`field-${group.category}-${field.id}`}>
              {locale === 'pl' ? field.name : field.name_en || field.name}
            </label>
          </div>
        ))}
      </div>
      {visibleMore ? (
        <button type="button" onClick={toggleExpanded} className="more">
          {expanded ? (
            <>
              {formatMessage({ id: 'filtrationShowLess' })}{' '}
              <ChevronIcon type="up" />
            </>
          ) : (
            <>
              {formatMessage({ id: 'filtrationShowMore' })}{' '}
              <ChevronIcon type="down" />
            </>
          )}
        </button>
      ) : null}
    </div>
  )
}

export default Filtration
