import React from 'react'
import { useIntl } from 'react-intl'

import { Loader } from 'components'

import ProfileIcon from 'assets/icons/ProfileIcon'
import EditIcon from 'assets/icons/EditIcon'

import { STicketSummary } from './TicketPaymentSummary.styled'

function TicketPaymentSummary({ data, onEdit }) {
  const { formatMessage } = useIntl()

  return (
    <STicketSummary>
      <div className="container">
        <div className="icon">
          <ProfileIcon />
        </div>
        <div className="content">
          <p className="text">
            {data.name} {data.surname}
          </p>
          <p className="text">
            {data.street} {data.house_number}/{data.apartment_number}
          </p>
          <p className="text">
            {data.postcode} {data.city}
          </p>
          <p className="text">{data.country}</p>
          {data.vat ? (
            <>
              <p className="text">{data.company}</p>
              <p className="text">NIP: {data.company_nip}</p>
            </>
          ) : null}
        </div>
        <div className="actions">
          <button type="button" className="edit" onClick={onEdit}>
            <EditIcon />
          </button>
        </div>
      </div>
      <p className="info">
        {formatMessage({ id: 'tickets.paymentSummaryVatInfo' })}
      </p>
    </STicketSummary>
  )
}

export default TicketPaymentSummary
