import React, { useCallback } from 'react'
import Dropzone from 'react-dropzone'
import UploadIcon from '../../assets/icons/UploadIcon'
import {
  SDropzoneArea,
  SDropzoneLabel,
  SFile,
  SUploadingProgress,
} from './Dropzone.styled'
import { Loader } from '../index'
import CloseIcon from '../../assets/icons/CloseIcon'

function DropzoneInput({
  userName,
  uploadedFile = null,
  uploadingProgress = false,
  onRemove,
  ...res
}) {
  const handleRemoveClick = () => {
    onRemove(null)
  }

  if (uploadedFile) {
    return (
      <SFile>
        <p>{userName ? `${userName} - CV.pdf` : uploadedFile}</p>
        <button onClick={handleRemoveClick} type="button">
          <span>
            <CloseIcon />
          </span>
        </button>
      </SFile>
    )
  }

  return (
    <Dropzone {...res}>
      {({ getRootProps, getInputProps }) => (
        <SDropzoneArea {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="wrapper">
            <UploadIcon width={'32px'} height={'24px'} />
            <SDropzoneLabel>
              Drop files here or <span>browse</span>
            </SDropzoneLabel>
          </div>
        </SDropzoneArea>
      )}
    </Dropzone>
  )
}

export default DropzoneInput
