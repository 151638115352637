import React from 'react'
import { useIntl } from 'react-intl'
import { useTheme } from 'styled-components'

import { Button, CustomUnderline, Numbers } from '../../components'
import { SContainer } from './CeInNumbers.styled'
import { Link } from 'react-router-dom'
import { AppRoute } from '../../const'
import useLocalizeRoute from '../../utils/useLocalizeRoute'
import preventClick from 'utils/preventClick'

function CeInNumbers() {
  const theme = useTheme()
  const { formatMessage } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()

  return (
    <SContainer>
      <section className="content">
        <h2 className="heading">
          {formatMessage(
            { id: 'ceInNumbersHeading' },
            {
              span: (chunk) => (
                <CustomUnderline color={theme.colors.blue}>
                  {chunk}
                </CustomUnderline>
              ),
            }
          )}
        </h2>
        <Numbers />
        <div className="buttons-container">
          <Button
            height="52px"
            as={Link}
            // to={localizeRouteKey(AppRoute.BuyTicket)}
            to="#"
            onClick={preventClick}
            className="disable-button-link"
          >
            {formatMessage({
              id: 'buyTicket',
            })}
            <i className="ri-arrow-right-line" />
          </Button>
          <Button
            secondary="true"
            height="52px"
            as={Link}
            to={localizeRouteKey(AppRoute.BecomePartner)}
          >
            {formatMessage({
              id: 'joinPartnersButton',
            })}
            <i className="ri-arrow-right-line" />
          </Button>
        </div>
      </section>
    </SContainer>
  )
}

export default CeInNumbers
