import * as React from "react"

function AccommodationIcon(props) {
  return (
    <svg viewBox="0 0 58 49" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g strokeWidth={1.5} fill="none" fillRule="evenodd">
        <path
          d="M5 43.399h48a4 4 0 004-4v-5.952a4 4 0 00-.318-1.564l-6.647-15.655a4 4 0 00-3.682-2.436h-4.638 0v2.74a2 2 0 01-2 2h-6.721a2 2 0 01-2-2v-2.74h0-3.99v2.74a2 2 0 01-2 2h-6.75a2 2 0 01-2-2v-2.74h0-4.607a4 4 0 00-3.682 2.436L1.318 31.883A4 4 0 001 33.447v5.952a4 4 0 004 4zm-1.333 0v5.444M54.333 43.4v5.444M1 32.633h56"
          stroke="#FFF"
          strokeLinejoin="round"
        />
        <path
          d="M10.333 13.792V5a4 4 0 014-4h29.334a4 4 0 014 4v8.792h0"
          stroke="#FFF"
        />
        <path
          d="M16.253 12.33v4.201a2 2 0 002 2h6.721a2 2 0 002-2v-4.2h0m4.008-.001v4.201a2 2 0 002 2h6.721a2 2 0 002-2v-4.2h0"
          stroke="#FC86F6"
        />
        <path
          d="M16.253 14.045v-4.2a2 2 0 012-2h6.721a2 2 0 012 2v4.2h0m4.008 0v-4.2a2 2 0 012-2h6.721a2 2 0 012 2v4.2h0"
          stroke="#FC86F6"
        />
      </g>
    </svg>
  )
}

export default AccommodationIcon
