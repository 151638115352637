import styled, { css } from "styled-components"
import respondTo from "../../theme/respondTo"

import { Button } from "../../components"
import checkIcon from "../../assets/icons/checkbox-check-icon.svg"
import apostrophe from "../../assets/images/apostrophe.svg"

export const SFormPolicy = styled.p`
  ${({ theme }) => css`
    font-size: 0.875rem;
    line-height: 1.14;
    color: white;

    margin-top: ${theme.space.lg}px;

    a {
      font-weight: ${theme.weight.bold};
      text-decoration: none;
      color: ${theme.colors.pink};
    }

    ${respondTo.lg`
      margin-left: ${theme.space.lg}px;
    `}
  `}
`

export const SSend = styled(Button)`
  ${({ theme }) => css`
    width: 100%;

    margin-top: ${theme.space.lg}px;
  `}
`
export const SFormGroup = styled.div`
  ${({ theme, error }) =>
    error &&
    css`
      & > input,
      .textarea {
        border-color: ${theme.colors.lightRed} !important;
      }

      [type="checkbox"] {
        & + label {
          &::before {
            border-color: ${theme.colors.lightRed} !important;
          }
        }
      }

      .error {
        font-size: 0.81rem;
        color: ${theme.colors.lightRed};

        margin-top: ${theme.space.sm}px;
      }
    `}
`
export const SFormSuccess = styled.div`
  ${({ theme }) => css`
    min-height: 100%;

    font-size: 0.875rem;
    line-height: 1.14;
    text-align: center;
    color: white;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 0 ${theme.space.xl}px;

    .expect {
      margin-top: ${theme.space.xxxl + theme.space.md}px;
    }

    div:not(.background) > svg {
      display: block;

      margin: 0 auto;
      margin-top: ${theme.space.md}px;
    }

    p {
      margin-top: ${theme.space.lg}px;
    }

    .plane-icon {
      width: 70px;
      height: 47px;
    }

    .play-icon {
      width: 88px;
      height: 88px;
    }

    a {
      font-weight: ${theme.weight.bold};
      text-decoration: underline;
      color: ${theme.colors.pink};

      display: block;

      margin-top: ${theme.space.xl}px;
    }

    .background {
      display: flex;
      justify-content: space-between;

      margin-top: ${theme.space.xxxl + theme.space.md}px;
      margin-left: ${-theme.space.xl}px;
      margin-right: ${-theme.space.xl}px;
    }
  `}
`
export const SFormContainer = styled.div`
  ${({ theme }) => css`
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
      0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14);
    border: solid 1px ${theme.colors.gray};
    background-color: ${theme.colors.gray2};

    padding: ${theme.space.xl}px ${theme.space.md}px;
    margin-top: ${theme.space.xl}px;

    form {
      ${SFormGroup} {
        display: flex;
        flex-direction: column;

        margin-top: ${theme.space.md}px;

        & > input,
        textarea {
          height: 48px;

          font-family: sofia-pro, sans-serif;
          font-size: 1rem;
          color: white;

          border: 1px solid ${theme.colors.lightGray};
          border-radius: 0;
          background-color: transparent;
          appearance: none;

          &:focus {
            outline: 0;
            border-width: 2px;
            border-color: white;
          }
        }

        & > input {
          padding: 0 ${theme.space.md}px;
        }

        .textarea {
          width: 100%;

          padding: ${theme.space.md}px;
          padding-right: 5px;

          border: 1px solid ${theme.colors.lightGray};

          textarea {
            width: 100%;
            height: initial;

            border: none;

            resize: none;

            &::-webkit-scrollbar {
              width: 3px;
              background-color: #e0e0e0;
            }

            &::-webkit-scrollbar-thumb {
              background-color: ${theme.colors.pink};
            }
          }
        }
      }

      .form-row {
        display: flex;
        flex-direction: column;

        margin-top: ${theme.space.sm}px;

        & > div {
          flex: 1;

          margin-top: ${theme.space.sm}px;

          &:first-child {
            margin-top: 0;
          }
        }
      }

      label {
        width: 100%;

        display: flex;
        align-items: baseline;
        justify-content: space-between;

        font-size: 1rem;
        color: white;

        margin-bottom: ${theme.space.sm}px;

        span {
          font-size: 0.75rem;
          color: ${theme.colors.lightGray};
        }
      }

      input {
        height: 34px;

        &[type="checkbox"] {
          visibility: hidden;
          position: absolute;
          top: 0;
          left: 0;
          width: 1px;
          height: 1px;
          pointer-events: none;

          & + label {
            font-size: 1rem;
            line-height: 1.5;
            color: white;

            display: block;

            position: relative;

            padding-left: 28px;

            cursor: pointer;

            &:hover {
              &::before {
                border-width: 2px;
                border-color: white;
              }
            }

            &::before {
              content: "";
              width: 20px;
              height: 20px;

              position: absolute;
              top: 2px;
              left: 0;

              border: 1px solid white;
            }
          }

          &:checked {
            & + label {
              &::before {
                background-image: url(${checkIcon});
                background-size: 80%;
                background-position: 1px 1px;
                background-repeat: no-repeat;

                border: 1px solid ${theme.colors.pink};
              }
            }
          }
        }
      }
    }

    ${respondTo.lg`
      flex: 1;
      min-width: 568px;

      margin-right: 44px;

      form {
        .form-row {
          flex-direction: row;

          & > div {
            margin-top: 0;
          }
        }
      }
    `}
  `}
`

export const SComment = styled.div`
  ${({ theme }) => css`
    position: relative;

    margin-bottom: ${theme.space.xxxl}px;

    z-index: 1;

    .comment {
      font-size: 1rem;
      line-height: 1.5;
      color: white;
    }

    .author {
      font-size: 1rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.5;
      color: ${theme.colors.pink};

      margin-top: ${theme.space.md}px;

      &-information {
        font-size: 0.875rem;
        color: ${theme.colors.lightGray};

        margin-top: ${theme.space.sm}px;

        strong {
          font-weight: ${theme.weight.bold};
        }
      }
    }
  `}
`
export const SComments = styled.div`
  ${({ theme }) => css`
    position: relative;

    padding: 0 ${theme.space.xl}px;
    padding-top: ${theme.space.xl}px;
    margin-top: ${theme.space.xxxl}px;

    &::before,
    &::after {
      content: "";
      width: 74px;
      height: 64px;

      position: absolute;

      background-image: url(${apostrophe});
      background-repeat: no-repeat;
      background-size: cover;
    }

    &::before {
      top: 0;
      left: 0;
    }

    &::after {
      bottom: -32px;
      right: 0;
    }
  `}
`
export const SAmenitiesList = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 140px 140px;
    grid-template-rows: 140px 140px;
    grid-gap: ${theme.space.sm}px;

    margin-top: ${theme.space.sm}px;
    justify-content: center;

    & > div {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;

      padding: 14px 8px 8px;
      background-color: ${theme.colors.gray2};

      svg {
        flex: 2;
      }

      p {
        flex: 1.5;

        display: flex;
        align-items: flex-end;
        justify-content: center;

        font-size: 0.875rem;
        line-height: 1.14;
        text-align: center;
        color: white;
      }
    }

    ${respondTo.lg`
      grid-template-columns: repeat(2, 122px);
      grid-template-rows: 122px 122px;
      grid-gap: ${theme.space.md}px;

      margin-top: ${theme.space.xxl}px;

      & > div {
        width: 122px;
        height: 122px;
      }
    `}

    ${respondTo.xl`
      grid-template-columns: repeat(4, 122px);
      grid-template-rows: 122px;
    `}
  `}
`
export const SAmenities = styled.div`
  ${({ theme }) => css`
    ${respondTo.lg`
        flex: 1;
        margin-top: ${theme.space.xxxl}px;
      `}
  `}
`
export const SContainer = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.space.xl}px;

    ${respondTo.lg`
      display: flex;
      flex-direction: row-reverse;
    `}
  `}
`
export const SBecomeSpeaker = styled.section`
  ${({ theme }) => css`
    padding: ${theme.space.md}px 0 ${theme.space.xl}px 0;

    h3 {
      font-size: 1.5rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.33;
      color: white;

      &.center {
        text-align: center;
      }

      span {
        color: ${theme.colors.pink};
      }
    }

    ${respondTo.lg`
      h3 {
        text-align: center;
      }
    `}
  `}
`
