import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import config from 'const/config'

import { Root } from './Root'
import * as serviceWorker from './serviceWorker'

Sentry.init({
  dsn: config.SENTRY_DSN,
  tracesSampleRate: 0.01,
})

ReactDOM.render(<Root />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
