import styled, { css } from 'styled-components'

export const SContainer = styled.div`
  display: flex;
  margin: 6px 0;
`

export const STrack = styled.div`
  margin-right: 3px;

  &:last-of-type {
    margin-right: 0;
  }

  .dot {
    width: 12px;
    height: 12px;
    border-radius: 6px;

    display: block;

    ${({ color }) =>
      color &&
      css`
        background-color: ${color};
      `}
  }

  .tooltip {
    .arrow {
      display: none;
    }
  }
`
