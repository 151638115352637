import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'
import tracksHomePageBackground from '../../assets/images/tracksHomePageBackground.svg'
import { Wrapper } from '../../components'

export const SBackgroundContent = styled(Wrapper)`
  height: 100%;
  margin: 0;
`
export const SContainer = styled.section`
  ${({ theme, partners }) => css`
    padding: ${partners
      ? `80px ${theme.space.lg}px ${theme.space.xl + 8}px`
      : `${theme.space.xl + 8}px ${theme.space.lg}px`};
    background-image: url(${tracksHomePageBackground});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 90% 0;
    overflow: hidden;

    position: relative;

    ${respondTo.lg`
      padding: ${theme.space.xl * 2}px ${theme.space.lg}px 80px;
      background-position: center bottom;
    `}

    .content {
      ${respondTo.xl`
        max-width: ${theme.breakpoints.xl}px;
  
        margin: 0 auto;
        padding: 0;
      `}
    }

    .top-title {
      color: ${theme.colors.pink};
      line-height: 1.375rem;
      font-weight: 500;
      text-align: center;

      ${respondTo.lg`
        font-size: 1.25rem;
        line-height: 2rem;
      `}
    }

    .heading {
      color: ${theme.colors.white};
      margin: ${theme.space.md - 4}px auto ${theme.space.md + 4}px;
      text-align: center;
      font-weight: ${theme.weight.bold};
      font-size: 1.5rem;
      line-height: 1.875rem;
      max-width: 680px;

      ${respondTo.lg`
        font-size: 2.25rem;
        line-height: 3.125rem;
        margin: ${theme.space.md + 4}px auto;
      `}
    }

    .subheader {
      color: #bdbdbd;
      font-size: 1.125rem;
      line-height: 1.56rem;
      margin: 0 auto ${theme.space.lg + 4}px;
      text-align: center;
      max-width: 680px;

      ${respondTo.lg`
        font-size: 1.375rem;
        line-height: 1.875rem;
        margin-bottom: ${
          partners ? `${theme.space.md}px` : `${theme.space.xxl}px`
        };
      `}
    }

    .button-wrapper {
      margin-top: ${theme.space.xl + 8}px;

      ${respondTo.lg`
        display: flex;
        justify-content: center;
        margin-top: ${theme.space.xl * 2}px;
      `}

      i {
        padding-top: ${theme.space.xs}px;
        margin-left: ${theme.space.sm}px;
      }
    }

    .positionLeft {
      position: absolute;
      top: ${theme.space.xxxl * 2.5}px;
      left: 0;
    }
    .positionRight {
      position: absolute;
      top: ${theme.space.xxxl * 4.5}px;
      right: -65px;

      svg {
        width: 250px;
      }
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  `}
`
