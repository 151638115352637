import React from 'react'
import { SContainer } from './TicketSelectionQuotes.styled'

function TicketSelectionQuotes({ quotes }) {
  return (
    <SContainer>
      {quotes.map((quote) => (
        <div key={quote.author} className="item">
          <p className="text">{quote.text}</p>
          <p>{quote.author}</p>
        </div>
      ))}
    </SContainer>
  )
}

export default TicketSelectionQuotes
