import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'

import client from 'api/client'

import {
  TicketProcessBox,
  TicketUserSteper,
  TicketProcessInput,
  TicketProcessCheckbox,
  TicketPriceSummary,
  Select,
} from '..'
import Button from 'components/Button/Button'

import { TicketStep } from 'state/reducers/tickets/ticketsReducer'
import { changeStep, setFields } from 'state/reducers/tickets/ticketsActions'
import {
  mailRegex,
  withoutDigits,
} from 'components/TicketUserRegistration/TicketUserRegistration'
import { AppRoute } from 'const'
import useLocalizeRoute from 'utils/useLocalizeRoute'
import scrollToError from 'utils/scrollToError'

function TicketPaymentData({ fields, price, isGroup, isAuthenticated }) {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()

  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    control,
    formState,
    watch,
    setValue,
    getValues,
    reset,
    setError,
  } = useForm({
    defaultValues: {
      payment_name: fields.name,
      payment_surname: fields.surname,
      payment_email: fields.email,
      street: fields.street,
      house_number: fields.house_number,
      apartment_number: fields.apartment_number,
      postcode: fields.postcode,
      city: fields.city,
      country: fields.country,

      terms: fields.terms,
      vat: fields.vat,

      company: fields.company,
      company_nip: fields.company_nip,

      // copy: fields.copy,

      company_street: fields.company_street,
      company_house_number: fields.company_house_number,
      company_apartment_number: fields.company_apartment_number,
      company_postcode: fields.company_postcode,
      company_city: fields.company_city,
      company_country: fields.company_country,
    },
    shouldUnregister: false,
  })

  const withVat = watch('vat')
  // const shouldCopy = watch('copy')
  const terms = watch('terms')

  const saveFieldsData = (data) => {
    const values = data || getValues()

    if (isGroup || isAuthenticated) {
      dispatch(setFields({ type: 'base', fields: { terms: values.terms } }))
    }

    dispatch(
      setFields({
        type: 'payment',
        fields: {
          name: values.payment_name,
          surname: values.payment_surname,
          email: values.payment_email,
          street: values.street,
          house_number: values.house_number,
          apartment_number: values.apartment_number,
          postcode: values.postcode,
          city: values.city,
          country: values.country,

          vat: values.vat,
        },
      })
    )
    dispatch(
      setFields({
        type: 'vat',
        fields: {
          copy: values.copy,
          company: values.company,
          company_nip: values.company_nip,
          company_street: values.company_street,
          company_house_number: values.company_house_number,
          company_apartment_number: values.company_apartment_number,
          company_postcode: values.company_postcode,
          company_city: values.company_city,
          company_country: values.company_country,
        },
      })
    )
  }

  const handlePrev = () => {
    saveFieldsData()
    dispatch(
      changeStep(
        isGroup || isAuthenticated ? TicketStep.SELECTION : TicketStep.DATA
      )
    )
  }

  scrollToError(formState.errors)

  const onSubmit = async (values) => {
    setLoading(true)
    try {
      const body = {
        step: 4,
        ...values,
        // copy: undefined,
        vat: undefined,
      }

      const response = await client({
        endpoint: 'api/buy/validate',
        method: 'POST',
        body: JSON.stringify(body),
      })

      if (!response.ok) {
        const data = await response.json()
        Object.keys(data).forEach((key) => {
          const error = data[key].flat()
          if (!Array.isArray(error)) return

          const [errorMessage] = error
          setError(key, { type: 'required', message: errorMessage })
        })

        throw new Error()
      }

      window.scrollTo({ top: 0 })

      saveFieldsData(values)
      dispatch(changeStep(TicketStep.PAYMENT_SUMMARY))
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('[POST] validate payment data step error: ', error)
    }
  }

  // const handleChangeForm = () => {
  //   const formValues = getValues()
  //   if (shouldCopy) {
  //     setValue('company_street', formValues.street)
  //     setValue('company_house_number', formValues.house_number)
  //     setValue('company_apartment_number', formValues.apartment_number)
  //     setValue('company_postcode', formValues.postcode)
  //     setValue('company_city', formValues.city)
  //     setValue('company_country', formValues.country)
  //   }

  //   if (!shouldCopy || !withVat) {
  //     setValue('company_street', '')
  //     setValue('company_house_number', '')
  //     setValue('company_apartment_number', '')
  //     setValue('company_postcode', '')
  //     setValue('company_city', '')
  //     setValue('company_country', '')
  //   }

  //   if (!withVat) {
  //     setValue('copy', false)
  //   }
  // }

  // useEffect(() => {
  //   handleChangeForm()
  // }, [shouldCopy, withVat])

  return (
    <TicketProcessBox>
      <header>
        <p className="heading">
          {formatMessage({ id: 'tickets.orderSummaryPaymentLabel' })}
        </p>
      </header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TicketProcessInput
          {...{
            label: 'labels.name',
            htmlFor: 'payment-name',
            error: formState.errors.payment_name,
          }}
        >
          <input
            id="payment-name"
            type="text"
            {...register('payment_name', {
              required: {
                value: true,
                message: 'errors.nameError',
              },
            })}
          />
        </TicketProcessInput>
        <TicketProcessInput
          {...{
            label: 'labels.surname',
            htmlFor: 'payment-surname',
            error: formState.errors.payment_surname,
          }}
        >
          <input
            id="payment-surname"
            type="text"
            {...register('payment_surname', {
              required: 'errors.surnameError',
            })}
          />
        </TicketProcessInput>
        <TicketProcessInput
          {...{
            label: 'labels.email',
            htmlFor: 'payment-email',
            error: formState.errors.payment_email,
          }}
        >
          <input
            id="payment-email"
            type="text"
            {...register('payment_email', {
              required: { value: true, message: 'errors.emailError' },
            })}
          />
        </TicketProcessInput>

        <TicketProcessInput
          {...{
            label: 'labels.street',
            htmlFor: 'street',
            error: formState.errors.street,
          }}
        >
          <input
            id="street"
            type="text"
            {...register('street', {
              required: { value: true, message: 'errors.streetError' },
            })}
          />
        </TicketProcessInput>
        <TicketProcessInput
          {...{
            label: 'labels.houseNumber',
            htmlFor: 'house-number',
            error: formState.errors.house_number,
          }}
        >
          <input
            id="house-number"
            type="text"
            {...register('house_number', {
              required: { value: true, message: 'errors.houseNumberError' },
            })}
          />
        </TicketProcessInput>
        <TicketProcessInput
          {...{
            label: 'labels.apartmentNumber',
            htmlFor: 'apartment-number',
            error: formState.errors.apartment_number,
          }}
        >
          <input
            id="apartment-number"
            type="text"
            {...register('apartment_number')}
          />
        </TicketProcessInput>
        <TicketProcessInput
          {...{
            label: 'labels.postcode',
            htmlFor: 'post-code',
            error: formState.errors.postcode,
          }}
        >
          <input
            id="post-code"
            type="text"
            {...register('postcode', {
              required: { value: true, message: 'errors.postcodeError' },
            })}
          />
        </TicketProcessInput>
        <TicketProcessInput
          {...{
            label: 'labels.city',
            htmlFor: 'city',
            error: formState.errors.city,
          }}
        >
          <input
            id="city"
            type="text"
            {...register('city', {
              required: { value: true, message: 'errors.cityError' },
            })}
          />
        </TicketProcessInput>
        <TicketProcessInput
          {...{
            label: 'labels.country',
            htmlFor: 'country',
            error: formState.errors.country,
          }}
        >
          <input
            id="country"
            type="text"
            {...register('country', {
              required: { value: true, message: 'errors.countryError' },
            })}
          />
        </TicketProcessInput>

        <TicketProcessCheckbox>
          <input id="vat" type="checkbox" {...register('vat')} />
          <label htmlFor="vat">{formatMessage({ id: 'labels.vat' })}</label>
        </TicketProcessCheckbox>

        {withVat ? (
          <>
            <TicketProcessInput
              {...{
                label: 'labels.company',
                htmlFor: 'company',
                error: formState.errors.company,
              }}
            >
              <input
                id="company"
                type="text"
                {...register('company', {
                  required: { value: true, message: 'errors.companyError' },
                })}
              />
            </TicketProcessInput>
            <TicketProcessInput
              {...{
                label: 'labels.companyNip',
                htmlFor: 'company-nip',
                error: formState.errors.company_nip,
              }}
            >
              <input
                id="company-nip"
                type="text"
                {...register('company_nip', {
                  required: { value: true, message: 'errors.companyNipError' },
                })}
              />
            </TicketProcessInput>

            {/* <TicketProcessCheckbox>
              <input id="copy" type="checkbox" {...register('copy')} />
              <label htmlFor="copy">
                {formatMessage({ id: 'labels.copy' })}
              </label>
            </TicketProcessCheckbox> */}

            <TicketProcessInput
              {...{
                label: 'labels.street',
                htmlFor: 'company-street',
                error: formState.errors.company_street,
              }}
            >
              <input
                id="company-street"
                type="text"
                // disabled={shouldCopy}
                {...register('company_street', {
                  required: { value: true, message: 'errors.streetError' },
                })}
              />
            </TicketProcessInput>
            <TicketProcessInput
              {...{
                label: 'labels.houseNumber',
                htmlFor: 'company-house-number',
                error: formState.errors.company_house_number,
              }}
            >
              <input
                id="company-house-number"
                type="text"
                // disabled={shouldCopy}
                {...register('company_house_number', {
                  required: { value: true, message: 'errors.houseNumberError' },
                })}
              />
            </TicketProcessInput>
            <TicketProcessInput
              {...{
                label: 'labels.apartmentNumber',
                htmlFor: 'company-apartment-number',
                error: formState.errors.company_apartment_number,
              }}
            >
              <input
                id="company-apartment-number"
                type="text"
                // disabled={shouldCopy}
                {...register('company_apartment_number')}
              />
            </TicketProcessInput>
            <TicketProcessInput
              {...{
                label: 'labels.postcode',
                htmlFor: 'company-post-code',
                error: formState.errors.company_postcode,
              }}
            >
              <input
                id="company-post-code"
                type="text"
                // disabled={shouldCopy}
                {...register('company_postcode', {
                  required: { value: true, message: 'errors.postcodeError' },
                })}
              />
            </TicketProcessInput>
            <TicketProcessInput
              {...{
                label: 'labels.city',
                htmlFor: 'company-city',
                error: formState.errors.company_city,
              }}
            >
              <input
                id="company-city"
                type="text"
                // disabled={shouldCopy}
                {...register('company_city', {
                  required: { value: true, message: 'errors.cityError' },
                })}
              />
            </TicketProcessInput>
            <TicketProcessInput
              {...{
                label: 'labels.country',
                htmlFor: 'company-country',
                error: formState.errors.company_country,
              }}
            >
              <input
                id="company-country"
                type="text"
                // disabled={shouldCopy}
                {...register('company_country', {
                  required: { value: true, message: 'errors.countryError' },
                })}
              />
            </TicketProcessInput>
          </>
        ) : null}

        <span className="or-separator" />
        <TicketPriceSummary {...{ price }} />

        {isGroup || isAuthenticated ? (
          <TicketProcessCheckbox {...{ error: formState.errors.terms }}>
            <input
              id="terms"
              type="checkbox"
              {...register('terms', { required: true })}
            />
            <label htmlFor="terms">
              {formatMessage(
                { id: 'labels.terms' },
                {
                  a: ([chunk]) => (
                    <Link
                      target="_blank"
                      to={localizeRouteKey(AppRoute.UserRegulations)}
                    >
                      {chunk}
                    </Link>
                  ),
                }
              )}
            </label>
          </TicketProcessCheckbox>
        ) : null}

        <div className="actions">
          <Button loading={loading}>
            {formatMessage({ id: 'general.summary' })}
          </Button>
          <Button type="button" onClick={handlePrev} bordered>
            {formatMessage({ id: 'general.back' })}
          </Button>
        </div>
      </form>
    </TicketProcessBox>
  )
}

export default TicketPaymentData
