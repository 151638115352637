import { useIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { OffersList } from 'sections'
import { useEffect } from 'react'

function Offers() {
  const { formatMessage } = useIntl()

  useEffect(() => {
    // prevent opening the page
    window.location.href = window.location.origin
    if (window?.callpage) {
      window.callpage('api.button.hide')
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: 'seoTitleOffers' })}</title>
        <meta
          name="description"
          content={formatMessage({ id: 'seoDescriptionOffers' })}
        />
        <meta
          name="keywords"
          content={formatMessage({ id: 'seoKeywordsOffers' })}
        />
        <meta
          property="og:title"
          content={formatMessage({ id: 'seoTitleOffers' })}
        />
        <meta
          property="og:site_name"
          content={formatMessage({ id: 'seoTitleOffers' })}
        />
        <meta
          property="og:description"
          content={formatMessage({ id: 'seoDescriptionOffers' })}
        />
        <meta name="twitter:image:src" content={''} />
        <meta
          name="twitter:title"
          content={formatMessage({ id: 'seoTitleOffers' })}
        />
        <meta
          name="twitter:description"
          content={formatMessage({ id: 'seoDescriptionOffers' })}
        />
      </Helmet>
      {/*<OffersList />*/}
    </>
  )
}

export { Offers }
