import * as React from "react"

function PlayIcon(props) {
  return (
    <svg viewBox="0 0 88 88" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        transform="translate(1 1)"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M36.823 31.779c.23-.073.28-.057.323-.026h0l15.292 11.044a.25.25 0 01.093.277h0L37.146 54.247a.25.25 0 01-.396-.203h0V31.956a.25.25 0 01.073-.177z"
          stroke="#FC86F6"
          fill="#FC86F6"
        />
        <circle stroke="#FFF" cx={43} cy={43} r={24.25} />
        <path
          d="M43 73c16.569 0 30-13.431 30-30 0-16.569-13.431-30-30-30-16.569 0-30 13.431-30 30 0 16.569 13.431 30 30 30z"
          stroke="#FC86F6"
          strokeLinecap="round"
          strokeDasharray="40,140"
        />
        <path
          d="M16.512 28.916C8.733 43.545 14.287 61.71 28.916 69.488c14.629 7.779 32.794 2.225 40.572-12.404 7.779-14.629 2.225-32.794-12.404-40.572-14.629-7.779-32.794-2.225-40.572 12.404z"
          stroke="#FC86F6"
          strokeLinecap="round"
          strokeDasharray="20,160"
        />
        <path
          d="M64.213 21.787c-11.716-11.716-30.71-11.716-42.426 0-11.716 11.716-11.716 30.71 0 42.426 11.716 11.716 30.71 11.716 42.426 0 11.716-11.716 11.716-30.71 0-42.426z"
          stroke="#FC86F6"
          strokeLinecap="round"
          strokeDasharray="5,180"
        />
      </g>
    </svg>
  )
}

export default PlayIcon
