import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'

export const SContainer = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundBlack};
    padding: ${theme.space.xl + 8}px ${theme.space.lg}px;

    ${respondTo.lg`
      padding: 60px ${theme.space.lg}px ${theme.space.xl + 8}px;
    `}

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: ${theme.space.xl + 8}px;

      ${respondTo.lg`
        flex-direction: row;
        align-items: center;
        justify-content: center;
        max-width: 1030px;
        margin: 0 auto;
      `}

      ${respondTo.xl`
        column-gap: 125px;
      `}
    }

    .heading {
      font-size: 1.5rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.875rem;
      color: ${theme.colors.white};
      text-align: center;

      ${respondTo.lg`
        text-align: left;
        font-size: 2.25rem;
        line-height: 3.125rem;
        white-space: nowrap;
      `};

      span {
        color: ${theme.colors.lightPink};
        font-size: 1.75rem;
        line-height: 2.2rem;
        text-shadow: 1px 1px 0 ${theme.colors.azure};

        ${respondTo.lg`
          color: ${theme.colors.white};
          font-size: 3.25rem;
          line-height: 4.125rem; 
          text-shadow: 2px 2px 0 ${theme.colors.azure};
        `}
      }
    }

    .subheader {
      color: ${theme.colors.lightGray2};
      font-size: 1.125rem;
      line-height: 1.56rem;
      margin: ${theme.space.md + 4}px 0 ${theme.space.xl + 8}px;
      text-align: center;

      ${respondTo.lg`
        text-align: left;
        font-size: 1.375rem;
        line-height: 1.875rem;
        font-weight: 500;
        max-width: 505px;
      `}
    }

    img {
      max-width: 252px;

      ${respondTo.lg`
        max-width: 400px;
      `}
    }

    .link-wrapper {
      ${respondTo.lg`
        display: flex;
      `}

      a {
        display: flex;
        align-items: center;
        width: 100%;

        ${respondTo.lg`
          width: auto;
        `}

        i {
          margin-left: ${theme.space.sm}px;
          padding-top: 4px;
        }
      }
    }
    .image-wrapper {
      position: relative;
    }
    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    .positionLeft {
      position: absolute;
      top: 111px;
      left: -44px;
      z-index: 2;
      svg {
        height: 17px;

        .blue-line {
          width: 65px;
        }
      }
    }
    .positionRight {
      position: absolute;
      top: 224px;
      right: 0;
      z-index: -1;
      img {
        width: 125px;
      }
    }
  `}
`
