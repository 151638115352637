import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import client from 'api/client'

const GET_DICTIONARY = 'app/getDictionary'
export const getDictionary = createAsyncThunk(
  GET_DICTIONARY,
  async (_, thunkAPI) => {
    try {
      const response = await client({ endpoint: 'api/meta/all' })
      if (!response.ok) {
        return thunkAPI.rejectWithValue(null)
      }
      const { data } = await response.json()
      return data
    } catch (error) {
      console.log('[GET filtration] error: ', error)
    }
  }
)

const SET_CONTACT_MODAL_VISIBILITY = 'app/contactModalVisibility'
export const setContactModalVisibility = createAction<boolean>(
  SET_CONTACT_MODAL_VISIBILITY
)

const SET_ACTIVITIES_MODAL_VISIBILITY = 'app/activitiesModalVisibility'
export const setActivitiesModalVisibility = createAction<boolean>(
  SET_ACTIVITIES_MODAL_VISIBILITY
)

const SET_HEADER_VISIBILITY = 'app/headerVisibility'
export const setHeaderVisibility = createAction<boolean>(SET_HEADER_VISIBILITY)

const SET_FOOTER_VISIBILITY = 'app/footerVisibility'
export const setFooterVisibility = createAction<boolean>(SET_FOOTER_VISIBILITY)

const SET_POLICY_PERMISSIONS = 'app/policyPermissions'
export const setPolicyPermissions = createAction<object>(SET_POLICY_PERMISSIONS)
