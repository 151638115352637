import VisibilitySensor from 'react-visibility-sensor'
import { animated, Spring } from 'react-spring'
import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-scroll'

import { SlideTop } from '../../components'
import { SContainer } from './ContactUs.styled'
import longBluePattern from '../../assets/patterns/long-blue-pattern.svg'
import { AppRoute } from '../../const'
import useLocalizeRoute from '../../utils/useLocalizeRoute'
import Martyna from '../../assets/images/martyna-toczyska.png'
import Wiktoria from '../../assets/images/wiktoria-lachowska.png'
import LongGreenPattern from '../../assets/patterns/LongGreenPattern'
import twoLinesPattern from '../../assets/patterns/two-lines-pattern.svg'
function ContactUs() {
  const { formatMessage } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()

  return (
    <VisibilitySensor
      minTopValue={15}
      offset={{ top: 400 }}
      scrollCheck
      scrollDelay={5}
      partialVisibility="top"
    >
      {({ isVisible }) => {
        return (
          <SContainer>
            <div className="content">
              <SlideTop>
                <div className="heading-wrapper">
                  <span className="emoticon">👋</span>
                  <h2 className="heading">
                    {formatMessage(
                      { id: 'contactUsHeading' },
                      { span: (chunk) => <span>{chunk}</span> }
                    )}
                  </h2>
                </div>
              </SlideTop>
              <Spring from={{ opacity: 0 }} to={{ opacity: isVisible ? 0 : 1 }}>
                {({ opacity }) => {
                  return (
                    <div className="background">
                      <animated.div
                        style={{
                          opacity,
                          height: '100%',
                        }}
                      >
                        <div className="positionRightMobile">
                          <LongGreenPattern />
                        </div>
                        <div className="positionLeftMobile">
                          <img src={twoLinesPattern} alt="" />
                        </div>
                        <div className="positionRight">
                          <img src={longBluePattern} alt="" />
                        </div>
                      </animated.div>
                    </div>
                  )
                }}
              </Spring>
              <SlideTop>
                <div className="data-wrapper">
                  <div className="learn-more-container">
                    <div className="learn-more-box">
                      <p className="learn-more-box-title">
                        {formatMessage({ id: 'contactUsParticipants' })}
                      </p>
                      <p className="learn-more-box-subtitle">
                        <a href="mailto:kontakt@codeeurope.pl">
                          kontakt@codeeurope.pl
                        </a>
                        {formatMessage({ id: 'contactUsFaq' })}
                      </p>
                      <Link
                        to="faq"
                        smooth
                        offset={-200}
                        className="learn-more-box-link"
                      >
                        {formatMessage({ id: 'learnMore' })}
                        <i className="ri-arrow-right-line" />
                      </Link>
                    </div>
                    <div className="learn-more-box">
                      <p className="learn-more-box-title">
                        {formatMessage({ id: 'contactUsPartners' })}
                      </p>
                      <p className="learn-more-box-subtitle">
                        {formatMessage({ id: 'contactUsJoinPartner' })}
                      </p>
                      <a
                        className="learn-more-box-link"
                        href={localizeRouteKey(AppRoute.BecomePartner)}
                      >
                        {formatMessage({ id: 'learnMore' })}
                        <i className="ri-arrow-right-line" />
                      </a>
                    </div>
                    <div className="learn-more-box">
                      <p className="learn-more-box-title">
                        {formatMessage({ id: 'contactUsSpeakers' })}
                      </p>
                      <p className="learn-more-box-subtitle">
                        {formatMessage({ id: 'contactUsJoinSpeaker' })}
                      </p>
                      <a
                        className="learn-more-box-link"
                        href={localizeRouteKey(AppRoute.BecomeSpeaker)}
                      >
                        {formatMessage({ id: 'learnMore' })}
                        <i className="ri-arrow-right-line" />
                      </a>
                    </div>
                  </div>
                  <div className="contact-info-container">
                    <div className="contact-box">
                      <p className="contact-box-title">
                        {formatMessage({ id: 'contactUsCommunities' })}
                      </p>
                      <p className="contact-box-subtitle">
                        {formatMessage({ id: 'contactUsPartnerships' })}
                      </p>
                      <div className="contact-box-container">
                        <img src={Wiktoria} alt="Wiktoria Lachowska" />
                        <div className="contact-box-personal-info">
                          <span className="contact-box-name">
                            Wiktoria Lachowska
                          </span>
                          <a
                            href="mailto:wiktoria.lachowska@codeeurope.pl"
                            className="contact-box-email"
                          >
                            wiktoria.lachowska@codeeurope.pl
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="contact-box">
                      <p className="contact-box-title">
                        {formatMessage({ id: 'contactUsMedia' })}
                      </p>
                      <div className="contact-box-container">
                        <img src={Martyna} alt="Martyna Toczyska" />
                        <div className="contact-box-personal-info">
                          <span className="contact-box-name">
                            Martyna Toczyska
                          </span>
                          <a
                            href="mailto:martyna.toczyska@codeeurope.pl"
                            className="contact-box-email"
                          >
                            martyna.toczyska@codeeurope.pl
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="contact-box">
                      <p className="contact-box-title">DPO</p>
                      <div className="contact-box-container">
                        <div className="contact-box-icon-wrapper">
                          <i className="ri-user-smile-line" />
                        </div>
                        <div className="contact-box-personal-info">
                          <span className="contact-box-name">
                            Anna Buczyńska
                          </span>
                          <a
                            href="mailto:iod@codeeurope.pl"
                            className="contact-box-email"
                          >
                            iod@codeeurope.pl
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SlideTop>
            </div>
          </SContainer>
        )
      }}
    </VisibilitySensor>
  )
}

export default ContactUs
