const config = {
  ABSOLVENT_API_URL: process.env.REACT_APP_ABSOLVENT_API_URL,
  MAILING_LIST: process.env.REACT_APP_MAILING_LIST,
  GTM_TRACKING_ID: process.env.REACT_APP_GTM,
  HJID: process.env.REACT_APP_HOTJAR_ID,
  HJSV: process.env.REACT_APP_GOTJAR_SV,
  CE_API_URL: process.env.REACT_APP_CODEEUROPE_API_URL,
  APP_ENV: process.env.REACT_APP_ENV,
  BECOME_SPEAKER_FORMSPARK_ID:
    process.env.REACT_APP_BECOME_SPEAKER_FORMSPARK_ID,

  STORAGE_TOKEN_KEY: 'ce21_token',
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
}

export default config
