import styled, { css } from 'styled-components'
import respondTo from 'theme/respondTo'

export const STicketStatus = styled.span`
  ${({ theme, waiting, error }) => css`
    font-size: 0.875rem;
    text-align: right;
    text-decoration: none;

    display: block;

    margin-top: ${theme.space.md}px;

    .download {
      color: ${theme.colors.green};
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    ${waiting &&
    css`
      color: ${theme.colors.lightGray};
    `}

    ${error &&
    css`
      color: ${theme.colors.lightRed};
    `}

    ${respondTo.lg`
        font-size: 1rem;
    `}
  `}
`

export const SUserTickets = styled.div`
  ${({ theme }) => css`
    .loader {
      margin-top: ${theme.space.xl}px;
    }

    .ticket {
      padding: ${theme.space.md}px 0;
      border-bottom: 1px solid #a5a5a5;
    }

    .help {
      margin-top: ${theme.space.md}px;
      p {
        font-size: 0.875rem;
        line-height: 1.43;
        text-align: center;
        color: white;
      }

      a {
        color: ${theme.colors.pink};
      }
    }

    ${respondTo.lg`
        .ticket {
            padding: ${theme.space.lg}px 0;
        }
        .help {
            margin-top: ${theme.space.xl}px;
            p {
                font-size: 1rem;
            }
        }
    `}
  `}
`
