import React from 'react'

import { STicketProcessCheckbox } from './TicketProcessCheckbox.styled'

function TicketProcessCheckbox({ error, children }) {
  return (
    <STicketProcessCheckbox {...{ error }}>{children}</STicketProcessCheckbox>
  )
}

export default TicketProcessCheckbox
