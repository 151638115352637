import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'
import background from '../../assets/images/net-hero-background.svg'

import { HEADER_HEIGHT } from 'components/Header/Header'

export const SContainer = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.backgroundBlack};
    overflow: hidden;
    position: relative;

    padding: calc(60px + ${HEADER_HEIGHT}) ${theme.space.lg}px
      ${theme.space.xl + 8}px;
    margin-top: -${HEADER_HEIGHT};

    ${respondTo.lg`
      padding: calc(62px + ${HEADER_HEIGHT}) ${theme.space.lg}px 120px;
      background-image: url(${background});
      background-repeat: no-repeat;
      background-size: contain;
    `}

    .content {
      max-width: 799px;
      margin: 0 auto;
    }

    .heading {
      font-size: 1.5rem;
      line-height: 1.875rem;
      font-weight: ${theme.weight.bold};
      color: white;
      text-align: center;

      ${respondTo.lg`
        font-size: 2.25rem;
        line-height: 2.875rem;
      `}

      span {
        display: block;
        font-size: 1.75rem;
        line-height: 2.188rem;
        color: ${theme.colors.lightPink};
        text-shadow: 1px 1px 0 ${theme.colors.azure};

        ${respondTo.lg`
          font-size: 3.25rem;
          line-height: 4.125rem;
          color: ${theme.colors.white};
          text-shadow: 2px 2px 0 ${theme.colors.azure};
        `}
      }
    }

    .subheader {
      font-size: 1.125rem;
      line-height: 1.56rem;
      color: ${theme.colors.lightGray2};
      text-align: center;
      margin-top: ${theme.space.xl + 8}px;

      ${respondTo.lg`
         margin-top: ${theme.space.md + 4}px;
         font-size: 1.375rem;
         line-height: 1.875rem;
      `}

      span {
        color: ${theme.colors.white};
        font-weight: ${theme.weight.bold};
      }
    }

    .hero-image {
      width: 100%;
    }
    .divider-horizontal {
      background-color: ${theme.colors.lightGray};
      width: 80px;
      height: 1px;
      border: none;
      margin: ${theme.space.md + 4}px auto;
    }
    .description {
      color: ${theme.colors.lightGray2};
      font-size: 0.875rem;
      line-height: 1.188rem;
      text-align: center;

      ${respondTo.lg`
         font-size: 1rem;
         line-height: 1.375rem;
      `}
    }
    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
    }

    .positionLeft {
      position: absolute;
      top: 222px;
      left: -38px;

      ${respondTo.lg`
        top: 220px;
        left: 0;
      `}

      img {
        height: 17px;
        ${respondTo.lg`
           height: 38px;
        `}
      }
    }
    .positionRight {
      position: absolute;
      top: 323px;
      right: -62px;

      ${respondTo.lg`
        top: 657px;
        right: -93px;
      `}
      svg {
        height: 17px;
        ${respondTo.lg`
           height: 38px;
        `}
      }
    }
  `}
`
