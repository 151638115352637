import styled, { css } from 'styled-components'

export const STicket = styled.div`
  ${({ theme, color, isDisabled }) => css`
    max-width: 245px;
    width: 100%;
    min-height: 418px;
    flex-shrink: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    font-size: 0.75rem;
    color: ${theme.colors.lightGray};

    position: relative;

    padding: ${theme.space.lg}px;
    overflow: hidden;

    &:hover {
      box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
        0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 16px 24px 2px rgba(0, 0, 0, 0.14);
    }

    ${
      isDisabled &&
      css`
        pointer-events: none;
      `
    }

    .lines,
    .shape {
      height: 100%;
      position: absolute;

      top: 0;
      left: 0;

      pointer-events: none;
      touch-action: none;
    }

    .shape {
      width: 100%;
    }

    .text-content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    .content {
      position: relative;
      z-index: 1;
    }

    .price {
      span:not(.vat) {
        font-size: 3rem;
        font-weight: ${theme.weight.bold};
        color: ${color};
        -webkit-text-stroke: 1px ${theme.colors.lightGray};
        
        ${
          isDisabled &&
          css`
            color: transparent;
            -webkit-text-stroke: 1px ${theme.colors.lightGray};
          `
        }
      }

      span {
        font-size: 0.625rem;
        -webkit-text-stroke: 0;

        color: ${theme.colors.lightGray};
      }
    }
    
    .pass-type {
      font-size: 1.1rem;
      margin-top: ${theme.space.sm}px;
      margin-bottom: ${theme.space.md}px;
      color: white;
      
      ${
        isDisabled &&
        css`
          color: ${theme.colors.lightGray};
        `
      }
    }

    .date-and-place {
      margin-top: ${theme.space.md}px;
    }

    .info {
      display: flex;
      align-items: center;

      font-size: 0.875rem;
      color: white;

      margin-top: ${theme.space.sm}px;

      ${
        isDisabled &&
        css`
          color: ${theme.colors.lightGray};
        `
      }
      
      svg {
        width: 11px;

        color: ${color};

        margin-top: 2px;
        margin-right: ${theme.space.sm}px;
        
        ${
          isDisabled &&
          css`
            color: ${theme.colors.lightGray};
          `
        }
      }
    }

    .benefits {
      font-size: 0.8rem;
      line-height: 1.2;
      color: white;

      margin-top: ${theme.space.lg}px;
      
      ${
        isDisabled &&
        css`
          color: ${theme.colors.lightGray};
        `
      }
      
      ul {
        margin-top: ${theme.space.md}px;

        li {
          position: relative;

          padding-left: 15px;
          margin-top: ${theme.space.sm}px;

          &::before {
            content: '';
            width: 4px;
            height: 4px;

            position: absolute;
            top: 6px;
            left: 0;

            background-color: white;
            
            ${
              isDisabled &&
              css`
                background-color: ${theme.colors.lightGray};
              `
            }
          }
        }
      }
    }

    .select {
      width: 100%;
      height: 40px;

      align-self: flex-end;

      font-weight: ${theme.weight.bold};
      color: ${color};

      border: 1px solid ${color};
      background-color: ${theme.colors.darkestGray};

      position: relative;
      margin: 0;
      margin-top: ${theme.space.md}px;
      padding: 0;

      cursor: pointer;
      z-index: 1;

      ${
        isDisabled &&
        css`
          color: ${theme.colors.lightGray};
          border-color: ${theme.colors.lightGray};
          cursor: not-allowed;
        `
      }
    }
    
    .buttons {
      z-index: 2;
      margin-top: ${theme.space.lg}px;
    }

    .sold-out {
      font-weight: ${theme.weight.bold};
      color: white;
      height: 40px;
      font-size: 1rem;
      margin-top: ${theme.space.md}px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .counter {
      height: 40px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: ${theme.space.lg}px;

      font-size: 1rem;
      font-weight: ${theme.weight.bold};
      color: ${color};

      position: relative;

      border: 1px solid ${color};
      background-color: ${theme.colors.darkestGray};

      z-index: 1;

      ${
        isDisabled &&
        css`
          color: ${theme.colors.lightGray};
          border-color: ${theme.colors.lightGray};
          cursor: not-allowed;
        `
      }
    }

    button {
      width: 40px;
      height: 40px;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      color: ${color};

      margin: 0;
      padding: 0;

      box-shadow: none;
      border: none;
      background: none;
      cursor: pointer;
      
      ${
        isDisabled &&
        css`
          color: ${theme.colors.lightGray};
          border-color: ${theme.colors.lightGray};
          cursor: not-allowed;
        `
      }

        svg {
          width: 10px;
          height: 10px;
        }

        &:first-of-type {
          svg {
            height: 2px;
          }
        }
      }
    }
  `}
`
