import styled, { css } from 'styled-components'
import respondTo from '../../theme/respondTo'
import background from '../../assets/images/net-left-background.svg'

export const SContainer = styled.div`
  ${({ theme }) => css`
    background-position: 25% 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${background});

    ${respondTo.lg`
       background-position: center top;
    `}

    .content {
      padding: ${theme.space.xl + 8}px ${theme.space.lg}px;
      margin: 0 auto;

      ${respondTo.lg`
        padding: 80px 0;
        max-width: 930px;
      `}
    }

    .heading {
      font-size: 1.5rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.875rem;
      color: ${theme.colors.white};
      text-align: center;
      max-width: 194px;
      margin: 0 auto ${theme.space.xl + 8}px;

      ${respondTo.lg`
        max-width: 100%;
        font-size: 2.25rem;
        line-height: 3.125rem;
        margin: 0 auto 60px;
      `};
    }

    .buttons-container {
      display: flex;
      flex-direction: column;

      & > a:last-of-type {
        margin-top: ${theme.space.lg}px;
      }

      a {
        display: flex;
        align-items: center;
        &.disable-link {
          background-color: ${theme.colors.white};
          cursor: not-allowed;
          color: ${theme.colors.disabledLink};
          pointer-events: none;

          &:hover {
            background-color: ${theme.colors.white};
            color: ${theme.colors.disabledLink};
          }
        }
      }

      i {
        margin-left: ${theme.space.sm}px;
        padding-top: 4px;
      }

      ${respondTo.lg`
        flex-direction: row;
        max-width: 525px;
        margin: 60px auto 0;
  
        & > a:first-of-type {
          margin-right: ${theme.space.xl + 8}px;
          flex: 1;
        }
  
        & > a:last-of-type {
          margin-top: 0;
        }
      `}
    }
  `}
`
