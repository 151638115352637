import React from 'react'
import { useIntl } from 'react-intl'

import { Button, SlideTop } from '../../components'
import { SContainer } from './Activities.styled'

import activities from '../../assets/images/home-activities.png'
import { setActivitiesModalVisibility } from '../../state/reducers/app/appActions'
import { useDispatch } from 'react-redux'

function Activities() {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const openActivitiesModal = () => {
    dispatch(setActivitiesModalVisibility(true))
  }

  return (
    <SContainer>
      <div className="wrapper">
        <SlideTop>
          <h2 className="heading">
            {formatMessage({ id: 'activitiesHeading' })}
          </h2>
        </SlideTop>
        <SlideTop>
          <div className="content">
            <img src={activities} alt="" />
            <div className="description">
              <p className="description-subheading">
                {formatMessage({ id: 'activitiesSubHeading' })}
              </p>
              <h3 className="description-schedule">
                {formatMessage({ id: 'activitiesSchedule' })}
              </h3>
              <p className="description-text">
                {formatMessage(
                  {
                    id: 'activitiesDescription',
                  },
                  {
                    br: <br />,
                  }
                )}
              </p>
              <Button
                secondary="true"
                height="52px"
                onClick={openActivitiesModal}
              >
                {formatMessage({
                  id: 'learnMore',
                })}
                <i className="ri-arrow-right-line" />
              </Button>
            </div>
          </div>
        </SlideTop>
      </div>
    </SContainer>
  )
}

export default Activities
